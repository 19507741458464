const breakVersion = (ver) => ver.substring(1).split(".");

export const compareVersions = (ver1, ver2) => {
  if (ver1 === ver2) return 0;
  const broken1 = breakVersion(ver1);
  const broken2 = breakVersion(ver2);
  return (
    broken1.reduce((resp, subver, i) => {
      if (resp !== null) return resp;
      if (subver === broken2[i]) {
        return null;
      } else if (subver < broken2[i]) {
        return -1;
      } else {
        return 1;
      }
    }, null) || 0
  );
};
