import { FORMAT_SPECIMEN, FORMAT_SPECIMENS } from "../actions/types";
import _ from "lodash";
import { formatSpecimen } from "../utils/reducerHelpers";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
  let old;
  switch (action.type) {
    // case EDIT_SPECIMEN:
    case FORMAT_SPECIMEN:
      old = { ...state?.[action.payload?.id] };
      return {
        ...state,
        [action.payload?.id]: formatSpecimen(old, action.payload),
      };
    case FORMAT_SPECIMENS:
      return {
        ...state,
        ..._.mapKeys(
          action.payload?.map((e) => formatSpecimen({ ...state?.[e?.position] }, e)),
          "position"
        ),
      };
    default:
      return state;
  }
};
