import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import advanced from "dayjs/plugin/advancedFormat";
import timeZoneOptions from "./files/timezones.json"

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advanced)

// const timeToUnix = (timeStr) => {
//   return dayjs.utc(timeStr, "YYYY-MM-DDTHH:MM:SS.SSS").local().toDate();
// };

export const timeToLocale = (timeStr, options = {}) => {
  const {
    timeZone = null,
    customFormat = 'M/DD/YYYY, h:mm A'
  } = options

  const curTimeZone = timeZoneOptions.find((val) => val.value === timeZone)?.utc?.[0]
  const timeZoneModify = (!!curTimeZone) ? curTimeZone : dayjs.tz.guess()
  return dayjs.utc(timeStr, "YYYY-MM-DDTHH:MM:SS.SSS").tz(timeZoneModify).format(customFormat)

  // return dayjs.utc(timeStr, "YYYY-MM-DDTHH:MM:SS.SSS").tz(timeZoneModify).format('M/DD/YYYY, h:mm A')
  // const date = timeToUnix(timeStr);
  // console.log(date.toLocaleDateString("en-US", {
  //   year: "numeric",
  //   month: "numeric",
  //   day: "numeric",
  //   hour: "numeric",
  //   minute: "numeric",
  // }))
  // return date.toLocaleDateString("en-US", {
  //   year: "numeric",
  //   month: "numeric",
  //   day: "numeric",
  //   hour: "numeric",
  //   minute: "numeric",
  // });
};

export const processDateTimePicker = (timeStr, timeZone) => {
  const curTimeZone = timeZoneOptions.find((val) => val.value === timeZone)?.utc?.[0]
  const phrasedTimeStr = String(timeStr).split(' ').slice(0, 5).join(' ') + ' GMT+000'
  let phrasedTime;

  dayjs.tz.setDefault(curTimeZone)
  phrasedTime = dayjs.tz(phrasedTimeStr).utc().format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z'
  dayjs.tz.setDefault()

  return phrasedTime;
}

export const twoDigitYearAutoFill = (dateStr) => {
  let curDate = new Date(dateStr)
  let curYear = curDate.getUTCFullYear()
  let tmpDate = new Date(dateStr)
  console.log(dateStr)
  console.log(tmpDate)

  if (curYear >= 0 && curYear <= 99) {
    tmpDate.setFullYear(curYear + 2000)
    if (tmpDate > new Date()) {
      tmpDate.setFullYear(curYear + 1900)
    }
  }
  return tmpDate
}