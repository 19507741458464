import { processDateTimePicker } from "./timeConversion";
import timeZoneOptions from './files/timezones.json'
import _ from 'lodash'

export const phraseDownloadDataFilter = (dataFilters, timeZone) => {
    const {
        deviceID,
        imagingStartDate,
        imagingEndDate,
        specimenCaptureStartDate,
        specimenCaptureEndDate,
        genusSpecies,
        plates,
        trapSitesFilter,
        trapTypesFilter,
        algorithmGenusSpeciesFilter,
        usersFilter,
        sexFilter,
        algorithmSexFilter,
        specimenTagFilter,
        algConfidence,
    } = dataFilters

    let filters = {}
    const curTimeZone = timeZoneOptions.find((val) => val.value === timeZone)?.utc?.[0]
    if (timeZone === 'AUTO') {
        filters = { timezone: Intl.DateTimeFormat().resolvedOptions().timeZone }
    }
    else {
        filters = { timezone: curTimeZone }
    }
    if (deviceID.length > 0) {
        filters = {
            ...filters,
            tools: deviceID.map((device) => device.uuid),
        };
    }
    if (usersFilter.length > 0) {
        filters = {
            ...filters,
            users: usersFilter,
        };
    }
    if (genusSpecies.length > 0) {
        filters = {
            ...filters,
            contribCls: _.flatten(
                genusSpecies.map(({ value }) => value),
                false
            ),
        };
    }
    if (algorithmGenusSpeciesFilter.length > 0) {
        filters = {
            ...filters,
            algCls: _.flatten(
                algorithmGenusSpeciesFilter.map(({ value }) => value),
                false
            ),
        };
    }
    if (plates.length > 0) {
        filters = {
            ...filters,
            plates,
        };
    }
    if (trapSitesFilter.length > 0) {
        filters = {
            ...filters,
            trapSites: trapSitesFilter.map((trapSite) => trapSite.id),
        };
    }
    if (trapTypesFilter.length > 0) {
        filters = {
            ...filters,
            trapTypes: trapTypesFilter,
        };
    }

    if (sexFilter.length > 0) {
        filters = {
            ...filters,
            contribSex: sexFilter.map((c) => c?.toLowerCase()
            ),
        };
    }
    if (algorithmSexFilter.length > 0) {
        filters = {
            ...filters,
            algSex: algorithmSexFilter,
        };
    }
    if (specimenTagFilter.length > 0) {
        filters = {
            ...filters,
            specimenTag: specimenTagFilter
        }
    }

    if (imagingStartDate != null) {
        filters = {
            ...filters,
            imagingStartTime: imagingStartDate ? processDateTimePicker(imagingStartDate, timeZone) : imagingStartDate,
        };
    }
    if (imagingEndDate != null) {
        filters = {
            ...filters,
            imagingEndTime: imagingEndDate ? processDateTimePicker(imagingEndDate, timeZone) : imagingEndDate,
        };
    }

    if (specimenCaptureStartDate != null) {
        filters = {
            ...filters,
            specimenCaptureStartTime: specimenCaptureStartDate ? processDateTimePicker(specimenCaptureStartDate, timeZone) : specimenCaptureStartDate,
        };
    }
    if (specimenCaptureEndDate != null) {
        filters = {
            ...filters,
            specimenCaptureEndTime: specimenCaptureEndDate ? processDateTimePicker(specimenCaptureEndDate, timeZone) : specimenCaptureEndDate,
        };
    }

    filters = {
        ...filters,
        algThreshold: dataFilters.algThreshold,
        excludeUnknown: dataFilters.excludeUnknown,
        excludeEmpty: dataFilters.excludeEmpty,
        excludeAlgorithmUnsure: dataFilters.excludeAlgorithmUnsure,
        algConfidence: algConfidence / 100,
    };

    return filters
}