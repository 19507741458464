import React from "react";
import SideBar from "./SideBar";
import { useHistory, useLocation } from "react-router-dom";
import routes from "../../AppRouter/routes";

const Index = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const handleChange = (event, newValue) => {
    console.log(newValue)
    if (newValue === '/details' && pathname.includes('tray')) {
      return
    }
    history.push(newValue);
  };

  const getPathRoot = ({ pathname }) => {
    //console.log(pathname)
    if (pathname === '/') {
      history.push(routes.traysTab)
      return
    }
    if (pathname !== undefined) {
      const pathRoot = `/${pathname.split("/")[1]}`;
      if (
        pathRoot !== "/data" &&
        pathRoot !== "/details" &&
        pathRoot !== "/trays"
      ) {
        return "/trays";
      }
      return pathRoot;
    }
  };

  return (
    <SideBar
      onChange={handleChange}
      pathname={pathname}
      getPathRoot={getPathRoot}
    />
  );
};

export default Index;
