import {
  genusSpeciesPattern,
  OverwriteOptionsEnum,
  DuplicateOptionsEnum,
} from "../../utils/const";
import _ from "lodash";

export const getNextPlate = (plate) => {
  if (plate) return plate;
};

export const getNextWell = (well) => {
  if (well === '') {
    return ""
  }
  if (well) {
    let wellNumber = parseInt(well.substring(1, well.length));
    let wellLetter = well.substring(0, 1);
    if (
      !(wellLetter.charCodeAt(0) === "H".charCodeAt(0) && wellNumber === 12)
    ) {
      // H12 resets well
      if (wellNumber === 12) {
        const nextLetter = String.fromCharCode(wellLetter.charCodeAt(0) + 1);
        return `${nextLetter}01`;
      } else {
        const newNumber = wellNumber + 1;
        const newNumberStr = newNumber < 10 ? `0${newNumber}` : `${newNumber}`;
        return `${wellLetter}${newNumberStr}`;
      }
    }
    return null;
  }
};

export const processGenusSpecies = (genusSpecies) => {
  if (genusSpecies === "" || genusSpecies === undefined) {
    return {
      genus: "",
      species: "",
    };
  } else if (genusSpecies) {
    const regexResult = genusSpeciesPattern.exec(genusSpecies);
    if (regexResult) {
      const genus = regexResult?.[1];
      let species = regexResult?.[2];
      if (genus === "0")
        return {
          genus: "",
          species: "",
        };
      if (genus && !species?.trim()) {
        species = "spp";
      }
      return {
        genus,
        species,
      };
    }
  }
  return {};
};

export const formatCaptureData = (specimenId, result) => {
  let humanId = {};

  if (result?.genus && result?.species) {
    humanId = {
      genus: result.genus,
      species: result.species,
      specimenId: specimenId,
    };
  } else {
    humanId = {
      genus: "",
      species: "",
      specimenId: specimenId,
    };
  }

  humanId["sex"] = result?.sex ? result.sex : null;
  humanId["gonotrophic"] = result?.gonotrophic;
  humanId["lifeStage"] = result?.lifeStage;

  let molecularId = {};
  if (result?.plate && result?.well) {
    molecularId = {
      plate: result.plate,
      well: result.well,
      specimenId: specimenId,
    };
  } else {
    molecularId = {
      plate: "",
      well: "",
      specimenId: specimenId,
    };
  }

  return { humanId, molecularId, institutionId: result.institutionId, tags: result.tags };
};

export const updateAutoFillData = (
  specimenArray,
  specimenNum,
  autofillSettings,
  wellAutocompleteOptions,
  duplicateWellsChoice,
  overwriteChoice,
  lastAutofillIdx,
  specimenPosList
) => {
  let updatedMetaData = _.cloneDeep(specimenArray)
  let transformedData = {};
  let batchMetadata = {};
  let cur = specimenArray[specimenNum]

  // generate species data
  if (autofillSettings.speciesChecked) {
    let fillValue = cur.genusSpecies || "";
    if (fillValue || fillValue === "") {
      let { genus, species } = processGenusSpecies(fillValue);
      batchMetadata[cur.id] = {
        humanId: { genus, species },
        specimenId: cur.id,
      };
      for (let i = 1; i <= lastAutofillIdx; ++i) {
        // skip overwrites if necessary
        let nextPosition = specimenNum + i
        let nextId = specimenArray[nextPosition]?.id;
        if (nextId === undefined) {
          continue;
        }
        if (
          overwriteChoice === OverwriteOptionsEnum.skip &&
          specimenArray[nextPosition]?.genusSpecies
        ) {
          continue;
        }

        //skip empty
        if (autofillSettings?.skipEmpty && specimenArray[nextPosition]?.empty) {
          continue;
        }

        transformedData[nextPosition] = {
          humanId: { genus, species, specimenId: nextId },
        };
        updatedMetaData[nextPosition] = { ...updatedMetaData[nextPosition], genusSpecies: fillValue };
        batchMetadata[nextId] = {
          humanId: { genus, species },
          specimenId: nextId,
        };
      }
    }
  }

  let sexData = {};
  let sexDataForUpload = {};
  //generate sex data
  if (autofillSettings.sexChecked) {
    let sex = cur.sex;
    sexDataForUpload[cur.id] = {
      humanId: { sex },
      specimenId: cur.id,
    };

    for (let i = 1; i <= lastAutofillIdx; ++i) {
      // skip overwrites if necessary
      let nextPosition = specimenNum + i
      let nextId = specimenArray[nextPosition]?.id;
      if (nextId === undefined) {
        continue;
      }
      if (
        overwriteChoice === OverwriteOptionsEnum.skip &&
        specimenArray[nextPosition]?.sex
      ) {
        continue;
      }
      //skip empty
      if (autofillSettings?.skipEmpty && specimenArray[nextPosition]?.empty) {
        continue;
      }
      sexData[nextPosition] = {
        humanId: { sex, specimenId: nextId },
      };
      updatedMetaData[nextPosition] = { ...updatedMetaData[nextPosition], sex: sex };
      sexDataForUpload[nextId] = {
        humanId: { sex },
        specimenId: nextId,
      };
    }
    transformedData = _.defaultsDeep(sexData, transformedData);
    batchMetadata = _.defaultsDeep(sexDataForUpload, batchMetadata);
  }

  let lifeStageData = {};
  let lifeStageForUpload = {};
  //generate life Stage data
  if (autofillSettings.lifeStageChecked) {
    let lifeStage = cur?.lifeStage;
    lifeStageForUpload[cur.id] = {
      humanId: { lifeStage },
      specimenId: cur.id,
    };

    for (let i = 1; i <= lastAutofillIdx; ++i) {
      // skip overwrites if necessary
      let nextPosition = specimenNum + i
      let nextId = specimenArray[nextPosition]?.id;
      if (nextId === undefined) {
        continue;
      }
      if (
        overwriteChoice === OverwriteOptionsEnum.skip &&
        specimenArray[nextPosition]?.lifeStage
      ) {
        continue;
      }
      //skip empty
      if (autofillSettings?.skipEmpty && specimenArray[nextPosition]?.empty) {
        continue;
      }
      lifeStageData[nextPosition] = {
        humanId: { lifeStage, specimenId: nextId },
      };
      updatedMetaData[nextPosition] = { ...updatedMetaData[nextPosition], lifeStage: lifeStage };
      lifeStageForUpload[nextId] = {
        humanId: { lifeStage },
        specimenId: nextId,
      };
    }
    transformedData = _.defaultsDeep(lifeStageData, transformedData);
    batchMetadata = _.defaultsDeep(lifeStageForUpload, batchMetadata);
  }

  let gonotrophicData = {};
  let gonotrophicDataForUpload = {};
  //generate gonotrophic data
  if (autofillSettings.gonotrophicChecked) {
    let gonotrophic = cur.gonotrophic;
    gonotrophicDataForUpload[cur.id] = {
      humanId: { gonotrophic },
      specimenId: cur.id,
    };

    for (let i = 1; i <= lastAutofillIdx; ++i) {
      // skip overwrites if necessary
      let nextPosition = specimenNum + i
      let nextId = specimenArray[nextPosition]?.id;
      if (nextId === undefined) {
        continue;
      }
      if (
        overwriteChoice === OverwriteOptionsEnum.skip &&
        specimenArray[nextPosition]?.gonotrophic
      ) {
        continue;
      }
      //skip empty
      if (autofillSettings?.skipEmpty && specimenArray[nextPosition]?.empty) {
        continue;
      }
      gonotrophicData[nextPosition] = {
        humanId: { gonotrophic, specimenId: nextId },
      };
      updatedMetaData[nextPosition] = { ...updatedMetaData[nextPosition], gonotrophic: gonotrophic };
      gonotrophicDataForUpload[nextId] = {
        humanId: { gonotrophic },
        specimenId: nextId,
      };
    }
    transformedData = _.defaultsDeep(gonotrophicData, transformedData);
    batchMetadata = _.defaultsDeep(gonotrophicDataForUpload, batchMetadata);
  }

  let plateWellData = {};
  let plateWellDataForUpload = {};
  // generate plate and well data
  if (autofillSettings.plateAndWellChecked) {
    let fillPlate = cur.plate || "";
    let fillWell = cur.well || "";
    if (fillPlate || fillPlate === "") {
      plateWellDataForUpload[cur.id] = {
        molecularId: { plate: fillPlate, well: fillWell },
        specimenId: cur.id,
      };
      for (let i = 1; i <= lastAutofillIdx; ++i) {
        let nextPosition = specimenNum + i
        let nextId = specimenArray[nextPosition]?.id;
        if (nextId === undefined) {
          continue;
        }
        // skip overwrites if necessary
        if (
          overwriteChoice === OverwriteOptionsEnum.skip &&
          specimenArray[nextPosition]?.well
        ) {
          continue;
        }

        //skip empty
        if (autofillSettings?.skipEmpty && specimenArray[nextPosition]?.empty) {
          continue;
        }

        fillWell = getNextWell(fillWell);
        if (duplicateWellsChoice === DuplicateOptionsEnum.skip) {
          // skip duplicate wells
          while (fillWell && !wellAutocompleteOptions.includes(fillWell)) {
            fillWell = getNextWell(fillWell);
          }
        }
        if (fillWell || fillWell === "") {
          plateWellData[nextPosition] = {
            molecularId: {
              plate: fillPlate,
              well: fillWell,
              specimenId: nextId,
            },
          };
          updatedMetaData[nextPosition] = { ...updatedMetaData[nextPosition], plate: fillPlate, well: fillWell };
          plateWellDataForUpload[nextId] = {
            molecularId: { plate: fillPlate, well: fillWell },
            specimenId: nextId,
          };
        } else {
          console.warn("ran out of wells");
        }
      }
    }
    transformedData = _.defaultsDeep(plateWellData, transformedData);
    batchMetadata = _.defaultsDeep(plateWellDataForUpload, batchMetadata);
  }

  return { updatedMetaData, transformedData, batchMetadata };
};

export const specimenAutoFillDataSubmit = async (
  value,
  specimenNum,
  autofillSettings,
  trayId,
  selectedDevice,
  updateSpecimenAutoMetadata,
  uploadSpecimenBatchMetadata,
  handleFieldUpdate,
  updateSpecimenCaptureMetadata,
  wellAutocompleteOptions,
  setMetadata,
  duplicateWellsChoice,
  overwriteChoice,
  lastAutofillIdx,
  specimenPosList,
  setSpecimensArray
) => {
  let { updatedMetaData, transformedData, batchMetadata } =
    updateAutoFillData(
      value,
      specimenNum,
      autofillSettings,
      wellAutocompleteOptions,
      duplicateWellsChoice,
      overwriteChoice,
      lastAutofillIdx,
      specimenPosList
    );

  if (Object.keys(transformedData).length !== 0) {
    //update state
    setSpecimensArray(updatedMetaData);
    //update redux
    await updateSpecimenAutoMetadata(trayId, selectedDevice, transformedData);
    //update capture
    await updateSpecimenCaptureMetadata(transformedData);
    console.log(transformedData)
    // //update component
    // await handleFieldUpdate(updatedMetaData);
    //upload to the sever
    await uploadSpecimenBatchMetadata(trayId, { batchMetadata });
    console.log(batchMetadata)
  }

};
