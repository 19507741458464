const licenseText = `
VECTECH, LLC TERMS AND CONDITIONS OF USE


Welcome to Vectech! Vectech builds technologies to help organizations, communities, and people detect and monitor disease-carrying insects. By utilizing Vectech’s websites at mos-id.vectech.io or www.vectech.io, you consent to the following terms and conditions of use, these Terms of Use (“Terms”). As noted below, Vectech reserves the right to modify these Terms at any time. Modifications to these Terms will be posted on this page, and use of the Vectech website following any such modifications constitutes your express acceptance of the revised Terms then in effect.

This website including all content, features, services, SMS notifications, APIs, email notifications, applications, buttons, widgets and other products or services ordered or provided (collectively, the “Site”) is a service made available by Vectech and may be used solely under these Terms. 

Our intellectual property

All content, software, and functionality on the Site and other materials uploaded, downloaded or appearing on the Site by Vectech, including text, graphics, logos, photos, button icons, images, digital downloads, data compilations, and software and the selection and arrangement thereof (the “Services”) and the trademarks, service marks, designs, and logos (collectively, the “Trademarks”) are the exclusive property of Vectech. or its licensors and are protected by copyright, trademark, and other laws of both the United States and foreign countries. Nothing in the Terms gives you a right to use the Vectech name or any of the Trademarks, logos, domain names, and other distinctive brand features or any confusingly similar Trademarks. You must obtain our written permission to modify, create derivative works of, decompile, or otherwise attempt to extract source code from us.

License grant by users

We need certain permissions to continuously improve our Site for customers like you. By using the Site, including without limitation, by uploading images, adding data, information, text, software, or other materials (“User Submitted Data”) to the Site or use relating to, in connection with or arising out of your use of the Site, you grant Vectech a nonexclusive, irrevocable, worldwide, perpetual, unlimited, assignable, sublicensable, fully paid-up and royalty-free right to use, copy, prepare derivative works of, improve, distribute, publish, remove, retain, add, process, analyze, use and commercialize such User Submitted data, and including after your termination of your account or the services. You represent and warrant that you have all the rights, power and authority for yourself and/or the entity you represent, or are the agent of or associated with or acting on behalf of, and that entity has authorized you to grant the licenses in this section. 

Representations and Warranties of Beta Test

While our (very small!) staff does their best to ensure the Services you access will be accurate, Vectech does not represent or warrant that this Site is or will be error-free or that defects will be corrected or that it will always be accessible. Our Site and products are in beta testing and we expect errors from time to time. Vectech does not warrant or represent that the Site will be correct, accurate, timely, or otherwise reliable. Vectech may make improvements and/or changes to the Site’s features, functionality or content at any time. You acknowledge that all Services accessed by you are at your own risk, and should be interpreted in consultation with a medical entomology professional. You will be solely responsible for any damage or loss to you or any other party resulting therefrom.

User restrictions and responsibilities

You are responsible for your use of the Site and for any data you submit to the Site. You should only provide data that you are comfortable sharing under these Terms. You are responsible for maintaining pertinent confidentiality of all Vectech accounts, and any data uploaded by you. Do not share any Vectech passwords or other account access information with any other party, or do anything else that might jeopardize the security of any Vectech account. You are responsible for all uses of Vectech registrations and Vectech passwords, whether or not authorized by you. You agree not to make any unauthorized use of the Site. You agree to immediately notify Vectech of any unauthorized use of your Vectech account or passwords. Vectech may un-sync, reset or terminate Vectech Passwords in its sole discretion or any reason. Vectech cannot and will not be liable for any loss or damage arising from your failure to comply with the above.

Governing law

These Terms shall be governed by and construed in accordance with the laws of the State of Delaware and subject to the exclusive jurisdiction of the federal and state courts located in Baltimore City, Maryland


Modifications to terms

Vectech may revise these Terms at any time and will notify you by posting an updated version to this Site. We recommend that you visit this page periodically to review the most current Terms because they are binding on you. Your continued use of the Site following changes to these Terms or any other policies incorporated herein constitutes your acceptance of our amended Terms or policies.


Effective July 30, 2021

`;
export default licenseText;
