import {
  SELECT_SUPERUSER_ORG,
  CHANGE_SUPERUSER_ORG_FILTER,
  CHANGE_SUPERUSER_USER_FILTER,
  CHANGE_SUPERUSER_DEVICE_FILTER,
} from "../actions/types";
import _ from "lodash";

export const DEFAULT_SUPERUSER_SETTINGS = {
  orgFilter: "",
  deviceFilter: "",
  userFilter: "",
  selected: "",
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = DEFAULT_SUPERUSER_SETTINGS, action) => {
  switch (action.type) {
    case SELECT_SUPERUSER_ORG:
      return { ..._.defaultsDeep(action.payload, state) };
    case CHANGE_SUPERUSER_ORG_FILTER:
      return { ..._.defaultsDeep(action.payload, state) };
    case CHANGE_SUPERUSER_USER_FILTER:
      return { ..._.defaultsDeep(action.payload, state) };
    case CHANGE_SUPERUSER_DEVICE_FILTER:
      return { ..._.defaultsDeep(action.payload, state) };
    default:
      return state;
  }
};
