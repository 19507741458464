import {
  FETCH_ORGANIZATION_FILTERS,
  FETCH_PLATE_WELLS,
  UPDATE_SPECIMEN_TAG_OPTIONS,
  UPDATE_ALGORITHM_CONFIDENCE
} from "../actions/types";
import { camelify, capitalize } from "./../utils/caseConvert";
import _ from "lodash";

const generateGenusSpeciesWildCard = (origData) => {
  if (Array.isArray(origData)) {
    const data = origData.map(({ genus, species }, i) => ({
      genus: capitalize(genus),
      species: species ? species.toLowerCase() : "spp",
      i: [i],
    }));

    const genusSpecies = data
      .map((item) => item.genus)
      .filter((item) => item && item.length > 0)
      /*eslint no-new-object: "off"*/
      .map((g) => new Object({ genus: g, species: "spp", i: [] }));

    // reduce step gathers old values that got wild carded to the same value (ie: different capitalizations of the same genus/species etc)
    // filter step filters dupes
    const filtered = [...genusSpecies, ...data]
      .reduce((ret, v, i, a) => {
        const predicate = (t) =>
          t.genus === v.genus && t.species === v.species && t.genus;
        const idxs = a.reduce(
          (arr, e) => (predicate(e) ? _.uniq([...arr, ...e.i]) : arr),
          []
        );
        return [...ret, { ...v, i: idxs }];
      }, [])
      .filter(
        (v, i, a) =>
          a.findIndex(
            (t) => t.genus === v.genus && t.species === v.species && t.genus
          ) === i
      );

    // convert the i component, which was a list of indexes of old values, to the actual old values
    return filtered.map(({ genus, species, i }) => ({
      name: { genus, species },
      value:
        i.length === 0 ? [{ genus, species }] : i.map((idx) => origData[idx]),
    }));
  }
  return origData;
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ORGANIZATION_FILTERS:
      const filters = camelify(action.payload);
      const {
        contributedGenusSpecies,
        algorithmGenusSpecies,
        plateWells,
        ...otherFilters
      } = filters;
      return {
        ...state,
        ...otherFilters,
        plateWells,
        contributedGenusSpecies: generateGenusSpeciesWildCard(
          contributedGenusSpecies
        ),
        algorithmGenusSpecies: generateGenusSpeciesWildCard(
          algorithmGenusSpecies
        ),
      };
    case FETCH_PLATE_WELLS:
      return {
        ...state,
        plateWells: {
          ...state?.plateWells,
          [action.payload.plate]: action.payload.wells,
        },
      };
    case UPDATE_SPECIMEN_TAG_OPTIONS:
      return {
        ...state, tags: [...new Set([...(state?.tags || []), ...(action.payload || [])])]
      }
    case UPDATE_ALGORITHM_CONFIDENCE:
      return {
        ...state, algorithmConfidence: {
          ...state.algorithmConfidence,
          [action.payload.vectorType]: action.payload.confidence
        }
      }
    default:
      return state;
  }
};
