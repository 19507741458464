// notifications
export const CREATE_NOTIFICATION = "CREATE_NOTIFICATION";
export const DELETE_NOTIFICATION = "DELETE_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";

// trap sites
export const CREATE_TRAP_SITE = "CREATE_TRAP_SITE";
export const EDIT_TRAP_SITE = "EDIT_TRAP_SITE";
export const FETCH_TRAP_SITES = "FETCH_TRAP_SITES";
export const DELETE_TRAP_SITE = "DELETE_TRAP_SITE";
export const UPDATE_TRAP_SITE_SETTINGS = "UPDATE_TRAP_SITE_SETTINGS";
export const SET_TRAP_SITE_LOADING = "SET_TRAP_SITE_LOADING";

// organization
export const CREATE_ORGANIZATION = "CREATE_ORGANIZATION";
export const UPDATE_ORGANIZATION = "UPDATE_ORGANIZATION";
export const FETCH_ORGANIZATION_FILTERS = "FETCH_ORGANIZATION_FILTERS";
export const FETCH_PLATE_WELLS = "FETCH_PLATE_WELLS";
export const UPDATE_SPECIMEN_TAG_OPTIONS = "UPDATE_SPECIMEN_TAG_OPTIONS";
export const UPDATE_ALGORITHM_CONFIDENCE = "UPDATE_ALGORITHM_CONFIDENCE"

// algVersions
export const FETCH_ALG_VECTORTYPES = "FETCH_ALG_VECTORTYPES";

// license
export const FETCH_LICENSE_CONFIRMATION_STATUS =
  "FETCH_LICENSE_CONFIRMATION_STATUS";
export const UPDATE_LICENSE_CONFIRMATION_STATUS =
  "UPDATE_LICENSE_CONFIRMATION_STATUS";

// user
export const FETCH_USER_AUTOFILL_SETTINGS = "FETCH_USER_AUTOFILL_SETTINGS";
export const UPDATE_USER_AUTOFILL_SETTINGS = "UPDATE_USER_AUTOFILL_SETTINGS";
export const FETCH_USER_TIMEZONE_SETTINGS = "FETCH_USER_TIMEZONE_SETTINGS";
export const UPDATE_USER_TIMEZONE_SETTINGS = "UPDATE_USER_TIMEZONE_SETTINGS";
export const FETCH_USER_SETTINGS = "FETCH_USER_SETTINGS";
export const UPDATE_USER_ADDRESS_SEARCH_RADIUS_SETTINGS =
  "UPDATE_USER__ADDRESS_SEARCH_RADIUS_SETTINGS";
export const UPDATE_USER_MAP_CENTER_SETTINGS =
  "UPDATE_USER__MAP_CENTER_SETTINGS";
export const FETCH_ORGANIZATIONS = "FETCH_ORGANIZATIONS";
export const UPDATE_SUPER_USER_ORGANIZATION = "UPDATE_SUPER_USER_ORGANIZATION";
export const FETCH_USERS_BY_ORG = "FETCH_USERS_BY_ORG";
export const APPROVE_USER = "APPROVE_USER";
export const DELETE_USER = "DELETE_USER";
export const UPDATE_USER_ALGORITHM_CONFIDENCE_SETTINGS =
  "UPDATE_USER_ALGORITHM_CONFIDENCE_SETTINGS";
export const UPDATE_USER_SELECTED_DEVICE = "UPDATE_USER_SELECTED_DEVICE"
//
// device
//
export const ESTABLISH_STATES_DEVICE = "ESTABLISH_STATES_DEVICE";
export const SELECT_DEVICE = "SELECT_DEVICE";
export const UPDATE_DEVICE = "UPDATE_DEVICE";
export const REBOOT_DEVICE = "REBOOT_DEVICE";
export const FETCH_DEVICE_MAC_ADDRESS = "FETCH_DEVICE_MAC_ADDRESS";
export const FETCH_DEVICE_MAC_ADDRESS_RESPONSE =
  "FETCH_DEVICE_MAC_ADDRESS_RESPONSE";
export const FINISH_FETCH_MAC_ADDRESS = "FINISH_FETCH_MAC_ADDRESS";
export const MOVE_DEVICE_ORG = "MOVE_DEVICE_ORG";
export const RENAME_DEVICE = "RENAME_DEVICE";
export const SET_DEVICE_ENV = "SET_DEVICE_ENV";
export const ERROR_SET_DEVICE_ENV = "ERROR_SET_DEVICE_ENV";
export const UPDATE_DEVICE_ENV = "UPDATE_DEVICE_ENV";
export const SET_DEVICE_VECTOR_TYPE = "SET_DEVICE_VECTOR_TYPE";
export const UPDATE_DEVICE_VECTOR_TYPE = "UPDATE_DEVICE_VECTOR_TYPE";
export const ERROR_SET_DEVICE_VECTOR_TYPE = "ERROR_SET_DEVICE_VECTOR_TYPE";
export const CHANGE_DEVICE_HARDWARE_ID = "CHANGE_DEVICE_HARDWARE_ID";

// trays
export const CREATE_TRAY = "CREATE_TRAY";
export const EDIT_TRAY = "EDIT_TRAY";
export const FETCH_TRAY = "FETCH_TRAY";
export const FETCH_TRAYS = "FETCH_TRAYS";
export const DELETE_TRAY = "DELETE_TRAY";
export const UPDATE_TRAY_SETTINGS = "UPDATE_TRAY_SETTINGS";
export const SET_TRAY_LOADING = "SET_TRAY_LOADING";
export const CLEAR_TRAY = "CLEAR_TRAY";

// specimens
// export const EDIT_SPECIMEN = 'EDIT_SPECIMEN';
export const FORMAT_SPECIMEN = "FORMAT_SPECIMEN";
export const FORMAT_SPECIMENS = "FORMAT_SPECIMENS";
export const UPDATE_SPECIMEN_AUTO_METADATA = "UPDATE_SPECIMEN_AUTO_METADATA";

// websocket
// FRONTEND
export const WEBSOCKET_OPENED = "WEBSOCKET_OPENED";
export const WEBSOCKET_CLOSED = "WEBSOCKET_CLOSED";
export const WEBSOCKET_REAUTHENTICATING = "WEBSOCKET_REAUTHENTICATING";
export const WEBSOCKET_ERROR = "WEBSOCKET_ERROR";
export const RELOAD_SOCKET = "RELOAD_SOCKET";
// BACKEND
// Note: backend requires that all outgoing actions must end in
//        CAPTURE, DEVICE, or WIFI (except for AUTHENTICATE_WS)
// auth
// outgoing
export const AUTHENTICATE_WS = "AUTHENTICATE_WS";

// capture
// internal

export const NEXT_SPECIMEN = "NEXT_SPECIMEN";
export const PREV_SPECIMEN = "PREV_SPECIMEN";
export const JUMP_SPECIMEN = "JUMP_SPECIMEN";
export const FLIP_TRAY = "FLIP_TRAY";
export const UPDATE_SPECIMEN_CAPTURE_METADATA =
  "UPDATE_SPECIMEN_CAPTURE_METADATA";
export const UPDATE_SPECIMEN_FROM_METADATA = "UPDATE_SPECIMEN_FROM_METADATA";
export const UPDATE_TRAY_FROM_METADATA = "UPDATE_TRAY_FROM_METADATA";
export const SET_METADATA_ERRORS = "SET_METADATA_ERRORS";
export const CLEAR_CAPTURE = "CLEAR_CAPTURE";
export const VERIFY_TRAY = "VERIFY_TRAY";

// outgoing
export const START_CAPTURE = "START_CAPTURE";
export const FLIP_CAPTURE = "FLIP_CAPTURE";
export const TRAY_UPLOAD_CAPTURE = "TRAY_UPLOAD_CAPTURE";
export const SPECIMEN_UPLOAD_CAPTURE = "SPECIMEN_UPLOAD_CAPTURE";
export const SPECIMEN_BATCH_UPLOAD_CAPTURE = "SPECIMEN_BATCH_UPLOAD_CAPTURE";
export const RETAKE_CAPTURE = "RETAKE_CAPTURE";
export const FINISH_CAPTURE = "FINISH_CAPTURE";
export const VIEW_TRAY_CAPTURE = "VIEW_TRAY_CAPTURE";

// incoming
export const CONFIRM_START_CAPTURE = "CONFIRM_START_CAPTURE";
export const CONFIRM_VIEW_CAPTURE = "CONFIRM_VIEW_CAPTURE";
export const FULFILL_CAPTURE = "FULFILL_CAPTURE";
export const THUMB_CAPTURE = "THUMB_CAPTURE";
export const CROP_CAPTURE = "CROP_CAPTURE";
export const BBOXES_CAPTURE = "BBOXES_CAPTURE";
export const SEX_CAPTURE = "SEX_CAPTURE";
export const EMPTY_CAPTURE = "EMPTY_CAPTURE";
export const ML_CAPTURE = "ML_CAPTURE";
export const ML_2_CAPTURE = "ML_2_CAPTURE";
export const ERROR_CAPTURE = "ERROR_CAPTURE";

// wifi
// outgoing
export const FETCH_NETWORKS_WIFI = "FETCH_NETWORKS_WIFI";
export const SETUP_WPA_WIFI = "SETUP_WPA_WIFI";
export const CANCEL_WIFI = "CANCEL_WIFI";
export const SELECT_WIFI = "SELECT_WIFI";
export const DELETE_WIFI = "DELETE_WIFI";
export const FETCH_CONNECTED_NETWORKS_WIFI = "FETCH_CONNECTED_NETWORKS_WIFI";
export const FETCH_CONNECTED_NETWORK_WIFI = "FETCH_CONNECTED_NETWORK_WIFI";

// incoming
export const FETCH_NETWORKS_RESPONSE_WIFI = "FETCH_NETWORKS_RESPONSE_WIFI";
export const SETUP_WPA_RESPONSE_WIFI = "SETUP_WPA_RESPONSE_WIFI";
export const ERROR_WIFI = "ERROR_WIFI";
export const FETCH_CONNECTED_NETWORKS_WIFI_RESPONSE =
  "FETCH_CONNECTED_NETWORKS_WIFI_RESPONSE";
export const FETCH_CONNECTED_NETWORK_WIFI_RESPONSE =
  "FETCH_CONNECTED_NETWORK_WIFI_RESPONSE";
export const DELETE_WIFI_RESPONSE = "DELETE_WIFI_RESPONSE";
export const SELECT_WIFI_RESPONSE = "SELECT_WIFI_RESPONSE";

// internal
export const FINISH_WIFI = "FINISH_WIFI";

//map
export const SET_MAP_CENTER = "SET_MAP_CENTER";
export const SET_TRAP_SITE_PLACEMENT_MODE = "SET_TRAP_SITE_PLACEMENT_MODE";
export const SET_MAP_CENTER_PLACEMENT_MODE = "SET_MAP_CENTER_PLACEMENT_MODE";

//data
export const UPDATE_GENERAL_FILTERS = "UPDATE_GENERAL_FILTERS";
export const CLEAR_GENERAL_FILTERS = "CLEAR_GENERAL_FILTERS";
export const UPDATE_IS_TRAY_FILTERS_MODAL = "UPDATE_TRAY_FILTERS_MODAL";

// superuser
export const SELECT_SUPERUSER_ORG = "SELECT_SUPERUSER_ORG";
export const CHANGE_SUPERUSER_ORG_FILTER = "CHANGE_SUPERUSER_ORG_FILTER";
export const CHANGE_SUPERUSER_USER_FILTER = "CHANGE_SUPERUSER_USER_FILTER";
export const CHANGE_SUPERUSER_DEVICE_FILTER = "CHANGE_SUPERUSER_DEVICE_FILTER";

//enlargedspecimen
export const TOGGLE_ENLARGED_SPECIMEN_MODAL = "TOGGLE_ENLARGED_SPECIMEN_MODAL";
