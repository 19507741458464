import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import OrganizationSelector from "./OrganizationSelector";
import { useWebServer } from "../../../providers";
import {
  fetchOrganizations,
  updateSuperUserOrganization,
} from "./../../../actions";

const Index = ({
  organization,
  organizationList,
  superUser,
  fetchOrganizations,
  updateSuperUserOrganization,
}) => {
  const { sendRequest, getToken } = useWebServer();
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (superUser) {
      fetchOrganizations(sendRequest);
    }
  }, [fetchOrganizations, sendRequest, superUser]);

  const onChangeOrganization = async (obj) => {
    setLoading(true);
    await updateSuperUserOrganization(sendRequest, obj.target.value, getToken);
    setLoading(false);
  };

  return (
    superUser && (
      <OrganizationSelector
        organizationList={organizationList}
        selectedDevice={organization ? organization : "none"}
        handleChangeOrganization={onChangeOrganization}
        loading={loading}
        disabled={loading}
      ></OrganizationSelector>
    )
  );
};

export const mapStateToProps = (state) => {
  const { superUser, organization, organizations } = state.user;
  const organizationList = Object.values(organizations || {}).sort((a, b) => {
    if (a.display_name < b.display_name) {
      return -1;
    }
    if (a.display_name > b.display_name) {
      return 1;
    }
    return 0;
  });
  return {
    organization,
    organizationList,
    superUser,
  };
};

export default connect(mapStateToProps, {
  fetchOrganizations,
  updateSuperUserOrganization,
})(Index);
