import React, { useState } from "react";
import { Typography, Box, Grid } from "@mui/material";

const fontSxTitle = {
  fontSize: ["1rem", "1rem", "1rem", "1rem", "1.25rem", "1.25rem"],
};

const fontSx = {
  fontSize: ["0.75rem", "0.75rem", "0.75rem", "0.75rem", "1rem", "1rem"],
};

const SuperUserPanel = ({
  orgs = [],
  orglessUsers = -1,
  handleSelectGroup,
  handleUpdateOrg,
}) => {
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const onSelectGroup = (org, group) => {
    if (org === selectedOrg && group === selectedGroup) {
      org = null;
      group = null;
    }
    setSelectedOrg(org);
    setSelectedGroup(group);
    handleSelectGroup(org, group);
  };

  const getSelectedStyle = (org, group) => {
    if (org === selectedOrg && group === selectedGroup) {
      return {
        fontWeight: "bold",
        color: "gold",
        // textShadow: "2px 4px 3px rgba(0,0,0,0.3)",
      };
    }
    return {};
  };

  return (
    <Box
      sx={{
        width: "100%",
        px: "4rem",
        py: "1rem",
        overflow: "scroll",
      }}
    >
      <Grid container spacing={1}>
        {orglessUsers > -1 ? (
          <Grid container sx={{ py: "1rem" }} spacing={0} key={`org-orgless`}>
            <Grid item xs={12}>
              <Typography sx={fontSxTitle} className={"text-gray-600"}>
                Unaffiliated
              </Typography>
            </Grid>

            <Grid item xs={3}></Grid>
            <Grid item xs={3}>
              <Typography sx={fontSx} className={"text-gray-300"}>
                Devices (0)
              </Typography>
            </Grid>

            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={3}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => onSelectGroup("orgless", "users")}
            >
              <Typography
                sx={{ ...fontSx, ...getSelectedStyle("orgless", "users") }}
              >
                Users ({orglessUsers})
              </Typography>
            </Grid>
          </Grid>
        ) : null}

        {orgs.map((orgInfo) => (
          <Grid
            container
            sx={{ py: "1rem" }}
            spacing={0}
            key={`org-${orgInfo.org}`}
          >
            <Grid item xs={12}>
              <Typography
                sx={fontSxTitle}
                className={"text-gray-600"}
                onClick={() => handleUpdateOrg(orgInfo.org)}
              >
                {orgInfo.org}
              </Typography>
            </Grid>

            <Grid item xs={3}></Grid>
            <Grid
              item
              xs={3}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => onSelectGroup(orgInfo.org, "devices")}
            >
              <Typography
                sx={{ ...fontSx, ...getSelectedStyle(orgInfo.org, "devices") }}
              >
                Devices ({orgInfo.devices})
              </Typography>
            </Grid>

            <Grid item xs={2}></Grid>
            <Grid
              item
              xs={3}
              sx={{
                cursor: "pointer",
              }}
              onClick={() => onSelectGroup(orgInfo.org, "users")}
            >
              <Typography
                sx={{ ...fontSx, ...getSelectedStyle(orgInfo.org, "users") }}
              >
                Users
              </Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SuperUserPanel;
