import React, { useEffect, useState, useCallback } from "react";
import { startCapture, finishCapture } from "../../../actions";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ROUTES from "../../AppRouter/routes";
//import ModalContainer from "./ModalContainer";
import TrayViewer from "./TrayViewer";
import useWindowSize from "../../../lib/useWindowSizeHook";

const CaptureTray = ({ startCapture, selectedDevice }) => {
  const history = useHistory();
  const [sentCapture, setSentCapture] = useState(false);

  //calculate the modal size base on window height
  const windowSize = useWindowSize()
  /* eslint-disable no-unused-vars */
  const [height, setHeight] = useState('900px')
  /* eslint-disable no-unused-vars */
  const [width, setWidth] = useState('824px')
  const setSize = (h, w) => {
    setHeight(h)
    setWidth(w)
  }
  const computeModalSize = useCallback(() => {
    if (windowSize.height > 1300) {
      setSize('1004px', '1106px')
    } else if (windowSize.height <= 1300 & windowSize.height > 1000) {
      setSize('913px', '984px')
    } else if (windowSize.height <= 1000 & windowSize.height > 800) {
      setSize('792px', '824px')
    } else {
      setSize('580px', '600px')
    }
  }, [windowSize])

  useEffect(() => {
    computeModalSize()
  }, [computeModalSize, windowSize])

  useEffect(() => {
    const loadTray = async () => {
      if (!selectedDevice) {
        history.push(ROUTES.traysTab);
      }
      if (!sentCapture) {
        await startCapture(selectedDevice);
        setSentCapture(true);
      }
    };
    loadTray();
  }, [history, selectedDevice, sentCapture, startCapture]);

  useEffect(() => {
    if (sentCapture) {
      return () => {
        finishCapture();
      };
    }
  }, [sentCapture]);

  return (
    //<ModalContainer height={height} width={width}>
    <TrayViewer />
    //</ModalContainer>
  );
};

export const mapStateToProps = (state) => {
  const { selectedDevice } = state.devices;
  if (!selectedDevice) return {};

  return {
    selectedDevice,
  };
};

export default connect(mapStateToProps, { startCapture, finishCapture })(
  CaptureTray
);
