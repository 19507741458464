import React, { useEffect, useMemo, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { Typography } from '@mui/material';

const generateColor = (species) => {
    const hash = species.split('').reduce((acc, char) => char.charCodeAt(0) + ((acc << 5) - acc), 0);
    const color = `hsl(${hash % 360}, 70%, 70%)`;
    return color;
};

export default function SpeciesStackedBarChart({ data, onDownloadData, filters }) {

    const jsonData = JSON.stringify(data);
    const parsedData = JSON.parse(jsonData);
    const [isNoMatchData, setIsNoMatchData] = useState(false);


    useEffect(() => {
        onDownloadData();
        // eslint-disable-next-line
    }, [filters]);

    useEffect(() => {
        if (data?.[0]?.['genus_species'] === "no match data") {
            setIsNoMatchData(true)
        } else {
            setIsNoMatchData(false)
        }
    }, [data])

    const filterOutColumns = (data) => {
        const columnsToHide = ['Male', 'Female', 'Total_Count', 'Sex unknown'];

        return data.map(item => {
            const filteredItem = Object.fromEntries(
                Object.entries(item).filter(([key]) => !columnsToHide.includes(key))
            );
            return filteredItem;
        });
    };

    const organizeDataByTrapsite = (data) => {
        const trapsites = {};
        data.forEach(item => {
            if (item.species !== 'All') {
                Object.entries(item).forEach(([key, value]) => {
                    if (key !== 'species') {
                        if (!trapsites[key]) {
                            trapsites[key] = {};
                        }
                        trapsites[key][item.species] = value;
                    }
                });
            }
        });
        return trapsites;
    };
    const filteredData = useMemo(() => filterOutColumns(data), [data]);
    const transformedData = useMemo(() => organizeDataByTrapsite(filteredData), [filteredData]);
    // let transformedData = organizeDataByTrapsite(parsedData);

    // const bardata = Object.keys(transformedData).map(category => ({
    //     name: category,
    //     ...transformedData[category]
    // }));

    const bardata = useMemo(() => {
        return Object.keys(transformedData).map(category => ({
            name: category,
            ...transformedData[category]
        }));
    }, [transformedData]);

    const colorMapping = useMemo(() => {
        const species = new Set();
        bardata.forEach(item => {
            Object.keys(item).forEach(key => {
                if (key !== 'name') species.add(key);
            });
        });
        const colors = {};
        species.forEach(sp => {
            colors[sp] = generateColor(sp);
        });
        return colors;
    }, [bardata]);

    const CustomTooltip = ({ payload, label }) => {
        if (payload && payload.length) {
            // Reverse the payload data
            const reversedPayload = [...payload].reverse();

            return (
                <div style={{ backgroundColor: '#fff', border: '1px solid #ccc', padding: '10px' }}>
                    <div>{label}</div>
                    {reversedPayload.map((entry, index) => (
                        <div key={`item-${index}`} style={{ color: entry.color }}>
                            <span style={{ fontWeight: 'bold' }}>{entry.name}:</span> {entry.value}
                        </div>
                    ))}
                </div>
            );
        }

        return null;
    };

    const CustomLegend = ({ payload }) => {
        const reversedPayload = [...payload].reverse();

        return (
            <div style={{ marginTop: '15px', display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                {reversedPayload.map((entry, index) => (
                    <span key={`item-${index}`} style={{ marginRight: 15, fontSize: '12px', fontWeight: 'bold', color: entry.color }}>
                        <span style={{ background: entry.color, width: 12, height: 10, display: 'inline-block', marginRight: 5 }} />
                        {entry.value}
                    </span>
                ))}
            </div>

        );
    };

    const uniqueKeys = useMemo(() => {
        const keys = bardata.reduce((acc, item) => {
            return [...acc, ...Object.keys(item).filter(key => key !== 'name')];
        }, []);
        return [...new Set(keys)];
    }, [bardata]);

    const legendOrder = Object.keys(colorMapping).reverse();

    return (
        <div style={{ marginLeft: '80px' }}>
            {/* Bar graph section */}
            <div style={{ textAlign: 'center', color: '#3f51b5', fontFamily: 'Roboto' }}>
                {/* <div>SPECIES SUMMARY AMONG COLLECTION SITES</div> */}
                {/* <div style={{ fontSize: '12px', fontWeight: '200px' }}>{'( '}Species Summary among Collections{' )'}</div> */}
            </div>
            <br /><br />
            <div style={{ height: '550px', width: '100%', fontSize: '11px' }}>
                {!isNoMatchData && data?.length > 0 && <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                        width={500}
                        height={300}
                        data={bardata}
                        margin={{
                            top: 20,
                            right: 30,
                            left: 20,
                            bottom: 20,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                            <Label value="Collection sites" offset={0} position="insideBottom" style={{ fontSize: '15px', fontWeight: 'bold' }} />
                        </XAxis>
                        <YAxis>
                            <Label value="Count" angle={-90} position="insideLeft" style={{ textAnchor: 'middle', fontSize: '15px', fontWeight: 'bold' }} />
                        </YAxis>
                        <Tooltip content={<CustomTooltip />} />

                        {/* <Bar dataKey="Aedeomyia africana" stackId="a" fill="#00004e" />
                        <Bar dataKey="Aedeomyia furfurea" stackId="a" fill="#82ca9d" />
                        <Bar dataKey="Aedes aegypti" stackId="a" fill="#FF5733" />
                        <Bar dataKey="Aedes albopictus" stackId="a" fill="#ffd9aa" />
                        <Bar dataKey="Aedes mansoniauri" stackId="a" fill="#ffafaa" />
                        <Bar dataKey="Algorithm unsure" stackId="a" fill="#aadoff" />
                        <Bar dataKey="Ambly spp" stackId="a" fill="#8b0000" />
                        <Bar dataKey="Amblyomma spp" stackId="a" fill="#aaff" /> */}
                        {/* <Bar dataKey="Anopheles funestus sl" stackId="a" fill="#ffaafa" /> */}
                        {/* <Legend
                            wrapperStyle={{
                                bottom: 0,
                                marginTop: 20,
                                fontSize: '13px',
                            }}
                        /> */}
                        <Legend content={<CustomLegend />} />
                        {legendOrder.map((key) => (
                            <Bar key={key} dataKey={key} stackId="a" fill={colorMapping[key]} />
                        ))}
                    </BarChart>
                </ResponsiveContainer>}
                {isNoMatchData && <Typography sx={{
                    fontSize: ['.66rem', '.66rem', '1rem', '1rem', '1.5rem', '1.75rem'],
                    width: '100%',
                    mx: 'auto',
                    paddingRight: '120px',
                    textAlign: 'center',
                }}>No Data</Typography>}
            </div>
            <br /><br /><br />
        </div>
    )
}

