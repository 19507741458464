import React from "react";
import routes from "../../AppRouter/routes";
import { Route, Switch } from "react-router-dom";
import CaptureTray from "./CaptureTray";
import ViewTray from "./ViewTray";
import DeviceSetup from "./DeviceSetup";
import TrayFilterModal from './TrayFilterModal'
import SuperUserPanel from "./SuperUserPanel";

const ModalRouter = () => {
  return (
    <Switch>
      <Route path={routes.captureTray} component={CaptureTray} />
      <Route path={routes.viewTray} component={ViewTray} />
      <Route path={routes.deviceSetup} component={DeviceSetup} />
      {/* <Route path={routes.reports} component={DataGraph} /> */}
      <Route path={routes.traysTab} component={TrayFilterModal} />
      <Route path={routes.superUserPanel} component={SuperUserPanel} />
      <Route path={"*"} component={null} />
    </Switch >
  );
};

export default ModalRouter;
