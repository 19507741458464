import { WELL_REGEX } from '../utils/const'

export const checkCommaAndBackSlash = (value) => {
  if (value && (typeof value === "string" || value instanceof String)) {
    if (`${value}`.includes(",")) {
      return "Cannot contain commas";
    } else if (`${value}`.includes("/")) {
      return "Cannot contain slashes";
    } else if (`${value}`.includes("\\")) {
      return "Cannot contain backslashes";
    }
  }
  return undefined;
};

export const checkCommaAndBackSlashOnTags = (value) => {
  let res;
  let errorIndex = [];
  if (Array.isArray(value)) {
    errorIndex = value.filter((x) => !!checkCommaAndBackSlash(x));
    if (errorIndex.length > 0) {
      res = {
        errorIndex,
        message: "Tags cannot contain commas or slashes",
      };
    }
  }
  return res;
};

export const composeValidators = (...validators) => value =>
  validators.reduce((error, validator) => error || validator(value), undefined)

export const checkWellNumber = (value) => {
  return (WELL_REGEX.test(value) || value?.length === 0 || !value) ? undefined : "Well must be between A01 and H12";
}