import {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  CLEAR_ERRORS,
} from "../actions/types";

export const displaySuccessNotification = (message) => async (dispatch) =>
  dispatch({
    type: CREATE_NOTIFICATION,
    payload: {
      id: Date.now(),
      message,
      severity: "success",
    },
  });

export const displayInfoNotification = (message) => async (dispatch) =>
  dispatch({
    type: CREATE_NOTIFICATION,
    payload: {
      id: Date.now(),
      message,
      severity: "info",
    },
  });

export const displayWarningNotification = (message) => async (dispatch) =>
  dispatch({
    type: CREATE_NOTIFICATION,
    payload: {
      id: Date.now(),
      message,
      severity: "warning",
    },
  });

export const displayErrorNotification = (message) => async (dispatch) =>
  dispatch({
    type: CREATE_NOTIFICATION,
    payload: {
      id: Date.now(),
      message,
      severity: "error",
      anchor: { vertical: "top", horizontal: "center" },
    },
  });

export const displayPermanentErrorNotification =
  (message) => async (dispatch) =>
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: {
        id: Date.now(),
        message,
        severity: "error",
        autoHideDuration: null,
        anchor: { vertical: "top", horizontal: "center" },
      },
    });

export const displayPermanentWarningNotification =
  (message, id) => async (dispatch) =>
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: {
        id,
        message,
        severity: "warning",
        autoHideDuration: null,
        anchor: { vertical: "bottom", horizontal: "center" },
      },
    });

export const displayPermanentSuccessNotification =
  (message) => async (dispatch) =>
    dispatch({
      type: CREATE_NOTIFICATION,
      payload: {
        id: Date.now(),
        message,
        severity: "success",
        autoHideDuration: null,
      },
    });

export const closeNotification = (id) => async (dispatch) =>
  dispatch({ type: DELETE_NOTIFICATION, payload: id });

export const clearNotifications = () => async (dispatch) =>
  dispatch({ type: CLEAR_NOTIFICATIONS });

export const clearErrors = () => async (dispatch) =>
  dispatch({ type: CLEAR_ERRORS });
