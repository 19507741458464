import TextField from "@mui/material/TextField";
import React, { useState } from "react";
import { Autocomplete, Paper } from '@mui/material';

const AutoCompleteField = (props) => {
  const {
    options,
    onChange,
    getOptionLabel,
    label,
    multiple,
    disableCloseOnSelect,
    noLabel = false,
    ...other
  } = props;
  const [inputValue, setInputValue] = useState('')

  return (
    <Autocomplete
      multiple={multiple}
      inputValue={inputValue}
      disableCloseOnSelect={disableCloseOnSelect}
      id="device-filter"
      options={options ? options : []}
      {...other}
      onChange={(e, value) => {
        onChange(e, value)
        if (e?.key === 'Enter') {
          if (
            !options?.includes(inputValue)
          ) {
            onChange(e, value.filter(val => val !== inputValue))
          } else if (inputValue.length > 0
            && !props?.value?.includes(inputValue)) {
            setInputValue('')
          }
        }
        if (e?.target?.parentNode?.className?.includes('clearIndicator')) {
          setInputValue('')
        }
      }}
      getOptionLabel={getOptionLabel}
      ListboxProps={{ 'data-testid': 'device-filter-listbox' }}
      PaperComponent={({ children }) => (
        <Paper
          sx={{ fontSize: ['.5rem', '.5rem', '.8rem', '.8rem', '1.15rem', '1.15rem'] }}
        >{children}</Paper>
      )}
      sx={{
        "& .MuiInputLabel-root": {
          fontSize: ['.5rem', '.5rem', '.8rem', '.8rem', '1.15rem', '1.15rem'],
        },
        "& .MuiInputLabel-shrink": {
          transform: {
            md: "translate(12px, 3px) scale(0.75)",
            lg: "translate(12px, 7px) scale(0.75)",
          }
        },
        "& .MuiFilledInput-root": {
          pt: noLabel ? '0rem' : ["1rem", "1rem", ".75rem", ".75rem", "1.25rem", "1.25rem"]
        },
        "& .MuiFilledInput-input": {
          m: ['0rem', '0rem', '0rem', '0rem', '.1rem', '.1rem'],
        },
        "& .MuiAutocomplete-input": {
          padding: "3 !important",
          height: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"]
        },
        '& .MuiTextField-root': {
          marginTop: noLabel ? '0rem' : ['.5rem', '.5rem', '.75rem', '.75rem', '1rem', '1rem'],
        },
        '& .MuiChip-root': {
          height: ['1.5rem', '1.5rem', '1.25rem', '1.25rem', '1.85rem', '1.85rem'],
          m: ['0rem', '0rem', '.15rem', '.25rem', '.25rem', '.25rem']
        },
        '& .MuiChip-label': {
          fontSize: ['.5rem', '.5rem', '.75rem', '.75rem', '1.05rem', '1.05rem'],
          px: ['.5rem', '.5rem', '.5rem', '.5rem', '.60rem', '.60rem']
        },
        '& .MuiChip-deleteIcon': {
          fontSize: ["1.5rem", "1.5rem", "1rem !important", "1rem !important", "1.5rem !important", "1.5rem !important"],
        },

      }}

      renderInput={(params) => {
        return (
          <TextField
            {...params}
            label={label}
            variant="filled"
            size="large"
            onChange={(e) => {
              setInputValue(e.target.value)
            }}
            onBlur={(e) => {
              if (label?.includes('Tag')) {
                if (e.target.value.length > 0
                  && !props.value?.includes(e.target.value)
                  && options?.includes(e.target.value)) {
                  onChange(null, [...props.value, e.target.value]);
                  setInputValue('')
                }
              }
            }}
          />
        );
      }}
    />
  );
};

export default AutoCompleteField;
