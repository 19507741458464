import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../AppRouter/routes";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/AddCircleOutlineRounded";
import FilterIcon from "@mui/icons-material/FilterAltOutlined";
import CloseIcon from "@mui/icons-material/Close";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import ModalContainer from "../ModalContainer";
import SuperUserPanel from "./SuperUserPanel";
import CreateOrganizationDialog from "./CreateOrganizationDialog";
import UpdateOrganizationDialog from "./UpdateOrganizationDialog";
import FiltersDialog from "./FiltersDialog";
import { useWebServer } from "../../../../providers";
import {
  getUsersByOrg,
  selectSuperUserOrg,
  createOrganization,
  updateOrganization,
  fetchAlgVectorTypes,
} from "../../../../actions";

const buttonStyles = {
  infoButton: {
    color: "status.info",
  },
  editButton: {
    "&:hover, &:focus": {
      color: "status.info",
    },
  },
  deleteButton: {
    "&:hover, &:focus": {
      color: "status.danger",
    },
  },
};

const Index = ({
  organizations,
  superUser,
  orgDevices,
  orglessUsers,
  orgFilter = "",
  deviceFilter = "",
  userFilter = "",
  getUsersByOrg,
  selectSuperUserOrg,
  createOrganization,
  updateOrganization,
  fetchAlgVectorTypes,
}) => {
  const history = useHistory();
  const { sendRequest } = useWebServer();
  const [orgs, setOrgs] = useState([]);
  const [displayCreateOrg, setDisplayCreateOrg] = useState(false);
  const [displayUpdateOrg, setDisplayUpdateOrg] = useState(null);
  const [displaySearchFilters, setDisplaySearchFilters] = useState(false);

  useEffect(() => {
    fetchAlgVectorTypes(sendRequest);
  }, [sendRequest, fetchAlgVectorTypes]);

  useEffect(() => {
    getUsersByOrg(sendRequest, "orgless");
  }, [sendRequest, getUsersByOrg]);

  useEffect(() => {
    setOrgs(
      Object.entries(orgDevices).reduce((arr, [org, tools]) => {
        if (org.includes(orgFilter)) {
          return [
            ...arr,
            {
              org,
              devices: (tools || []).filter((tool) =>
                tool.uuid.includes(deviceFilter)
              ).length,
            },
          ];
        }
        return arr;
      }, []).sort((a, b) => {
        if (a.org < b.org) {
          return -1;
        }
        if (a.org > b.org) {
          return 1;
        }
        return 0;
      })
    );
  }, [setOrgs, orgDevices, orgFilter, deviceFilter, userFilter]);

  const handleSelectGroup = (org, group) => {
    let nextSelected = `${org};${group}`;
    if (org === null || group === null) {
      nextSelected = "";
    }
    selectSuperUserOrg(nextSelected);
  };

  const handleAddOrg = (orgInfo) => {
    const { uuid, name, address, gps, vectorTypes } = orgInfo;
    createOrganization(sendRequest, uuid, address, gps, name, vectorTypes);
  };

  const handleUpdateOrg = (orgInfo) => {
    const { uuid, name, address, gps, vectorTypes } = orgInfo;
    updateOrganization(sendRequest, uuid, address, gps, name, vectorTypes);
  };

  const handleClose = () => {
    history.push(ROUTES.app);
  };

  return superUser ? (
    <ModalContainer>
      <CreateOrganizationDialog
        isActive={displayCreateOrg}
        organizations={organizations}
        setIsActive={setDisplayCreateOrg}
        onConfirm={handleAddOrg}
      />
      <UpdateOrganizationDialog
        selectedOrg={displayUpdateOrg}
        organizations={organizations}
        setSelectedOrg={setDisplayUpdateOrg}
        onConfirm={handleUpdateOrg}
      />
      <FiltersDialog
        isActive={displaySearchFilters}
        setIsActive={setDisplaySearchFilters}
      />
      <Grid container spacing={3} id={"superuserpanel-header"}>
        <Grid item xs={2} />
        <Grid item xs={6}>
          <Typography
            className={"text-white shadow-sm"}
            sx={{
              my: [
                "0.25rem",
                "0.25rem",
                "0.25rem",
                "0.25rem",
                "0.5rem",
                "0.5rem",
              ],
              fontSize: [
                "1.25rem",
                "1.25rem",
                "1.25rem",
                "1.25rem",
                "1.75rem",
                "1.75rem",
              ],
            }}
          >
            Organization Management
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={`Create Organization`}>
            <IconButton
              onClick={() => setDisplayCreateOrg(true)}
              sx={{
                ...buttonStyles.infoButton,
                padding: [
                  "0.35rem",
                  "0.35rem",
                  "0.35rem",
                  "0.5rem",
                  "0.5rem",
                  "0.5rem",
                ],
              }}
            >
              <AddIcon
                className={"text-white shadow-xl"}
                sx={{
                  fontSize: [
                    "1.5rem",
                    "1.5rem",
                    "1.5rem",
                    "1.5rem",
                    "2rem",
                    "2rem",
                  ],
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={1}>
          <Tooltip title={`Search Filters`}>
            <IconButton
              onClick={() => setDisplaySearchFilters(true)}
              sx={{
                ...buttonStyles.infoButton,
                padding: [
                  "0.35rem",
                  "0.35rem",
                  "0.35rem",
                  "0.5rem",
                  "0.5rem",
                  "0.5rem",
                ],
              }}
            >
              <FilterIcon
                className={"text-white shadow-xl"}
                sx={{
                  fontSize: [
                    "1.5rem",
                    "1.5rem",
                    "1.5rem",
                    "1.5rem",
                    "2rem",
                    "2rem",
                  ],
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid item xs={1} />
        <Grid item xs={1}>
          <Tooltip title={`Close`}>
            <IconButton
              onClick={() => handleClose()}
              sx={{
                ...buttonStyles.infoButton,
                padding: [
                  "0.35rem",
                  "0.35rem",
                  "0.35rem",
                  "0.5rem",
                  "0.5rem",
                  "0.5rem",
                ],
              }}
            >
              <CloseIcon
                className={"text-white shadow-xl"}
                sx={{
                  fontSize: [
                    "1.5rem",
                    "1.5rem",
                    "1.5rem",
                    "1.5rem",
                    "2rem",
                    "2rem",
                  ],
                }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>

      <Paper
        className={
          "flex bg-white rounded-xl border-4 border-transparent transition-opacity duration-1000"
        }
        sx={{
          height: ["560px", "560px", "560px", "560px", "786px", "786px"],
          width: ["100vw", "544px", "544px", "544px", "824px", "824px"],
        }}
      >
        <SuperUserPanel
          orgs={orgs}
          orglessUsers={orglessUsers.length}
          handleSelectGroup={handleSelectGroup}
          handleUpdateOrg={(org) => setDisplayUpdateOrg(org)}
        />
      </Paper>
    </ModalContainer>
  ) : (
    <Typography
      sx={{
        my: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "0.5rem", "0.5rem"],
        fontSize: [
          "1.25rem",
          "1.25rem",
          "1.25rem",
          "1.25rem",
          "1.75rem",
          "1.75rem",
        ],
        color: "red",
      }}
    >
      Access Denied
    </Typography>
  );
};

export const mapStateToProps = (state) => {
  const organizations = state?.user?.organizations || {};
  const superUser = state?.user?.superUser || false;
  const orgDevices = state?.user?.orgDevices || {};
  const orglessUsers = Object.values(state?.user?.orgUsers?.orgless || {});
  const { orgFilter, deviceFilter, userFilter } = state?.superuser;
  return {
    organizations,
    superUser,
    orgDevices,
    orglessUsers,
    orgFilter,
    deviceFilter,
    userFilter,
  };
};

export default connect(mapStateToProps, {
  getUsersByOrg,
  selectSuperUserOrg,
  createOrganization,
  updateOrganization,
  fetchAlgVectorTypes,
})(Index);
