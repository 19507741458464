import {
  SELECT_DEVICE,
  REBOOT_DEVICE,
  SET_DEVICE_ENV,
  FETCH_DEVICE_MAC_ADDRESS,
  FINISH_FETCH_MAC_ADDRESS,
  MOVE_DEVICE_ORG,
  RENAME_DEVICE,
  FETCH_DEVICE_MAC_ADDRESS_RESPONSE,
  SET_DEVICE_VECTOR_TYPE,
  CHANGE_DEVICE_HARDWARE_ID
} from "./types";

import {
  displaySuccessNotification,
  displayErrorNotification,
} from "./notifications";

export const selectDevice = (deviceUuid) => async (dispatch) => {
  dispatch({ type: SELECT_DEVICE, payload: deviceUuid });
};

export const rebootDevice = (deviceUuid) => async (dispatch) => {
  dispatch({
    type: REBOOT_DEVICE,
    payload: { deviceUuid },
    socket: { send: true },
  });
};

export const fetchDeviceMacAddress =
  (deviceUuid, jobId) => async (dispatch) => {
    dispatch({
      type: FETCH_DEVICE_MAC_ADDRESS,
      payload: { deviceUuid, jobId },
      socket: { send: true },
    });
  };

export const finishMacFetch = () => async (dispatch) => {
  dispatch({
    type: FINISH_FETCH_MAC_ADDRESS,
  });
};

export const updateMacAddress =
  (deviceUuid, macAddress) => async (dispatch) => {
    dispatch({
      type: FETCH_DEVICE_MAC_ADDRESS_RESPONSE,
      payload: { device_uuid: deviceUuid, macAddress },
    });
  };

export const moveDevice =
  (sendRequest, device, oldOrg, newOrg, userOrg) => async (dispatch) => {
    const res = await sendRequest("patch")({
      url: `/tool/${device}/move`,
      devPort: 5005,
      data: { oldOrg, newOrg },
    }).catch((err) => {
      dispatch(displayErrorNotification("Failed to move device..."));
    });
    if (res?.status === 200) {
      if (oldOrg !== newOrg) {
        if (newOrg === userOrg) {
          // if changing a device to your org, establish_states
          //   must be rerun, so just refresh the page
          window.location.reload(false);
        } else {
          dispatch({
            type: MOVE_DEVICE_ORG,
            payload: {
              device,
              oldOrg,
              newOrg,
            },
          });
        }
      }
      dispatch(
        displaySuccessNotification(
          `Successfully moved ${device} from ${oldOrg} to ${newOrg}`
        )
      );
    }
  };

export const renameDevice =
  (sendRequest, device, name, org) => async (dispatch) => {
    const res = await sendRequest("patch")({
      url: `/tool/${device}/rename`,
      devPort: 5005,
      data: { name, organization: org },
    }).catch((err) => {
      dispatch(displayErrorNotification("Failed to rename device..."));
    });
    if (res?.status === 200) {
      dispatch({
        type: RENAME_DEVICE,
        payload: {
          device,
          org,
          name,
        },
      });
    }
  };

export const updateDeviceEnv = (deviceUuid, env) => async (dispatch) => {
  console.log("updateDeviceEnv dispatched");
  dispatch({
    type: SET_DEVICE_ENV,
    payload: { deviceUuid, env },
    socket: { send: true },
  });
};

export const updateDeviceVectorType = (deviceUuid, vectorType) => async (dispatch) => {
  console.log("updateDeviceVectorType dispatched");
  dispatch({
    type: SET_DEVICE_VECTOR_TYPE,
    payload: { deviceUuid, vectorType },
    socket: { send: true },
  });
};

export const updateDeviceHardwareId = (sendRequest, device, hardwareId, org) => async (dispatch) => {
  console.log(device, hardwareId, org)
  const res = await sendRequest("patch")({
    url: `/tool/${device}/hardware_id`,
    devPort: 5005,
    data: { hardwareId, organization: org },
  }).catch((err) => {
    dispatch(displayErrorNotification("Failed to change device hardware id..."));
  });
  if (res?.status === 200) {
    dispatch({
      type: CHANGE_DEVICE_HARDWARE_ID,
      payload: {
        device,
        org,
        hardwareId,
      },
    });
  }
}