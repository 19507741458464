import React, { useEffect, useState } from "react";
import { ResponsiveContainer, PieChart, Pie, Tooltip, Sector, Cell } from 'recharts'
import { Typography, CircularProgress, Box, useMediaQuery } from "@mui/material";
import NotesModal from './NotesModal'

const colors = ["#fbf8cc", "#fde4cf", "#ffcfd2", "#f1c0e8", "#cfbaf0", "#a3c4f3", "#90dbf4", "#8eecf5", "#98f5e1", "#b9fbc0"]
const innerColors = ["#ffac81", "#ff928b", "#fec3a6", "#efe9ae", "#cdeac0"]
const RADIAN = Math.PI / 180;
const INFOKEYWORDS = 'empty|cell|algorithm unsure|N/A'
const re = new RegExp(INFOKEYWORDS, 'i')

const DataSummaryGraph = ({ dataInner, dataOuter, noMatchData, activeIndex, setActiveIndex, pointerEvent, error }) => {

    const [activeGraph, setActiveGraph] = useState('outer')
    const setArch = (graph, index) => {
        if (pointerEvent) {
            setActiveIndex(index)
            setActiveGraph(graph)
        }
    }
    const [pieChartRadius, setPieChartRadius] = useState([100, 150])
    const lgMatches = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const hdMatches = useMediaQuery((theme) => theme.breakpoints.up('hd'));
    const xlMatches = useMediaQuery((theme) => theme.breakpoints.up('xl'));
    const [notesOpen, setNotesOpen] = useState(false)

    const addStarToInfoKeyWords = (str) => {
        if (re.test(str)) {
            return `${str} *`
        }
        return str
    }

    useEffect(() => {
        if (xlMatches) {
            setPieChartRadius([180, 250])
        } else if (lgMatches) {
            setPieChartRadius([150, 200])
        } else {
            setPieChartRadius([100, 150])
        }

    }, [lgMatches, hdMatches, xlMatches])

    const renderActiveShape = (props) => {
        const RADIAN = Math.PI / 180;
        const { cx, cy, midAngle, innerRadius, startAngle, endAngle, fill, payload, percent, value } = props;
        const outerRadius = pieChartRadius[1]
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const sx = cx + (outerRadius + 10) * cos;
        const sy = cy + (outerRadius + 10) * sin;
        const mx = cx + (outerRadius + 30) * cos;
        const my = cy + (outerRadius + 30) * sin;
        const ex = mx + (cos >= 0 ? 1 : -1) * 22;
        const ey = my;
        const textAnchor = cos >= 0 ? 'start' : 'end';

        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
                <Sector
                    cx={cx}
                    cy={cy}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    innerRadius={outerRadius + 6}
                    outerRadius={outerRadius + 10}
                    fill={fill}
                />
                <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
                <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
                {
                    (activeGraph === 'outer')
                    && <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey - 20} textAnchor={textAnchor} fill="#333"
                        style={{ 'fontStyle': 'italic' }}
                    >
                        {`Species: ${addStarToInfoKeyWords(payload.genus)} ${addStarToInfoKeyWords(payload.name)}`}
                    </text>
                }
                {
                    (activeGraph === 'inner')
                    && <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey - 20} textAnchor={textAnchor} fill="#333">{`genus: ${addStarToInfoKeyWords(payload.name)}`}</text>
                }

                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`Count: ${value}`}</text>
                <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill="#999">
                    {`(Rate ${(percent * 100).toFixed(2)}%)`}
                </text>
            </g>
        );
    };

    // const renderSpecialNote = () => {
    //     return (
    //         <Box
    //             sx={{
    //                 width: '450px',
    //                 mx: 'auto',
    //                 borderTop: '1px solid black'
    //             }}
    //         >
    //             <ResponsiveButton onClick={() => setNotesOpen(true)}>*Note</ResponsiveButton>
    //         </Box>
    //     )
    // }

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text
                x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'
                } dominantBaseline="central" >
                {`${(percent * 100).toFixed(0)}%`
                }
            </text>
        );
    };

    const hasNoMatchData = (data) => {
        return data.some(item => {
            const combinedString = Object.values(item).slice(0, 13).join('').trim();
            return combinedString === 'no match data';
        });
    };

    return (
        <div style={{ width: '100%', height: '400px', maxWidth: '1000px' }}>
            <NotesModal autofillModalOpen={notesOpen} setAutofillModalOpen={setNotesOpen} />

            {(!dataInner || hasNoMatchData(dataInner) || error) ?
                <>
                    <Typography sx={{
                        fontSize: ['.66rem', '.66rem', '1rem', '1rem', '1.5rem', '1.75rem'],
                        mt: '25%',
                        width: '100%',
                        mx: 'auto',
                        textAlign: 'center'
                    }}>No Matching Data</Typography>
                </>
                :
                ((!noMatchData) ?
                    <ResponsiveContainer width='120%' height='160%'>
                        <PieChart >
                            <Pie data={dataInner} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={pieChartRadius[0]} fill="#8884d8"
                                onMouseEnter={(e, i) => setArch('inner', i)}
                                activeIndex={activeGraph === 'inner' ? activeIndex : undefined}
                                activeShape={activeGraph === 'inner' ? renderActiveShape : undefined}
                                labelLine={false}
                                // label={renderCustomizedLabel}
                                label={false}
                            >
                                {dataInner.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={innerColors[index % 5]} />
                                ))}
                            </Pie>
                            <Pie data={dataOuter} dataKey="value" nameKey="name" cx="50%" cy="50%"
                                innerRadius={pieChartRadius[0]}
                                outerRadius={pieChartRadius[1]}
                                fill="#82ca9d"
                                label={false}
                                activeIndex={activeGraph === 'outer' ? activeIndex : undefined}
                                activeShape={activeGraph === 'outer' ? renderActiveShape : undefined}
                                onMouseEnter={(e, i) => setArch('outer', i)}
                            >
                                {dataOuter.map((entry, index) => (
                                    <Cell key={`cell-${index}`} fill={colors[index % 8]} />
                                ))}
                                <Tooltip />
                            </Pie>
                            {/* <Legend
                                wrapperStyle={{ bottom: '4rem' }}
                                content={renderSpecialNote}
                                verticalAlign='bottom'
                            /> */}
                        </PieChart>
                    </ResponsiveContainer> :
                    <Typography sx={{
                        fontSize: ['.66rem', '.66rem', '1rem', '1rem', '1.5rem', '1.75rem'],
                        mt: '25%',
                        width: '100%',
                        mx: 'auto',
                        textAlign: 'center'
                    }}>No Matching Data</Typography>
                )
            }
        </div>
    );
};

export default DataSummaryGraph;
