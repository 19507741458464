import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Label } from 'recharts';
import { interpolateRainbow } from 'd3-scale-chromatic';

// export default function AbundanceLineGraph({ data, onDownloadData, filters }) {

//     const jsonData = JSON.stringify(data);
//     const lastTwoEntries = JSON.parse(jsonData);
//     const parsedData = lastTwoEntries.slice(-2);

//     useEffect(() => {
//         onDownloadData();
//         // eslint-disable-next-line
//     }, [filters]);

//     // console.log(parsedData);

//     // Function to format month name
//     const formatMonth = (month) => {
//         const date = new Date(month + '-01');
//         return date.toLocaleString('en-us', { month: 'long' });
//     };

//     const allSpecies = new Set();

//     // const parseData = (data) => {
//     //     const graphData = [];

//     //     // Get all species
//     //     // eslint-disable-next-line
//     //     for (const [_, speciesCounts] of Object.entries(data)) {
//     //         for (const species in speciesCounts) {
//     //             allSpecies.add(species);
//     //         }
//     //     }
//     //     // Initialize species count to 0
//     //     const initialCounts = {};
//     //     for (const species of allSpecies) {
//     //         initialCounts[species] = 0;
//     //     }
//     //     for (const [month, speciesCounts] of Object.entries(data)) {
//     //         const monthName = formatMonth(month);
//     //         const monthData = { name: monthName, ...initialCounts }; // Spread initial counts
//     //         for (const [species, count] of Object.entries(speciesCounts)) {
//     //             // Assign counts to species dynamically
//     //             monthData[species] = count;
//     //         }
//     //         graphData.push(monthData);
//     //     }
//     //     return graphData;
//     // };

//     const parseData = (data) => {
//         const graphData = [];
//         const monthToDateMap = {};

//         for (const [_, speciesCounts] of Object.entries(data)) {
//             for (const species in speciesCounts) {
//                 allSpecies.add(species);
//             }
//         }

//         const initialCounts = {};
//         for (const species of allSpecies) {
//             initialCounts[species] = 0;
//         }

//         for (const [month, speciesCounts] of Object.entries(data)) {
//             const monthName = formatMonth(month);
//             const date = new Date(month + '-01');
//             monthToDateMap[monthName] = date;

//             const monthData = { name: monthName, ...initialCounts };
//             for (const [species, count] of Object.entries(speciesCounts)) {
//                 monthData[species] = count;
//             }
//             graphData.push(monthData);
//         }

//         // Sort graphData by date
//         graphData.sort((a, b) => monthToDateMap[a.name] - monthToDateMap[b.name]);

//         return graphData;
//     };

//     const graphData = parseData(parsedData);
//     console.log(graphData);


//     const numSpecies = allSpecies.size;
//     // const getColor = (i) => interpolateViridis(i / (numSpecies - 1));

//     const darkenColor = (color, factor) => {
//         const rgb = color.match(/\d+/g).map(Number);
//         const r = Math.max(0, Math.min(255, Math.floor(rgb[0] * factor)));
//         const g = Math.max(0, Math.min(255, Math.floor(rgb[1] * factor)));
//         const b = Math.max(0, Math.min(255, Math.floor(rgb[2] * factor)));
//         return `rgb(${r},${g},${b})`;
//     };

//     const getColor = (i) => {

//         const color = interpolateRainbow(i / (numSpecies - 1));
//         return darkenColor(color, 0.7);
//     };


//     const lines = Array.from(allSpecies).map((species, index) => (
//         <Line
//             key={index}
//             type="monotone"
//             dataKey={species}
//             // stroke={`#${Math.floor(Math.random() * 16777215).toString(16)}`} 
//             stroke={getColor(index)}
//             activeDot={{ r: 8 }} />
//     ));

//     return (

//         <div style={{ marginLeft: '80px' }}>
//             {/* Line graph section */}
//             <div style={{ textAlign: 'center', color: '#3f51b5', fontFamily: 'Roboto' }}>
//                 <div>SPECIES ABUNDANCE BY MONTH</div>
//                 {/* <div style={{ fontSize: '12px', fontWeight: '200px' }}>{'( '}Abundance over time{' )'}</div> */}
//             </div>

//             <div style={{ marginTop: '20px', height: '350px', width: '90%', fontSize: '11px' }}>


//                 <ResponsiveContainer width="100%" height="100%">
//                     <LineChart
//                         width={500}
//                         height={300}
//                         data={graphData}
//                         margin={{
//                             top: 5,
//                             right: 5,
//                             left: 5,
//                             bottom: 20,
//                         }}
//                     >
//                         <CartesianGrid strokeDasharray="3 3" />
//                         <XAxis dataKey="name" >
//                             <Label value="Month" offset={0} position="insideBottom" style={{ fontSize: '15px', fontWeight: 'bold' }} />
//                         </XAxis>
//                         <YAxis>
//                             <Label value="Count" angle={-90} position="insideLeft" style={{ textAnchor: 'middle', fontSize: '15px', fontWeight: 'bold' }} />
//                         </YAxis>
//                         <Tooltip />
//                         <Legend
//                             wrapperStyle={{
//                                 bottom: 0,
//                                 marginTop: 20,
//                                 fontSize: '13px',
//                             }}
//                         />
//                         {/* <Line type="monotone" dataKey="sp1" stroke="#8884d8" activeDot={{ r: 8 }} /> */}
//                         {/* <Line type="monotone" dataKey="sp2" stroke="#82ca9d" /> */}
//                         {lines}
//                     </LineChart>
//                 </ResponsiveContainer>

//             </div>

//         </div>



//     );




// }

export default function AbundanceLineGraph({ data, onDownloadData, filters }) {

    const jsonData = JSON.stringify(data);
    const parsedData = JSON.parse(jsonData);

    console.log("parsed data", parsedData);
    const [numMonths, setNumMonths] = useState(4);

    useEffect(() => {
        onDownloadData();
    }, [filters]);

    const parseDate = (dateString) => new Date(dateString + '-01');

    const formatMonth = (dateString) => {
        const [year, month] = dateString.split('-').map(Number);
        const date = new Date(year, month - 1);
        return date.toLocaleString('en-us', { month: 'long', year: 'numeric' });
    };

    const sortedData = parsedData.slice().sort((a, b) => parseDate(a.date) - parseDate(b.date));

    const uniqueMonths = Array.from(new Set(sortedData.map(entry => entry.date)))
        .sort((a, b) => parseDate(a) - parseDate(b));

    const lastMonths = uniqueMonths.slice(-numMonths);

    const filteredData = sortedData.filter(entry => lastMonths.includes(entry.date));

    const capitalizeFirstLetter = (str) => str.charAt(0).toUpperCase() + str.slice(1);

    const processData = (data) => {
        const allSpecies = new Set();

        data.forEach(entry => {
            entry.species.forEach(({ species }) => {
                allSpecies.add(capitalizeFirstLetter(species));
            });
        });

        const initialCounts = {};
        allSpecies.forEach(species => {
            initialCounts[species] = 0;
        });

        return data.map(entry => {
            const monthName = formatMonth(entry.date);
            const monthData = { name: monthName, ...initialCounts };
            entry.species.forEach(({ species, count }) => {
                const capitalizedSpecies = capitalizeFirstLetter(species);
                monthData[capitalizedSpecies] = (monthData[capitalizedSpecies] || 0) + count;
            });
            return monthData;
        });
    };

    const graphData = processData(filteredData);

    const monthOrder = { January: 1, February: 2, March: 3, April: 4, May: 5, June: 6, July: 7, August: 8, September: 9, October: 10, November: 11, December: 12 };
    graphData.sort((a, b) => {
        const monthA = monthOrder[a.name];
        const monthB = monthOrder[b.name];
        return monthA - monthB;
    });

    const numSpecies = new Set(graphData.flatMap(item => Object.keys(item).slice(1))).size;

    const darkenColor = (color, factor) => {
        const rgb = color.match(/\d+/g).map(Number);
        const r = Math.max(0, Math.min(255, Math.floor(rgb[0] * factor)));
        const g = Math.max(0, Math.min(255, Math.floor(rgb[1] * factor)));
        const b = Math.max(0, Math.min(255, Math.floor(rgb[2] * factor)));
        return `rgb(${r},${g},${b})`;
    };

    const getColor = (i) => {
        const color = interpolateRainbow(i / (numSpecies - 1));
        return darkenColor(color, 0.7);
    };

    const allSpecies = new Set(graphData.flatMap(item => Object.keys(item).slice(1)).map(species => capitalizeFirstLetter(species)));

    const lines = Array.from(allSpecies).map((species, index) => (
        <Line
            key={index}
            type="monotone"
            dataKey={species}
            stroke={getColor(index)}
            activeDot={{ r: 8 }} />
    ));

    // console.log("species", allSpecies); 

    return (
        <div style={{}}>
            <div style={{ textAlign: 'center', color: '#3f51b5', fontFamily: 'Roboto' }}>
                {/* <div>SPECIES ABUNDANCE BY MONTH</div> */}
            </div>
            <div style={{
                marginTop: '20px', textAlign: 'center', padding: '10px',
                border: '1px solid #ccc',
                borderRadius: '4px',
                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                display: 'inline-block',
            }}>
                <label htmlFor="numMonths">Previous no. of months: </label>
                <select
                    id="numMonths"
                    value={numMonths}
                    onChange={(e) => setNumMonths(Number(e.target.value))}
                >
                    {[...Array(12).keys()].map(num => (
                        <option key={num + 1} value={num + 1}>
                            {num + 1}
                        </option>
                    ))}
                </select>
            </div>
            <div style={{ marginTop: '20px', height: '350px', width: '90%', fontSize: '11px' }}>
                <ResponsiveContainer width="100%" height="100%">
                    <LineChart
                        width={500}
                        height={300}
                        data={graphData}
                        margin={{
                            top: 5,
                            right: 5,
                            left: 5,
                            bottom: 20,
                        }}
                    >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis dataKey="name">
                            <Label value="Month/Year" offset={0} position="insideBottom" style={{ fontSize: '15px', fontWeight: 'bold' }} />
                        </XAxis>
                        <YAxis>
                            <Label value="Count" angle={-90} position="insideLeft" style={{ textAnchor: 'middle', fontSize: '15px', fontWeight: 'bold' }} />
                        </YAxis>
                        <Tooltip />
                        <Legend
                            wrapperStyle={{
                                bottom: 0,
                                marginTop: 20,
                                fontSize: '13px',
                            }}
                        />
                        {lines}
                    </LineChart>
                </ResponsiveContainer>
            </div>
        </div>
    );
}