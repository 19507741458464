import {
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  CLEAR_NOTIFICATIONS,
  CLEAR_ERRORS,
} from "../actions/types";
import _ from "lodash";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
  const { error } = action;
  if (
    error &&
    (typeof error === "string" || error instanceof String) &&
    error !== ""
  ) {
    return {
      ...state,
      error,
    };
  } else {
    const notificationCount = state?.count || 0;
    switch (action.type) {
      case CREATE_NOTIFICATION:
        return {
          ...state,
          [action.payload?.id]: action.payload,
          count: notificationCount + 1,
        };
      case DELETE_NOTIFICATION:
        return {
          ..._.omit(state, action.payload),
          count: Math.max(notificationCount - 1, 0),
        };
      case CLEAR_NOTIFICATIONS:
        return {
          error: state?.error,
          count: 0,
        };
      case CLEAR_ERRORS:
        return _.omit(state, "error");
      default:
        return state;
    }
  }
};
