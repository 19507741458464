import {
  WEBSOCKET_OPENED,
  WEBSOCKET_CLOSED,
  WEBSOCKET_REAUTHENTICATING,
  WEBSOCKET_ERROR,
  ESTABLISH_STATES_DEVICE,
} from "../actions/types";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = { status: "disconnected" }, action) => {
  switch (action.type) {
    case WEBSOCKET_OPENED: // websocket is opened
      return { ...state, status: "open" };
    case ESTABLISH_STATES_DEVICE: // finish authentication
      return { ...state, socketId: action.payload?.socket_id };
    case WEBSOCKET_CLOSED: // websocket lost connection (waiting for ping)
      return { ...state, status: "closed" };
    case WEBSOCKET_REAUTHENTICATING: // ping cycle complete, attempting to reauthenticate
      return { ...state, status: "reauthenticating" };
    case WEBSOCKET_ERROR:
      console.log("websocket error:", action.payload);
      return state;
    default:
      return state;
  }
};
