import { Button } from "@mui/material";

const ResponsiveButton = (props) => {
    const { sx, ...rest } = props

    return <Button {...rest}
        sx={{
            fontSize: ["0.55rem", "0.55rem", "0.6rem", "0.6rem", "0.875rem", "0.875rem"],
            // padding: ["0.1875rem 0.625rem", "0.1875rem 0.625rem", "0.1875rem 0.625rem",
            //     "0.1875rem 0.625rem", "0.3125rem 0.9375rem", "0.3125rem 0.9375rem"],
            // minWidth: ["2.68rem", "2.68rem", "2.68rem", "2.68rem", "4rem", "4rem"],
            // borderRadius: ["3px", "3px", "3px", "3px", "4px", "4px"],
            "& .MuiSvgIcon-root": {
                fontSize: ["0.55rem", "0.55rem", "0.83rem !important", "0.83rem !important", "1.25rem !important", "1.25rem !important"]
            },
            ...sx
        }}
    />;
};
export default ResponsiveButton;