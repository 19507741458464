import React from "react";
import { connect } from "react-redux";
import ResponsiveButton from "./ResponsiveButton";

const buttonStyles = {
  background: 'status.success',
  color: 'white.main',
}

const RemoteSubmitButton = ({
  onClick,
  id,
  disabled,
  settings = {},
  children = null,
}) => {
  const { variant = "contained" } = settings;
  const handleClick = async (e) => {
    onClick(e);
  };

  return (
    <ResponsiveButton
      onClick={handleClick}
      sx={buttonStyles}
      variant={variant}
      disabled={disabled}
      id={id}
    >
      {children}
    </ResponsiveButton>
  );
};

export default connect()(RemoteSubmitButton);
