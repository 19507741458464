import React from "react";
import SyncIcon from "@mui/icons-material/Sync";
import CancelIcon from "@mui/icons-material/Cancel";
import SignalCellular4BarTwoToneIcon from "@mui/icons-material/SignalCellular4BarTwoTone";
import { Box, MenuItem, InputLabel, InputAdornment, IconButton, FormControl, Fab, Select, CircularProgress, Paper, FilledInput } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

const fabSx = {
  fontSize: ["0.55rem", "0.55rem", "0.6rem", "0.6rem", "0.875rem", "0.875rem"],
  "& .MuiSvgIcon-root": {
    fontSize: [
      "0.55rem",
      "0.55rem",
      "0.83rem !important",
      "0.83rem !important",
      "1.25rem !important",
      "1.25rem !important",
    ],
  },
};

const selectSx = {
  m: ".5rem",
  minWidth: '300px',
  "& .MuiInputLabel-root": {
    width: "100%",
    fontSize: [".5rem", ".5rem", ".8rem", ".8rem", "1.15rem", "1.15rem"],
    transform: {
      md: "translate(12px, 10px) scale(1)",
      lg: "translate(12px, 16px) scale(1)",
    },
  },
  "& .MuiInputLabel-shrink": {
    transform: {
      md: "translate(12px, 3px) scale(0.75)",
      lg: "translate(12px, 7px) scale(0.75)",
    },
  },
  "& .MuiSelect-select": {
    fontSize: [".5rem", ".5rem", ".75rem", ".75rem", "1rem", "1rem"],
    paddingTop: [
      "1.05rem",
      "1.05rem",
      "1.05rem",
      "1.05rem",
      "1.5625rem",
      "1.5625rem",
    ],
    paddingBottom: [
      "0.335rem",
      "0.335rem",
      "0.335rem",
      "0.335rem",
      "0.5rem",
      "0.5rem",
    ],
    lineHeight: ["1rem", "1rem", "1rem", "1rem", "1.4375rem", "1.4375rem"],
    minHeight: [
      "0.96rem",
      "0.96rem",
      "0.96rem !important",
      "0.96rem !important",
      "1.4375rem",
      "1.4375rem",
    ],
  },
};

const textAreaSx = {
  m: ".5rem",
  minWidth: '300px',
  "& .MuiInputLabel-root": {
    fontSize: [".5rem", ".5rem", ".8rem", ".8rem", "1.15rem", "1.15rem"],
    transform: {
      md: "translate(12px, 10px) scale(1)",
      lg: "translate(12px, 16px) scale(1)",
    },
  },
  "& .MuiInputLabel-shrink": {
    transform: {
      md: "translate(12px, 3px) scale(0.75)",
      lg: "translate(12px, 7px) scale(0.75)",
    },
  },
  "& .MuiInputBase-input": {
    paddingTop: "0rem",
    paddingBottom: "0rem",
  },
  "& .MuiFilledInput-root": {
    paddingTop: ["1.0rem", "1.0rem", "1.0rem", "1.0rem", "25px", "25px"],
    paddingBottom: ["5px", "5px", "5px", "5px", "8px", "8px"],
    fontSize: [".5rem", ".5rem", ".75rem", ".75rem", "1rem", "1rem"],
    lineHeight: ["1rem", "1rem", "1rem", "1rem", "1.4375rem", "1.4375rem"],
  },
};

const DeviceSetup = ({
  setupState,
  handleChangeSSID,
  handleChangePSK,
  handleConnectButtonPressed,
  handleRescanWifiButtonPressed,
  handleCancelButtonPressed,
  wifiList,
  ssid,
  psk,
}) => {
  const connecting = setupState === "connecting";
  const loadingWifi = !wifiList?.length;
  const loading = loadingWifi || connecting;
  const disabled = loading || ssid.length === 0;
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  let ssidList = null;
  if (Array.isArray(wifiList)) {
    ssidList = wifiList.map((ssidStr, i) => (
      <MenuItem
        value={ssidStr}
        key={`${ssidStr}-${i}`}
        sx={{
          fontSize: [".5rem", ".5rem", ".8rem", ".8rem", "1.15rem", "1.15rem"],
        }}
      >
        {ssidStr}
      </MenuItem>
    ));
  }

  return (
    <Paper
      sx={{
        height: ["560px", "560px", "560px", "560px", "786px", "786px"],
        width: ["100vw", "544px", "544px", "544px", "824px", "824px"],
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      className={
        "bg-white rounded-xl border-4 border-transparent m-auto transition-opacity duration-1000"
      }
    >
      <Box
        className={"flex flex-1 flex-col items-center justify-center"}
        id={"wifi-credentials"}
      >
        <span
          className={"text-gray-600 font-medium px-2"}
          style={{ marginRight: 52 }}
        >
          Enter WiFi Credentials
        </span>
        <form className={"flex flex-col py-4 items-start"} id={"bt-setup-form"}>
          <div className={"flex flex-1 flex-row items-center justify-center"}>
            <FormControl
              sx={selectSx}>
              <InputLabel
                id="ssid-select-label"
                margin="dense"
                className={"text-gray-800 font-small"}
              >
                WiFi SSID
              </InputLabel>
              <Select
                labelId="ssid-select-label"
                id="ssid-select"
                variant="filled"
                value={ssid}
                onChange={handleChangeSSID}
                disabled={loading}
              >
                {ssidList}
              </Select>
            </FormControl>

            {loadingWifi ? (
              <CircularProgress className={"p-2"} />
            ) : (
              <Fab
                id={"wifi-rescan-button"}
                color={"primary"}
                size={"small"}
                aria-label={"Rescan"}
                onClick={handleRescanWifiButtonPressed}
              >
                <SyncIcon />
              </Fab>
            )}
          </div>
          <FormControl sx={textAreaSx} variant="filled">
            <InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
            <FilledInput
              id="psk-field"
              labelid="psk-field-label"
              label="WiFi PSK"
              variant="filled"
              type={showPassword ? 'text' : 'password'}
              value={psk}
              onChange={handleChangePSK}
              disabled={loading}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
          </FormControl>
        </form>
        <div
          className={"flex flex-row items-center justify-center"}
          style={{ marginRight: 52 }}
        >
          <Fab
            id={"wifi-connect-button"}
            style={{ marginRight: "0.5rem" }}
            variant={"extended"}
            color={"primary"}
            size={"small"}
            disabled={disabled}
            onClick={handleConnectButtonPressed}
            sx={fabSx}
          >
            {connecting ? (
              <CircularProgress className={"p-2"} />
            ) : (
              <SignalCellular4BarTwoToneIcon className={"mr-1"} />
            )}
            Connect
          </Fab>
          {handleCancelButtonPressed ? (
            <Fab
              id={"wifi-cancel-button"}
              style={{ marginLeft: "0.5rem" }}
              variant={"extended"}
              color={"secondary"}
              size={"small"}
              disabled={!connecting}
              onClick={handleCancelButtonPressed}
              sx={fabSx}
            >
              {<CancelIcon className={"mr-1"} />}
              Cancel
            </Fab>
          ) : null}
        </div>
      </Box>
    </Paper >
  );
};

export default DeviceSetup;
