import React from "react";
import ReactDOM from "react-dom";
import _ from "lodash";
import "sanitize.css/sanitize.css";
import "./styles/app.css";
import "./styles/customeLeafletMap.css";
import { compose } from "redux";
import App from "./App";

// const composeEnhancers =
//   process.env.REACT_APP_ENV !== "production"
//     ? window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__?.({
//         trace: true,
//         traceLimit: 25,
//       }) || compose
//     : compose;

let composeEnhancers = compose;
if (
  process.env.REACT_APP_ENV !== "production" &&
  window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
  _.isFunction(window?.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__)
) {
  composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    trace: true,
    traceLimit: 25,
  });
}

ReactDOM.render(
  <App composeEnhancers={composeEnhancers} />,
  document.querySelector("#root")
);
