import React from 'react'
import { FormSpy } from 'react-final-form'
import { diff } from 'deep-object-diff'

class AutoSave extends React.Component {
    constructor(props) {
        super(props)
        this.state = { values: this.props.values, submitting: false }
    }

    componentWillReceiveProps(nextProps) {
        if (this.timeout) {
            clearTimeout(this.timeout)
        }
        // console.log('componentWillReceiveProps componentWillReceiveProps')
        this.timeout = setTimeout(this.save, this.props.debounce)
    }

    save = async () => {
        if (this.promise) {
            await this.promise
        }
        const { values, save, form } = this.props
        let difference = diff(this.state.values, values)
        if (difference['tags']) {
            difference['tags'] = values['tags']
        }
        if (Object.keys(difference).length && Object.keys(form.getState()?.errors)?.length === 0) {
            // values have changed
            this.setState({ submitting: true, values })
            this.promise = save(values, difference)
            await this.promise
            delete this.promise
            this.setState({ submitting: false })
        }
    }

    render() {
        // This component doesn't have to render anything, but it can render
        // submitting state.
        return (
            this.state.submitting && <div className="submitting">Submitting...</div>
        )
    }
}

// Make a HOC
// This is not the only way to accomplish auto-save, but it does let us:
// - Use built-in React lifecycle methods to listen for changes
// - Maintain state of when we are submitting
// - Render a message when submitting
// - Pass in debounce and save props nicely
//eslint-disable-next-line
export default props => (
    <FormSpy {...props} subscription={{ values: true }} component={AutoSave} />
)
