import {
  CREATE_TRAP_SITE,
  EDIT_TRAP_SITE,
  FETCH_TRAP_SITES,
  DELETE_TRAP_SITE,
  SET_TRAP_SITE_LOADING,
  CREATE_NOTIFICATION
} from "./types";

export const createTrapSite = (formValues, sendRequest) => async (dispatch) => {
  const response = await sendRequest("post")({
    url: "/trap-site/",
    data: formValues,
    devPort: 5005,
  });
  dispatch({ type: CREATE_TRAP_SITE, payload: response.data });
};

export const editTrapSite = (id, formValues, sendRequest) => async (
  dispatch
) => {
  const response = await sendRequest("put")({
    url: `/trap-site/${id}`,
    data: { ...formValues },
    devPort: 5005,
  });
  dispatch({ type: EDIT_TRAP_SITE, payload: response.data });
};

export const fetchTrapSites = (queryParams, sendRequest) => async (
  dispatch
) => {
  dispatch({ type: SET_TRAP_SITE_LOADING, payload: true });
  let response
  try {
    response = await sendRequest("get")({
      url: "/trap-sites/",
      params: queryParams,
      devPort: 5005,
    });
  } catch (err) {
    if ((err?.response.status === 500) && (err?.response?.data?.message === 'TrapSite could not be found')) {
      response = { data: {} }
    }
  }
  dispatch({ type: FETCH_TRAP_SITES, payload: response.data });
  dispatch({ type: SET_TRAP_SITE_LOADING, payload: false });
};

export const deleteTrapSite = (id, sendRequest) => async (dispatch) => {
  // eslint-disable-next-line no-unused-vars
  try {
    await sendRequest("delete")({
      url: `/trap-site/${id}`,
      devPort: 5005,
    });
    dispatch({ type: DELETE_TRAP_SITE, payload: id });
  } catch (err) {
    console.error(err)
    const msg = 'TrapSite could not be deleted'
    let errMsg = err.response.data.message
    if (errMsg?.indexOf(msg) !== -1) {
      dispatch({
        type: CREATE_NOTIFICATION,
        payload: {
          id: Date.now(),
          message: msg + ": trapsite is being used by some tray" + errMsg?.slice(msg.length),
          severity: "error",
          anchor: { vertical: "top", horizontal: "center" },
        },
      });
    }

  }
};
