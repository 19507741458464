import {
  CREATE_TRAY,
  EDIT_TRAY,
  FETCH_TRAYS,
  FETCH_TRAY,
  DELETE_TRAY,
  SET_TRAY_LOADING,
  CLEAR_TRAY
} from "./types";

export const createTray = (formValues, sendRequest) => async (dispatch) => {
  const response = await sendRequest("post")({
    url: "/tray",
    data: formValues,
    devPort: 5005,
  });
  dispatch({ type: CREATE_TRAY, payload: response.data });
};

export const editTray = (id, formValues, sendRequest) => async (dispatch) => {
  const response = await sendRequest("patch")({
    url: `/tray/${id}`,
    data: { ...formValues },
    devPort: 5005,
  });
  dispatch({ type: EDIT_TRAY, payload: response.data });
};

export const fetchTray = (id, queryParams, sendRequest) => async (dispatch) => {
  const response = await sendRequest("get")({
    url: `/tray/${id}`,
    params: queryParams,
    devPort: 5005,
  });
  dispatch({ type: FETCH_TRAY, payload: response.data });
};

export const clearTrays = (deviceUuid) => async (dispatch) => {
  dispatch({ type: CLEAR_TRAY, payload: { deviceUuid } });
}

export const fetchTrays =
  (toolId, queryParams, sendRequest, deviceUuid = null) =>
    async (dispatch) => {
      try {

        dispatch({ type: SET_TRAY_LOADING, payload: true });
        let response = await sendRequest("get")({
          url: `/tool/${deviceUuid}/trays`,
          params: { ...queryParams, skipChildren: true },
          devPort: 5005,
        });
        dispatch({ type: FETCH_TRAYS, payload: { ...response.data, deviceUuid } });
        response = await sendRequest("get")({
          url: `/tool/${deviceUuid}/trays`,
          params: { ...queryParams, skipChildren: false },
          devPort: 5005,
        });
        dispatch({ type: FETCH_TRAYS, payload: { ...response.data, deviceUuid } });
        dispatch({ type: SET_TRAY_LOADING, payload: false });
      } catch (err) {
        console.error(err)
      }
    };

export const initAllTrays = (toolIds, queryParams, sendRequest, deviceUuid = null) =>
  async (dispatch) => {
    // eslint-disable-next-line no-unused-vars
    for (const [_, { uuid: deviceUuid }] of toolIds) {
      try {
        dispatch({ type: SET_TRAY_LOADING, payload: true });
        let response = await sendRequest("get")({
          url: `/tool/${deviceUuid}/trays`,
          params: { ...queryParams, skipChildren: true },
          devPort: 5005,
        });
        dispatch({ type: FETCH_TRAYS, payload: { ...response.data, deviceUuid } });
        response = await sendRequest("get")({
          url: `/tool/${deviceUuid}/trays`,
          params: { ...queryParams, skipChildren: false },
          devPort: 5005,
        });
        dispatch({ type: FETCH_TRAYS, payload: { ...response.data, deviceUuid } });
        dispatch({ type: SET_TRAY_LOADING, payload: false });
      } catch (err) {
        console.error(err)
      }
    }
  };

export const deleteTray = (id, sendRequest) => async (dispatch) => {
  // eslint-disable-next-line no-unused-vars
  const response = await sendRequest("delete")({
    url: `/tray/${id}`,
    devPort: 5005,
  });
  dispatch({ type: DELETE_TRAY, payload: id });
};
