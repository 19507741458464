import {
    UPDATE_GENERAL_FILTERS,
    CLEAR_GENERAL_FILTERS,
    UPDATE_IS_TRAY_FILTERS_MODAL
} from "../actions/types";

const DEFAULT_GENERAL_FILTERS = {
    deviceID: [],
    imagingStartDate: null,
    imagingEndDate: null,
    specimenCaptureStartDate: null,
    specimenCaptureEndDate: null,
    genusSpecies: [],
    plates: [],
    trapSitesFilter: [],
    trapTypesFilter: [],
    algorithmGenusSpeciesFilter: [],
    usersFilter: [],
    sexFilter: [],
    algorithmSexFilter: [],
    specimenTagFilter: [],
    excludeUnknown: false,
    excludeEmpty: true,
    excludeAlgorithmUnsure: false,
}

const DEFAULT_USER_SETTINGS = {
    generalFilters: DEFAULT_GENERAL_FILTERS,
    isTrayModalOpen: false
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = DEFAULT_USER_SETTINGS, action) => {
    switch (action.type) {
        case UPDATE_GENERAL_FILTERS:
            return { ...state, generalFilters: { ...state.generalFilters, ...action.payload.generalFilters } };
        case CLEAR_GENERAL_FILTERS:
            return { ...state, generalFilters: DEFAULT_GENERAL_FILTERS };
        case UPDATE_IS_TRAY_FILTERS_MODAL:
            return { ...state, isTrayModalOpen: action.payload }
        default:
            return state;
    }
};
