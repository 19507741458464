import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import DataTable from "../../../../DataTable/DataTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useWebServer } from "../../../../../providers/WebServerProvider";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../AppRouter/routes";
import { fetchTrapSites, deleteTrapSite } from "../../../../../actions";
import { setMapCenter } from "../../../../../actions/map";

const columns = [
  {
    field: "displayName",
    label: "Name",
    isSortable: true,
    align: "left",
    sx: { maxWidth: "100px" },
  },
  {
    field: "address",
    label: "Address",
    isSortable: true,
    align: "right",
    sx: {
      maxWidth: ["6rem", "6rem", "6rem", "13.75rem", "13.75rem", "13.75rem"],
    },
  },
];

const options = {
  allowCreate: true,
  allowClick: true,
};

const buttonStyles = {
  infoButton: {
    color: "status.info",
  },
  editButton: {
    "&:hover, &:focus": {
      color: "status.info",
    },
  },
  deleteButton: {
    "&:hover, &:focus": {
      color: "status.danger",
    },
  },
};

const Index = ({
  trapSites,
  count,
  page,
  pageSize,
  order,
  orderBy,
  fetchTrapSites,
  deleteTrapSite,
  setMapCenter,
}) => {
  const { sendRequest } = useWebServer();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [trapSitesData, setTrapSitesData] = useState([]);

  const handleEditTrapSite = (rowData) => {
    history.push(ROUTES.editTrapSite.replace(":id", rowData.id));
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      await handleFetchTrapSites();
      setLoading(false);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, pageSize, orderBy, order]);

  useEffect(() => {
    setTrapSitesData(
      _.chain(trapSites)
        .orderBy([orderBy], [order])
        .slice(page * pageSize, Math.min(count, (page + 1) * pageSize))
        .value()
    );
  }, [trapSites, count, page, pageSize, orderBy, order]);

  const handleAddTrapSite = () => {
    history.push(ROUTES.createTrapSite);
  };

  const handleDeleteTrapSite = async (row) => {
    console.log("handleDeleteTrapSite", row.id);
    await deleteTrapSite(row.id, sendRequest);
  };

  const handleFetchTrapSites = async () => {
    await fetchTrapSites({ page, pageSize, orderBy, order }, sendRequest);
    if ((count || 0) > (page + 1) * pageSize)
      await fetchTrapSites(
        { page: page + 1, pageSize, orderBy, order },
        sendRequest
      );
  };

  return (
    <div id={"trap-sites"}>
      <DataTable
        dataList={trapSitesData}
        total={count || 0}
        tableName={"Collection Site"}
        columns={columns}
        isFetchingData={loading}
        handleAddData={handleAddTrapSite}
        options={options}
        actions={[
          {
            name: "Edit",
            icon: EditIcon,
            position: "primary",
            dialog: null,
            sx: buttonStyles.editButton,
            handler: handleEditTrapSite,
          },
          {
            name: "Delete",
            icon: DeleteIcon,
            position: "primary",
            dialog: (row) =>
              `Are you sure you want to delete Collection Site ${row.displayName}?\nThis action can not be undone`,
            sx: buttonStyles.deleteButton,
            handler: handleDeleteTrapSite,
          },
        ]}
        onClickRow={(row) => {
          const lat = row?.gpsLocation?.latitude || 0;
          const lon = row?.gpsLocation?.longitude || 0;
          setMapCenter([lat, lon]);
        }}
      />
    </div>
  );
};

export const mapStateToProps = (state) => {
  const { page, pageSize, order, orderBy } =
    state?.settings?.["Collection Site"];
  const { count, ...trapSites } = state.trapSites;
  return {
    trapSites: Object.values(trapSites),
    count,
    page,
    pageSize,
    order,
    orderBy,
  };
};

export default connect(mapStateToProps, {
  fetchTrapSites,
  deleteTrapSite,
  setMapCenter,
})(Index);
