import React, { useEffect, useRef, useState } from "react";
import { Marker, Popup } from "react-leaflet";
import { useMapEvents } from '../../../../node_modules/react-leaflet/lib/hooks'
import { Typography, Box, useMediaQuery } from "@mui/material";
import { connect } from "react-redux";
import * as L from "leaflet";
import homeIconSvg from '../../../assets/icons/homeIconSvg.svg'
import { setMapCenterPlacementMode } from "../../../actions/map";
import { updateUserMapCenterSettings } from "../../../actions";
import { useLocation } from "react-router-dom";

const markerFontSizeSx = { fontSize: [".75rem", ".75rem", ".75rem", "0.75rem", "1.125rem", "1.125rem"], m: "0 !important" }

const MapCenterMarker = ({ latitude, longitude, setMapCenterPlacementMode, mapCenterPlacementMode, updateUserMapCenterSettings, userFetched }) => {
    const [show, setShow] = useState(false)
    const markerRef = useRef()
    const lgMatches = useMediaQuery((theme) => theme.breakpoints.up('lg'));
    const location = useLocation()
    const LeafIcon = L.Icon.extend({
        options: {}
    });

    useEffect(() => {
        setShow(location.pathname === '/details/settings' && userFetched ? true : false)
    }, [location, userFetched])

    const homeIcon = new LeafIcon({
        iconUrl: homeIconSvg,
        iconAnchor: [20, 5],
        className: 'leaflet-homeIcon'
    })
    // fill = "#3f51b5"

    useEffect(() => {
        if (mapCenterPlacementMode) {
            // eslint-disable-next-line
            document.getElementById('map').style.cursor = 'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="50" width="50" style=" fill\: rgb(63, 81, 181)\;" ><path d="M8.833 32.833v-16.75l11.209-8.416 11.125 8.416v16.75h-8.125V22.375h-6.084v10.458Z"/></svg>\') 5 25,auto'
        } else {
            document.getElementById('map').style.cursor = 'auto'
        }
    }, [mapCenterPlacementMode])

    useMapEvents({
        click: (e) => {
            let lat = e?.latlng?.lat || 0
            let lng = e.latlng.lng || 0
            if (mapCenterPlacementMode) {
                updateUserMapCenterSettings(`${lat.toFixed(7)}, ${lng.toFixed(7)}`)
                setMapCenterPlacementMode(false)
            }
        },
        mouseout: () => {
            if (mapCenterPlacementMode) {
                setMapCenterPlacementMode(false)
            }
        },
    })


    return (show && <Marker
        ref={markerRef}
        key={'map-center'}
        position={[latitude, longitude]}
        onClick={() => console.log('hello')}
        icon={homeIcon}
    >
        <Popup maxWidth={lgMatches ? 500 : 300} width={lgMatches ? 500 : 300} keepInView={false} autoClose={true}>
            <Box >
                <Typography sx={markerFontSizeSx}>
                    Map Center<br />
                    {`GPS: ${'\xa0'.repeat(7)}${latitude?.toFixed(5) || 0}, ${longitude?.toFixed(5) || 0}`}</Typography>
            </Box>
        </Popup>
    </Marker>)
}


export const mapStateToProps = (state) => {
    let gps = state.user?.mapCenter?.split(',').map(parseFloat) || [39.294201, -76.588686]
    let userFetched = state.user.fetched
    let mapCenterPlacementMode = state.map.mapCenterPlacementMode
    return {
        latitude: gps[0] || 39.294201,
        longitude: gps[1] || -76.588686,
        mapCenterPlacementMode,
        userFetched
    };
};

export default connect(mapStateToProps, { setMapCenterPlacementMode, updateUserMapCenterSettings })(MapCenterMarker);

