import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";

const UpdateOrganizationDialog = ({
  organizations,
  selectedOrg,
  setSelectedOrg,
  onConfirm,
  cancelMessage = "Cancel",
}) => {
  const [loaded, setLoaded] = useState(false);
  const [uuidVal, setUuidVal] = useState("");
  const [nameVal, setNameVal] = useState("");
  const [addressVal, setAddressVal] = useState("");
  const [gpsVal, setGpsVal] = useState("");
  const [uuidError, setUuidError] = useState(["UUID must be supplied."]);
  const [nameError, setNameError] = useState(["Name must be supplied."]);
  const [addressError, setAddressError] = useState([
    "Address must be supplied.",
  ]);
  const [gpsError, setGpsError] = useState(["GPS must be supplied."]);

  useEffect(() => {
    if (selectedOrg && organizations?.[selectedOrg]) {
      const org = organizations?.[selectedOrg];
      setUuidVal(org?.uuid);
      setNameVal(org?.display_name);
      setAddressVal(org?.address);
      setGpsVal(org?.map_center);
      setLoaded(true);
    }
  }, [selectedOrg, organizations]);

  const handleClose = () => {
    setUuidVal("");
    setNameVal("");
    setAddressVal("");
    setGpsVal("");
    setSelectedOrg(null);
  };

  useEffect(() => {
    const validateUuid = (val) => {
      let currErrors = [];
      if (!val) {
        currErrors = [...currErrors, `UUID must be unique`];
      }

      return currErrors;
    };

    setUuidError(validateUuid(uuidVal));
  }, [setUuidError, uuidVal, organizations]);

  useEffect(() => {
    const validateName = (val) => {
      const BANNED_CHARACTERS = [";", ",", "."];
      // check for errors
      let currErrors = [];
      if (val.length < 4) {
        currErrors = [
          ...currErrors,
          "Name must be at least 4 characters long.",
        ];
      }
      if (val.trim() !== val) {
        currErrors = [...currErrors, "Name cannot start or end with a space."];
      }
      BANNED_CHARACTERS.filter((ban) => val.includes(ban)).forEach((banned) => {
        currErrors = [...currErrors, `Name cannot include character ${banned}`];
      });

      return currErrors;
    };
    setNameError(validateName(nameVal));
  }, [setNameError, nameVal]);

  useEffect(() => {
    const validateAddress = (val) => {
      // const BANNED_CHARACTERS = [";", ",", "."];
      // check for errors
      let currErrors = [];
      if (!val) {
        return currErrors;
      }
      if (val.length < 4) {
        currErrors = [
          ...currErrors,
          "Address must be at least 4 characters long.",
        ];
      }
      if (val.trim() !== val) {
        currErrors = [
          ...currErrors,
          "Address cannot start or end with a space.",
        ];
      }
      // BANNED_CHARACTERS.filter((ban) => val.includes(ban)).forEach((banned) => {
      //   currErrors = [
      //     ...currErrors,
      //     `Address cannot include character ${banned}`,
      //   ];
      // });

      return currErrors;
    };
    setAddressError(validateAddress(addressVal));
  }, [setAddressError, addressVal]);

  useEffect(() => {
    const validateGps = (val) => {
      // check for errors
      let currErrors = [];
      if (val.length < 1) {
        currErrors = [...currErrors, "You must specify a GPS"];
      } else {
        if (val.trim() !== val) {
          console.log("no space", `"${val.trim()}"`, `"${val}"`);
          currErrors = [...currErrors, "GPS cannot start or end with a space."];
        }
        const latlonarr = val.split(",").map((x) => parseFloat(x));
        if (
          Array.isArray(latlonarr) &&
          latlonarr.length === 2 &&
          !latlonarr.some((x) => isNaN(x) || x === null || x === undefined)
        ) {
          const [latitude, longitude] = latlonarr;
          if (latitude < -90 || latitude > 90) {
            currErrors = [...currErrors, "Latitude must be between -90 and 90"];
          }
          if (longitude < -180 || longitude > 180) {
            currErrors = [
              ...currErrors,
              "Longitude must be between -180 and 180",
            ];
          }
        } else {
          currErrors = [...currErrors, "GPS has invalid format"];
        }
      }

      return currErrors;
    };
    setGpsError(validateGps(gpsVal));
  }, [setGpsError, gpsVal]);

  const onFieldChange = (field) => (event) => {
    const newVal = event.target.value || "";
    let val;
    let setVal;
    switch (field) {
      case "uuid":
        val = uuidVal;
        setVal = setUuidVal;
        break;
      case "name":
        val = nameVal;
        setVal = setNameVal;
        break;
      case "address":
        val = addressVal;
        setVal = setAddressVal;
        break;
      case "gps":
        val = gpsVal;
        setVal = setGpsVal;
        break;
      default:
        console.error("Invalid field");
        return;
    }
    if (newVal !== val) {
      setVal(newVal);
    }
  };

  const isSubmitValid = () =>
    !(!!uuidError && Array.isArray(uuidError) && uuidError.length > 0) &&
    !(!!nameError && Array.isArray(nameError) && nameError.length > 0) &&
    !(
      !!addressError &&
      Array.isArray(addressError) &&
      addressError.length > 0
    ) &&
    !(!!gpsError && Array.isArray(gpsError) && gpsError.length > 0);

  return !!selectedOrg && loaded ? (
    <Dialog
      open={!!selectedOrg}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{`Update Organization`}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <TextField
              id="outlined-uuid-field"
              error={
                !!uuidError && Array.isArray(uuidError) && uuidError.length > 0
              }
              label="UUID"
              value={uuidVal}
              onChange={onFieldChange("uuid")}
              helperText={uuidError?.[0] || null}
              disabled
            />
            <TextField
              id="outlined-name-field"
              error={
                !!nameError && Array.isArray(nameError) && nameError.length > 0
              }
              label="Name"
              value={nameVal}
              onChange={onFieldChange("name")}
              helperText={nameError?.[0] || null}
            />
            <TextField
              id="outlined-address-field"
              error={
                !!addressError &&
                Array.isArray(addressError) &&
                addressError.length > 0
              }
              label="Address"
              value={addressVal}
              onChange={onFieldChange("address")}
              helperText={addressError?.[0] || null}
            />
            <TextField
              id="outlined-gps-field"
              error={
                !!gpsError && Array.isArray(gpsError) && gpsError.length > 0
              }
              label="GPS"
              value={gpsVal}
              onChange={onFieldChange("gps")}
              helperText={gpsError?.[0] || null}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="secondary"
          id={"dialog-cancel"}
        >
          {cancelMessage}
        </Button>
        <Button
          variant="contained"
          disabled={!isSubmitValid()}
          onClick={() => {
            handleClose();
            onConfirm({
              uuid: uuidVal,
              name: nameVal,
              address: addressVal,
              gps: gpsVal,
              vectorTypes: ["mosquito", "tick"],
            });
          }}
          id={"dialog-confirm"}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default UpdateOrganizationDialog;
