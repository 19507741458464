export const getLut = (lower, upper, srcMin = 0, srcMax = 255) => {
  const slope = (srcMax - srcMin) / (upper - lower);
  const intercept = srcMin - slope * lower;
  return Object.fromEntries(
    [...Array(srcMax + 1 - srcMin).keys()].map((_, i) => [
      i + srcMin,
      i + srcMin < lower
        ? 0
        : i + srcMin > upper
          ? 255
          : Math.round(slope * (i + srcMin) + intercept),
    ])
  );
};

export const implementLut = (imageData, lut) => {
  if (imageData && imageData.data?.length) {
    for (let i = 0; i < imageData.data.length; i += 4) {
      imageData.data[i] = lut[imageData.data[i]];
      imageData.data[i + 1] = lut[imageData.data[i + 1]];
      imageData.data[i + 2] = lut[imageData.data[i + 2]];
      // s channel is unchanged
    }
  }
  return imageData;
};

export const hardClip = (imageData, lower, upper) => {
  //   console.log("Called hardClip with lower: ", lower, " and upper: ", upper);
  const lut = getLut(lower, upper);
  return implementLut(imageData, lut);
};

export const percUpHardLowClip = (
  imageData,
  lowerThresh = 20,
  upper = 0.2
) => {
  const { histogram, count: sumHist } = getHistogram(imageData);
  const upperTarget = Math.round(sumHist * upper);
  let upSum = 0;
  let upperThresh = histogram.length;
  for (let i = histogram.length - 1; i >= 0; i--) {
    const val = histogram[i];
    upSum += val;
    if (upSum >= upperTarget) {
      upperThresh = i;
      break;
    }
  }
  return hardClip(imageData, lowerThresh, upperThresh);
};

export const getHistogram = (imageData) => {
  // const imageData = image.get_image_data();
  const histogram = [...Array(256).keys()].map((i) => 0);
  const data = imageData.data;
  //   console.log("original image: ", imageData);
  for (let i = 0; i < data.length; i += 5) {
    const bucket = Math.min(
      Math.round((data[i] + data[i + 1] + data[i + 2]) / 3),
      255
    );
    histogram[bucket]++;
  }
  //   console.log("histogram: ", histogram);
  return { histogram, count: data.length / 4 };
};
