import { useEffect } from 'react';
import { connect } from "react-redux";

const PendoProvider = ({ email, organization }) => {
    useEffect(() => {
        if (!organization) {
            return
        }
        if (process.env.REACT_APP_ENV === 'development') {
            return
        }
        (function (apiKey) {
            (function (p, e, n, d, o) {
                var v, w, x, y, z;
                o = p[d] = p[d] || {};
                o._q = o._q || [];
                v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
                for (w = 0, x = v.length; w < x; ++w) (function (m) {
                    o[m] = o[m] || function () {
                        o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
                    };
                })(v[w]);
                y = e.createElement(n);
                y.async = true;
                y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
                z = e.getElementsByTagName(n)[0];
                z.parentNode.insertBefore(y, z);
            })(window, document, 'script', 'pendo');

            if (window.pendo && typeof window.pendo.initialize === 'function') {
                window.pendo.initialize({
                    visitor: {
                        id: email, // Required if user is logged in
                    },
                    account: {
                        id: email, // Highly recommended, required if using Pendo Feedback,
                        organization: organization
                    },
                });
                // window.pendo.track("hello world", {
                //     id: email, // Highly recommended, required if using Pendo Feedback,
                //     organization: organization
                // })
            }
        })('ac311559-9d25-48b2-72f7-c4ff1d56b660');
    }, [email, organization]);

    return null; // This component does not render anything in the UI
};

export const mapStateToProps = (state) => {
    const { email, organization } = state.user
    return {
        email,
        organization
    };
};

export default connect(mapStateToProps, {
})(PendoProvider);
