import React from "react";
import { connect } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { closeNotification, clearNotifications, clearErrors } from "../actions";

const Index = ({
  clearNotifications,
  closeNotification,
  clearErrors,
  notifications,
  count,
}) => {
  const makeCloseHandler = (id) => (event, reason) => {
    if (reason === "clickaway") return;
    else if (id === 1) clearErrors();
    else closeNotification(id);
  };

  return notifications.map(
    ({ id, message, severity, anchor, autoHideDuration = 6000 }) => (
      <Snackbar
        key={`snackbar-${id}`}
        open={true}
        autoHideDuration={autoHideDuration}
        onClose={makeCloseHandler(id)}
        anchorOrigin={anchor ? anchor : undefined}
      >
        <Alert
          elevation={6}
          variant="filled"
          severity={severity}
          onClose={makeCloseHandler(id)}
          key={id}
        >
          {message}
        </Alert>
      </Snackbar>
    )
  );
};

export const mapStateToProps = (state) => {
  const { error, count, ...notificationObjs } = state.notifications;
  const notifications = [
    ...Object.values(notificationObjs),
    ...(error
      ? [
          {
            id: 1,
            message: error,
            severity: "error",
            anchor: { vertical: "top", horizontal: "center" },
          },
        ]
      : []),
  ];
  return {
    notifications: notifications,
    count,
  };
};

export default connect(mapStateToProps, {
  clearNotifications,
  closeNotification,
  clearErrors,
})(Index);
