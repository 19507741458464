import React from "react";
import { createTheme, StyledEngineProvider, ThemeProvider, responsiveFontSizes } from "@mui/material/styles";

let theme = createTheme({
  typography: {
    // fontSize: "clamp(1em, 1.2vw, 2.5em)",
    // body1: {
    //   fontSize: "clamp(1em, 1.2vw, 2.2em)"
    // },
    // body2: {
    //   fontSize: "clamp(0.875em, 1vw, 2em)"
    // },
    // caption: {
    //   fontSize: "clamp(0.75em, 0.8vw, 1.6em)"
    // },
    // overline: {
    //   fontSize: "clamp(0.75em, 0.8vw, 1.6em)"
    // }
  },
  components: {
    // ,
    // MuiTextField: {
    //   styleOverrides: {
    //     root: {
    //       fontSize: "100rem",
    //       color: "white",
    //     },
    //   }
    // }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 480,
      md: 960,
      hd: 1340,
      lg: 1600,
      xl: 2500,
    },
  },
  status: {
    danger: "#E10050",
    success: "#4caf50",
    // info: "#97BFF6",
    info: "#428BCA",
  },
  palette: {
    status: {
      danger: "#E10050",
      success: "#4caf50",
      // info: "#97BFF6",
      info: "#428BCA",
    },
    primary: {
      main: "#3f51b5",
    },
    secondary: {
      main: "#ffffff",
    },
    disabled: {
      main: "#A7A7A7",
    },
    white: {
      main: "#ffffff",
    },
  },
});

theme = responsiveFontSizes(theme);

const redTheme = createTheme({
  palette: {
    primary: {
      main: "#E10050",
    },
    secondary: {
      main: "#ffffff",
    },
  },
});

const greenTheme = createTheme({
  palette: {
    primary: {
      main: "#B0F2B4",
    },
    secondary: {
      main: "#A7A7A7",
    },
  },
});

const IDToolMUIThemeProvider = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

const GreenThemeProvider = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={greenTheme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default IDToolMUIThemeProvider;
export { redTheme, GreenThemeProvider, theme };
