import { AUTHENTICATE_WS } from "./types";

export const sendAuthToken = (token) => async (dispatch) => {
  dispatch({
    type: AUTHENTICATE_WS,
    payload: { token },
    socket: { send: true },
  });
};

export const reconnectWithAuthToken =
  (token, trayId, disableCapturing) => async (dispatch) => {
    dispatch({
      type: AUTHENTICATE_WS,
      payload: { token, trayId, capturing: !disableCapturing },
      socket: { send: true },
    });
  };
