import { OverwriteOptionsEnum } from "./const";
import { getNextWell } from "../components/Forms/SpecimenMetadataSubmit";
import { insertSortedSet } from "../utils/helpers";

export const getAutocompleteOptions = (
  overwriteChoice,
  wellAutocompleteOptions,
  specimensArray,
  specimenNum,
  lastAutofillIdx
) => {
  let autocompleteOptions = [...wellAutocompleteOptions];
  if (overwriteChoice === OverwriteOptionsEnum.overwrite) {
    // if we're overwriting wells but stopping before a conflict,
    //   we need to make sure we don't use a duplicate well that won't actually be overwritten because it stops
    let overwritableWells = [];
    const fillPlate = specimensArray[specimenNum]?.plate;
    let fillWell = specimensArray[specimenNum]?.well;
    for (let i = 1; i <= lastAutofillIdx; i++) {
      let w = specimensArray[specimenNum + i]?.well
      if (w && specimensArray[specimenNum + i]?.plate === fillPlate) {
        overwritableWells.push(w);
      }
    }
    for (let i = 1; i <= lastAutofillIdx; i++) {
      // go to next valid fillWell
      do {
        fillWell = getNextWell(fillWell);
        if (!fillWell) break;
      } while (
        !(
          wellAutocompleteOptions.includes(fillWell) &&
          !overwritableWells.includes(fillWell)
        )
      );
      // check if its gone past H12
      if (fillWell) {
        // we now know this cell will be overwritten
        let w = specimensArray[specimenNum + i]?.well;
        // so if there's a well filled with the same plate
        if (specimensArray[specimenNum + i]?.plate === fillPlate && w) {
          // we can add it to the autocomplete options
          autocompleteOptions = insertSortedSet(autocompleteOptions, w);
        }
      } else {
        break;
      }
    }
  }
  return autocompleteOptions;
};

export const getAutofillOverwriteCount = (
  autofillProtectionData,
  specimenId,
  specimenNum
) => {
  const valId = autofillProtectionData.reduce((a, v) => {
    const vv = parseInt(v, 10);
    return a === null ? vv : vv < a ? vv : a;
  }, null);
  return valId - specimenId + specimenNum - 1;
};

export const getAutofillOverwriteMessage = (
  autofillProtectionData,
  specimenId,
  specimenNum
) => {
  const val = getAutofillOverwriteCount(
    autofillProtectionData,
    specimenId,
    specimenNum
  );
  if (val > specimenNum) {
    return `Autofill positions ${specimenNum + 1} - ${val + 1}`;
  }
  return null;
};

export const getSkipOverwriteMessage = (
  autofillProtectionData,
  lastSpecimenNum,
  specimenNum
) =>
  lastSpecimenNum - specimenNum !== autofillProtectionData.length
    ? `Skip those postions`
    : null;

export const getDuplicateWells = (
  well,
  lastAutofillIdx,
  autocompleteOptions
) => {
  let fillWell = well
  let duplicateWells = [];
  for (let i = 1; i <= lastAutofillIdx; i++) {
    fillWell = getNextWell(fillWell);
    if (!autocompleteOptions.includes(fillWell)) {
      duplicateWells = insertSortedSet(duplicateWells, fillWell);
    }
  }
  return duplicateWells;
};
