import React, { useEffect, useState } from 'react';

// Material UI imports 
import { DataGrid, GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';
import { Container, Box, TextField, MenuItem, Select, InputLabel, FormControl } from '@mui/material';
import { styled } from '@mui/material/styles';
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    '& .MuiDataGrid-columnHeader[data-field="weeklyChange"]': {
        borderRight: '2px dotted #3f51b5',
    },
    '& .MuiDataGrid-cell[data-field="weeklyChange"]': {
        borderRight: '2px dotted #3f51b5',
    },
    '& .MuiDataGrid-cell': {
        textAlign: 'center',
    },
    '& ::-webkit-scrollbar': {
        'border-top': '1px solid #e9e9ec',
        'height': '7px',
        'width': '7px'
    },
    '& ::-webkit-scrollbar-thumb:horizontal': {
        'border-radius': '15px',
        'background-color': '#6d6d6dc9',
    },
    '& ::-webkit-scrollbar-thumb:vertical': {
        'border-radius': '15px',
        'background-color': '#6d6d6dc9',
    },
    '& .MuiDataGrid-scrollbar--horizontal': {
        'display': 'block',
    },
}));

const CustomToolbar = () => {
    return (
        <Box sx={{
            p: 1, display: 'flex',
            justifyContent: 'flex-start',
        }}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </Box>
    );
};

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

// export default function WeeklyReportPreview({ data, onDownloadData, filters, loading }) {


//     const jsonData = JSON.stringify(data);
//     const parsedData = JSON.parse(jsonData);
//     console.log(parsedData);
//     // const headers = Object.keys(parsedData[0] || {});
//     // const rows2 = parsedData;
//     const preferredColumns = ['trapsite', 'Total Count', 'weeklyChange'];
//     const allColumns = Object.keys(parsedData[0] || {});
//     const remainingColumns = allColumns.filter(column => !preferredColumns.includes(column));
//     const sortedRemainingColumns = remainingColumns.sort();
//     const columns = [
//         ...preferredColumns.map((key) => ({
//             field: key,
//             // headerName: key === 'weeklyChange' ? 'Weekly Change' : key,
//             headerName: key === 'weeklyChange' ? 'Weekly Change' : key === 'trapsite' ? 'Trap Site' : key,
//             width: 200,
//             headerClassName: key === 'weeklyChange' ? 'border-right' : '',
//             cellClassName: key === 'weeklyChange' ? 'border-right' : '',
//             headerAlign: 'center',
//         })),
//         ...sortedRemainingColumns.map((key, index) => ({
//             field: key,
//             headerName: key,
//             width: 200,
//             cellClassName: index === 0 ? 'padding-left' : '',
//             headerAlign: 'center',
//         })),
//     ];


//     // const columns = Object.keys(parsedData[0] || {}).map((key) => ({
//     //     field: key,
//     //     headerName: key === 'weeklyChange' ? 'Weekly Change' : key,
//     //     width: 200,
//     // }));

//     const rowsWithId = parsedData.map((row, index) => ({
//         ...row,
//         id: index + 1,
//     }));


//     useEffect(() => {
//         onDownloadData();
//         console.log("weekly report previewed", jsonData);
//         // eslint-disable-next-line
//     }, [filters]);




//     return (

//         <div style={{}}>
//             {/* Line graph section */}
//             <div style={{ textAlign: 'center', color: '#3f51b5', fontFamily: 'Roboto' }}>
//                 <div>WEEKLY REPORT</div>
//                 {/* <div style={{ fontSize: '12px', fontWeight: '200px' }}>{'( '}Abundance over time{' )'}</div> */}
//             </div>

//             <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', marginLeft: '70px' }}>
//                 <div
//                     style={{
//                         // fontSize: "15px",
//                         fontWeight: "500",
//                         // marginBottom: "5px",
//                         marginRight: '10px'
//                     }}
//                 >
//                     START WEEK:
//                 </div>
//                 <div>
//                     <DateTimePicker
//                         autoOk
//                         style={{ maxWidth: "4rem" }}
//                         slotProps={{ textField: { size: "small" } }}
//                         ampm={true}
//                         // value={imagingStartDate}
//                         // onChange={setImagingStartDate}
//                         inputFormat="MM/dd/yyyy HH:mm"
//                         label="Week Start Date"
//                         mask="__/__/____ __:__"
//                         // maxDate={imagingEndDate}
//                         showToolbar
//                         renderInput={(props) => (
//                             <TextField
//                                 data-testid="Week start date"
//                                 label="Week Start"
//                                 variant="standard"
//                                 {...props}
//                                 style={{ marginRight: '10px' }}
//                             />
//                         )}
//                     />
//                 </div>


//             </div>
//             <div style={{ marginTop: '20px', height: '350px', width: '100%', fontSize: '11px' }}>
//                 <Container>
//                     <Box sx={{ height: '500px', width: '100%' }}>
//                         <StyledDataGrid
//                             rows={rowsWithId}
//                             columns={columns}
//                             localeText={{ noRowsLabel: loading ? "" : "No Data" }}
//                             initialState={{
//                                 pagination: {
//                                     paginationModel: {
//                                         pageSize: 10,
//                                     },
//                                 },
//                                 columns: {
//                                     columnVisibilityModel: {
//                                         empty: false,
//                                         Unsure: false,
//                                     },
//                                 }
//                             }}
//                             slots={{
//                                 toolbar: CustomToolbar,
//                             }}
//                             disableDensitySelector={true}
//                             pageSizeOptions={[10, 20, 50]}
//                         />
//                     </Box>
//                 </Container>
//             </div>
//         </div>
//     );
// }

export default function WeeklyReportPreview({ data, onDownloadData, filters, loading }) {
    const [rows, setRows] = useState([]);
    const [selectedDate, setSelectedDate] = useState('');

    useEffect(() => {
        onDownloadData();
        // eslint-disable-next-line
    }, [filters]);


    const jsonData = JSON.stringify(data);
    const parsedData = JSON.parse(jsonData);
    // const parsedData = lastFiveEntries.slice(-5, -1);
    const date = parsedData.length > 0 ? parsedData[0].date : '';
    console.log(parsedData);

    //how many dates in dropdown
    const lastTwoDates = parsedData.map(entry => entry.date);
    const defaultDate = parsedData.length > 0 ? parsedData[parsedData.length - 1].date : '';

    // const selectedDate = defaultDate;
    useEffect(() => {
        if (defaultDate && !selectedDate) {
            setSelectedDate(defaultDate);
        }
    }, [defaultDate, selectedDate]);

    const handleDateChange = (event) => {
        setSelectedDate(event.target.value);
    };

    const selectedData = parsedData.find(entry => entry.date === selectedDate);
    // console.log("selected data", selectedData);

    const speciesSet = new Set();
    if (selectedData) {
        Object.values(selectedData.trapsites).forEach(trapsite => {
            if (trapsite.species) {
                trapsite.species.forEach(species => {
                    speciesSet.add(species.species);
                });
            }
        });
    }

    const speciesArray = Array.from(speciesSet)
    const uniqueSpecies = speciesArray.sort((a, b) => a.localeCompare(b));


    const rowsData = selectedData ? Object.keys(selectedData.trapsites).map((trapSite, index) => {
        const trapData = selectedData.trapsites[trapSite];
        const row = {
            id: index,
            trapSite: trapSite,
            sum: trapData.sum || 0,
            weeklyChange: trapData.weeklyChange || 0
        };
        uniqueSpecies.forEach(speciesName => {
            row[speciesName] = 0;
        });


        if (trapData.species) {
            trapData.species.forEach(species => {
                if (uniqueSpecies.includes(species.species)) {
                    row[species.species] = species.count;
                }
            });
        }

        return row;
    }) : [];

    const columns = [
        { field: 'trapSite', headerName: 'Trap Site', width: 250, headerAlign: 'center' },
        { field: 'sum', headerName: 'Total_Count', width: 150, headerAlign: 'center' },
        { field: 'weeklyChange', headerName: 'Weekly Change', width: 200, headerAlign: 'center' },

        ...uniqueSpecies.map(speciesName => ({
            field: speciesName,
            headerName: capitalizeFirstLetter(speciesName),
            width: 150,
            headerAlign: 'center'
        }))
    ];

    // console.log(columns);

    return (
        <div style={{}}>
            <div>
                {/* {date && <h3>Date: {date}</h3>} */}
                <FormControl fullWidth margin="normal" sx={{ width: '250px' }}>
                    <InputLabel>Start Week</InputLabel>
                    <Select
                        value={selectedDate}
                        onChange={handleDateChange}
                    >
                        {lastTwoDates.map(date => (
                            <MenuItem key={date} value={date}>
                                {date}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>


                <div style={{ height: 400, width: '100%' }}>
                    <Container>
                        <Box sx={{ height: '500px', width: '100%', maxWidth: '100%', overflow: 'scroll', }}>
                            <div
                                style={{
                                    height: '100%',
                                    width: '100%',
                                    overflow: 'auto',


                                }}
                            >
                                <StyledDataGrid
                                    rows={rowsData}
                                    columns={columns}
                                    pageSize={rowsData.length}
                                    rowsPerPageOptions={[rowsData.length]}
                                    localeText={{ noRowsLabel: loading ? "" : "No Data" }}
                                    slots={{
                                        toolbar: CustomToolbar,
                                    }}
                                    initialState={{
                                        pagination: {
                                            paginationModel: {
                                                pageSize: 10,
                                            },
                                        },
                                        columns: {
                                            columnVisibilityModel: {
                                                empty: false,
                                                Unsure: false,
                                            },
                                        }
                                    }}
                                    disableSelectionOnClick
                                />
                            </div>
                        </Box>
                    </Container>

                </div>
            </div>
        </div>

    );

}
