import React from "react";
import { Typography, Box, Grid } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const fontSx = {
  fontSize: ["1rem", "1rem", "1rem", "1rem", "1.25rem", "1.25rem"],
};

const DeviceDetails = ({
  id,
  displayName,
  firmwareVersion,
  algVersion,
  wifiName,
  wifiStatus,
  macAddress,
}) => {
  return (
    <Box
      sx={{
        width: "100%",
        px: ["0rem", "3rem", "3rem", "3rem", "3rem", "3rem"],
        py: ["0rem", "5rem", "5rem", "5rem", "5rem", "5rem"],
      }}
    >
      <Grid container spacing={3} alignItems="center" justifyContent="center" >
        <Grid item xs={12}>
          <Typography sx={fontSx} className={" text-gray-600 text-center"}>
            Device Info
          </Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={fontSx} className={" text-gray-600"}>
            Id:{" "}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography sx={fontSx}>{id}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={fontSx} className={" text-gray-600"}>
            Display Name:{" "}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          <Typography sx={fontSx}>{displayName}</Typography>
        </Grid>

        <Grid item xs={6} md={4}>
          <Typography sx={fontSx} className={" text-gray-600"}>
            Firmware Version:{" "}
          </Typography>
        </Grid>
        <Grid item xs={6} md={8}>
          <Typography sx={fontSx}>{firmwareVersion}</Typography>
        </Grid>

        <Grid item xs={6} md={4}>
          <Typography sx={fontSx} className={" text-gray-600"}>
            Algorithm Version:{" "}
          </Typography>
        </Grid>
        <Grid item xs={6} md={8}>
          <Typography sx={fontSx}>{algVersion}</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography sx={fontSx} className={" text-gray-600"}>
            Connected Wifi:{" "}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {!!wifiName ? (
            <Typography sx={fontSx} display={"inline"}>
              {wifiName}
            </Typography>
          ) : (
            <CircularProgress
              sx={{
                width: ['20px !important', '40px', '40px', '40px', '40px', '40px'],
                height: ['20px !important', '40px', '40px', '40px', '40px', '40px'],
                padding: ['0px !important', '8px', '8px', '8px', '8px', '8px', '8px']
              }}
              className={"p-2"} />
          )}
        </Grid>
        <Grid item xs={4}>
          <Typography sx={fontSx} className={" text-gray-600"}>
            Mac Address:{" "}
          </Typography>
        </Grid>

        <Grid item xs={8}>
          {macAddress ? (
            <Typography sx={fontSx}>{macAddress}</Typography>
          ) : (
            <CircularProgress
              sx={{
                width: ['20px !important', '40px', '40px', '40px', '40px', '40px'],
                height: ['20px !important', '40px', '40px', '40px', '40px', '40px'],
                padding: ['0px !important', '8px', '8px', '8px', '8px', '8px', '8px']
              }}
              className={"p-2"} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DeviceDetails;
