import React from "react";
import { connect } from "react-redux";
import FilterListIcon from "@mui/icons-material/FilterList";
import { IconButton, Tooltip } from "@mui/material";
import { updateIsTrayFiltersModal } from "../../actions";

const TrayFetchFilter = ({ tableName, isOpen, updateIsTrayFiltersModal }) => {
  return tableName === "Tray" ? (
    <Tooltip
      title={`Tray Filters`}
      sx={{
        float: "right",
      }}
      className={'tray-filter-on-list'}
    >
      <IconButton
        sx={{
          color: "status.info",
          padding: [
            "0.35rem",
            "0.35rem",
            "0.35rem",
            "0.5rem",
            "0.5rem",
            "0.5rem",
          ],
        }}
        onClick={() => updateIsTrayFiltersModal(!isOpen)}
      >
        <FilterListIcon
          sx={{
            fontSize: ["1rem", "1rem", ".90rem", "1rem", "1.75rem", "1.75rem"],
          }}
        />
      </IconButton>
    </Tooltip>
  ) : (
    <></>
  );
};

export const mapStateToProps = (state) => {
  return {
    isOpen: state.data.isTrayModalOpen,
  };
};

export default connect(mapStateToProps, { updateIsTrayFiltersModal })(
  TrayFetchFilter
);
