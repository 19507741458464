import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

const fontSx = {
  fontSize: ["0.75rem", "0.75rem", "0.75rem", "0.75rem", "1rem", "1rem"],
};

const ChangeVectorTypeDialog = ({
  actionName,
  device,
  active,
  vectorTypesMapping,
  setActive,
  onConfirm,
  cancelMessage = "Cancel",
}) => {
  const [envOptions, setEnvOptions] = useState([]);
  const [vectorTypes, setVectorTypes] = useState([]);
  const [currVectorType, setCurrVectorType] = useState('mosquito');
  const [moveVal, setMoveVal] = useState("");

  useEffect(() => {
    const region = device?.vectorType || "mosquito";
    const vectorType = region.startsWith('moz') ? 'mosquito' : region;
    setCurrVectorType(vectorType);
    if (device?.vectorType && moveVal === "") {
      setMoveVal(vectorType);
    }
    // eslint-disable-next-line
  }, [device])

  useEffect(() => {
    setVectorTypes(Object.keys(vectorTypesMapping || {}));
  }, [vectorTypesMapping]);

  useEffect(() => {
    setEnvOptions(
      vectorTypes?.map((option) => {
        return {
          value: option,
          label:
            option === (currVectorType)
              ? `${option} (*)`
              : option,
        };
      })
    );
    // eslint-disable-next-line
  }, [device, vectorTypes]);


  const handleClose = () => {
    setMoveVal("");
    setActive(null);
  };

  return !!active ? (
    <Dialog
      open={!!active}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{`Change ${device?.displayName} (${device?.uuid})`}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <Typography sx={fontSx} className={"text-gray-600"}>
              Which vector type should this device use?
            </Typography>
            <TextField
              id="outlined-select-org"
              select
              label="New Vector Type"
              value={moveVal}
              onChange={(event) => {
                setMoveVal(event.target.value);
              }}
            >
              {envOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="secondary"
          id={"dialog-cancel"}
        >
          {cancelMessage}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
            onConfirm(moveVal);
          }}
          id={"dialog-confirm"}
        >
          {actionName}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default ChangeVectorTypeDialog;
