import {
  FETCH_NETWORKS_WIFI,
  SETUP_WPA_WIFI,
  CANCEL_WIFI,
  FETCH_NETWORKS_RESPONSE_WIFI,
  SETUP_WPA_RESPONSE_WIFI,
  ERROR_WIFI,
  FINISH_WIFI,
  FETCH_CONNECTED_NETWORK_WIFI,
  FETCH_CONNECTED_NETWORKS_WIFI,
  DELETE_WIFI,
  DELETE_WIFI_RESPONSE,
  SELECT_WIFI,
  SELECT_WIFI_RESPONSE
} from "../actions/types";
import _ from "lodash"

export const DEFAULT_STATE = { status: "disconnected", networks: [], connectedWifis: [], currentConnectedWifi: null, connectedWifiStatus: 'disconnected' };

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case FETCH_NETWORKS_WIFI:
      return { ...state, status: "fetching" };
    case SETUP_WPA_WIFI:
      return { ...state, status: "connecting" };
    case FETCH_NETWORKS_RESPONSE_WIFI:
      if (state.status === "fetching") {
        let transformNetworks = _.orderBy((action.payload?.networks || []).map((cur) => {
          let tmp = cur.split('$')
          return {
            name: tmp[0], connected: tmp[1] === '1' ? true : false, savedPassword: tmp[2] === '1' ? true : false
          }

        }), ['connected', 'savedPassword', 'name'], ['desc', 'desc', 'asc'])
        return {
          ...state,
          status: "ready",
          networks: transformNetworks || [],
        };
      }
      return state;
    case SETUP_WPA_RESPONSE_WIFI:
      if (state.status === "connecting") {
        return { ...state, status: "connected" };
      }
      return state;
    case CANCEL_WIFI:
    case ERROR_WIFI:
      if (state.networks.length > 0) {
        return { ...state, status: "ready" };
      }
      return { ...state, status: "disconnected" };
    case FETCH_CONNECTED_NETWORK_WIFI:
      return { ...state, currentConnectedWifi: null, connectedWifiStatus: 'fetching' }
    case FETCH_CONNECTED_NETWORKS_WIFI:
      return { ...state, connectedWifis: [] }
    case DELETE_WIFI:
      return { ...state, status: 'deleting' }
    case DELETE_WIFI_RESPONSE:
      return { ...state, status: action.payload?.success ? 'deleteSuccess' : 'deleteFail' }
    case SELECT_WIFI:
      return { ...state, status: 'selecting' }
    case SELECT_WIFI_RESPONSE:
      return { ...state, status: action.payload?.success ? 'selectSuccess' : 'selectFail' }
    case FINISH_WIFI:
      return DEFAULT_STATE;
    default:
      return state;
  }
};
