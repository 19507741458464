// export const isAuthenticated = true;
// export const user = {};
// export const loading = false;
// export const popupOpen = false;
// export const loginWithPopup = () => null;
// export const handleRedirectCallback = () => null;
// export const getIdTokenClaims = () => null;
// export const loginWithRedirect = () => null;
// export const getTokenSilently = () => null;
// export const getTokenWithPopup = () => null;
// export const logout = () => null;

export const Auth0ClientMock = {
  handleRedirectCallback: async () => {
    return { appState: { targetUrl: "/data" } };
  },

  getUser: async () => {
    return {
      __raw:
        "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlkwVTJBdHhXVWdWamdUNV8zUTFYTSJ9.eyJodHRwczovL21vcy1pZC5jb20vYXBwX21ldGFkYXRhIjp7fSwiaHR0cHM6Ly9tb3MtaWQuY29tL3VzZXJfbWV0YWRhdGEiOnsib3JnYW5pemF0aW9uIjoidmVjdGVjaC1zdGFnaW5nIiwibWFwX2NlbnRlciI6IjM5LjI5NDIwMSwgLTc2LjU4ODY4NiIsImhhc0NvbmZpcm1lZExpY2Vuc2UiOnRydWV9LCJuaWNrbmFtZSI6InN0YWdpbmciLCJuYW1lIjoic3RhZ2luZ0B2ZWN0ZWNoLmlvIiwicGljdHVyZSI6Imh0dHBzOi8vcy5ncmF2YXRhci5jb20vYXZhdGFyLzYxYWNiMDZkMTQ2N2IzNzc2YmMyNGMyOWI2N2MyMDY4P3M9NDgwJnI9cGcmZD1odHRwcyUzQSUyRiUyRmNkbi5hdXRoMC5jb20lMkZhdmF0YXJzJTJGc3QucG5nIiwidXBkYXRlZF9hdCI6IjIwMjEtMDctMjJUMjI6Mzg6MjIuOTUwWiIsImVtYWlsIjoic3RhZ2luZ0B2ZWN0ZWNoLmlvIiwiZW1haWxfdmVyaWZpZWQiOmZhbHNlLCJpc3MiOiJodHRwczovL3ZlY3RlY2guYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDVmZGExNmY0MmM1ZmJhMDA2OTI2NzgyNCIsImF1ZCI6IjRWY2tuR2JyQUhSTEppaHJJZnJpTllMVFlKV1VkdDJpIiwiaWF0IjoxNjI2OTkzNTA1LCJleHAiOjE2MjcwMjk1MDUsIm5vbmNlIjoiUTFSeGJpMVhNV2xTVUV4S2VYQktlSGhhYlVnMUxUUkpiMUJXZERkUVNYRm1abFJaWDI1V2FUVXVOQT09In0.ZIuwToBJ9OqqTzFavLMRJAnlnN3ySVmD7qrOYI_ynT7KCYwFBWPPsS6UAz_wYDmBuVcOV6E9_6mCCk6CVMasZViIvIyH9j2KoZup27y-3akNv2sueg_6fGFvBJ7UTBxYmW70IPnck_i9NSwXZA182XStKzFBuWfz6eOd_Jhpc3GT40wE8W7B353zyc7HmxE58gCyaPzkVwcDBAgJnpRlRxUpG-E-PehJcbQLE-qphh60uwhmY_hnXVeuVggq7QQX63BDtEhzr5LQaWKe4p2LYSTCr4hgnqw_nqEQkFPBNy30dBBBZIL9AI2HTfb7WIT4Ct_S5auel1Bucj5tyNIIlA",
      "https://mos-id.com/app_metadata": {},
      "https://mos-id.com/user_metadata": {
        organization: "vectech-staging",
        map_center: "39.294201, -76.588686",
        hasConfirmedLicense: true,
      },
      nickname: "staging",
      name: "staging@vectech.io",
      picture:
        "https://s.gravatar.com/avatar/61acb06d1467b3776bc24c29b67c2068?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fst.png",
      updated_at: "2021-07-22T22:38:22.950Z",
      email: "staging@vectech.io",
      email_verified: false,
      iss: "https://vectech.auth0.com/",
      sub: "auth0|5fda16f42c5fba0069267824",
      aud: "4VcknGbrAHRLJihrIfriNYLTYJWUdt2i",
      iat: Date.now(),
      exp: Date.now() + 24 * 60 * 60,
      nonce: "Q1Rxbi1XMWlSUExKeXBKeHhabUg1LTRJb1BWdDdQSXFmZlRZX25WaTUuNA==",
    };
  },

  isAuthenticated: async () => {
    return true;
  },

  loginWithPopup: async (params) => {
    return true;
  },

  getIdTokenClaims: async (params) => {
    return {};
  },

  loginWithRedirect: async (params) => {
    return true;
  },

  getTokenSilently: async (params) => {
    return "token";
  },

  getTokenWithPopup: async (params) => {
    return "token";
  },

  logout: async (params) => {
    return true;
  },
};

const createAuth0ClientMock = async (initOptions) => Auth0ClientMock;

export default createAuth0ClientMock;
