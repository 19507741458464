import _ from "lodash";
import {
  CONFIRM_START_CAPTURE,
  FLIP_TRAY,
  FLIP_CAPTURE,
  RETAKE_CAPTURE,
  NEXT_SPECIMEN,
  PREV_SPECIMEN,
  JUMP_SPECIMEN,
  FINISH_CAPTURE,
  FULFILL_CAPTURE,
  THUMB_CAPTURE,
  CROP_CAPTURE,
  BBOXES_CAPTURE,
  SEX_CAPTURE,
  EMPTY_CAPTURE,
  ML_CAPTURE,
  ML_2_CAPTURE,
  AUTHENTICATE_WS,
  EDIT_TRAY,
  FETCH_TRAYS,
  FETCH_TRAY,
  VIEW_TRAY_CAPTURE,
  CONFIRM_VIEW_CAPTURE,
  ERROR_CAPTURE,
  UPDATE_SPECIMEN_CAPTURE_METADATA,
  CLEAR_CAPTURE,
  TRAY_UPLOAD_CAPTURE,
  VERIFY_TRAY,
} from "../actions/types";
import { orientationFlipped } from "../utils/orientation";
import { camelify } from "../utils/caseConvert";
import {
  modulo,
  checkTrayValidity,
  getRelevantSide,
  unsetImages,
  flipCaptureFail,
  formatTray,
} from "../utils/reducerHelpers";
import { arrayDefaultDeep } from "../utils/defaultDeep";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
  let traySide;
  const { capturing: isCapturing = false } = state;
  switch (action.type) {
    case CLEAR_CAPTURE:
      return {};
    case ERROR_CAPTURE:
      const newState = flipCaptureFail(state);
      if (!newState?.trayValid) {
        return { erroredTray: newState?.tray?.id || 0 };
      }
      return newState;
    case VIEW_TRAY_CAPTURE:
      return {
        tray: action.payload.tray,
        specimenOnDisplay: 0,
        traySide: "front",
        capturing: false,
        disableCapturing: true,
        trayValid: true,
      };
    case CONFIRM_VIEW_CAPTURE:
      const viewedTray = { ...camelify(action.payload.tray, true) };
      const fullViewedTray = _.defaultsDeep({}, viewedTray, { ...state?.tray });
      const viewedTraySide = getRelevantSide(fullViewedTray);
      const viewedOtherSideDone = checkTrayValidity(
        fullViewedTray,
        orientationFlipped(viewedTraySide)
      );
      return _.defaultsDeep(
        {},
        {
          tray: fullViewedTray,
          traySide: viewedTraySide,
          otherSideDone: viewedOtherSideDone,
        },
        state
      );
    case CONFIRM_START_CAPTURE:
      return {
        tray: formatTray({}, action.payload.tray),
        specimenOnDisplay: 0,
        traySide: null,
        capturing: true,
        disableCapturing: false,
        trayValid: false,
      };
    case FULFILL_CAPTURE:
      if (isCapturing) {
        if (checkTrayValidity(state.tray, state.traySide)) {
          return {
            ...state,
            capturing: false,
            trayValid: true,
            completedProcessing: false,
          };
        }
        return { ...state, completedProcessing: true };
      }
      return state;
    case FLIP_TRAY:
      if (state?.traySide && state?.otherSideDone) {
        return { ...state, traySide: orientationFlipped(state.traySide) };
      }
      return state;
    case FLIP_CAPTURE:
      if (state?.traySide) {
        return {
          ...state,
          traySide: orientationFlipped(state.traySide),
          capturing: true,
          otherSideDone: state.trayValid,
        };
      }
      return state;
    case RETAKE_CAPTURE:
      if (state?.traySide) {
        return {
          ...state,
          capturing: true,
          tray: unsetImages(state.tray, state.traySide),
          trayValid: !!state?.otherSideDone,
        };
      }
      return state;
    case NEXT_SPECIMEN:
      if (
        state?.tray?.specimens &&
        (state?.specimenOnDisplay || state?.specimenOnDisplay === 0)
      ) {
        return {
          ...state,
          specimenOnDisplay: modulo(
            state.specimenOnDisplay + 1,
            Object.keys(state.tray.specimens).length
          ),
        };
      }
      return state;
    case PREV_SPECIMEN:
      if (
        state?.tray?.specimens &&
        (state?.specimenOnDisplay || state?.specimenOnDisplay === 0)
      ) {
        return {
          ...state,
          specimenOnDisplay: modulo(
            state.specimenOnDisplay - 1,
            Object.keys(state.tray.specimens).length
          ),
        };
      }
      return state;
    case JUMP_SPECIMEN:
      if (
        state?.tray?.specimens &&
        (state?.specimenOnDisplay || state?.specimenOnDisplay === 0)
      ) {
        return { ...state, specimenOnDisplay: action.payload.specimenNum };
      }
      return state;
    case FINISH_CAPTURE:
      return {};
    case THUMB_CAPTURE:
      if (!isCapturing) return state;
      traySide = state?.traySide ? state.traySide : action.payload?.orientation;
      const thumbState = _.defaultsDeep(
        {},
        {
          traySide,
          tray: { [`${action.payload.orientation}Img`]: action.payload.img },
        },
        state
      );
      if (state?.completedProcessing) {
        if (checkTrayValidity(thumbState.tray, thumbState.traySide)) {
          return {
            ...thumbState,
            capturing: false,
            trayValid: true,
            completedProcessing: false,
          };
        }
      }
      return thumbState;
    case CROP_CAPTURE:
      if (!isCapturing) return state;
      // console.log(action.payload)
      traySide = state?.traySide ? state.traySide : action.payload?.orientation;
      const cropState = _.defaultsDeep(
        {},
        {
          traySide,
          tray: {
            specimens: {
              [action.payload.pos]: {
                id: action.payload.spec,
                [action.payload.orientation]: { imgPath: action.payload.img },
                institutionId: action.payload.institutionId,
                defaultInstitutionId: action.payload.institutionId,
              },
            },
          },
        },
        state
      );
      if (state?.completedProcessing) {
        if (checkTrayValidity(cropState.tray, cropState.traySide)) {
          return {
            ...cropState,
            capturing: false,
            trayValid: true,
            completedProcessing: false,
          };
        }
      }
      return cropState;
    case BBOXES_CAPTURE:
      if (!isCapturing) return state;
      traySide = state?.traySide ? state.traySide : action.payload?.orientation;
      const bbox_delta_specimens = Object.fromEntries(
        action.payload.bboxes.map((bbox, num) => [
          num,
          { [action.payload.orientation]: { bbox } },
        ])
      );
      const bboxesState = _.defaultsDeep(
        {},
        { traySide, tray: { specimens: bbox_delta_specimens } },
        state
      );
      if (state?.completedProcessing) {
        if (checkTrayValidity(bboxesState.tray, bboxesState.traySide)) {
          return {
            ...bboxesState,
            capturing: false,
            trayValid: true,
            completedProcessing: false,
          };
        }
      }
      return bboxesState;
    case SEX_CAPTURE:
      traySide = state?.traySide ? state.traySide : action.payload?.orientation;
      const sexState = _.defaultsDeep(
        {},
        {
          traySide,
          tray: {
            specimens: {
              [action.payload.num]: {
                [action.payload.orientation]: {
                  algorithmId: { sex: action.payload?.sex || "" },
                },
              },
            },
          },
        },
        state
      );
      return { ...sexState };
    case EMPTY_CAPTURE:
      traySide = state?.traySide ? state.traySide : action.payload?.orientation;
      const emptyState = _.defaultsDeep(
        {},
        {
          traySide,
          tray: {
            specimens: {
              [action.payload.num]: {
                [action.payload.orientation]: {
                  algorithmId: { empty: action.payload?.empty || "" },
                },
              },
            },
          },
        },
        state
      );
      return { ...emptyState };
    case ML_CAPTURE:
      // if (!isCapturing) return state;
      if (action.payload?.orientation === null) return state;
      traySide = state?.traySide ? state.traySide : action.payload?.orientation;
      const ml_delta_specimens = Object.fromEntries(
        action.payload.results.reduce(
          (arr, res, num) =>
            res
              ? [
                ...arr,
                [
                  num,
                  {
                    [action.payload.orientation]: {
                      algorithmId: camelify(res),
                    },
                  },
                ],
              ]
              : arr,
          []
        )
      );
      const mlState = _.defaultsDeep(
        {},
        { traySide, tray: { specimens: ml_delta_specimens } },
        state
      );
      if (state?.completedProcessing) {
        if (checkTrayValidity(mlState.tray, mlState.traySide)) {
          return {
            ...mlState,
            capturing: false,
            trayValid: true,
            completedProcessing: false,
          };
        }
      }
      return { ...mlState };
    case ML_2_CAPTURE:
      // if (!isCapturing) return state;
      if (action.payload?.orientation === null) return state;
      traySide = state?.traySide ? state.traySide : action.payload?.orientation;
      const mlnr_delta_specimens = Object.fromEntries(
        action.payload.results.reduce(
          (arr, res, num) =>
            res
              ? [
                ...arr,
                [
                  num,
                  {
                    [action.payload.orientation]: {
                      algorithmId2: camelify(res),
                    },
                  },
                ],
              ]
              : arr,
          []
        )
      );
      const mlNrState = _.defaultsDeep(
        {},
        { traySide, tray: { specimens: mlnr_delta_specimens } },
        state
      );
      if (state?.completedProcessing) {
        if (checkTrayValidity(mlNrState.tray, mlNrState.traySide)) {
          return {
            ...mlNrState,
            capturing: false,
            trayValid: true,
            completedProcessing: false,
          };
        }
      }
      return { ...mlNrState };
    case FETCH_TRAYS:
      const fetchedTray = _.filter(action.payload?.trays, [
        "id",
        state?.tray?.id,
      ]);
      if (fetchedTray.length === 1) {
        return _.defaultsDeep(
          {},
          { tray: formatTray(state.tray, fetchedTray[0]) },
          state
        );
      }
      return state;
    case EDIT_TRAY:
    case VERIFY_TRAY:
      if (state?.tray?.id === action.payload.id) {
        const fetchedTray = formatTray(state.tray, action.payload);
        const resp = {
          tray: fetchedTray,
        };
        if (
          isCapturing &&
          checkTrayValidity(fetchedTray, state?.traySide || "front")
        ) {
          return _.defaultsDeep(
            {},
            {
              ...resp,
              capturing: false,
              trayValid: true,
              completedProcessing: false,
            },
            state
          );
        }
      }
      return state;
    case FETCH_TRAY:
      if (state?.tray?.id === action.payload.id) {
        const fetchedTray = formatTray(state.tray, action.payload);
        const fetchedTraySide = getRelevantSide(fetchedTray, null);
        const fetchedOtherSideDone =
          fetchedTraySide === "front"
            ? checkTrayValidity(fetchedTray, "back")
            : fetchedTraySide === "back"
              ? checkTrayValidity(fetchedTray, "front")
              : null;
        const resp = {
          tray: fetchedTray,
        };
        if (fetchedTraySide) {
          resp.traySide = fetchedTraySide;
        }
        if (fetchedOtherSideDone) {
          resp.otherSideDone = fetchedOtherSideDone;
        }
        return _.defaultsDeep({}, resp, state);
      }
      return state;
    // case EDIT_SPECIMEN:
    //     if (state?.tray?.id === action.payload.tray_id) {
    //         return _.defaultsDeep({}, { tray: { specimens: specimenReducer(state.tray.specimens, action) } }, state);
    //     }
    case AUTHENTICATE_WS:
      if (state?.tray?.id) {
        if (!state?.disableCapturing) {
          if (state?.capturing) {
            if (state?.trayValid) {
              if (state?.otherSideDone) {
                return flipCaptureFail(state); // captureOtherSide fails
              } else {
                // recapture first side fails
                return { erroredTray: state.tray.id };
              }
            } else {
              // fail first side
              return { erroredTray: state.tray.id };
            }
          } else {
            // on the capture scene but a capture is not actively occurring
            return state;
          }
        } else {
          // tray viewing
          return state;
        }
      }
      return state; // no capture happening
    case UPDATE_SPECIMEN_CAPTURE_METADATA:
      return arrayDefaultDeep(
        {
          tray: {
            specimens: {
              ...action.payload.result,
            },
          },
        },
        state
      );
    case TRAY_UPLOAD_CAPTURE:
      if (state?.tray?.id !== action.payload.trayId) {
        return state;
      }
      return arrayDefaultDeep(
        {
          tray: {
            ...action.payload,
          },
        },
        state
      );
    default:
      return state;
  }
};
