import {
  CREATE_TRAP_SITE,
  EDIT_TRAP_SITE,
  FETCH_TRAP_SITES,
  DELETE_TRAP_SITE,
  RELOAD_SOCKET
} from "../actions/types";
import { camelify } from "./../utils/caseConvert";
import _ from "lodash";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
  const trapSiteCount = state?.count || 0;
  switch (action.type) {
    case FETCH_TRAP_SITES:
      return {
        ...state,
        ..._.mapKeys(
          action.payload?.trapSites?.map((s) => camelify(s)),
          "id"
        ),
        count: action.payload?.count,
      };
    case CREATE_TRAP_SITE:
      return {
        ...state,
        [action.payload?._id]: camelify(action.payload),
        count: trapSiteCount + 1,
      };
    case EDIT_TRAP_SITE:
      return { ...state, [action.payload?._id]: camelify(action.payload) };
    case DELETE_TRAP_SITE:
      return {
        ..._.omit(state, action.payload),
        count: Math.max(trapSiteCount - 1, 0),
      };
    case RELOAD_SOCKET:
      return {}
    default:
      return state;
  }
};
