import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import ROUTES from "../../AppRouter/routes";
// import ModalContainer from "./ModalContainer";
import TrayViewer from "./TrayViewer";
import { viewTray, fetchTray, finishCapture } from "../../../actions";
import { useWebServer } from "../../../providers";
import useWindowSize from "../../../lib/useWindowSizeHook";

const ViewTray = ({ viewTray, fetchTray, finishCapture, tray, trayId }) => {
  const history = useHistory();
  const { sendRequest } = useWebServer();
  const [hasViewed, setHasViewed] = useState(false);
  const [previousTray, setPreviousTray] = useState();

  //calculate the modal size base on window height
  const windowSize = useWindowSize();
  /* eslint-disable no-unused-vars */
  const [height, setHeight] = useState("900px");
  /* eslint-disable no-unused-vars */
  const [width, setWidth] = useState("824px");
  const setSize = (h, w) => {
    setHeight(h);
    setWidth(w);
  };
  const computeModalSize = useCallback(() => {
    if (windowSize.height > 1300) {
      setSize("1004px", "1106px");
    } else if ((windowSize.height <= 1300) & (windowSize.height > 1000)) {
      setSize("913px", "984px");
    } else if ((windowSize.height <= 1000) & (windowSize.height > 800)) {
      setSize("792px", "824px");
    } else {
      setSize("580px", "600px");
    }
  }, [windowSize]);
  useEffect(() => {
    computeModalSize();
  }, [computeModalSize, windowSize]);

  useEffect(() => {
    const loadTray = async () => {
      if (hasViewed && previousTray === trayId) return;
      if (trayId) {
        if (tray?.id && tray?.specimens) {
          await viewTray(tray);
          setHasViewed(true);
          setPreviousTray(trayId);
        } else {
          await fetchTray(trayId, {}, sendRequest);
        }
      } else {
        history.push(ROUTES.traysTab);
      }
    };
    loadTray();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tray]);

  useEffect(() => {
    if (hasViewed) {
      return () => {
        finishCapture();
      };
    }
  }, [hasViewed, finishCapture]);

  return (
    // <ModalContainer height={height} width={width}>
    <TrayViewer />
    // </ModalContainer >
  );
};

export const mapStateToProps = (state, ownProps) => {
  const trayId = ownProps.match.params.id;
  const { selectedDevice } = state.devices;
  if (!selectedDevice) return {};
  const {
    [selectedDevice]: {
      trays: { [trayId]: tray },
    },
  } = state.devices;

  return {
    tray,
    trayId,
  };
};

export default connect(mapStateToProps, { viewTray, fetchTray, finishCapture })(
  ViewTray
);
