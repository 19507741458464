//import { canvas } from "leaflet";
import React, { useEffect, useRef, useState } from "react";
import { Menu, MenuItem, useMediaQuery } from "@mui/material";
import { percUpHardLowClip } from '../../utils/imageProcessing'
const ENLARGE_RATE = 1.2;
const SHRINK_RATE = 0.8;

const Canvas = (props) => {
  const { img, draw, zoom, shouldProcess, specimenName, width, height, canImageProcess,
    rotate, ...other } = props;

  const canvasRef = useRef(null);
  const mouseDownRef = useRef(false);
  const [hasSetListeners, setHasSetListeners] = useState(false);
  const startXYRef = useRef([0, 0]);
  const offsetXYRef = useRef([0, 0]);
  const zoomRef = useRef(1);
  const shouldProcessRef = useRef(true);
  const [contextMenu, setContextMenu] = React.useState(null);
  const hdMatches = useMediaQuery((theme) => theme.breakpoints.up('hd'));
  useEffect(() => {
    if (canImageProcess) {
      img.width = width
      img.height = height
      drawImage(0, 0, rotate)
    }
  }, [hdMatches, canImageProcess])

  const handleContextMenu = (event) => {
    event.preventDefault();
    setContextMenu(
      contextMenu === null
        ? {
          mouseX: event.clientX - 2,
          mouseY: event.clientY - 4,
        } : null,
    );
  };

  const handleClose = () => {
    setContextMenu(null);
  };

  const saveImg = (data, filename) => {
    let a = document.createElement('a');
    a.style.display = 'none'
    a.href = data;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    handleClose()
  }

  const saveCanvasImg = () => {
    const canvas = canvasRef.current
    const data = canvas.toDataURL("image/jpeg", 1.0)
    saveImg(data, `current_view_${specimenName}_${getImgName}`)
  }

  //delete all the front website and the aws cookie
  const getImgName = img.src.replace(/^.*[\\/]/, '').replace(/\?.*/, '')

  const saveEnhanceImg = () => {
    let canvas = document.createElement("canvas");
    canvas.height = img.naturalHeight
    canvas.width = img.naturalWidth

    let ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, img.naturalWidth, img.naturalHeight);
    ctx.rect(0, 0, img.naturalWidth, img.naturalHeight);
    ctx.save();
    ctx.fillStyle = "rgb(248,248,255)";
    ctx.fill();
    ctx.drawImage(img, 0, 0);

    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    if (imageData && shouldProcessRef.current) {
      let processedData = percUpHardLowClip(imageData)
      ctx.putImageData(processedData, 0, 0)
    }

    ctx.restore();

    const data = canvas.toDataURL("image/jpeg", 1.0)
    saveImg(data, `enhanced_${specimenName}_${getImgName}`)
  }

  const saveOriginImg = () => {
    let canvas = document.createElement("canvas");
    canvas.height = img.naturalHeight
    canvas.width = img.naturalWidth
    let ctx = canvas.getContext("2d");
    ctx.drawImage(img, 0, 0);

    const data = canvas.toDataURL("image/jpeg", 1.0)
    saveImg(data, `original_${specimenName}_${getImgName}`)
  }

  const windowToCanvas = (canvas, x, y) => {
    const { left, top, width, height } = canvas.getBoundingClientRect();
    return [
      (x - left) * (canvas.width / width),
      (y - top) * (canvas.height / height),
    ];
  };

  const canvasToWindow = (canvas, x, y) => {
    const { left, top, width, height } = canvas.getBoundingClientRect();
    return [
      (x * width) / canvas.width + left,
      (y * height) / canvas.height + top,
    ];
  };

  function handleMouseDown(e) {
    // console.log("canvas mouse down");
    e.preventDefault();
    e.stopPropagation();
    startXYRef.current = windowToCanvas(
      canvasRef.current,
      e.clientX,
      e.clientY
    );
    mouseDownRef.current = true;
  }

  const handleMouseMove = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!mouseDownRef.current) {
      return;
    }
    const { clientX, clientY } = e;
    const [startX, startY] = startXYRef.current;
    const canvas = canvasRef.current;
    const [posX, posY] = windowToCanvas(canvas, clientX, clientY);
    const diffX = posX - startX;
    const diffY = posY - startY;
    if (diffX === 0 && diffY === 0) return;
    startXYRef.current = [posX, posY];
    let offsetX, offsetY = 0
    let rotation = parseInt(canvas.getAttribute("rotation"))
    console.log(rotation === 90)
    switch (rotation) {
      case 90:
        console.log('i got called')
        console.log(diffX, diffY)
        offsetX = parseInt(`${offsetXYRef.current[0] + diffY}`, 10);
        offsetY = parseInt(`${offsetXYRef.current[1] - diffX}`, 10);
        break;
      case 180:
        offsetX = parseInt(`${offsetXYRef.current[0] - diffX}`, 10);
        offsetY = parseInt(`${offsetXYRef.current[1] - diffY}`, 10);
        break;
      case 270:
        offsetX = parseInt(`${offsetXYRef.current[0] - diffY}`, 10);
        offsetY = parseInt(`${offsetXYRef.current[1] + diffX}`, 10);
        break;
      default:
        offsetX = parseInt(`${offsetXYRef.current[0] + diffX}`, 10);
        offsetY = parseInt(`${offsetXYRef.current[1] + diffY}`, 10);
    }

    offsetXYRef.current = [offsetX, offsetY];
    drawImage(offsetX, offsetY, rotate);
  }

  function handleMouseUp(e) {
    e.preventDefault();
    e.stopPropagation();
    mouseDownRef.current = false;
  }

  function handleMouseOut(e) {
    // console.log("canvas mouse out");
    e.preventDefault();
    e.stopPropagation();
    mouseDownRef.current = false;
  }

  function handleWheel(e) {
    // console.log("canvas mouse wheel");
    e.preventDefault();
    e.stopPropagation();
    const canvas = canvasRef.current;
    const { clientX = null, clientY = null, deltaY } = e;
    const [offsetX, offsetY] = offsetXYRef.current;
    let posX = 0,
      posY = 0;
    if (clientX && clientY) {
      [posX, posY] = windowToCanvas(canvas, clientX, clientY);
    }

    const xPerc = offsetX / img.width;
    const yPerc = offsetY / img.height;
    const xPosPerc = posX / img.width;
    const yPosPerc = posY / img.height;
    const bigger = deltaY > 0 ? -1 : 1;
    const rate = bigger > 0 ? ENLARGE_RATE : SHRINK_RATE;
    img.width = Math.max(canvas.width, img.width * rate);
    img.height = Math.max(canvas.height, img.height * rate);
    const deltaPosX = xPosPerc * img.width - posX;
    const deltaPosY = yPosPerc * img.height - posY;
    const newOffsetX = xPerc * img.width - deltaPosX;
    const newOffsetY = yPerc * img.height - deltaPosY;
    drawImage(newOffsetX, newOffsetY, rotate);
  }

  useEffect(() => {
    if (shouldProcessRef.current !== shouldProcess) {
      shouldProcessRef.current = shouldProcess;
      const [offsetX, offsetY] = offsetXYRef.current;
      if (img) {
        drawImage(offsetX, offsetY, rotate);
      }
    }
  }, [shouldProcess]);

  useEffect(() => {
    if (zoomRef.current !== zoom) {
      const deltaY = zoomRef.current > zoom ? 1 : -1;
      zoomRef.current = zoom;
      const canvas = canvasRef.current;
      const posX = canvas.width / 2;
      const posY = canvas.height / 2;
      const [clientX, clientY] = canvasToWindow(canvas, posX, posY);
      handleWheel({
        deltaY,
        clientX,
        clientY,
        preventDefault: () => { },
        stopPropagation: () => { },
      });
    }
  }, [zoom]);
  /* eslint react-hooks/exhaustive-deps: off */
  useEffect(() => {
    if (!hasSetListeners && img) {
      const canvas = canvasRef.current;
      img.width = canvas.width;
      img.height = canvas.height;
      canvas.addEventListener("mousedown", handleMouseDown);
      canvas.addEventListener("mousemove", handleMouseMove);
      canvas.addEventListener("mouseup", handleMouseUp);
      canvas.addEventListener("mouseout", handleMouseOut);
      canvas.addEventListener("wheel", handleWheel);
      setHasSetListeners(true);
    }
  }, [img, hasSetListeners]);

  useEffect(() => {
    if (hasSetListeners) {
      const [offsetX, offsetY] = offsetXYRef.current;
      canvasRef.current.setAttribute("rotation", rotate)
      if (img) {
        drawImage(offsetX, offsetY, rotate);
      }
    }
  }, [img, zoom, hasSetListeners, rotate]);

  const drawImage = (x, y, imgRotate) => {
    const canvas = canvasRef.current;
    const [offsetX, offsetY] = offsetXYRef.current;
    if (x + img.width <= canvas.width) {
      x = canvas.width - img.width;
    } else if (x >= 0) {
      x = 0;
    }
    if (y + img.height <= canvas.height) {
      y = canvas.height - img.height;
    } else if (y >= 0) {
      y = 0;
    }
    if (x !== offsetX || y !== offsetY) {
      offsetXYRef.current = [x, y];
    }

    const ctx = canvas.getContext("2d");
    ctx.save();
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    ctx.rect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "rgb(248,248,255)";
    ctx.fill();
    // Translate to the center of the canvas
    ctx.translate(canvas.width / 2, canvas.height / 2);
    // Apply rotation (convert degrees to radians)
    ctx.rotate(canvas.getAttribute("rotation") * Math.PI / 180);
    // Translate back and draw the image at the adjusted position
    ctx.translate(-canvas.width / 2, -canvas.height / 2);
    ctx.drawImage(img, x, y, img.width, img.height);

    let imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    if (imageData && shouldProcessRef.current) {
      let processedData = percUpHardLowClip(imageData)
      ctx.putImageData(processedData, 0, 0)
    }

    ctx.restore();
  };

  return (
    <div
      onContextMenu={handleContextMenu}
    >
      <canvas
        ref={canvasRef}
        style={{ cursor: "grabbing", pointerEvents: canImageProcess ? "auto" : "none" }}
        width={width}
        height={height}
        {...other}
      />
      <Menu
        open={contextMenu !== null}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={
          contextMenu !== null
            ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
            : undefined
        }
        sx={{
          "& .MuiMenuItem-root": {
            fontSize: ['.5rem', '.5rem', '.8rem', '.8rem', '1.15rem', '1.15rem'],
          }
        }}
      >
        <MenuItem onClick={saveOriginImg}>Save original image</MenuItem>
        <MenuItem onClick={saveEnhanceImg}>Save enhanced image</MenuItem>
        <MenuItem onClick={saveCanvasImg}>Save current view</MenuItem>
      </Menu>
    </div>

  );
};

export default Canvas;
