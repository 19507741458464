import React, { useEffect, useState } from "react";

// Material UI imports
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { Container } from "@mui/material";
import { timeToLocale } from "../../../../utils/timeConversion";
import { styled } from "@mui/material/styles";
import {
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
} from "@mui/x-data-grid";
import { rawDataColumns } from '../../../../utils/const'

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& ::-webkit-scrollbar": {
        "border-top": "1px solid #e9e9ec",
        height: "7px",
        width: "7px",
    },
    "& ::-webkit-scrollbar-thumb:horizontal": {
        "border-radius": "15px",
        "background-color": "#6d6d6dc9",
    },
    "& ::-webkit-scrollbar-thumb:vertical": {
        "border-radius": "15px",
        "background-color": "#6d6d6dc9",
    },
    "& .MuiDataGrid-scrollbar--horizontal": {
        display: "block",
    },
}));

const CustomToolbar = () => {
    return (
        <Box
            sx={{
                p: 1,
                display: "flex",
                justifyContent: "flex-start",
            }}
        >
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </Box>
    );
};

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function RawDataPreview({
    data,
    onDownloadData,
    timeZone,
    filters,
    loading,
    columnVisibilityModel,
    setColumnVisibilityModel
}) {
    const jsonData = JSON.stringify(data);
    let parsedData = JSON.parse(jsonData);
    if (
        Array.isArray(parsedData) &&
        parsedData.length === 1 &&
        parsedData[0] === "no match data"
    ) {
        parsedData = [];
    }
    const hasData = parsedData.length > 0;
    // const headers = Object.keys(parsedData[0] || {});
    // const rows2 = parsedData;
    const columns = rawDataColumns.map((key) => ({
        field: key,
        headerName: key,
        minWidth: 200,
    }))

    const rowsWithId = hasData
        ? parsedData.map((row, index) => {
            const updatedRow = {
                ...row,
                id: index + 1,
                imaging_date_time: row.imaging_date_time
                    ? timeToLocale(row.imaging_date_time, { timeZone })
                    : "",
            };

            // Capitalize the first letter of each entry in columns containing 'genus'
            Object.keys(updatedRow).forEach((key) => {
                if (
                    key.toLowerCase().includes("genus") &&
                    typeof updatedRow[key] === "string"
                ) {
                    updatedRow[key] = capitalizeFirstLetter(updatedRow[key]);
                }
            });

            return updatedRow;
        })
        : [];

    useEffect(() => {
        onDownloadData();
        // console.log(jsonData);
        // eslint-disable-next-line
    }, [filters]);

    return (
        <div style={{}}>
            {/* Line graph section */}
            <div
                style={{ textAlign: "center", color: "#3f51b5", fontFamily: "Roboto" }}
            >
                {/* <div>RAW DATA</div> */}
                {/* <div style={{ fontSize: '12px', fontWeight: '200px' }}>{'( '}Abundance over time{' )'}</div> */}
            </div>

            <div
                style={{
                    marginTop: "20px",
                    height: "350px",
                    width: "100%",
                    fontSize: "11px",
                }}
            >
                <Container>
                    <Box
                        sx={{
                            height: "500px",
                            width: "100%",
                            maxWidth: "100%",
                            overflow: "scroll",
                        }}
                    >
                        <div
                            style={{
                                height: "100%",
                                width: "100%",
                                overflow: "auto",
                            }}
                        >
                            <StyledDataGrid
                                rows={rowsWithId}
                                columns={columns}
                                localeText={{ noRowsLabel: loading ? "" : "No Data" }}
                                slots={{
                                    toolbar: CustomToolbar,
                                }}
                                initialState={{
                                    pagination: {
                                        paginationModel: {
                                            pageSize: 10,
                                        },
                                    },
                                }}
                                disableDensitySelector={true}
                                pageSizeOptions={[10, 20, 50]}
                                columnVisibilityModel={columnVisibilityModel}
                                onColumnVisibilityModelChange={(newModel) => {
                                    setColumnVisibilityModel(newModel)
                                }}
                            />
                        </div>
                    </Box>
                </Container>
            </div>
        </div>
    );
}
