import React, { useState } from "react";
import FilterIcon from "@mui/icons-material/FilterAlt";
import InputAdornment from "@mui/material/InputAdornment";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";

const FiltersDialog = ({
  orgFilter,
  deviceFilter,
  userFilter,
  onCancel,
  onSave,
  isActive,

  cancelMessage = "Cancel",
}) => {
  const [orgVal, setOrgVal] = useState(orgFilter);
  const [deviceVal, setDeviceVal] = useState(deviceFilter);
  const [userVal, setUserVal] = useState(userFilter);

  const onFieldChange = (field) => (event) => {
    const newVal = event?.target?.value || "";
    let val;
    let setVal;
    switch (field) {
      case "org":
        val = orgVal;
        setVal = setOrgVal;
        break;
      case "device":
        val = deviceVal;
        setVal = setDeviceVal;
        break;
      case "user":
        val = userVal;
        setVal = setUserVal;
        break;

      default:
        console.error("Invalid field");
        return;
    }
    if (newVal !== val) {
      setVal(newVal);
    }
  };

  return !!isActive ? (
    <Dialog
      open={!!isActive}
      onClose={onCancel}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">Search Filters</DialogTitle>
      <DialogContent>
        <Grid
          container
          component="form"
          spacing={5}
          sx={{
            "& .MuiTextField-root": { m: 1, minWidth: "25ch" },
          }}
          id={"superuser-filters-form"}
          noValidate
          autoComplete="off"
        >
          <Grid item xs={12}>
            <TextField
              id="outlined-org-field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterIcon />
                  </InputAdornment>
                ),
              }}
              label="Organization UUID"
              value={orgVal}
              onChange={onFieldChange("org")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="outlined-device-field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterIcon />
                  </InputAdornment>
                ),
              }}
              label="Device UUID"
              value={deviceVal}
              onChange={onFieldChange("device")}
            />
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="outlined-user-field"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <FilterIcon />
                  </InputAdornment>
                ),
              }}
              label="User Email"
              value={userVal}
              onChange={onFieldChange("user")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={onCancel}
          color="secondary"
          id={"dialog-cancel"}
        >
          {cancelMessage}
        </Button>
        <Button
          variant="contained"
          onClick={() => onSave(deviceVal, orgVal, userVal)}
          id={"dialog-confirm"}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default FiltersDialog;
