import React, { useEffect } from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import TrapSiteForm from "./TrapSiteForm";
import validateForm from "./TrapSiteValidation";
import { useWebServer } from "../../../../../providers";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../AppRouter/routes";
import { editTrapSite } from "../../../../../actions";

const TrapSiteEdit = ({ trapSiteId, editTrapSite, ...otherProps }) => {
  const { sendRequest } = useWebServer();
  const history = useHistory();
  const { initialize, initialValues } = otherProps


  useEffect(() => {
    initialize(initialValues)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [trapSiteId]);

  useEffect(() => {
    if (_.isEmpty(otherProps.initialValues)) {
      history.push(ROUTES.trapSite);
    }
  }, [history, otherProps?.initialValues]);
  const onSave = async (trapSite) => {
    await editTrapSite(trapSiteId, trapSite, sendRequest);
    history.push(ROUTES.trapSite);
  };

  return (
      <TrapSiteForm {...otherProps} onSave={onSave} />
  );
};

const mapStateToProps = (state, ownProps) => {
  const trapSiteId = ownProps.match.params.id;
  const initialValues = _.pick(
    state.trapSites[trapSiteId],
    "displayName",
    "description",
    "address",
    "gpsLocation"
  );
  if (initialValues?.gpsLocation) {
    const { latitude, longitude } = initialValues.gpsLocation;
    initialValues.gpsLocation = `${latitude} ${longitude}`;
  }
  return {
    trapSiteId,
    initialValues,
  };
};

const formWrapped = reduxForm({
  form: "TrapSiteEdit",
  validate: validateForm,
})(TrapSiteEdit);

export default connect(mapStateToProps, { editTrapSite })(formWrapped);
