import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import ApproveIcon from "@mui/icons-material/CheckCircleOutline";
import MoveIcon from "@mui/icons-material/Moving";
import RenameIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import { useWebServer } from "../../../../../providers";
import {
  fetchOrganizations,
  getUsersByOrg,
  approveUser,
  deleteUser,
  moveDevice,
  renameDevice,
  setSuperUserUserFilterDebounced,
  setSuperUserDeviceFilterDebounced,
  displaySuccessNotification,
  displayErrorNotification,
  updateDeviceEnv,
  updateDeviceVectorType,
  updateDeviceHardwareId,
  fetchAlgVectorTypes,
} from "../../../../../actions";
import DataTable from "../../../..//DataTable/DataTable";
import { capitalize } from "../../../../../utils/caseConvert";
import ApproveUserDialog from "./ApproveUserDialog";
import MoveDeviceDialog from "./MoveDeviceDialog";
import RenameDeviceDialog from "./RenameDeviceDialog";
import _ from "lodash";
import Typography from "@mui/material/Typography";
import ContextMenu from "./ContextMenu";

const buttonStyles = {
  infoButton: {
    "&:hover, &:focus": {
      color: "status.info",
    },
  },
  successButton: {
    "&:hover, &:focus": {
      color: "status.success",
    },
  },
  editButton: {
    "&:hover, &:focus": {
      color: "status.info",
    },
  },
  deleteButton: {
    "&:hover, &:focus": {
      color: "status.danger",
    },
  },
};

const GROUP_COLUMNS = {
  users: [
    {
      field: "name",
      label: "Name",
      isSortable: true,
      align: "left",
      sx: { maxWidth: "100px" },
    },
    {
      field: "email",
      label: "Email",
      isSortable: true,
      align: "left",
      sx: {
        maxWidth: ["6rem", "6rem", "6rem", "13.75rem", "13.75rem", "13.75rem"],
      },
    },
  ],
  devices: [
    {
      field: "displayName",
      label: "Name",
      isSortable: true,
      align: "left",
      sx: { maxWidth: "100px" },
    },
    {
      field: "uuid",
      label: "UUID",
      isSortable: true,
      align: "left",
      sx: {
        maxWidth: ["6rem", "6rem", "6rem", "13.75rem", "13.75rem", "13.75rem"],
      },
    },
    {
      field: "hardwareId",
      label: "HW_ID",
      isSortable: true,
      align: "left",
      sx: {
        maxWidth: ["6rem", "6rem", "6rem", "13.75rem", "13.75rem", "13.75rem"],
      },
    },
  ],
};

const Index = ({
  organizations,
  suserOrg,
  superUser,
  users,
  devices,
  deviceFilter = "",
  userFilter = "",
  selected = "",
  fullDevices,
  fetchOrganizations,
  getUsersByOrg,
  approveUser,
  deleteUser,
  moveDevice,
  renameDevice,
  displaySuccessNotification,
  displayErrorNotification,
  setSuperUserUserFilterDebounced,
  setSuperUserDeviceFilterDebounced,
  updateDeviceEnv,
  updateDeviceVectorType,
  updateDeviceHardwareId,
  fetchAlgVectorTypes,
  possibleVectorTypes,
}) => {
  const { sendRequest } = useWebServer();
  const [org, setOrg] = useState("");
  const [group, setGroup] = useState("");
  const [data, setData] = useState("");
  const [loading, setLoading] = useState(false);
  const [approvingUser, setApprovingUser] = useState(null);
  const [movingDevice, setMovingDevice] = useState(null);
  const [renamingDevice, setRenamingDevice] = useState(null);
  const [userFilterVal, setUserFilterVal] = useState(`${userFilter}`);
  const [deviceFilterVal, setDeviceFilterVal] = useState(`${deviceFilter}`);
  const contextMenuRef = useRef();

  useEffect(() => {
    fetchAlgVectorTypes(sendRequest);
  }, [sendRequest, fetchAlgVectorTypes]);

  useEffect(() => {
    setSuperUserUserFilterDebounced(userFilterVal);
  }, [setSuperUserUserFilterDebounced, userFilterVal]);

  useEffect(() => {
    setUserFilterVal(userFilter);
  }, [userFilter, setUserFilterVal]);

  useEffect(() => {
    setSuperUserDeviceFilterDebounced(deviceFilterVal);
  }, [setSuperUserDeviceFilterDebounced, deviceFilterVal]);

  useEffect(() => {
    setDeviceFilterVal(deviceFilter);
  }, [deviceFilter, setDeviceFilterVal]);

  useEffect(() => {
    if (superUser) {
      fetchOrganizations(sendRequest);
    }
  }, [fetchOrganizations, sendRequest, superUser]);

  useEffect(() => {
    if (group === "users" && org !== "") {
      const processUsers = (users) =>
        users.reduce(
          (arr, user) =>
            user?.email?.includes?.(userFilter)
              ? [
                ...arr,
                {
                  name: user.name,
                  email: user.email,
                  id: user.user_id,
                  organization: user?.metadata?.organization || "orgless",
                },
              ]
              : arr,
          []
        );
      const newData = processUsers(Object.values(users?.[org] || {}));
      setData(newData);
    }
  }, [users, org, group, userFilter]);

  useEffect(() => {
    console.log(`SuperUser data: ${JSON.stringify(data, null, 4)}`);
  }, [data]);

  useEffect(() => {
    if (group === "devices" && org !== "") {
      const processDevices = (devices) =>
        devices.reduce(
          (arr, device) =>
            device?.uuid?.includes?.(deviceFilter)
              ? [
                ...arr,
                {
                  displayName: device.display_name,
                  uuid: device.uuid,
                  id: device.uuid,
                  hardwareId: device.hardware_id,
                  organization: org,
                },
              ]
              : arr,
          []
        );
      setData(processDevices(devices?.[org] || {}));
    }
  }, [devices, org, group, setData, deviceFilter]);

  useEffect(() => {
    if (_.isString(selected)) {
      if (selected === "") {
        setOrg("");
        setGroup("");
      } else {
        const [currOrg, currGroup] = selected.split(";");
        setOrg(currOrg);
        setGroup(currGroup);
      }
    }
  }, [selected]);

  useEffect(() => {
    if (group === "users") {
      setLoading(true);
      getUsersByOrg(sendRequest, org).then(() => {
        setLoading(false);
      });
    }
  }, [sendRequest, getUsersByOrg, group, org]);

  const isSelected = () =>
    [group, org].reduce(
      (curr, x) => curr & _.isString(x) && x.length > 0,
      true
    );

  const handleApproveUser = async (user, org) => {
    const newOrg = organizations?.[org];
    if (newOrg) {
      await approveUser(
        sendRequest,
        newOrg.uuid,
        user.id,
        newOrg.map_center,
        user.name
      );
    } else {
      displayErrorNotification(
        "Organization was invalid. Failed to approve user..."
      );
    }
  };

  const handleDeleteUser = async (user) => {
    if (user?.id) {
      return deleteUser(sendRequest, org, user.id, user.name);
    } else {
      displayErrorNotification("User was not found. Failed to delete user...");
    }
  };

  const handleMoveDevice = async (device, oldOrg, newOrg) => {
    if (organizations?.[newOrg]) {
      console.log(`Moving ${device}, ${oldOrg}, ${newOrg}, ${suserOrg}`);
      return moveDevice(sendRequest, device, oldOrg, newOrg, suserOrg);
    } else {
      displayErrorNotification(
        "New organization was invalid. Failed to move device..."
      );
    }
  };

  const handleRenameDevice = async (device, name) => {
    return renameDevice(sendRequest, device, name, org);
  };

  const getActions = () => {
    if (group === "users") {
      return [
        org === "orgless"
          ? {
            name: "Approve",
            icon: ApproveIcon,
            position: "primary",
            dialog: null,
            sx: buttonStyles.successButton,
            handler: (user) => setApprovingUser(user),
          }
          : null,
        {
          name: "Delete",
          icon: DeleteIcon,
          position: "primary",
          dialog: (row) =>
            `Are you sure you want to delete ${row.name} (${row.email})?\nThis action cannot be undone`,
          sx: buttonStyles.deleteButton,
          handler: handleDeleteUser,
        },
      ].filter((x) => !!x);
    } else if (group === "devices") {
      return [
        {
          name: "Move",
          icon: MoveIcon,
          position: "primary",
          dialog: null,
          sx: buttonStyles.infoButton,
          handler: (device) => setMovingDevice(device),
        },
        {
          name: "Rename",
          icon: RenameIcon,
          position: "primary",
          dialog: null,
          sx: buttonStyles.infoButton,
          handler: (device) => setRenamingDevice(device),
        },
      ];
    }
    return [];
  };

  // context menu
  const onUpdateDeviceEnv = (deviceUuid, newEnv) => {
    console.log(`updateDeviceEnv in superuserpanel ${deviceUuid}, ${newEnv}`);
    updateDeviceEnv(deviceUuid, newEnv);
  };

  const onUpdateDeviceVectorType = (deviceUuid, newVectorType) => {
    console.log(
      `updateDeviceVectorType in superuserpanel ${deviceUuid}, ${newVectorType}`
    );
    updateDeviceVectorType(deviceUuid, newVectorType);
  };

  const onUpdateDeviceHardwareId = (deviceUuid, newHardwareId) => {
    console.log(
      `updateDeviceHardwareId in superuserpanel ${deviceUuid}, ${newHardwareId}`
    );
    updateDeviceHardwareId(sendRequest, deviceUuid, newHardwareId, org);
  };

  return superUser ? (
    <div id={"superuserpanel-datatable"}>
      {isSelected() ? (
        <>
          <ContextMenu
            ref={contextMenuRef}
            devices={fullDevices}
            organizations={organizations}
            possibleVectorTypes={possibleVectorTypes}
            updateDeviceEnv={onUpdateDeviceEnv}
            updateDeviceVectorType={onUpdateDeviceVectorType}
            updateDeviceHardwareId={onUpdateDeviceHardwareId}
          />
          <ApproveUserDialog
            actionName={org === "orgless" ? "Approve" : "Move"}
            user={approvingUser}
            organizations={organizations}
            setUser={setApprovingUser}
            onConfirm={handleApproveUser}
          />
          <MoveDeviceDialog
            actionName={"Move"}
            device={movingDevice}
            org={org}
            organizations={organizations}
            setDevice={setMovingDevice}
            onConfirm={handleMoveDevice}
          />
          <RenameDeviceDialog
            actionName={"Rename"}
            device={renamingDevice}
            setDevice={setRenamingDevice}
            onConfirm={handleRenameDevice}
          />
          <Grid
            container
            spacing={2}
            sx={{
              "& .MuiTextField-root": { m: 1, minWidth: "25ch" },
            }}
            id={"superuser-filters-form"}
          >
            <Grid item xs={3}>
              <Typography
                sx={{
                  my: [
                    "0.25rem",
                    "0.25rem",
                    "0.25rem",
                    "0.25rem",
                    "0.5rem",
                    "0.5rem",
                  ],
                  fontSize: [
                    "1.25rem",
                    "1.25rem",
                    "1.25rem",
                    "1.25rem",
                    "1.75rem",
                    "1.75rem",
                  ],
                }}
              >
                {`${capitalize(group)}`}
              </Typography>
            </Grid>
            <Grid item xs={7}>
              {group === "users" ? (
                <TextField
                  id="outlined-dt-search-field"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  label={"Users"}
                  value={userFilterVal}
                  onChange={(event) =>
                    setUserFilterVal(event?.target?.value || "")
                  }
                />
              ) : group === "devices" ? (
                <TextField
                  id="outlined-dt-search-field"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  label={"Devices"}
                  value={deviceFilterVal}
                  onChange={(event) =>
                    setDeviceFilterVal(event?.target?.value || "")
                  }
                />
              ) : null}
            </Grid>
            <Grid item xs={2}></Grid>
            <Grid item xs={12}>
              <DataTable
                dataList={data}
                total={data?.length || 0}
                tableName={`${group.slice(0, -1)}`}
                columns={GROUP_COLUMNS?.[group]}
                isFetchingData={loading}
                options={{
                  allowCreate: false,
                  allowClick: false,
                  allowContext: true,
                }}
                actions={getActions()}
                // handleAddData={() => {}}
                // onClickRow={(row) => {}}
                onContextMenu={(e, row) => {
                  if (row?.organization === suserOrg) {
                    contextMenuRef.current.handleContextMenu(
                      e,
                      row?.uuid,
                      row?.organization,
                      row
                    );
                  }
                }}
              />
            </Grid>
          </Grid>
        </>
      ) : (
        <Typography
          sx={{
            my: [
              "0.25rem",
              "0.25rem",
              "0.25rem",
              "0.25rem",
              "0.5rem",
              "0.5rem",
            ],
            fontSize: [
              "1.25rem",
              "1.25rem",
              "1.25rem",
              "1.25rem",
              "1.75rem",
              "1.75rem",
            ],
          }}
        >
          Please select datatype
        </Typography>
      )}
    </div>
  ) : (
    <Typography
      sx={{
        my: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "0.5rem", "0.5rem"],
        fontSize: [
          "1.25rem",
          "1.25rem",
          "1.25rem",
          "1.25rem",
          "1.75rem",
          "1.75rem",
        ],
        color: "red",
      }}
    >
      Access Denied
    </Typography>
  );
};

export const mapStateToProps = (state) => {
  const organizations = state?.user?.organizations || {};
  const suserOrg = state?.user?.organization || "";
  const superUser = state?.user?.superUser || false;
  const users = state?.user?.orgUsers || {};
  const devices = state?.user?.orgDevices || {};
  const fullDevices = state?.devices;

  const { deviceFilter, userFilter, selected } = state?.superuser;
  return {
    superUser,
    organizations,
    suserOrg,
    users,
    devices,
    possibleVectorTypes: state?.algVersion?.vectorTypes,
    deviceFilter,
    userFilter,
    selected,
    fullDevices,
  };
};

export default connect(mapStateToProps, {
  fetchOrganizations,
  getUsersByOrg,
  approveUser,
  deleteUser,
  moveDevice,
  renameDevice,
  displaySuccessNotification,
  displayErrorNotification,
  setSuperUserUserFilterDebounced,
  setSuperUserDeviceFilterDebounced,
  updateDeviceEnv,
  updateDeviceVectorType,
  updateDeviceHardwareId,
  fetchAlgVectorTypes,
})(Index);
