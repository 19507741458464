import React, { useState } from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import Switch from "@mui/material/Switch";
import SexIcon from "../../../../../../src/assets/icons/sex_icons.svg";
import SpeciesIcon from "../../../../../../src/assets/icons/sp.svg";
import DNAIcon from "../../../../../../src/assets/icons/twemoji_dna.svg";
import { connect } from "react-redux";
import { updateUserAutofillSettings } from "../../../../../actions";
import DialogContent from "@mui/material/DialogContent";
import { useWebServer } from "../../../../../providers/WebServerProvider";
import { Typography, Box } from "@mui/material";
import ResponsiveButton from "../../../../Forms/ResponsiveButton";
import { Grid } from "@mui/material";
import OpacityIcon from '@mui/icons-material/Opacity';
import LoopIcon from '@mui/icons-material/Loop';

const GreenSwitchStyle = {
  root: {
    width: "4rem",
    height: "3rem",
    padding: 0,
    margin: ".25rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  switchBase: {
    color: "white",
    "&$checked": {
      color: "#219653",
    },
    "&$checked + $track": {
      backgroundColor: "#219653",
    },
  },
  thumb: {
    width: "1.5rem",
    height: "1.5rem",
    padding: "0",
  },
  checked: {},
  track: {
    backgroundColor: "#000000",
    opacity: 0.34,
    width: "2.8rem",
    height: "1.30rem",
    borderRadius: 10,
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: ".3rem",
  },
}

const GreenSwitch = (props) => {
  return <Switch
    {...props}
    sx={GreenSwitchStyle}
  />
}


const AutofillSettingDisplayText = ({
  autofillSettings,
  updateUserAutofillSettings,
  onClose,
}) => {
  const [state, setStates] = useState(autofillSettings);
  const [previous, setPrevious] = useState(autofillSettings);
  const { sendRequest } = useWebServer();

  const handleChange = (event) => {
    if (event.target.name.includes("DisplayChecked") && !event.target.checked) {
      // if display toggle off the following shoud also off
      setStates({
        ...state,
        [event.target.name.replace("Checked", "")]: event.target.checked,
        [event.target.name.replace("DisplayChecked", "")]: event.target.checked,
      });
    } else if (!event.target.name.includes("DisplayChecked") && event.target.checked) {
      // auto fill on, the display should also on
      setStates({
        ...state,
        [event.target.name.replace("Checked", "")]: event.target.checked,
        [event.target.name.replace("Checked", "Display")]: event.target.checked,
      });
    } else {
      setStates({
        ...state,
        [event.target.name.replace("Checked", "")]: event.target.checked,
      });
    }
  };

  //save the setting to the previous and store to the redux
  const saveSettings = () => {
    setPrevious(state);
    updateUserAutofillSettings(sendRequest, state);
    onClose();
  };

  const discardChanges = () => {
    setStates(previous);
    onClose();
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  };

  return (
    <div style={style} className={"m-auto transition-opacity duration-1000"}>
      <div
        className={
          "flex flex-col bg-white max-w-2xl text-xl px-10 pt-8 pb-4 rounded-xl border-4 border-transparent"
        }
      >
        <Typography
          sx={{
            fontWeight: 500,
            fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
            mb: "0.5rem"
          }}
        >
          Contributed Data Section Settings
        </Typography>

        <Typography
          className={"text-black text-opacity-50"}
          sx={{
            fontSize: ["0.85rem", "0.85rem", "0.85rem", "0.85rem", "1.25rem", "1.25rem"]
          }}
        >
          AutoFill: When a switch is turned on, the autofill button will fill the relevant
          fields with the current specimen's contributed data. Example: If the{" "}
          <span className={"text-blue-600"}> Autofill 1-12 </span> button is
          pressed, all twelve cells will be autofilled. If the{" "}
          <span className={"text-blue-600"}> Autofill 4-12 </span>
          button is pressed, cells 4-12 will be filled and cells 1-3 will remain
          unaffected.

        </Typography>
        <Grid container spacing={2}>
          <Grid item md={8}>
          </Grid>
          <Grid item md={2}>
            <div>Display</div>
          </Grid>
          <Grid item md={2}>
            <div>Autofill</div>
          </Grid>

          <Grid item md={8}>
            <label className={"my-auto mx-4"}>Species</label>
            <Box
              sx={{
                "& img": {
                  height: ["1.25rem", "1.25rem", "1.25rem", "1.25rem", "1.5rem", "1.5rem"]
                },
                display: 'inline-block'
              }}
            >
              <img
                className={"inline-block mx-auto"}
                src={SpeciesIcon}
                alt='Species Icon'
              />
            </Box>
          </Grid>
          <Grid item md={2}>
            <GreenSwitch
              checked={state.speciesDisplay}
              onChange={handleChange}
              color="primary"
              name="speciesDisplayChecked"
              inputProps={{ "aria-label": "speciesDisplayChecked" }}
            />
          </Grid>
          <Grid item md={2}>
            <GreenSwitch
              checked={state.species}
              onChange={handleChange}
              color="primary"
              name="speciesChecked"
              inputProps={{ "aria-label": "speciesChecked" }}
            />
          </Grid>

          <Grid item md={8}>
            <label className={"my-auto mx-4"}>Sex</label>
            <Box
              sx={{
                "& img": {
                  height: ["2rem", "2rem", "2rem", "2rem", "2.5rem", "2.5rem"]
                },
                display: 'inline-block'
              }}
            >
              <img className={"my-auto inline-block"} src={SexIcon} alt='Sex Icon' />
            </Box>
          </Grid>
          <Grid item md={2}>
            <GreenSwitch
              checked={state.sexDisplay}
              onChange={handleChange}
              color="primary"
              name="sexDisplayChecked"
              inputProps={{ "aria-label": "sexDisplayChecked" }}
            />
          </Grid>
          <Grid item md={2}>
            <GreenSwitch
              checked={state.sex}
              onChange={handleChange}
              color="primary"
              name="sexChecked"
              inputProps={{ "aria-label": "sexChecked" }}
            />
          </Grid>
          <Grid item md={8}>
            <label className={"my-auto mx-4"}>Plate and Well</label>
            <Box
              className={
                "w-32 justify-center ml-auto"
              }
              sx={{
                "& img": { height: "2rem" },
                display: 'inline-block'
              }}
            >
              <img className={"inline-block my-auto mx-auto"} src={DNAIcon} alt="DNA Icon" />
            </Box>
          </Grid>
          <Grid item md={2}>
            <GreenSwitch
              checked={state.molecularDisplay}
              onChange={handleChange}
              color="primary"
              name="molecularDisplayChecked"
              inputProps={{ "aria-label": "molecularDisplayChecked" }}
            />
          </Grid>
          <Grid item md={2}>
            <GreenSwitch
              checked={state.molecular}
              onChange={handleChange}
              color="primary"
              name="molecularChecked"
              inputProps={{ "aria-label": "molecularChecked" }}
            />
          </Grid>
          <Grid item md={8}>
            <label className={"my-auto mx-4"}>Life Stage</label>  <LoopIcon sx={{ color: 'rgb(26, 93, 26)' }} />
          </Grid>
          <Grid item md={2}>
            <GreenSwitch
              checked={state.lifeStageDisplay}
              onChange={handleChange}
              color="primary"
              name="lifeStageDisplayChecked"
              inputProps={{ "aria-label": "lifeStageDisplayChecked" }}
            />
          </Grid>
          <Grid item md={2}>
            <GreenSwitch
              checked={state.lifeStage}
              onChange={handleChange}
              color="primary"
              name="lifeStageChecked"
              inputProps={{ "aria-label": "lifeStageChecked" }}
            />
          </Grid>
          <Grid item md={8}>
            <label className={"my-auto mx-4"}>Gonotrophic/Feed Status</label>  <OpacityIcon sx={{ color: 'rgb(255,0,0,0.7)' }} />
          </Grid>
          <Grid item md={2}>
            <GreenSwitch
              checked={state.gonotrophicDisplay}
              onChange={handleChange}
              color="primary"
              name="gonotrophicDisplayChecked"
              inputProps={{ "aria-label": "gonotrophicDisplayChecked" }}
            />
          </Grid>
          <Grid item md={2}>
            <GreenSwitch
              checked={state.gonotrophic}
              onChange={handleChange}
              color="primary"
              name="gonotrophicChecked"
              inputProps={{ "aria-label": "gonotrophicChecked" }}
            />
          </Grid>
          <Grid item sm={8}>
            <label className={"my-auto mx-4"}>Skip the algorithm empty during autofill</label>

          </Grid>
          <Grid item sm={4}>
            <GreenSwitch
              checked={state.skipEmpty}
              onChange={handleChange}
              color="primary"
              name="skipEmptyChecked"
              inputProps={{ "aria-label": "skipEmptyChecked" }}
            />
          </Grid>
        </Grid>

        <Box className={"flex flex-row-reverse mt-8"}
          sx={{
            "& button + button": {
              mr: "1rem"
            }
          }}
        >
          <ResponsiveButton
            // size="large"
            variant="contained"
            // color="secondary"
            style={{
              color: "#2196F3",
              backgroundColor: "white",
              borderColor: "rgba(63, 81, 181, 0.5)",
              border: "1px solid",
              boxShadow: "none",
            }}
            onClick={saveSettings}

          >
            SAVE
          </ResponsiveButton>
          <ResponsiveButton
            // size="large"
            variant="contained"
            // style={{ color: "#EA596E" }}
            onClick={discardChanges}
            style={{ backgroundColor: "#E10050" }}
          >
            DISCARD CHANGES
          </ResponsiveButton>
        </Box>
      </div >
    </div >
  );
};

const AutofillSettingModal = ({
  autofillSettings,
  autofillModalOpen,
  setAutofillModalOpen,
  updateUserAutofillSettings,
}) => {
  const onClose = () => setAutofillModalOpen(false);
  return (
    <div>
      <Modal
        open={autofillModalOpen}
        onClose={onClose}
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <DialogContent>
          <AutofillSettingDisplayText
            updateUserAutofillSettings={updateUserAutofillSettings}
            autofillSettings={autofillSettings}
            onClose={onClose}
          />
        </DialogContent>
      </Modal>
    </div>
  );
};

export const mapStateToProps = (state) => {
  const { autofillSettings } = state.user;

  return {
    autofillSettings,
  };
};

export default connect(mapStateToProps, { updateUserAutofillSettings })(
  AutofillSettingModal
);
