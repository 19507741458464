import {
    UPDATE_GENERAL_FILTERS,
    CLEAR_GENERAL_FILTERS,
    UPDATE_IS_TRAY_FILTERS_MODAL
} from "./types";

export const updateGeneralFilters = (val) => (dispatch) => {
    let payload = { generalFilters: { ...val } }
    dispatch({ type: UPDATE_GENERAL_FILTERS, payload });
};

export const clearGeneralFilters = () => (dispatch) => {
    dispatch({ type: CLEAR_GENERAL_FILTERS });
};

export const updateIsTrayFiltersModal = (isOpen) => (dispatch) => {
    dispatch({ type: UPDATE_IS_TRAY_FILTERS_MODAL, payload: isOpen });
}