import React from "react";
import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";
import WifiIcon from "@mui/icons-material/Wifi";

const WifiScanPage = ({ handleScanButtonPressed }) => {
  return (
    <Paper
      className={"absolute inset-0 flex items-center justify-center"}
      id={"wifi-scan"}
    >
      <Fab
        id={"wifi-scan-button"}
        variant={"extended"}
        color={"secondary"}
        onClick={handleScanButtonPressed}
      >
        <WifiIcon className={"mr-1"} />
        Scan
      </Fab>
    </Paper>
  );
};

export default WifiScanPage;
