import { snakeCase, camelCase, isString, isPlainObject } from "lodash";

const DONTSNAKEVALUE = [
  "plates",
  "algCls",
  "contribCls",
  "users",
  "trapSites",
  "trapTypes",
  "contribSex",
  "imagingStartTime",
  "imagingEndTime",
  "specimenCaptureStartTime",
  "specimenCaptureEndTime",
];

export const snakeify = (o, inclValues = false) =>
  o
    ? Object.fromEntries(
      Object.entries(o).map(([k, v]) => {
        return [
          snakeCase(k),
          inclValues && isString(v) && !DONTSNAKEVALUE.includes(k)
            ? snakeCase(v)
            : v,
        ];
      })
    )
    : null;

export const camelify = (o, recursive = false) =>
  o
    ? Object.fromEntries(
      Object.entries(o).map(([k, v]) => [
        camelCase(k),
        recursive && isPlainObject(v) ? camelify(v, recursive) : v,
      ])
    )
    : null;

export const capitalize = (s) =>
  s ? s.charAt(0)?.toUpperCase() + s?.substr(1)?.toLowerCase() : "";

export const getSppDisplayName = (genus, species, size = -1) => {
  genus = genus || ""
  species = species || ""
  const genusLen = size > 0 ? Math.min(size, genus.length) : genus.length;
  if (genusLen !== genus.length) {
    switch (genus.toLowerCase()) {
      case "unknown":
        return "Unknown spp";
      case "empty":
        return "Empty cell";
      case "dirty":
        return "Dirty cell";
      case "aedes":
        return `Ae. ${species.toLowerCase()}`;
      case "anopheles":
        return `An. ${species.toLowerCase()}`;
      case "culiseta":
        return `Cu. ${species.toLowerCase()}`;
      case "culex":
        return `Cx. ${species.toLowerCase()}`;
      case "psorophora":
        return `Ps. ${species.toLowerCase()}`;
      case "mansonia":
        return `Mn. ${species.toLowerCase()}`;
      case "deinocerites":
        return `De. ${species.toLowerCase()}`;
      case "coquillettidia":
        return `Cq. ${species.toLowerCase()}`;
      default:
      // pass
    }
  }
  if (genus === "") return "";
  return `${genus.charAt(0).toUpperCase() + genus.substr(1, genusLen - 1).toLowerCase()
    }${genusLen !== genus.length ? "." : ""} ${species.toLowerCase()}`;
};
