import React, { useState, useEffect } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import MoveDownIcon from "@mui/icons-material/MoveDown";

const SuperUserContextMenu = ({
  device,
  onMoveDeviceEnv,
  handleClose,
  contextMenuEvent,
  onChangeDeviceVectorType,
  onChangeDeviceRegion,
  onChangeHardwareId,
}) => {
  const [region, setRegion] = useState("mosquito");
  const [vectorType, setVectorType] = useState("mosquito");

  useEffect(() => {
    setRegion(device?.vectorType?.toLowerCase?.() || "mosquito");
  }, [device]);

  useEffect(() => {
    setVectorType(
      region.startsWith("moz")
        ? "mosquito"
        : region.startsWith("tick")
        ? "tick"
        : region
    );
  }, [region]);

  return (
    <Menu
      open={contextMenuEvent !== null}
      onClose={handleClose}
      anchorReference="anchorPosition"
      anchorPosition={
        contextMenuEvent !== null
          ? { top: contextMenuEvent.mouseY, left: contextMenuEvent.mouseX }
          : undefined
      }
    >
      <MenuItem onClick={onMoveDeviceEnv}>
        <ListItemIcon>
          <MoveDownIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>
          Move env (From {device?.env?.toLowerCase?.()})
        </ListItemText>
      </MenuItem>
      <MenuItem onClick={onChangeDeviceVectorType}>
        <ListItemIcon>
          <MoveDownIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Change Vector Type (From {vectorType})</ListItemText>
      </MenuItem>
      <MenuItem onClick={onChangeDeviceRegion}>
        <ListItemIcon>
          <MoveDownIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Change Region (From {region})</ListItemText>
      </MenuItem>
      <MenuItem onClick={onChangeHardwareId}>
        <ListItemIcon>
          <MoveDownIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText>Change Hardware Id</ListItemText>
      </MenuItem>
      {/* <MenuItem>
        <ListItemIcon>
          <ContentCopy fontSize="small" />
        </ListItemIcon>
        <ListItemText>Copy</ListItemText>
        <Typography variant="body2" color="text.secondary">
          ⌘C
        </Typography>
      </MenuItem>
      <MenuItem>
        <ListItemIcon>
          <ContentPaste fontSize="small" />
        </ListItemIcon>
        <ListItemText>Paste</ListItemText>
        <Typography variant="body2" color="text.secondary">
          ⌘V
        </Typography>
      </MenuItem>
      <Divider />
      <MenuItem>
        <ListItemIcon>
          <Cloud fontSize="small" />
        </ListItemIcon>
        <ListItemText>Web Clipboard</ListItemText>
      </MenuItem> */}
    </Menu>
  );
};

export default SuperUserContextMenu;
