export const orientationFlipped = (o) => {
  switch (o) {
    case "front":
      return "back";
    case "back":
      return "front";
    default:
      return o;
  }
};
