import _ from "lodash";
import { twoDigitYearAutoFill } from "../../utils/timeConversion";
import { checkCommaAndBackSlash, checkCommaAndBackSlashOnTags } from "../../lib/validationHelper"
import { SubmissionError } from "redux-form";

const trayMetadataSubmit = async (
  values,
  {
    uploadTrayMetadata,
    updateTrayFromMetadata,
    selectedDevice,
    trayId,
    createdOn,
    trapSites,
    specList,
    // initialize,
    // initialValues,
  },
  isChanged
) => {
  let { error, formattedValues } = validateAndFormate(values)
  if (formattedValues?.['notes'] === undefined) {
    formattedValues['notes'] = null
  }

  const trapSitesMap = _.mapKeys(trapSites, "id");
  const trapSite =
    values?.trapSiteId === null || values?.trapSiteId === ""
      ? null
      : trapSitesMap?.[values?.trapSiteId];
  // validate
  const vals = JSON.parse(JSON.stringify(formattedValues));
  const formVal = JSON.parse(JSON.stringify(formattedValues));
  if (values?.captureDate) {
    const capDate = twoDigitYearAutoFill(values.captureDate);
    if (
      capDate < new Date("1900-01-01") ||
      capDate.toDateString() === "Invalid Date" ||
      capDate > new Date()
    ) {
      return;
    }
    // vals.captureDate = capDate.toUTCString().replace(",", "").substring(0, CAPTURE_DATE_FORMAT.length);
    vals.captureDate = `${capDate.getUTCFullYear()}-${(capDate.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}-${capDate
        .getUTCDate()
        .toString()
        .padStart(2, "0")}T00:00:00.000Z`;
    formVal.captureDate = `${capDate.getUTCFullYear()}-${(capDate.getUTCMonth() + 1)
      .toString()
      .padStart(2, "0")}-${capDate
        .getUTCDate()
        .toString()
        .padStart(2, "0")}T00:00:00.000Z`;
  }

  // console.log('i am saving tray metadata', vals)
  if (isChanged) {
    //console.log('i am saving tray metadata', vals)
    await uploadTrayMetadata(trayId, vals);
  }

  await updateTrayFromMetadata(
    trayId,
    selectedDevice,
    { ...formVal, specimens: specList, createdOn },
    trapSite
  );
  if (Object.keys(error).length > 0) {
    throw new SubmissionError(error)
  }
};

const validateAndFormate = (values) => {
  let error = {}
  let tmpResult = _.cloneDeep(values)
  let cur
  if (values?.trapType) {
    cur = checkCommaAndBackSlash(values?.trapType)
    if (cur) {
      error['trapType'] = cur
      tmpResult.trapType = ''
    }
  }
  if (values?.tags) {
    cur = checkCommaAndBackSlashOnTags(values?.tags)
    if (cur) {
      error['tags'] = cur
      tmpResult['tags'] = values.tags.filter((val) => !checkCommaAndBackSlash(val))
    }
  }
  return { error, formattedValues: tmpResult }
}

export default trayMetadataSubmit;
