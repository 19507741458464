
import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";

const addressInputSx = {
    '& .MuiOutlinedInput-input': {
        fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
        py: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "0.5rem", "0.5rem"],
    }
}

const MapCenterInput = ({ mapCenter, updateUserMapCenterSettings }) => {
    const [value, setValue] = useState('0 0');
    const [error, setError] = useState(false)
    const [errorMsg, setErrorMsg] = useState('')
    useEffect(() => {
        setValue(mapCenter.replace(',', ''))
    }, [mapCenter])

    const computeError = (value) => {
        let err = false, errMsg = '';

        const pattern = new RegExp(/([-]*[0-9]+\.[0-9]+ [-]*[0-9]+\.[0-9]+)/i)
        if (value !== '') {
            if (pattern.test(value) && (value?.search(/[a-zA-Z_]+/i) === -1)) {
                let mapCenterStr = value.split(' ').map(parseFloat).join(', ')
                updateUserMapCenterSettings(mapCenterStr)
            }
            else {
                err = true
                errMsg = 'Gps location Format should like 1.222 -1.222'
            }
        }
        setError(err)
        setErrorMsg(errMsg)
    }

    useEffect(() => {
        computeError(value)
        // eslint-disable-next-line
    }, [value])

    return (
        <TextField
            sx={addressInputSx}
            error={error}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            helperText={errorMsg}
        />)
};

export default MapCenterInput;
