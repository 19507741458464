import React, { useState } from "react";
import { connect } from "react-redux";
import _ from "lodash";
import {
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Tooltip,
  Button
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import ConfirmDialog from "../ConfirmDialog";
import { updateDataTableSettings } from "../../actions";
import TrayFetchFilter from "./TrayFetchFilter";

const buttonStyles = {
  infoButton: {
    color: "status.info",
  },
  editButton: {
    "&:hover, &:focus": {
      color: "status.info",
    },
  },
  deleteButton: {
    "&:hover, &:focus": {
      color: "status.danger",
    },
  },
  tableHeadRow: {
    "&:first-of-type": {
      backgroundColor: "#000000",
      color: "#000000",
    },
  },
  finishButton: {
    background: 'status.success',
    color: 'white.main',
  }
};

const TABLE_SX = {
  "& .MuiTablePagination-toolbar": {
    paddingLeft: ["1rem", "1rem", "1rem", "1rem", "1rem", "1rem"],
    height: ["2rem", "2rem", "2.15rem", "2.15rem", "3rem", "3rem"],
    fontSize: [
      "0.6rem", "0.6rem", "0.6rem", "0.6rem", "0.875rem", "0.875rem",
    ],
    minHeight: ["3.25rem", "3.25rem", "2.15rem", "2.15rem", "3.25rem", "3.25rem",
    ],
  },
  "& .MuiTablePagination-selectLabel": {
    fontSize: ["0.6rem", "0.6rem", "0.6rem", "0.6rem", "0.875rem", "0.875rem",
    ],
  },
  "& .MuiTablePagination-select": {
    paddingLeft: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "0.75rem", "0.75rem",
    ],
    paddingRight: ["0.5rem", "0.5rem", "1.15rem !important",
      "1.15rem !important", "2rem !important", "2rem !important",
    ],
  },
  "& .MuiTablePagination-displayedRows": {
    fontSize: ["0.6rem", "0.6rem", "0.6rem", "0.6rem", "0.875rem", "0.875rem",
    ],
  },
  "& .MuiInputBase-root": {
    marginRight: ["0.5rem", "0.5rem", "0.5rem", "0.5rem", "1.5rem", "2rem",
    ],
    marginLeft: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "1.5rem", "2rem",
    ],
  },
  "& .MuiSvgIcon-root": {
    fontSize: ["1.5rem", "1.5rem", "1.15rem", "1.15rem", "2rem", "2rem",
    ],
  },
  "& .MuiTablePagination-actions": {
    marginLeft: ["0.8rem", "0.8rem", "0.8rem !important",
      "0.8rem !important", "1.2rem !important", "1.2rem !important",
    ],
  },
  "& .MuiIconButton-root": {
    padding: ["0.5rem", "0.5rem", "0.33rem", ".33rem", ".5rem", ".5rem",
    ],
  },
  "& .MuiIconButton": {
    fontSize: ["1.5rem", "1.5rem", "1.15rem", "1.15rem", "1.5rem", "1.5rem",
    ],
  },
}

const ACTIONS_COLUMN = {
  field: "actions",
  label: "Actions",
  isSortable: false,
  align: "right",
};

const getField = (row, field) =>
  field.split(".").reduce((ret, subfield) => ret?.[subfield] || null, row);

const DataTable = ({
  dataList,
  total,
  tableName,
  columns,
  isFetchingData,
  onClickRow,
  onClickEdit,
  handleAddData,
  handleDeleteData,
  onContextMenu,
  options,
  actions = [],
  page,
  pageSize,
  orderBy,
  order,
  loading,
  rowsPerPageOptions,
  updateDataTableSettings,
}) => {
  const {
    allowCreate = false,
    allowClick = false,
    allowContext = false,
  } = options;

  const [showConfirmation, setShowConfirmation] = useState({});

  dataList = _.orderBy(dataList, [orderBy], [order]);
  if (actions.length > 0) {
    columns = [...columns, ACTIONS_COLUMN];
  }

  const handleChangePage = (e, newPage) => {
    updateDataTableSettings(tableName, { page: newPage });
  };

  const handleChangeRowsPerPage = (e) => {
    updateDataTableSettings(tableName, { page: 0, pageSize: e.target.value });
  };

  const handleSorting = (property) => (e) => {
    const isAsc = property === orderBy && order === "asc";
    updateDataTableSettings(tableName, {
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  return (
    <Paper className={"flex flex-col"}>
      <div className={"flex flex-row justify-between"}>
        {(page || page === 0) && pageSize && handleChangePage ? (
          <TablePagination
            rowsPerPageOptions={rowsPerPageOptions}
            component={"div"}
            rowsPerPage={pageSize}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            count={total}
            backIconButtonProps={{
              sx: { ...buttonStyles.infoButton },
            }}
            nextIconButtonProps={{
              sx: { ...buttonStyles.infoButton },
            }}
            sx={TABLE_SX}
          />
        ) : null}

        {loading ? (
          <CircularProgress
            // size="2rem"
            sx={{
              padding: [
                "0.5rem",
                "0.5rem",
                "0.33rem",
                ".33rem",
                ".5rem",
                ".5rem",
              ],
              fontSize: [
                "1.5rem",
                "1.5rem",
                "1.15rem",
                "1.15em",
                "1.5rem",
                "1.5rem",
              ],
              cx: 20,
              cy: 20,
              width: [
                "30px !important",
                "30px !important",
                "30px !important",
                "30px !important",
                "40px !important",
                "40px !important",
              ],
              height: [
                "30px !important",
                "30px !important",
                "30px !important",
                "30px !important",
                "40px !important",
                "40px !important",
              ],
            }}
          />
        ) : tableName === "Collection Site" && allowCreate ? (
          <Tooltip
            title={`Add new ${tableName}`}
            sx={{
              float: "right",
            }}
          >
            <Button
              onClick={handleAddData}
              sx={{
                ...buttonStyles.infoButton,
                fontSize: [
                  "0.6rem",
                  "0.6rem",
                  "0.6rem",
                  "0.6rem",
                  "0.875rem",
                  "0.875rem",
                ],
                padding: 0,
              }}
            >
              Create
            </Button>
          </Tooltip>
        ) : (
          <TrayFetchFilter tableName={tableName} />
        )}
      </div>
      <TableContainer style={{ height: "75vh" }}>
        <Table
          stickyHeader
          sx={{
            "& .MuiTableCell-root": {
              fontSize: [
                "0.6rem",
                "0.6rem",
                "0.6rem",
                "0.6rem",
                "0.875rem",
                "0.875rem",
              ],
              padding: [
                "0.66rem",
                "0.66rem",
                "0.66rem",
                "0.66rem",
                "0.75rem",
                "0.75rem",
              ],
            },
            "& .MuiTableCell-paddingNone": {
              padding: "0 !important",
            },
          }}
        >
          <TableHead
            sx={{
              "& .MuiTableRow-head": {
                fontSize: "parent",
                padding: [
                  "0.5rem",
                  "0.5rem",
                  "0.5rem",
                  "0.5rem",
                  "1rem",
                  "1rem",
                ],
              },
              "& .MuiTableCell-root": {
                padding: [
                  "0.5rem",
                  "0.5rem",
                  "0.5rem",
                  "0.5rem",
                  "1rem",
                  "1rem",
                ],
              },
              "& .MuiTableSortLabel-icon": {
                fontSize: [
                  "0.75rem",
                  "0.75rem",
                  "0.75rem",
                  "0.75rem",
                  "1.125rem",
                  "1.125rem",
                ],
              },
            }}
          >
            <TableRow sx={buttonStyles.tableHeadRow}>
              {columns.map((column) => {
                return (
                  <TableCell
                    key={column.field}
                    align={column.align}
                    sx={{ backgroundColor: "#fafafa", ...column?.sx }}
                  >
                    {column.isSortable && (
                      <TableSortLabel
                        active={orderBy === column.field}
                        direction={order}
                        onClick={handleSorting(column.field)}
                      />
                    )}
                    {column.label}
                  </TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {dataList.map((row) => {
              return (
                <TableRow
                  hover
                  className={allowClick ? "cursor-pointer" : "cursor-default"}
                  onClick={allowClick ? () => onClickRow(row) : () => null}
                  onContextMenu={
                    allowContext ? (e) => onContextMenu(e, row) : () => null
                  }
                  key={`table-row-${row.id}`}
                >
                  {columns
                    .slice(0, actions.length > 0 ? -1 : undefined)
                    .map((col) => (
                      <TableCell
                        align={col.align}
                        key={`table-cell-row${row.id}-col${col.field}`}
                        sx={col?.sx || {}}
                      >
                        <Typography
                          noWrap
                          sx={{
                            fontSize: [
                              "0.6rem",
                              "0.6rem",
                              "0.6rem",
                              "0.6rem",
                              "0.875rem",
                              "0.875rem",
                            ],
                          }}
                        >
                          {(col?.formatFn || ((s) => s))(
                            getField(row, col.field)
                          )}
                        </Typography>
                      </TableCell>
                    ))}
                  {actions.length > 0 ? (
                    <TableCell padding={"none"} align={"right"}>
                      {actions.map((action) =>
                        action ? (
                          <span key={`action-${action.name}`}>
                            {action?.dialog ? (
                              <ConfirmDialog
                                title={`${action.name} ${tableName}`}
                                open={
                                  Array.isArray(
                                    showConfirmation?.[action.name]
                                  ) &&
                                  showConfirmation?.[action.name].length > 0
                                }
                                setOpen={() =>
                                  setShowConfirmation({
                                    ...showConfirmation,
                                    [action.name]: false,
                                  })
                                }
                                onConfirm={showConfirmation?.[action.name]?.[1]}
                              >
                                {showConfirmation?.[action.name]?.[0]}
                              </ConfirmDialog>
                            ) : null}
                            <Tooltip title={`${action.name} ${tableName}`}>
                              <IconButton
                                onClick={() =>
                                  action?.dialog
                                    ? setShowConfirmation({
                                      ...showConfirmation,
                                      [action.name]: [
                                        action.dialog(row),
                                        () => action.handler(row),
                                      ],
                                    })
                                    : action.handler(row)
                                }
                                sx={{
                                  ...action.sx,
                                  padding: [
                                    "0.75rem",
                                    "0.75rem",
                                    "0.5rem",
                                    "0.5rem",
                                    "0.75rem",
                                    "0.75rem",
                                  ],
                                }}
                              >
                                <action.icon
                                  sx={{
                                    fontSize: [
                                      "1rem",
                                      "1rem",
                                      "1rem !important",
                                      "1rem !important",
                                      "1.5rem !important",
                                      "1.5rem !important",
                                    ],
                                  }}
                                />
                              </IconButton>
                            </Tooltip>
                          </span>
                        ) : null
                      )}
                    </TableCell>
                  ) : null}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper >
  );
};

export const mapStateToProps = (state, { tableName }) => {
  if (tableName) {
    return state?.settings?.[tableName] || {};
  }
  return {};
};

export default connect(mapStateToProps, { updateDataTableSettings })(DataTable);
