import {
  SELECT_SUPERUSER_ORG,
  CHANGE_SUPERUSER_ORG_FILTER,
  CHANGE_SUPERUSER_USER_FILTER,
  CHANGE_SUPERUSER_DEVICE_FILTER,
} from "./types";

import _ from "lodash";

export const selectSuperUserOrg = (selected) => async (dispatch) => {
  dispatch({ type: SELECT_SUPERUSER_ORG, payload: { selected } });
};

export const setSuperUserOrgFilter = (orgFilter) => async (dispatch) => {
  dispatch({ type: CHANGE_SUPERUSER_ORG_FILTER, payload: { orgFilter } });
};

export const setSuperUserUserFilter = (userFilter) => async (dispatch) => {
  dispatch({ type: CHANGE_SUPERUSER_USER_FILTER, payload: { userFilter } });
};

const setSuperUserUserFilterDebouncedInner = _.debounce(
  (userFilter, dispatch) => dispatch(setSuperUserUserFilter(userFilter)),
  250
);

export const setSuperUserUserFilterDebounced =
  (userFilter) => async (dispatch) => {
    setSuperUserUserFilterDebouncedInner(userFilter, dispatch);
  };

export const setSuperUserDeviceFilter = (deviceFilter) => async (dispatch) => {
  dispatch({ type: CHANGE_SUPERUSER_DEVICE_FILTER, payload: { deviceFilter } });
};

const setSuperUserDeviceFilterDebouncedInner = _.debounce(
  (deviceFilter, dispatch) => dispatch(setSuperUserDeviceFilter(deviceFilter)),
  250
);

export const setSuperUserDeviceFilterDebounced =
  (deviceFilter) => async (dispatch) => {
    setSuperUserDeviceFilterDebouncedInner(deviceFilter, dispatch);
  };
