import React from "react";
import { Switch, Route } from "react-router-dom";
import ROUTES from "../../../../AppRouter/routes";
import TrapSiteTable from "./TrapSiteTable";
import TrapSiteEdit from "./TrapSiteEdit";
import TrapSiteCreate from "./TrapSiteCreate";

const Index = () => {
  return (
    <Switch>
      <Route path={ROUTES.editTrapSite} component={TrapSiteEdit} />
      <Route path={ROUTES.createTrapSite} component={TrapSiteCreate} />
      <Route path={ROUTES.trapSite} component={TrapSiteTable} />
    </Switch>
  );
};

export default Index;
