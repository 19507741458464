import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

const ConfirmDialog = (props) => {
  const {
    title,
    children,
    open,
    setOpen,
    onConfirm,
    id,
    onClose = () => { },
    cancelMessage = "cancel",
    confirmMessage = "continue",
  } = props;
  const handleClose = () => {
    onClose();
    setOpen(false);
  };
  return (
    <Dialog
      id={id}
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="secondary"
          id={"dialog-cancel"}
        >
          {cancelMessage}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            onConfirm();
          }}
          id={"dialog-confirm"}>
          {confirmMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
