import React from "react";
import { connect } from "react-redux";
import {
  setSuperUserOrgFilter,
  setSuperUserUserFilter,
  setSuperUserDeviceFilter,
} from "../../../../../actions";
import FiltersDialog from "./FiltersDialog";

const Index = ({
  orgFilter,
  deviceFilter,
  userFilter,
  setSuperUserOrgFilter,
  setSuperUserUserFilter,
  setSuperUserDeviceFilter,
  isActive,
  setIsActive,
}) => {
  const handleClose = () => {
    setIsActive(null);
  };

  const handleSave = (deviceVal, orgVal, userVal) => {
    setSuperUserDeviceFilter(deviceVal);
    setSuperUserOrgFilter(orgVal);
    setSuperUserUserFilter(userVal);
    handleClose();
  };

  return !!isActive ? (
    <FiltersDialog
      orgFilter={orgFilter}
      deviceFilter={deviceFilter}
      userFilter={userFilter}
      isActive={isActive}
      onCancel={handleClose}
      onSave={handleSave}
    />
  ) : null;
};

export const mapStateToProps = (state) => {
  const { orgFilter, deviceFilter, userFilter } = state?.superuser;
  return {
    orgFilter,
    deviceFilter,
    userFilter,
  };
};

export default connect(mapStateToProps, {
  setSuperUserOrgFilter,
  setSuperUserUserFilter,
  setSuperUserDeviceFilter,
})(Index);
