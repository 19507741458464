import React, { useEffect, useMemo } from 'react';

// Material UI imports 
import Box from '@mui/material/Box';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Container } from '@mui/material';
import { styled } from '@mui/material/styles';
import { GridToolbarColumnsButton, GridToolbarFilterButton } from '@mui/x-data-grid';


const StyledDataGrid = styled(DataGrid)(({ theme }) => ({

    '& ::-webkit-scrollbar': {
        'border-top': '1px solid #e9e9ec',
        'height': '7px',
        'width': '7px'
    },
    '& ::-webkit-scrollbar-thumb:horizontal': {
        'border-radius': '15px',
        'background-color': '#6d6d6dc9',
    },
    '& ::-webkit-scrollbar-thumb:vertical': {
        'border-radius': '15px',
        'background-color': '#6d6d6dc9',
    },
    '& .MuiDataGrid-columnHeader[data-field="Male"]': {
        borderLeft: '2px dotted #3f51b5',
    },
    '& .MuiDataGrid-cell[data-field="Male"]': {
        borderLeft: '2px dotted #3f51b5',
    },
    '& .MuiDataGrid-cell': {
        textAlign: 'center',
    },
    '& .MuiDataGrid-scrollbar--horizontal': {
        'display': 'block',
    },
}));

const CustomToolbar = () => {
    return (
        <Box sx={{
            p: 1, display: 'flex',
            justifyContent: 'flex-start',
        }}>
            <GridToolbarColumnsButton />
            <GridToolbarFilterButton />
        </Box>
    );
};

export default function AbundanceDataPreview({ data, onDownloadData, filters, loading }) {


    const jsonData = JSON.stringify(data);
    const parsedData = JSON.parse(jsonData);
    console.log(parsedData);
    // const headers = Object.keys(parsedData[0] || {});
    // const rows2 = parsedData;


    // const columns = useMemo(() => [
    //     ...Object.keys(parsedData[0] || {}).sort()
    //         .filter((key) => !['Male', 'Female', 'Total_Count', 'Sex unknown'].includes(key)),
    //     ...['Male', 'Female', 'Sex unknown', 'Total_Count']]
    //     .map((key) => ({
    //         field: key,
    //         headerName: key,
    //         width: 200,
    //     })));

    const columns = useMemo(() => {
        const fixedStart = 'species';
        const fixedEnd = ['Male', 'Female', 'Sex unknown', 'Total_Count'];


        const allKeys = Object.keys(data[0] || {});

        // Separate keys into different categories
        const startColumn = allKeys.includes(fixedStart) ? [fixedStart] : [];
        const endColumns = fixedEnd.filter(key => allKeys.includes(key));
        const middleColumns = allKeys
            .filter(key => ![fixedStart, ...fixedEnd].includes(key))
            .sort(); // Sort remaining columns alphabetically

        // Combine columns in the desired order
        const orderedColumns = [
            ...startColumn,
            ...middleColumns,
            ...endColumns
        ];

        // Map to column definitions for DataGrid
        return orderedColumns.map(key => ({
            field: key,
            headerName: key === 'Male' ? 'Total_Male' : key === 'Female' ? 'Total_Female' : key,
            minWidth: 200,
            headerAlign: 'center',
        }));
    }, [data]);

    const lastRow = parsedData.pop();

    const rowsWithId = parsedData.map((row, index) => ({
        ...row,
        id: index + 1,
    }));

    if (lastRow) {
        rowsWithId.unshift({
            ...lastRow,
            id: 0,
        });
    }

    useEffect(() => {
        onDownloadData();
        // eslint-disable-next-line
    }, [filters]);

    return (
        <div style={{}}>
            {/* Line graph section */}
            <div style={{ textAlign: 'center', color: '#3f51b5', fontFamily: 'Roboto' }}>
                {/* <div>SPECIES SUMMARY AMONG COLLECTION SITES</div> */}
                {/* <div style={{ fontSize: '12px', fontWeight: '200px' }}>{'( '}Abundance over time{' )'}</div> */}
            </div>

            <div style={{ marginTop: '20px', height: '350px', width: '100%', fontSize: '11px' }}>

                <Container>
                    <Box sx={{ height: '500px', width: '100%' }}>
                        <StyledDataGrid
                            rows={rowsWithId}
                            columns={columns}
                            localeText={{ noRowsLabel: loading ? "" : "No Data" }}
                            initialState={{
                                pagination: {
                                    paginationModel: {
                                        pageSize: 10,
                                    },
                                },
                                columns: {
                                    columnVisibilityModel: {
                                        empty: false,
                                        Unsure: false,
                                        // 'Male': false,
                                        // 'Female': false,
                                        // 'Sex unknown': false,
                                    },
                                }
                            }}
                            slots={{
                                toolbar: CustomToolbar,
                            }}
                            disableDensitySelector={true}

                            pageSizeOptions={[10, 20, 50]}
                        />
                    </Box>
                </Container>
            </div>
        </div>
    );
}

