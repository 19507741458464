import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import DeviceDetails from "./DeviceDetails";
import NoShow from "./NoShow";
import {
  fetchWifiNetworks,
  cancelWifiAction,
  finishWifiSetup,
  fetchDeviceMacAddress,
  finishMacFetch,
} from "../../../../../actions";
import { uuidv4 } from "../../../../../utils/uuid";
import _ from "lodash";

const Index = ({
  selectedDevice,
  device,
  fetchWifiNetworks,
  cancelWifiAction,
  finishWifiSetup,
  wifiStatus,
  fetchDeviceMacAddress,
  wifiName,
  fetchingMacAddressStatus,
  finishMacFetch,
}) => {
  const {
    id = null,
    displayName = null,
    firmwareVersion = null,
    algVersion = null,
    macAddress = "",
    stateMachine,
  } = device;
  const [curWifiName, setCurWifiName] = useState(null);
  const [wifiInit, setWifiInit] = useState(false);

  useEffect(() => {
    if (!wifiInit && wifiStatus === "ready") {
      setWifiInit(true);
      setCurWifiName(wifiName);
    }
  }, [wifiName, wifiInit, wifiStatus]);

  const [currentJob, setCurrentJob] = useState(null);

  const startFetchWifiJob = useCallback(() => {
    if (currentJob) {
      return;
    }
    const uuid = uuidv4();
    setCurrentJob(uuid);
    fetchWifiNetworks(selectedDevice, uuid);
  }, [fetchWifiNetworks, selectedDevice, currentJob]);

  const cancelRunningWifiJob = useCallback(() => {
    if (currentJob) {
      cancelWifiAction(currentJob);
      setCurrentJob(null);
    }
  }, [cancelWifiAction, currentJob]);

  useEffect(() => {
    if (selectedDevice && stateMachine === "IDLE") {
      startFetchWifiJob();
    }
    return () => {
      cancelRunningWifiJob();
      finishWifiSetup();
      finishMacFetch();
      //clean all wifi and mac address
      setWifiInit(false);
      setCurWifiName(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedDevice]);

  //if the Wifi status is ready then fetch the mac address
  useEffect(() => {
    if (wifiStatus === "ready" && wifiName) {
      //check the mac address
      if (macAddress === "") {
        const uuid = uuidv4();
        setCurrentJob(uuid);
        fetchDeviceMacAddress(selectedDevice, uuid);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wifiStatus]);

  //clean fetch mac address jobs when it finish
  useEffect(() => {
    if (fetchingMacAddressStatus === "ready") {
      setCurrentJob(null);
    }
  }, [fetchingMacAddressStatus]);

  return (
    <>
      {selectedDevice ? (
        <DeviceDetails
          id={id}
          displayName={displayName}
          firmwareVersion={firmwareVersion}
          algVersion={algVersion}
          wifiName={stateMachine !== "OFF" ? curWifiName : "None"}
          wifiStatus={wifiStatus}
          macAddress={macAddress}
        />
      ) : (
        <NoShow />
      )}
    </>
  );
};

export const mapStateToProps = (state) => {
  const selectedDevice = state.devices.selectedDevice;
  if (!state.devices?.[selectedDevice]) {
    return {}
  }
  const device = state.devices[selectedDevice] || {};
  const wifiStatus = state.wifi.status;
  const wifi = _.find(state.wifi.networks || {}, (x) => x?.connected === true);
  const wifiName = wifi?.name || "Not find";
  const fetchingMacAddressStatus = state.devices.fetchingMacAddressStatus;

  return {
    currentWifi: state.wifi.currentConnectedWifi,
    selectedDevice: state.devices.selectedDevice,
    device,
    wifiStatus,
    wifiName,
    fetchingMacAddressStatus,
  };
};

export default connect(mapStateToProps, {
  fetchWifiNetworks,
  cancelWifiAction,
  finishWifiSetup,
  fetchDeviceMacAddress,
  finishMacFetch,
})(Index);
