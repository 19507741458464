import { FETCH_ALG_VECTORTYPES } from "./types";

export const fetchAlgVectorTypes = (sendRequest) => async (dispatch) => {
  let response;
  try {
    response = await sendRequest("get")({
      url: "/algVersions/vectorTypes",
      devPort: 5005,
    });
  } catch (err) {
    if (
      err?.response.status === 500 &&
      err?.response?.data?.message === "VectorTypes could not be found"
    ) {
      response = { data: {} };
    }
  }
  dispatch({ type: FETCH_ALG_VECTORTYPES, payload: response.data });
};
