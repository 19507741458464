import React from "react";
import { connect } from "react-redux";
import { reduxForm } from "redux-form";
import TrapSiteForm from "./TrapSiteForm";
import validateForm from "./TrapSiteValidation";
import { useWebServer } from "../../../../../providers";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../AppRouter/routes";
import { createTrapSite } from "../../../../../actions";

const TrapSiteCreate = ({ createTrapSite, ...otherProps }) => {
  const { sendRequest } = useWebServer();
  const history = useHistory();

  const onSave = async (trapSite) => {
    await createTrapSite(trapSite, sendRequest);
    history.push(ROUTES.trapSite);
  };

  return <TrapSiteForm {...otherProps} onSave={onSave} />;
};

const formWrapped = reduxForm({
  form: "TrapSiteCreate",
  validate: validateForm,
})(TrapSiteCreate);

export default connect(null, { createTrapSite })(formWrapped);
