import React from "react";
import Fab from "@mui/material/Fab";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import BluetoothIcon from "@mui/icons-material/Bluetooth";
import DeviceSetup from "../DeviceSetup";

const BluetoothSetup = ({
  bluetoothState,
  handleScanButtonPressed,
  ...otherParams
}) => {
  const getDisplayed = () => {
    if (bluetoothState === "connected" || bluetoothState === "connecting") {
      return <DeviceSetup setupState={bluetoothState} {...otherParams} />;
    } else if (bluetoothState === "disconnected") {
      // scan flow
      return (
        <Paper
          className={"absolute inset-0 flex items-center justify-center"}
          id={"bluetooth-scan"}
        >
          <Fab
            id={"bluetooth-scan-button"}
            variant={"extended"}
            color={"secondary"}
            onClick={handleScanButtonPressed}
          >
            <BluetoothIcon className={"mr-1"} />
            Scan
          </Fab>
        </Paper>
      );
    } else {
      // loading flow
      return (
        <Paper
          className={"absolute inset-0 flex items-center justify-center"}
          id={"loading-circle"}
        >
          <CircularProgress className={"mr-1"} size={"20rem"} />
        </Paper>
      );
    }
  };

  return <>{getDisplayed()}</>;
};

export default BluetoothSetup;
