import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";

const buttonStyles = {
  cancelButton: {
    bg: 'status.danger',
    color: 'white.main'
  },
  infoButton: {
    color: 'status.info',
  },
}

const StyledRadio = (props) => {
  return <Radio color="primary"
    {...props}
    sx={{
      root: {
        color: "primary",
        "&$checked": {
          color: "primary",
        },
      },
      checked: {},
    }} />
}

const OptionsDialog = (props) => {
  const {
    title,
    children,
    open,
    setClosed,
    id,
    defaultValue,
    onCancel = () => null,
    options = {}, // [key: string]: {
    //   message: () => void;
    //   handler: () => void;
    // };
    confirmMessage = "Continue",
    cancelMessage = "Cancel",
    skipOptions = [],
    kwargs = {},
  } = props;

  const [value, setValue] = useState();

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const handleClose = () => {
    setClosed();
    onCancel();
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const handleConfirm = () => {
    setClosed();
    const { message, handler } = options?.[value];
    if (message && handler) {
      handler(kwargs);
    }
  };

  return (
    <Dialog
      id={id}
      open={open}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{title}</DialogTitle>
      <DialogContent>
        <>
          <div style={{ marginBottom: "1rem" }}>{children}</div>
          <FormControl component="fieldset">
            <FormLabel component="legend">What would you like to do?</FormLabel>
            <RadioGroup
              aria-label="duplicate-well-options"
              name="duplicate-well"
              value={value}
              onChange={handleChange}
            >
              {Object.entries(options).map(([key, { message, handler }]) =>
                key && message && handler && !skipOptions.includes(key) ? (
                  <FormControlLabel
                    value={key}
                    control={<StyledRadio />}
                    label={message}
                    key={key}
                  />
                ) : null
              )}
            </RadioGroup>
          </FormControl>
        </>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          id={"dialog-cancel"}
          sx={buttonStyles.cancelButton}
        >
          {cancelMessage}
        </Button>
        <Button
          variant="outlined"
          onClick={handleConfirm}
          id={"dialog-fill"}
          sx={buttonStyles.infoButton}
        >
          {confirmMessage}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OptionsDialog;
