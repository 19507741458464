import Box from "@mui/material/Box";
import React from "react";

const TabContent = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      className={"h-full"}
    >
      <Box
        className={"flex flex-1 flex-col h-full flex-no-wrap overflow-auto"}
        sx={{
          padding: ["1rem", "1rem", "1rem", "1rem", "1.75rem", "1.75rem"],
        }}
      >
        {children}
      </Box>
    </div>
  );
};

export default TabContent;
