import React, { createContext, useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const HistoryContext = createContext();

const MAX_HISTORY_LENGTH = 50;

export const HistoryProvider = ({ children }) => {
    const location = useLocation();
    const [historyStack, setHistoryStack] = useState([]);

    // Load the history from localStorage when the component mounts
    useEffect(() => {
        const storedHistory = JSON.parse(localStorage.getItem('historyStack')) || [];
        setHistoryStack(storedHistory);
    }, []);

    // Update the history stack when the location changes
    useEffect(() => {
        setHistoryStack((prevHistoryStack) => {
            if (prevHistoryStack[0] !== location.pathname) {
                const updatedStack = [location.pathname, ...prevHistoryStack,];
                // Limit the stack to the defined maximum length
                if (updatedStack.length > MAX_HISTORY_LENGTH) {
                    updatedStack.pop(); // Remove the oldest entry to maintain the stack size
                }
                // Persist the updated history stack in localStorage
                localStorage.setItem('historyStack', JSON.stringify(updatedStack));
                return updatedStack;
            }
            return prevHistoryStack;
        });
    }, [location]);

    return (
        <HistoryContext.Provider value={historyStack}>
            {children}
        </HistoryContext.Provider>
    );
};

export const useHistoryStack = () => {
    return useContext(HistoryContext);
};