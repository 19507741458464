import validate from "validate.js";

const formPresenceMessage = (attribute) => `^${attribute} cant be empty.`;
const constraints = {
  displayName: {
    presence: {
      message: formPresenceMessage("Name"),
      allowEmpty: false,
    },
  },
  gpsLocation: {
    format: {
      pattern: "([-]*[0-9]+.[0-9]+ [-]*[0-9]+.[0-9]+)*",
      message: "Format should like 1.222 -1.222",
      allowEmpty: true,
    },
  },
};

const validateForm = ({ displayName, gpsLocation }) => {
  const errors = validate(
    {
      displayName,
      gpsLocation,
    },
    constraints
  );
  return errors;
};

export default validateForm;
