import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useWebServer } from "./../../../../../../providers/WebServerProvider";
import { useHistory } from "react-router-dom";
import ROUTES from "../../../../../AppRouter/routes";
import TrapSiteForm from "./TrapSiteForm";
import { getDistanceMeters, getDistanceMiles } from "../../../../../../utils/geography";
import { connect } from "react-redux";
import ConfirmDialog from "../../../../../ConfirmDialog";
import { setTrapSitePlacementMode } from "../../../../../../actions/map";

const DISTANCE_TOLERANCE = 100;

const Index = (props) => {
  const {
    onSave,
    trapSites,
    addressSearchRadius,
    trapSitePlacementMode,
    setTrapSitePlacementMode,
    values,
    userMapCenter,
    userFetched,
    ...otherProps
  } = props;
  const { dirty, initialValues } = otherProps;
  const [mapCenter, setMapCenter] = useState([0, 0]);
  const [loadingAddress, setLoadingAddress] = useState();
  const [locationOptions, setLocationOptions] = useState([]);
  const { getGPSLocationFromAddress } = useWebServer();
  const [sameLocationProtectionDialog, setSameLocationProtectionDialog] =
    useState(false);
  const [curFormValues, setCurFormValues] = useState({});

  const history = useHistory();
  const [init, setInit] = useState(false)

  useEffect(() => {
    if (!init && userFetched) {
      setInit(true)
      setMapCenter(userMapCenter)
    }
  }, [userMapCenter, userFetched, init, setMapCenter]);

  const onDiscardChanges = () => {
    history.push(ROUTES.trapSite);
  };

  const searchAddress = async (address) => {
    if (!address) return;
    setLoadingAddress(true);
    // get lat,lon,address, dist of results sorted by distance to mapCenter
    const gpsResp = await getGPSLocationFromAddress(address);
    const locations = _.sortBy(
      (gpsResp?.data || []).map(({ lat, lon, display_name }) => ({
        lat,
        lon,
        display_name,
        dist: getDistanceMiles(lat, lon, ...mapCenter),
      })),
      "dist"
    );
    // try filtering out addresses > 100 miles away
    const closeLocations = locations.filter(
      (loc) => loc?.dist < (addressSearchRadius ? addressSearchRadius : DISTANCE_TOLERANCE)
    );
    let currentLocations = locationOptions;
    if (closeLocations.length > 0) {
      setLocationOptions(closeLocations);
      currentLocations = closeLocations;
    } else if (locations.length > 0) {
      setLocationOptions(locations);
      currentLocations = locations;
    }
    setLoadingAddress(false);
    if (currentLocations.length > 0) {
      const { lat, lon } = currentLocations[0];
      if (lat && lon) {
        return `${lat} ${lon}`;
      }
    }
    return "";
  };

  const onSubmit = (formValues) => {
    let isAddressChange =
      (initialValues?.address !== formValues?.address || initialValues?.gpsLocation !== formValues?.gpsLocation)
    if (dirty) {
      if (sameLocationProtectionDialog === true) {
        return onSave(curFormValues);
      }

      const tmpTrapSites = { ...trapSites };
      delete tmpTrapSites.count;

      const siteLocation = formValues?.gpsLocation?.split(" ");

      //if the location is undefined it just save without compare the distance with other location
      if (!siteLocation || !isAddressChange) {
        return onSave(formValues);
      }

      /*eslint array-callback-return: ["error", { allowImplicit: true }]*/
      const sortedSites = _.chain(tmpTrapSites)
        .map((value, key) => {
          const { latitude, longitude } = value?.gpsLocation || {};
          if (key !== "count") {
            return {
              dist: getDistanceMeters(latitude, longitude, ...siteLocation),
            };
          }
          return;
        })
        .filter((value, key) => value.dist < 10) // display pop-up warning if another site is < 10 meters away
        .value();
      if (sortedSites.length > 0) {
        setSameLocationProtectionDialog(true);
        setCurFormValues(formValues);
      } else {
        return onSave(formValues);
      }
    } else {
      history.push(ROUTES.trapSite);
    }
  };

  return (
    <div>
      <ConfirmDialog
        title={`Duplicate location`}
        children={`Are you sure you want to add a trap site? You already have a trap site at this location.`}
        open={sameLocationProtectionDialog}
        setOpen={setSameLocationProtectionDialog}
        onConfirm={onSubmit}
      />
      <TrapSiteForm
        {...otherProps}
        onSubmit={onSubmit}
        onDiscardChanges={onDiscardChanges}
        searchAddress={searchAddress}
        loadingAddress={loadingAddress}
        addressOptions={_.map(locationOptions, "display_name")}
        trapSitePlacementMode={trapSitePlacementMode}
        setTrapSitePlacementMode={setTrapSitePlacementMode}
      />
    </div>
  );
};

export const mapStateToProps = (state) => {
  return {
    trapSites: state.trapSites,
    trapSitePlacementMode: state.map.placementMode,
    addressSearchRadius: state.user.addressSearchRadius,
    userMapCenter: state.user.mapCenter?.split(',').map(parseFloat),
    userFetched: state.user.fetched,
  };
};

export default connect(mapStateToProps, { setTrapSitePlacementMode })(Index);
