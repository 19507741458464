import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Router, Switch } from "react-router-dom";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { PrivateRoute } from "./PrivateRoute";
import history from "../../providers/history";
import { useAuth0 } from "../../providers/react-auth0-spa";
import Home from "../Home";
import { useWebServer } from "../../providers";
import IDToolMUIThemeProvider from "../../providers/mui-theme-provider";
import { HistoryProvider } from "../../providers/HistoryProvider";
import {
  sendAuthToken,
  reconnectWithAuthToken,
  fetchUserAutofillSettings,
  fetchUserSettings
} from "./../../actions";
import PendoProvider from "../../providers/PendoProvider";


const AppRouter = ({
  sendAuthToken,
  reconnectWithAuthToken,
  fetchUserAutofillSettings,
  fetchUserSettings,
  socketStatus,
  trayId,
  disableCapturing,
  inCaptureState,
  children,
  inMemHistory,
}) => {
  // eslint-disable-next-line no-unused-vars
  const { getToken, sendRequest } = useWebServer();
  const { user } = useAuth0();
  const [status, setStatus] = useState(socketStatus);
  const [reconnectionMessage, setReconnectionMessage] = useState(null);
  const testEnv = process.env.REACT_APP_TEST_STATUS !== "test";

  useEffect(() => {
    const {
      autofillSpecies: species = false,
      autofillSex: sex = false,
      autofillMolecular: molecular = false,
      map_center: mapCenter = "39.294201, -76.588686",
      organization = 'organization',
      time_zone: timeZone = 'AUTO'
    } = user?.["https://mos-id.com/user_metadata"] || {};

    const {
      email = '',
      name = ''
    } = user || {}

    // testEnv &&
    //   fetchUserAutofillSettings(sendRequest, { species, sex, molecular });

    const autofillSettings = {
      species, sex, molecular
    }
    testEnv &&
      fetchUserSettings(sendRequest, { email, organization, autofillSettings, name, mapCenter, timeZone });

  }, [user, fetchUserAutofillSettings, sendRequest, testEnv, fetchUserSettings]);

  useEffect(() => {
    if (socketStatus !== status) {
      setStatus(socketStatus);
    }
  }, [socketStatus, status]);

  useEffect(() => {
    const authenticateWithToken = async () => {
      //let token;
      if (status === "authenticating" || status === "reauthenticating") {
        //token = await getToken();
      }
      switch (status) {
        case "authenticating":
          testEnv && console.log("Websocket opening");
          // await sendAuthToken(token);
          break;
        case "reauthenticating":
          testEnv && console.log("Websocket reconnecting");
          setReconnectionMessage("Found Connection...");
          // await reconnectWithAuthToken(token, trayId, disableCapturing);
          break;
        case "closed":
          testEnv && console.log("Websocket lost connection");
          setReconnectionMessage("Reconnecting...");
          break;
        case "open":
          testEnv && console.log("Websocket connected");
          setReconnectionMessage(null);
          break;
        default:
          break;
      }
    };
    authenticateWithToken();
  }, [status, testEnv]);

  return (
    <IDToolMUIThemeProvider>
      <PendoProvider />
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Router history={inMemHistory ? inMemHistory : history}>
          <Switch>
            {testEnv ? (
              <PrivateRoute path={"*"} exact>
                <HistoryProvider >
                  <Home
                    reconnectionMessage={
                      inCaptureState ? reconnectionMessage : null
                    }
                  />
                </HistoryProvider>
              </PrivateRoute>
            ) : (
              children
            )}
          </Switch>
        </Router>
      </LocalizationProvider>
    </IDToolMUIThemeProvider >
  );
};

export const mapStateToProps = (state) => {
  const { status } = state.websocket;
  const { tray, trayValid, disableCapturing } = state.capture;
  const trayId = trayValid ? tray?.id : null;
  const inCaptureState = Object.keys(state.capture).length > 0;
  return {
    socketStatus: status,
    trayId,
    disableCapturing,
    inCaptureState,
  };
};

export default connect(mapStateToProps, {
  sendAuthToken,
  reconnectWithAuthToken,
  fetchUserAutofillSettings,
  fetchUserSettings
})(AppRouter);
