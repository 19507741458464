import SocketIOClient from "./SocketIoClient";
import { CREATE_NOTIFICATION, DELETE_WIFI_RESPONSE } from "../../actions/types";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default ([socketUrl, getToken]) =>
  (store) => {
    const client = new SocketIOClient(store, socketUrl, getToken);
    return (next) => (action) => {
      if (action.socket && action.socket.send) {
        if (!action.socket.keepSocket) {
          let { socket, ...action2 } = action;
          action = action2;
        }
        client.send(action);
      }
      if (action.type === DELETE_WIFI_RESPONSE) {
        store.dispatch({ type: CREATE_NOTIFICATION, payload: { message: "Wifi deleted successfully", type: "success" } })
      }
      return next(action);
    };
  };
