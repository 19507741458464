import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import useImage from "use-image";
import Canvas from "./canvas";

// TODO: had to add CORS policy to s3 bucket to allow for download using useImage
const Index = ({ src, zoom, draw, shouldProcess = true, specimenName, width, height, canImageProcess, rotate, ...other }) => {
  const [img, imgStatus] = useImage(src, "anonymous");

  if (src && imgStatus === "failed") {
    return (
      <div>
        <ErrorOutline style={{ fontSize: "5rem", color: "red" }} />
      </div>
    );
  }
  if (imgStatus === "loading" || !src) {
    return (
      <div>
        <CircularProgress size={"5rem"} />
      </div>
    );
  }
  return (
    <Canvas
      img={img}
      draw={draw || ((im) => im)}
      zoom={zoom || 1}
      shouldProcess={shouldProcess}
      specimenName={specimenName}
      width={width}
      height={height}
      rotate={rotate}
      canImageProcess={canImageProcess}
      {...other}
    />
  );
};

export default Index;
