import {
    SET_MAP_CENTER,
    SET_TRAP_SITE_PLACEMENT_MODE,
    SET_MAP_CENTER_PLACEMENT_MODE
} from './types'

export const setMapCenter = (mapLocation) => async (dispatch) => {
    dispatch({ type: SET_MAP_CENTER, payload: mapLocation });
};

export const setTrapSitePlacementMode = (mode) => async (dispatch) => {
    dispatch({ type: SET_TRAP_SITE_PLACEMENT_MODE, payload: mode });
};

export const setMapCenterPlacementMode = (mode) => async (dispatch) => {
    dispatch({ type: SET_MAP_CENTER_PLACEMENT_MODE, payload: mode });
};