import React from "react";
import { Box } from "@mui/material";

const ModalContainer = ({ children, onClickOutsideModal, open = true, height, width }) => {
  const style = {
    backgroundColor: "rgb(0, 0, 0, 0.33)",
  };

  const defaultHeight = ["50vh", "580px", "580px", "580px", '90vh', '90vh']
  const defaultWidth = ["100vw", "600px", "600px", "600px", "824px", '824px']

  return (
    open ? <Box
      className={"transition-opacity duration-1000 z-30 flex absolute m-auto  w-full h-full"

      }
      sx={{
        position: ['unset !important', 'absolute !important', 'absolute !important', 'absolute !important', 'absolute !important'],
      }}
      // className={
      //   "flex absolute m-auto  w-full h-full"
      // }
      id={"tray-modal"}
      style={{ ...style, }}
      onClick={onClickOutsideModal}
    >
      <Box
        className={"absolute inset-0 m-auto z-30 rounded-3xl"}
        style={height ? { height, width } : {}}
        sx={height ? {} : {
          height: defaultHeight,
          width: defaultWidth,
          backgroundColor: "rgb(0, 0, 0, 0.25)",
          filter: "blur(50px)",
          top: ["15% !important", '0 !important', '0 !important', '0 !important', '0 !important', '0 !important'],
        }}
      >
      </Box>
      <Box
        className={
          "absolute m-auto z-30 filter blur-2xl inset-0"
        }
        style={height ? { height, width } : {}}
        sx={height ? {} : {
          height: defaultHeight,
          width: defaultWidth,
          top: ["15% !important", '0 !important', '0 !important', '0 !important', '0 !important', '0 !important'],
        }}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        {children}
      </Box>
    </Box > : <></>
  );
};

export default ModalContainer;
