import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const fontSx = {
    fontSize: ["0.75rem", "0.75rem", "0.75rem", "0.75rem", "1rem", "1rem"],
};

const ChangeHardwareDialog = ({
    actionName,
    device,
    active,
    hardwareId,
    setActive,
    onConfirm,
    cancelMessage = "Cancel",
}) => {
    const [moveVal, setMoveVal] = useState("");

    useEffect(() => {
        setMoveVal(hardwareId)
    }, [device, hardwareId])

    const handleClose = () => {
        setMoveVal("");
        setActive(null);
    };

    return !!active ? (
        <Dialog
            open={!!active}
            onClose={handleClose}
            aria-labelledby="confirm-dialog"
        >
            <DialogTitle id="confirm-dialog">{`Change ${device?.displayName} (${device?.uuid})`}</DialogTitle>
            <DialogContent>
                <Box
                    component="form"
                    sx={{
                        "& .MuiTextField-root": { m: 1, width: "25ch" },
                    }}
                    noValidate
                    autoComplete="off"
                >
                    <div>
                        <Typography sx={fontSx} className={"text-gray-600"}>
                            What hardware id should this device use?
                        </Typography>
                        <TextField
                            variant="outlined"
                            id="outlined-hardware-id"
                            label="New Hardware Id"
                            value={moveVal}
                            onChange={(event) => {
                                setMoveVal(event.target.value);
                            }}
                        >
                        </TextField>
                    </div>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="contained"
                    onClick={handleClose}
                    color="secondary"
                    id={"dialog-cancel"}
                >
                    {cancelMessage}
                </Button>
                <Button
                    variant="contained"
                    onClick={() => {
                        handleClose();
                        onConfirm(moveVal);
                    }}
                    id={"dialog-confirm"}
                >
                    {actionName}
                </Button>
            </DialogActions>
        </Dialog>
    ) : null;
};

export default ChangeHardwareDialog;
