import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Tooltip,
  Box,
  useMediaQuery,
  Typography,
  Button,
} from "@mui/material";
import _ from "lodash";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Draggable from "react-draggable";
import SwapVertIcon from '@mui/icons-material/SwapVert';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import ClearIcon from '@mui/icons-material/Clear';
const INFOKEYWORDS = 'empty|cell|algorithm unsure|N/A'
const re = new RegExp(INFOKEYWORDS, 'i')
const IconFontSize = [
  "0.6rem",
  "0.6rem",
  "0.6rem",
  "0.6rem",
  "0.875rem",
  "0.875rem",
]

const DataSummaryGraphTableLegend = ({
  dataInner,
  dataOuter,
  setPointerEvent,
  toggleVisible,
  toggleVisibleAll,
  clearVisible }) => {
  const [expand, setExpand] = useState(false);
  const [displayNumber, setDisplayNumber] = useState(8);
  const [topDisplayNumber, setTopDisplayNumber] = useState([]);
  const [restTotal, setRestTotal] = useState(0);
  const [total, setTotal] = useState(0);
  const [restDisplayData, setRestDisplayData] = useState([]);
  const [pos, setPos] = useState([50, 50]);
  const lgMatches = useMediaQuery((theme) => theme.breakpoints.up("lg"));
  const hdMatches = useMediaQuery((theme) => theme.breakpoints.up("hd"));
  const xlMatches = useMediaQuery((theme) => theme.breakpoints.up("xl"));
  const [dataSwitch, setDataSwitch] = useState(true);
  const [data, setData] = useState([]);
  const [selectExpand, setSelectExpand] = useState(false)

  useEffect(
    () => {
      setData((dataSwitch ? dataInner : dataOuter))
    }, [dataSwitch, dataInner, dataOuter]
  )

  useEffect(() => {
    if (xlMatches) {
      setPos([250, 200]);
      setDisplayNumber(8);
    } else if (lgMatches) {
      setPos([150, 150]);
      setDisplayNumber(8);
    } else {
      setPos([100, 130]);
      setDisplayNumber(5);
    }
  }, [lgMatches, hdMatches, xlMatches]);

  useEffect(() => {
    if (data) {
      let sortedData = [...data].sort(
        (a, b) => parseFloat(b.value) - parseFloat(a.value)
      );
      setTopDisplayNumber(sortedData.slice(0, displayNumber));
      setTotal(sortedData.reduce((sum, cur) => cur.visible ? sum + parseFloat(cur.value) : sum, 0));

      if (data.length > displayNumber) {
        let tmpRestDisplayData = sortedData.slice(displayNumber, data.length);
        setRestTotal(
          tmpRestDisplayData.reduce(
            (sum, cur) => cur.visible ? sum + parseFloat(cur.value) : sum,
            0
          )
        );
        setRestDisplayData(tmpRestDisplayData);
      } else {
        setRestDisplayData([]);
        setRestTotal(0);
      }
      setSelectExpand(data.some((d) => d.visible === false))

    }
  }, [data, displayNumber]);

  const computeDisplayText = (entry) => {
    let res = ''
    if (entry.genus) {
      if (entry.genus.toLowerCase() === entry.name.toLowerCase()) {
        res = entry.genus
      } else {
        res = entry.genus + ' ' + entry.name
      }
    } else {
      res = entry.name
    }

    if (re.test(res)) {
      res = res + ' *'
    }
    return _.capitalize(res)
  }

  const italic = (str, isSpecies) => {
    return isSpecies ? <i>{str}</i> : str;
  }

  // console.log("Data Inner", dataInner);
  // console.log("Data outer", dataOuter);

  return (
    <Draggable
      onStart={() => setPointerEvent(false)}
      onStop={() => setPointerEvent(true)}
      handle='.handle'
    >
      <div
        style={{
          position: "absolute",
          zIndex: 150,
          cursor: 'pointer'
        }}
        sx={{
          width: ["150px", "150px", "160px", "200px", "220px", "220px"],
          top: `${pos[0]}px`,
          left: `${pos[1]}px`,
          bgcolor: "#fff",
          borderRadius: "0.75rem",
          border: "solid 1px rgb(33, 150, 243)",
        }}
      >
        <Box
          className="handle"
          style={{
            cursor: 'move'
          }}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            px: "1rem",
            py: ".5rem",
            borderBottom: expand ? "1px solid black" : "",
          }}
        >
          <Typography>Legend</Typography>
          {expand ? (
            <RemoveIcon
              onClick={() => setExpand(!expand)}
              style={{
                cursor: "pointer",
              }}
            />
          ) : (
            <AddIcon
              onClick={() => setExpand(!expand)}
              style={{
                cursor: "pointer",
              }}
            />
          )}
        </Box>
        {expand && (
          <Box>
            {selectExpand && (
              <Box sx={{ display: 'flex', justifyContent: 'space-between', py: '0.5rem', borderBottom: 1 }}>
                <Button sx={{
                  color: "status.info",
                  fontSize: IconFontSize,
                  padding: 0,
                }}
                  onClick={toggleVisibleAll}
                ><SwapHorizIcon /></Button>
                <Button sx={{
                  color: "status.info",
                  fontSize: IconFontSize,
                  padding: 0,
                }}
                  onClick={clearVisible}
                ><ClearIcon /></Button>
              </Box>)}
            <TableContainer
              sx={{
                ".MuiTableCell-body": {
                  py: ".5rem",
                  px: ".5rem",
                },
                ".MuiTableCell-root": {
                  fontSize: [
                    "0.75rem",
                    "0.75rem",
                    "0.66rem",
                    "0.75rem",
                    "0.75rem",
                    "0.75rem",
                  ],
                },
              }}
            >
              <Table>
                <TableHead>
                  <TableRow
                    sx={{
                      ".MuiTableCell-root": {
                        m: [".5rem", ".5rem", ".5rem", ".75rem", "1rem", "1rem"],
                        p: [".5rem", ".5rem", ".5rem", ".75rem", "1rem", "1rem"],
                      }
                    }}
                  >
                    <TableCell
                      sx={{
                        ".MuiTableCell-root": {
                          m: [".5rem", ".5rem", ".5rem", ".75rem", "1rem", "1rem"],
                          p: [".5rem", ".5rem", ".5rem", ".75rem", "1rem", "1rem"],
                        }
                      }}
                    >

                      <Tooltip
                        title={
                          <Typography variant="subtitle1">
                            {"Swap between genus and species\n"}
                          </Typography>
                        }
                        placement="bottom"
                      >
                        <div>
                          {dataSwitch ? 'Genus' : 'Species'}
                          <SwapVertIcon
                            onClick={() => setDataSwitch(!dataSwitch)}
                          />
                        </div>
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center"
                      sx={{
                        width: '65px'
                      }}
                    >Count</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {topDisplayNumber.map((entry, index) => (
                    <TableRow key={index}
                      sx={entry.visible ? { opacity: 1 } : { opacity: 0.5 }}
                      onClick={() => toggleVisible(entry, dataSwitch)}
                    >
                      <TableCell>{italic(computeDisplayText(entry), !dataSwitch)}</TableCell>
                      <TableCell align="right">{`${entry.value}`}</TableCell>
                    </TableRow>
                  ))}
                  {restTotal !== 0 && (
                    <Tooltip
                      componentsProps={{
                        tooltip: {
                          sx: {
                            m: "0 !important",
                            p: 0,
                            background: "white",
                          },
                        },
                      }}
                      title={
                        <Box
                          sx={{
                            m: 0,
                            p: "10px",
                            background: "white",
                            borderRadius: ".75rem",
                            border: "solid 1px rgb(33, 150, 243)",
                            ".MuiTableCell-root": {
                              fontSize: [
                                "0.75rem",
                                "0.75rem",
                                "0.66rem",
                                "0.75rem",
                                "0.75rem",
                                "0.75rem",
                              ],
                            },
                          }}
                        >
                          <TableContainer sx={{ maxHeight: 250 }}>
                            <Table>
                              <TableBody>
                                {restDisplayData.map((entry, index) => (
                                  <TableRow
                                    key={index}
                                    sx={entry.visible ? { opacity: 1 } : { opacity: 0.5 }}
                                    onClick={() => toggleVisible(entry, dataSwitch)}
                                  >
                                    <TableCell>{italic(computeDisplayText(entry), !dataSwitch)}</TableCell>
                                    <TableCell align="right">{`${entry.value}`}</TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Box>
                      }
                    >
                      <TableRow key={"rest-total"}>
                        <TableCell>{`more...`}</TableCell>
                        <TableCell align="right">{`${restTotal}`}</TableCell>
                      </TableRow>
                    </Tooltip>
                  )}
                  <TableRow
                    key={"total"}
                    sx={{
                      ".MuiTableCell-root": {
                        borderBottom: "0px",
                      },
                    }}
                  >
                    <TableCell>{`Total`}</TableCell>
                    <TableCell align="right">{`${total}`}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}
      </div>
    </Draggable >
  );
};

export default DataSummaryGraphTableLegend;
