import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { useWebServer } from "./../../../providers/WebServerProvider";
import TrapsMap from "./TrapsMap";
import ROUTES from "../../AppRouter/routes";
import { fetchTrapSites } from "./../../../actions";
import { change } from "redux-form";
import { useLocation } from "react-router-dom";
import { setMapCenter } from "../../../actions/map";
import { setTrapSitePlacementMode } from "../../../actions/map";
import { displayPermanentWarningNotification } from "../../../actions/notifications";

const Index = ({
  displayPermanentWarningNotification,
  fetchTrapSites,
  trapSites,
  change,
  mapCenter,
  setMapCenter,
  trapSitePlacementMode,
  setTrapSitePlacementMode,
  userMapCenter,
  userFetched,
  arcgisKey,
}) => {
  const { sendRequest, getAddressFromGPSLocation } = useWebServer();
  let history = useHistory();
  const location = useLocation();
  const [onTrapSiteMap, setOnTrapSiteMap] = useState(false);
  const clickableMap =
    location.pathname.startsWith("/details/collection-site/edit") ||
    location.pathname.startsWith("/details/collection-site/create");
  const [currentFormName, setCurrentFormName] = useState("");
  const detectMouseEvent = () => {
    if (!onTrapSiteMap && trapSitePlacementMode) {
      setTrapSitePlacementMode(false);
    }
  };
  const [init, setInit] = useState(false);
  //////////////////////// Enable to send maintenance alert ///////////////////////////////
  // const [hasSentServiceToast, setHasSentServiceToast] = useState();

  // useEffect(() => {
  //   if (displayPermanentWarningNotification && !hasSentServiceToast) {
  //     setHasSentServiceToast(true);
  //     displayPermanentWarningNotification(
  //       "The server will be down for maintenance from 8-9pm (EST) on Tuesday Oct 11th. Any changes made during that time may be lost.",
  //       "maintenance-alert"
  //     );
  //   }
  // }, [hasSentServiceToast, displayPermanentWarningNotification]);
  ////////////////////////////////////////////////////////////////////////////////////////
  useEffect(() => {
    if (history.location.pathname.includes("create")) {
      setCurrentFormName("TrapSiteCreate");
    } else {
      setCurrentFormName("TrapSiteEdit");
    }
  }, [history.location.pathname]);

  useEffect(() => {
    window.addEventListener("click", detectMouseEvent);
    return () => {
      window.removeEventListener("click", detectMouseEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onTrapSiteMap]);

  useEffect(() => {
    fetchTrapSites({ page: 0, pageSize: 10000000 }, sendRequest);
  }, [fetchTrapSites, sendRequest]);

  useEffect(() => {
    if (!init && userFetched) {
      setInit(true);
      setMapCenter(userMapCenter);
    }
  }, [userMapCenter, userFetched, init, setMapCenter]);

  const handleClickTrapSite = (id) => {
    history.push(ROUTES.editTrapSite.replace(":id", id));
  };

  const handleClickOnMapSetAddressAndGPSLocation = async (
    lat,
    lng,
    zoom,
    setMarkerInfo
  ) => {
    if (clickableMap) {
      let result = await getAddressFromGPSLocation(lat, lng, zoom);
      const address = result?.data?.display_name || "unknown address";
      const latResult = lat;
      const lngResult = lng;
      const name = result?.data?.name || "";

      change(currentFormName, "address", address);
      change(currentFormName, "gpsLocation", `${latResult} ${lngResult}`);
      setMarkerInfo({
        name: name,
        address: address.replace(`${name},`, ""),
        gps: [parseFloat(latResult), parseFloat(lngResult)],
      });
    }
    setTrapSitePlacementMode(false);
  };

  return (
    <TrapsMap
      mapCenter={mapCenter}
      minZoom={3}
      maxZoom={18}
      zoom={13}
      worldCopyJump={true}
      trapSites={trapSites}
      arcgisKey={arcgisKey}
      onClickMarker={handleClickTrapSite}
      onClickMap={handleClickOnMapSetAddressAndGPSLocation}
      trapSitePlacementMode={trapSitePlacementMode}
      setTrapSitePlacementMode={setTrapSitePlacementMode}
      setOnTrapSiteMap={setOnTrapSiteMap}
    />
  );
};

export const mapStateToProps = (state) => {
  const { count, ...trapSites } = state.trapSites;
  const mapCenter = state?.map?.mapCenter || [0, 0];
  const trapSitePlacementMode = state.map.placementMode;
  const userMapCenter = state.user.mapCenter?.split(",").map(parseFloat);
  const userFetched = state.user?.fetched || false;
  const arcgisKey = state.user?.arcgisKey || null;

  return {
    trapSites,
    mapCenter,
    trapSitePlacementMode,
    userMapCenter,
    userFetched,
    arcgisKey,
  };
};

export default connect(mapStateToProps, {
  displayPermanentWarningNotification,
  fetchTrapSites,
  change,
  setMapCenter,
  setTrapSitePlacementMode,
})(Index);
