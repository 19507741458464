import {
  FETCH_LICENSE_CONFIRMATION_STATUS,
  UPDATE_LICENSE_CONFIRMATION_STATUS,
} from "./types";

export const getLicenseConfirmationStatus =
  (hasConfirmed, sendRequest) => async (dispatch) => {
    console.log(`get confirmation starts as ${hasConfirmed}`);
    let confirmed = hasConfirmed;
    if (!confirmed) {
      const response = await sendRequest("get")({
        url: `/user/confirm-license`,
        devPort: 5004,
      });
      confirmed = response.data;
    }
    dispatch({ type: FETCH_LICENSE_CONFIRMATION_STATUS, payload: confirmed });
  };

export const confirmLicense = (sendRequest) => async (dispatch) => {
  dispatch({ type: UPDATE_LICENSE_CONFIRMATION_STATUS, payload: "PENDING" });
  const response = await sendRequest("post")({
    url: `/user/confirm-license`,
    devPort: 5004,
  });
  let seriesResponse = JSON.stringify(response)
  dispatch({ type: FETCH_LICENSE_CONFIRMATION_STATUS, payload: JSON.parse(seriesResponse) });
};
