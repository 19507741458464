import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import DataSummaryGraph from "../DataGraph/DataSummaryGraph";
import DataSummaryGraphTableLegend from "../DataGraph/DataSummaryGraphTableLegend";
import { phraseDownloadDataFilter } from "../../../../utils/dataDownload";
import { CircularProgress } from "@mui/material";
import { useWebServer } from "../../../../providers";
import { DEFAULT_ALG_CONFIDENCE_THRESHOLDS } from "../../../../utils/const";
import _ from "lodash";

const ReportsIndex = ({ generalFilters, timeZone }) => {
  const [legendData, setLegendData] = useState({
    dataInner: null,
    dataOuter: null,
  });
  const [graphPointerEvent, setGraphPointerEvent] = useState(true);
  const { getOrganizationSummaryData } = useWebServer();
  const [noMatchData, setNoMatchData] = useState(true);
  const [dataInner, setDataInner] = useState([]);
  const [dataOuter, setDataOuter] = useState([]);
  const [activeIndex, setActiveIndex] = useState(undefined);
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);

  const fetchDataHandler = _.debounce(
    (generalFilters, timeZone) => {
      const filters = phraseDownloadDataFilter(generalFilters, timeZone);
      getOrganizationSummaryData({ filters })
        .then((data) => {
          for (let i of JSON.parse(data)) {
            if (i["count"] === "no match data") {
              setNoMatchData(true);
            } else {
              setNoMatchData(false);
            }
            setLoading(false);
          }
          console.log(data);
          let array_data = _.sortBy(JSON.parse(data), ["genus", "species"]);
          array_data = array_data.map((cur, index) => {
            return {
              ...cur,
              genus: _.capitalize(cur.genus),
              visible: true,
              fullSpecies: _.capitalize(cur.genus) + " " + cur.species,
            };
          });
          computeSelectedData(array_data);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
        });
    },
    500,
    { trailing: true }
  );

  //eslint-disable-next-line
  const debounceFetchData = useCallback(fetchDataHandler, []);
  const [loading, setLoading] = useState(true);
  const legendItemStyle = { cursor: "pointer" };

  useEffect(() => {
    let getDate = async () => {
      setActiveIndex(undefined);
      setLoading(true);
      debounceFetchData(generalFilters, timeZone);
    };
    getDate();
    //eslint-disable-next-line
  }, [generalFilters, timeZone, getOrganizationSummaryData, setLegendData]);

  const toggleVisible = (entry, isInner) => {
    const matchKey = entry.name;
    let filteredData = data.map((cur) => {
      if (
        !isInner &&
        cur.fullSpecies.endsWith(entry.genus + " " + entry.name)
      ) {
        return {
          ...cur,
          visible: !cur.visible,
        };
      } else if (isInner && cur.fullSpecies.startsWith(matchKey)) {
        return {
          ...cur,
          visible: !cur.visible,
        };
      }
      return cur;
    });
    computeSelectedData(filteredData);
  };

  const toggleVisibleAll = () => {
    computeSelectedData(
      data.map((cur) => {
        return {
          ...cur,
          visible: !cur.visible,
        };
      })
    );
  };
  const clearVisible = () => {
    computeSelectedData(
      data.map((cur) => {
        return {
          ...cur,
          visible: true,
        };
      })
    );
  };

  const computeSelectedData = (filteredData) => {
    let data1 = [],
      data2 = [],
      order = 0,
      array = [];
    for (let i of filteredData) {
      data2.push({
        ...i,
        name: i.species,
        value: i.count,
        genus: _.capitalize(i.genus),
      });
      if (data1[i?.genus]) {
        data1[i.genus] = {
          ...i,
          ...data1[i.genus],
          value: data1[i.genus].value + i.count,
        };
      } else {
        data1[i.genus] = {
          ...i,
          name: _.capitalize(i.genus),
          value: i.count,
          order: order,
        };
        order += 1;
      }
    }
    for (var i in data1) {
      array.push(data1[i]);
    }
    setData(filteredData);
    setDataOuter(data2.filter((cur) => cur.visible));
    setDataInner(
      array
        .filter((cur) => cur.visible)
        .sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
    );
    setLegendData({
      dataOuter: data2,
      dataInner: array.sort(
        (a, b) => parseFloat(a.order) - parseFloat(b.order)
      ),
    });
  };

  useEffect(() => console.log(dataInner, dataOuter), [dataInner, dataOuter]);

  return (
    <div>
      {/* <div style={{ fontSize: '20px', marginLeft: '100px' }}>SPECIES SUMMARY</div> */}

      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            flexBasis: "50%",
            justifyContent: "center",
            marginTop: "0px",
            marginLeft: "50px",
            position: "relative",
          }}
        >
          {loading ? (
            <div
              style={{
                textAlign: "center",
                marginTop: "20vh",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                position: "absolute",
                top: "0",
                left: "0",
                zIndex: 1,
              }}
            >
              <CircularProgress size={"2rem"} id={"loading-tray"} />
            </div>
          ) : (
            <DataSummaryGraph
              generalFilters={generalFilters}
              timeZone={timeZone}
              setLegendData={setLegendData}
              pointerEvent={graphPointerEvent}
              dataInner={dataInner}
              dataOuter={dataOuter}
              noMatchData={noMatchData}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              error={error}
            />
          )}
        </div>

        <div
          style={{
            flexBasis: "50%",
            justifyContent: "center",
            marginTop: "100px",
          }}
        >
          {legendData?.dataInner && (
            <DataSummaryGraphTableLegend
              dataInner={legendData.dataInner}
              dataOuter={legendData.dataOuter}
              setPointerEvent={setGraphPointerEvent}
              toggleVisible={toggleVisible}
              toggleVisibleAll={toggleVisibleAll}
              clearVisible={clearVisible}
              style={legendItemStyle}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state) => {
  const { generalFilters } = state.data;
  const { timeZone, algConfidence } = state.user;
  return {
    generalFilters: {
      ...generalFilters,
      algThreshold: algConfidence
        ? algConfidence
        : DEFAULT_ALG_CONFIDENCE_THRESHOLDS.default,
    },
    timeZone,
  };
};

export default connect(mapStateToProps, {})(ReportsIndex);
