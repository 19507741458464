import {
  FETCH_LICENSE_CONFIRMATION_STATUS,
  UPDATE_LICENSE_CONFIRMATION_STATUS,
} from "../actions/types";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = { confirmed: "UNKNOWN" }, action) => {
  switch (action.type) {
    case FETCH_LICENSE_CONFIRMATION_STATUS:
      return { confirmed: action.payload ? "CONFIRMED" : "NOT_CONFIRMED" };
    case UPDATE_LICENSE_CONFIRMATION_STATUS:
      return { confirmed: action.payload };
    default:
      return state;
  }
};
