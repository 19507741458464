import React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import trayIcon from '../../../assets/icons/tray_vector.svg'
import trapSiteIcon from '../../../assets/icons/trapsite_vector.svg'
import reportIcon from '../../../assets/icons/report_vector.svg'
import logoutIcon from '../../../assets/icons/logout.svg'
import settingIcon from '../../../assets/icons/setting.svg'
import { useState } from 'react';
import { useHistory } from "react-router-dom";
import ROUTES from '../../AppRouter/routes';
import { useAuth0 } from '../../../providers'
import ConfirmDialog from '../../ConfirmDialog';
import { connect } from 'react-redux';
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import WifiIcon from "@mui/icons-material/Wifi";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import zIndex from '@mui/material/styles/zIndex';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import RebootButton from './RebootButton';


const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip
        {...props} classes={{ popper: className }}
        title={
            <React.Fragment
            >
                <Typography color="inherit"
                    onClick={props.onClick}
                >
                    {props.title}</Typography>
                {props.title2 &&
                    <Typography color="inherit"
                        onClick={props.onClick}
                        sx={{
                            textAlign: 'center',
                            cursor: 'pointer'
                        }}>
                        {props.title2}
                    </Typography>}
            </React.Fragment>
        }
        sx={{
            ...props.sx,
            paddingTop: '15px',
            paddingBottom: '20px',
            cursor: 'pointer',
            zIndex: 1
        }}
        slotProps={props.slotProps || {
            popper: {
                modifiers: [
                    {
                        name: 'offset',
                        options: {
                            offset: [0, -25],
                        },
                    },
                ],
                sx: {
                    padding: 0,
                    zIndex: 0
                }
            },
        }}
    />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        background: 'transparent',
        color: 'rgb(59, 126, 178)',
        boxShadow: '',
        fontSize: 15,
        margin: '0 !important',
    },
}));


const NavigationBar = ({ superUser, deviceUuid }) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [focus, setFocus] = useState(true);
    const history = useHistory()
    const [value, setValue] = useState(0);
    const { logout } = useAuth0();
    const [showLogoutConfirm, setShowLogoutConfirm] = useState(false);
    const [rebootPopup, setRebootPopup] = useState(false);

    const handleMenu = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleTabChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleLogoutClick = () => {
        setShowLogoutConfirm(true);
    };

    const onConfirm = () => {
        logout();
        handleClose();
    };

    return (
        <>
            <ConfirmDialog
                title={"Logout"}
                children={"Are you sure you want to logout?"}
                open={showLogoutConfirm}
                setOpen={setShowLogoutConfirm}
                onConfirm={onConfirm}
            />
            <Box sx={{
                flexGrow: 1, maxHeight: '10vh', width: '100%',
                display: ['block', 'none', 'none', 'none', 'none', 'none'],
            }}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton
                            size="large"
                            edge="start"
                            color="inherit"
                            aria-label="menu"
                            sx={{ mr: 2 }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                            Photos
                        </Typography>
                        <div>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleMenu}
                                color="inherit"
                            >
                                <AccountCircle />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorEl)}
                                onClose={handleClose}
                            >
                                <MenuItem onClick={handleClose}>Profile</MenuItem>
                                <MenuItem onClick={handleClose}>My account</MenuItem>
                            </Menu>
                        </div>

                    </Toolbar>
                </AppBar>
            </Box>

            <Box
                onMouseEnter={() => {
                    setFocus(true)
                }}
                onMouseLeave={(e) => {
                    e.preventDefault()
                    // setFocus(false)
                }}
            >
                <Box
                    sx={{
                        bgcolor: 'background.paper', display: 'flex', height: '100vh',
                    }}
                >
                    <Tabs
                        orientation="vertical"
                        value={value}
                        onChange={handleTabChange}
                        sx={{
                            borderRight: 1,
                            borderColor: 'divider',
                            '.MuiTabs-flexContainer': {
                                display: 'flex',
                                'height': '100vh',
                            },
                            '.MuiButtonBase-root': {
                                minWidth: '90px',
                                paddingX: ['8px', '8px', '8px', '12px', '16px', '16px'],
                            }
                        }}
                    >
                        <LightTooltip
                            title="Trays"
                            onClick={() => {
                                history.push(ROUTES.traysTab)
                            }}
                            size="md" open={focus}
                        >
                            <Tab
                                // label={focus ? "Tray" : null}
                                icon={<img
                                    className={"w-6"}
                                    src={trayIcon}
                                    alt='Tray Icon'
                                ></img>}
                                onClick={() => {
                                    history.push(ROUTES.traysTab)
                                }}
                            // sx={{ marginBottom: focus ? 0 : '20px', }}
                            />
                        </LightTooltip>
                        <LightTooltip
                            title={
                                `Collection`}
                            title2={"Sites"}
                            size="md" open={focus}
                            onClick={() => { history.push(ROUTES.trapSite) }}
                            slotProps={{
                                popper: {
                                    modifiers: [
                                        {
                                            name: 'offset',
                                            options: {
                                                offset: [0, -50],
                                            },
                                        },
                                    ],
                                    sx: {
                                        padding: 0,
                                        zIndex: 0
                                    }
                                },
                            }}
                        >
                            <Tab
                                icon={<img
                                    className={"w-10"}
                                    src={trapSiteIcon}
                                    alt='Collection Sites Icon'
                                />}
                                // label={focus ? "Collection Sites" : null}
                                onClick={() => { history.push(ROUTES.trapSite) }}
                                sx={{ paddingBottom: '40px' }}
                            />
                        </LightTooltip>

                        <LightTooltip title="Reports" size="md" open={focus}
                            onClick={() => { history.push(ROUTES.dataTab) }}>
                            {/* <Button variant="plain">Medium</Button> */}
                            <Tab
                                icon={<img
                                    className={"w-8"}
                                    src={reportIcon}
                                    alt='Reports Icon'
                                ></img>}
                                // label={focus ? "Reports" : null}
                                onClick={() => { history.push(ROUTES.dataTab) }}
                            // sx={{ marginBottom: focus ? 0 : '20px', }}
                            />
                        </LightTooltip>


                        <Tab sx={{ flexGrow: 1 }}
                            onMouseOut={(e) => {
                                e.preventDefault()
                            }}></Tab>

                        {superUser && <LightTooltip title="Admin" size="md" open={focus}
                            onClick={() => {
                                history.push(ROUTES.superUserPanel)
                            }}>
                            <Tab
                                // label={focus ? "Super User" : null}
                                icon={<AdminPanelSettingsIcon sx={{ color: '#3B7EB2', fontSize: '32px' }} />}
                                alt='Admin user Icon'
                                className={"w-6 w-full"}
                                onClick={() => {
                                    history.push(ROUTES.superUserPanel)
                                }}
                            // sx={{ marginBottom: focus ? 0 : '20px', }}
                            /></LightTooltip>}
                        {deviceUuid && deviceUuid !== 'ALLTRAY' && <LightTooltip title="Reboot" size="md" open={focus}
                            onClick={() => {
                                setRebootPopup(true)
                            }}>
                            <Tab
                                // label={focus ? "Super User" : null}
                                icon={<RestartAltIcon sx={{ color: '#3B7EB2', fontSize: '32px' }} />}
                                alt='Reboot'
                                className={"w-6 w-full"}
                                onClick={() => setRebootPopup(true)}
                            >
                            </Tab>
                        </LightTooltip>}
                        <LightTooltip title="Setup" size="md" open={focus}
                            onClick={() => {
                                history.push(ROUTES.deviceSetup)
                            }}>
                            <Tab
                                // label={focus ? "Device Setup" : null}
                                icon={<WifiIcon sx={{ color: '#3B7EB2', fontSize: '32px' }} />}
                                onClick={() => {
                                    history.push(ROUTES.deviceSetup)
                                }}
                            // sx={{ marginBottom: focus ? 0 : '20px', }}
                            />
                        </LightTooltip>
                        <LightTooltip title="Settings" size="md" open={focus}
                            onClick={() => {
                                history.push(ROUTES.settings)
                            }}
                        >
                            <Tab
                                // label={focus ? "Settings" : null}
                                icon={<img
                                    className={"w-6"}
                                    src={settingIcon}
                                    alt='Settings Icon'

                                ></img>}
                                onClick={() => {
                                    history.push(ROUTES.settings)
                                }}
                            // sx={{ marginBottom: focus ? 0 : '20px', }}
                            />
                        </LightTooltip>
                        <LightTooltip title="Logout" size="md" open={focus}
                            placement="bottom"
                            onClick={handleLogoutClick}

                        >
                            <Tab
                                icon={<img
                                    className={"w-10"}
                                    src={logoutIcon}
                                    alt='Logout Icon'

                                ></img>}
                                // label={focus ? "Logout" : null}
                                onClick={handleLogoutClick}
                                sx={{ marginBottom: '20px' }}
                            />
                        </LightTooltip>
                    </Tabs>
                </Box>
            </Box >
            {rebootPopup && <RebootButton setModal={setRebootPopup} />}
        </>
    );
};


export const mapStateToProps = (state) => {
    const superUser = state?.user?.superUser || false;
    const selectedDevice = state.devices?.selectedDevice || null;

    return {
        superUser,
        deviceUuid: selectedDevice,
    };
};

export default connect(mapStateToProps, {
})(NavigationBar);

