import { SvgIcon } from "@mui/material";

const ResizableIcon = (props) => {
    return (
        <SvgIcon {...props}>
            <rect width="26.7892" height="3.13559" rx="1.56779" transform="matrix(0.707107 -0.707107 0.689836 0.723966 0.328125 19.229)" fill="#97BFF6" />
            <rect width="17.5937" height="3.13559" rx="1.5678" transform="matrix(0.707107 -0.707107 0.689836 0.723966 7 19.3135)" fill="#97BFF6" />
            <path d="M14.6283 20.5104C13.9868 19.8887 13.9789 18.8727 14.6105 18.2411L18.0789 14.7726C18.7106 14.141 19.7427 14.1329 20.3841 14.7546V14.7546C21.0256 15.3763 21.0336 16.3923 20.4019 17.0239L16.9335 20.4924C16.3018 21.124 15.2698 21.1321 14.6283 20.5104V20.5104Z" fill="#97BFF6" />
        </SvgIcon>
    )
}

export default ResizableIcon;