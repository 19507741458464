import React, { useState, useEffect, useCallback } from "react";
import { connect } from "react-redux";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraIcon from "@mui/icons-material/Camera";
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import { Typography, Fab, CircularProgress, Box, Grid } from "@mui/material";
import RemoteSubmitButton from "../../../Forms/FormSubmitButton";
import ConfirmDialog from "./../../../ConfirmDialog";
import {
  renderInput,
  RenderSelect,
  renderAutocomplete,
  RenderDatePicker,
} from "../../../Forms/FormFields";
import { THUMB_H, THUMB_W } from "../../../../utils/const";
import ResponsiveButton from "../../../Forms/ResponsiveButton";
import { timeToLocale } from "../../../../utils/timeConversion";
import { RenderAutocompleteMultiple } from "../../../Forms/renderAutocompleteMultiple";
import SpecimenBBox from "./SpecimenBBox";
import useWindowSize from "../../../../lib/useWindowSizeHook";
// import AutoSave from "../../SideBar/DetailsRouter/SpecimenDetails/AutoSave";
import AutoSave from "./AutoSave";
import { Form, Field } from "react-final-form";
import {
  checkCommaAndBackSlash,
  checkCommaAndBackSlashOnTags,
} from "../../../../lib/validationHelper";
import { createFilterOptions } from "@mui/material/Autocomplete";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";

// const TypographySx = {
//   my: ["0.25rem", "0.25rem", "0.25rem", "0.25rem", "0.5rem", "0.5rem"],
//   fontSize: ["0.85rem", "0.85rem", "0.85rem", "0.85rem", "1.25rem", "1.25rem"],
// }

const captureOtherSideSX = {
  fontSize: ["0.55rem", "0.55rem", "0.6rem", "0.6rem", "0.875rem", "0.875rem"],
  "& .MuiSvgIcon-root": {
    fontSize: [
      "0.55rem",
      "0.55rem",
      "0.83rem !important",
      "0.83rem !important",
      "1.25rem !important",
      "1.25rem !important",
    ],
  },
};

const TrayViewer = ({
  handleRetakeTray,
  handleDeleteTray,
  handleCaptureOtherSide,
  handleSwitchImage,
  disableSwitchButton,
  disableCaptureOtherSide,
  handleFinish,
  disableRetakeSide,
  trapSites,
  trayId,
  trayDisplayName,
  trayCaptureTimeStamp,
  specimens,
  traySideMsg,
  specimenOnDisplay,
  loading,
  trayImage,
  handleChangeMetadata,
  metadataErrorDialogOpts,
  trapTypeAutoCompleteOptions,
  disableFinish,
  pathname,
  handleSpecimenJump,
  timeZone,
  metadata,
  tagsOptions,
}) => {
  const [showRetakeConfirm, setShowRetakeConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [hasValidationErrors, setHasValidationErrors] = useState(false);
  const hiddenViewElement = pathname === "/details/tray/capture" ? false : true;
  const headerButtonStyle = {
    background: "rgba(218, 244, 254, 0.1)",
  };

  //calculate the modal factor base on window height
  const windowSize = useWindowSize();
  //eslint-disable-next-line
  const [trayImageFactor, setImageFactor] = useState(0.5);
  const [imageHeightFactor, setImageHeightFactor] = useState(0.5);
  const [imageWidthFactor, setImageWidthFactor] = useState(0.5);
  const [showFields, setShowFields] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const handleCaptureDataButtonClick = () => {
    setShowFields(!showFields);
    setIsOpen(!isOpen);
  };

  // const minWidthCapturedata =
  //   Math.max(0, Math.floor(windowSize.width * 0.6)) + "px";
  // const maxWidthCapturedata =
  //   Math.max(0, Math.floor(windowSize.width * 0.8)) + "px";

  const captureDataBoxStyle = {
    // minWidth: minWidthCapturedata,
    // maxWidth: maxWidthCapturedata,
    minWidth: ["100px"],
    // maxWidth: ["100px"],
    width: "100%",
    overflow: "hidden",
  };

  const computeTrayImageFactor = useCallback(() => {


    function getResponsiveImageDimensions(screenWidth) {
      //const minHeight = 470;
      const minWidth = 650;
      const scaleFactorWidth = screenWidth / minWidth;
      return scaleFactorWidth;
    }

    if (showFields) {
      // setImageHeightFactor(1.2);
      // setImageWidthFactor(1.2);
    } else {
      // console.log(windowSize.width - 950)
      // console.log(windowSize.height)
      // console.log(getResponsiveImageDimensions(windowSize.width, windowSize.height))
      const ratio = getResponsiveImageDimensions(windowSize.width)
      // console.log(ratio)
      setImageHeightFactor(.55 * ratio);
      setImageWidthFactor(.55 * ratio);
      // if (windowSize.height > 1300) {
      //   setImageHeightFactor(2);
      //   setImageWidthFactor(2);
      // } else if ((windowSize.height <= 1300) & (windowSize.height > 1000)) {
      //   setImageHeightFactor(1.8);
      //   setImageWidthFactor(1.8);
      // } else if ((windowSize.height <= 1000) & (windowSize.height > 800)) {
      //   setImageHeightFactor(1.5);
      //   setImageWidthFactor(1.5);
      // } else {
      //   setImageHeightFactor(1);
      //   setImageWidthFactor(1);
      // }
    }
  }, [windowSize, showFields]);

  // console.log("tray image src", trayImage);

  useEffect(() => {
    computeTrayImageFactor();
  }, [windowSize, computeTrayImageFactor, showFields]);

  const phraseTimestamp = (trayCaptureTimeStamp) => {
    let customFormat = "M/DD/YYYY, h:mm:ss A z";
    return timeToLocale(trayCaptureTimeStamp, { timeZone, customFormat });
  };

  return (
    <Form
      style={{ marginTop: "50px" }}
      onSubmit={() => { }}
      mutators={{
        setValue: ([field, value], state, { changeValue }) => {
          changeValue(state, field, () => value);
        },
      }}
      initialValues={metadata}
      validate={(values) => {
        const errors = {};
        // if (values.well && values.well?.length > 0 && (values.plate?.length <= 0 || !values.plate)) {
        //   errors.plate = 'Must have a plate number'
        // }
        return errors;
      }}
      render={(form, change) => (
        <div id={"specimen-details"}>
          <AutoSave
            debounce={0}
            trayId={trayId}
            save={(value) => {
              let isChanged = value?.id ? false : true;
              handleChangeMetadata(value, isChanged);
            }}
          />
          <Box id={"capture-tray"} style={{ width: '100%' }}>
            <form id={"trap-info-form"}>
              <div style={{ backgroundColor: "white", marginTop: "3rem" }}>
                <div id={"tray-header"}>
                  <div id={"retake-image"}>
                    <ConfirmDialog
                      title={"Retake tray image?"}
                      children={
                        "The new image will overwrite the current image. Make sure the tray is in the same orientation."
                      }
                      open={showRetakeConfirm}
                      setOpen={setShowRetakeConfirm}
                      onConfirm={handleRetakeTray}
                      id={"retake-tray-confirm-dialog"}
                    />
                  </div>

                  {/* <div style={captureDataBoxStyle} > */}
                  <div style={{
                    // border: '1px solid #D3D3D3',
                    // borderRadius: '4px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    width: '100%',
                    height: '100%',
                  }}>
                    <ResponsiveButton
                      id={"upload-tray-metadata"}
                      disabled={hasValidationErrors || disableFinish}
                      onClick={() => handleFinish()}
                      sx={{
                        marginTop: '-30px', marginRight: '-10px', fontSize: '20px', border: "1px solid #D3D3D3",
                      }}
                    >
                      {/* Finish */}
                      <Tooltip title={`Close Tray`}
                        PopperProps={{
                          sx: {
                            '& .MuiTooltip-tooltip': {
                              fontSize: '15px', // Increase font size directly
                            },
                          },
                        }}
                      >
                        <CloseIcon style={{ color: '#3f51b5' }} />
                        {/* <CancelOutlinedIcon style={{ color: '#3f51b5', fontSize: '40px' }} /> */}
                      </Tooltip>
                    </ResponsiveButton>
                  </div>
                  <div
                    style={{
                      marginTop: "10px",
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                    }}
                  >
                    <Button
                      endIcon={
                        isOpen ? (
                          <KeyboardArrowUpIcon />
                        ) : (
                          <KeyboardArrowDownIcon />
                        )
                      }
                      variant="outlined"
                      color="primary"
                      onClick={handleCaptureDataButtonClick}
                      sx={{
                        fontSize: "10px",
                        marginRight: hiddenViewElement ? "-60px" : "0px"
                      }}
                    >
                      Capture Data
                    </Button>
                    <Typography
                      // className={"primary"}
                      sx={{
                        fontSize: "15px",
                        color: "#3f51b5",
                        display: "flex",
                        justifyContent: "center",
                        marginLeft: "auto",
                        marginTop: '5px'
                      }}
                    >
                      {trayDisplayName}
                      {traySideMsg.toUpperCase()}
                    </Typography>
                    <ResponsiveButton
                      variant="outlined"
                      style={{
                        ...headerButtonStyle,
                        backgroundColor: "white",
                        // color: "gray",
                        // border: "1px solid gray",
                        marginLeft: "5px",
                      }}
                      onClick={handleSwitchImage}
                      disabled={loading || disableSwitchButton}
                      sx={{
                        // maxHeight: "20px",
                        display: disableSwitchButton ? "none" : "block",
                      }}
                    >
                      <Tooltip
                        title={`Flip Tray`}
                        PopperProps={{
                          sx: {
                            '& .MuiTooltip-tooltip': {
                              fontSize: '15px', // Increase font size directly
                            },
                          },
                        }}
                      >
                        {/* FLIP */}
                        <SwapHorizIcon />
                      </Tooltip>
                    </ResponsiveButton>
                    <div
                      id={"switch-tray-side"}
                      style={{
                        display: "flex",
                        // marginRight: "10px",
                        justifyContent: "flex-end",
                        marginLeft: "auto",
                      }}
                    >
                      <ResponsiveButton
                        sx={{
                          border: "0.5px solid",
                        }}
                        onClick={() => setShowRetakeConfirm(true)}
                        disabled={disableRetakeSide}
                        style={{
                          visibility: hiddenViewElement ? "hidden" : "visible",
                          display: hiddenViewElement ? "none" : "block",
                        }}
                      >
                        Retake
                        <AutorenewIcon />
                      </ResponsiveButton>
                      <ResponsiveButton
                        sx={{
                          border: "0.5px solid ",
                          marginLeft: '5px',
                        }}
                        onClick={handleCaptureOtherSide}
                        disabled={disableCaptureOtherSide}
                        id={"capture-other-side"}
                        style={{
                          visibility: hiddenViewElement ? "hidden" : "visible",
                          display: hiddenViewElement ? "none" : "block",
                        }}
                      >
                        CAPTURE OTHER SIDE
                        <CameraIcon className={"mr-1"} />
                      </ResponsiveButton>
                    </div>

                  </div>

                  {showFields && (
                    <Box
                      border="1px solid #ccc"
                      borderRadius={1}
                      style={captureDataBoxStyle}
                      marginTop="10px"
                    >
                      <div
                        className={"flex flex-1 flex-col p-2"}
                        id={"trap-info"}
                      >
                        <div className={"flex flex-1 justify-around py-4 px-6"}>
                          <Grid container spacing={{ xs: 1, md: 3, lg: 5 }}>
                            <Grid item sm={6}>
                              <Box
                                className={"flex flex-1 flex-col"}
                                sx={{
                                  "& >div:first-of-type": {
                                    height: [
                                      "60px",
                                      "60px",
                                      "60px",
                                      "60px",
                                      "71px",
                                      "71px",
                                    ],
                                  },
                                  ".MuiAutocomplete-root + .MuiFormControl-root":
                                  {
                                    mt: [
                                      "0.5rem",
                                      "0.5rem",
                                      "0.5rem",
                                      "0.5rem",
                                      "1rem",
                                      "1rem",
                                    ],
                                  },
                                }}
                              >
                                <Field
                                  key="trapType"
                                  label="Collection Method"
                                  name="trapType"
                                  inputProps={{
                                    id: "trap-type-field",
                                    "data-testid": "collection-method",
                                  }}
                                  options={trapTypeAutoCompleteOptions}
                                  disabled={loading}
                                  component={renderAutocomplete}
                                  validate={checkCommaAndBackSlash}
                                />
                                <Field
                                  key="trapSiteId"
                                  data-testid="collection-site"
                                  id="Collection Site"
                                  label="Collection Site"
                                  name="trapSiteId"
                                  autocompleteProps={{
                                    id: "trap-site-field",
                                    // style: { width: "100%" },
                                  }}
                                  disabled={loading}
                                  options={trapSites || []}
                                  getOptionLabel={(value) => {
                                    return (
                                      trapSites?.find(
                                        (option) => option?.id === value
                                      )?.displayName || ""
                                    );
                                  }}
                                  isOptionEqualToValue={(option, value) => {
                                    return option?.id === value;
                                  }}
                                  getOptionKey={(option) => {
                                    return option?.id || "";
                                  }}
                                  filterOptions={createFilterOptions({
                                    matchFrom: "any",
                                    stringify: (option) => option.displayName,
                                  })}
                                  component={RenderSelect}
                                />
                              </Box>
                            </Grid>
                            <Grid item sm={6}>
                              <Box
                                className={"flex flex-1 flex-col"}
                                sx={{
                                  "& >div:first-of-type": {
                                    height: [
                                      "60px",
                                      "60px",
                                      "60px",
                                      "60px",
                                      "71px",
                                      "71px",
                                    ],
                                  },
                                  ".MuiFormControl-root + .MuiFormControl-root":
                                  {
                                    mt: [
                                      "0.5rem",
                                      "0.5rem",
                                      "0.5rem",
                                      "0.5rem",
                                      "1rem",
                                      "1rem",
                                    ],
                                  },
                                }}
                              >
                                <Field
                                  key="captureDate"
                                  label="Specimen Capture Date"
                                  name="captureDate"
                                  disabled={loading}
                                  component={RenderDatePicker}
                                  inputProps={{
                                    "data-testid": "capture-date",
                                  }}
                                  setHasErrorsFlag={setHasValidationErrors}
                                  cusMinDate="1900-01-01"
                                  trayId={trayId}
                                  handleChange={() => { }}
                                />
                                <Field
                                  key="notes"
                                  data-testid="notes"
                                  label="Notes"
                                  name="notes"
                                  inputProps={{
                                    id: "tray-notes",
                                    multiline: true,
                                    maxRows: 5,
                                    fullWidth: true,
                                  }}
                                  testId="notes"
                                  disabled={loading}
                                  handleChange={() => { }}
                                  component={renderInput}
                                />
                              </Box>
                            </Grid>
                            <Grid item sm={12} sx={{ marginTop: "-1rem" }}>
                              <Box
                                className={"flex flex-1 flex-col"}
                                sx={{
                                  ".MuiFormControl-root + .MuiFormControl-root":
                                  {
                                    mt: [
                                      "0.5rem",
                                      "0.5rem",
                                      "0.5rem",
                                      "0.5rem",
                                      "1rem",
                                      "1rem",
                                    ],
                                  },
                                }}
                              >
                                <Box
                                  sx={{
                                    border: "1px solid #ccc",
                                    borderRadius: 4,
                                    padding: "0.5px",
                                    mt: 2,
                                    textAlign: "center",
                                  }}
                                >
                                  <Field
                                    // multi
                                    label="Tray Tags"
                                    name={`tags`}
                                    chipColor="#ab47bc"
                                    shadowLabel={false}
                                    outlined={false}
                                    inputProps={{
                                      id: `tags`,
                                    }}
                                    component={RenderAutocompleteMultiple}
                                    // options={tagOptions || []}
                                    fieldValue={form?.values?.tags}
                                    onChange={(event, value) => {
                                      form.form.mutators.setValue("tags", [
                                        ...value,
                                      ]);
                                    }}
                                    options={tagsOptions}
                                    onBlur={(e) => e.preventDefault()}
                                    multiple={true}
                                    validate={checkCommaAndBackSlashOnTags}
                                    inputSX={{
                                      pl: ".5rem",
                                      "& .MuiFormLabel-root": {
                                        pl: "-2rem",
                                      },
                                    }}
                                  />
                                </Box>
                              </Box>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Box>
                  )}
                  <div
                    id={"trap-info"}
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                      marginTop: '1rem',
                      marginBottom: ".25rem",
                    }}
                  >
                    <Typography
                      className={"text-gray-600"}
                      sx={{
                        fontSize: [
                          "0.375rem",
                          "0.375rem",
                          "0.375rem",
                          "0.375rem",
                          "0.8625rem",
                          "0.9375rem",
                        ],
                      }}
                    >
                      Imaging Time:{" "}
                      {trayCaptureTimeStamp !== "" &&
                        phraseTimestamp(trayCaptureTimeStamp)}
                    </Typography>
                  </div>
                  {/* TRAY IMAGE  */}
                  <div
                    className={"flex relative flex-1"}
                    id={"tray-image"}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "0",
                      marginLeft: "0",
                      width: '100%'
                    }}
                  >
                    <Box
                      style={{
                        height: `${THUMB_H * imageHeightFactor}px`,
                        width: `${THUMB_W * imageWidthFactor}px`,
                      }}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      {!trayImage ? (
                        <CircularProgress size={"5rem"} id={"loading-tray"} />
                      ) : (
                        <img
                          src={trayImage}
                          width={imageWidthFactor * THUMB_W}
                          height={imageHeightFactor * THUMB_H}
                          alt={"tray"}
                          data-testid="tray-image"
                          style={{ width: '100%' }}
                        />
                      )}
                      {specimens &&
                        /*eslint array-callback-return: ["error", { allowImplicit: true }]*/
                        specimens.map((s, i) => {
                          if (Array.isArray(s?.bbox)) {
                            const h = `${[s.bbox[3] - s.bbox[1]] *
                              (imageHeightFactor * 0.95)
                              }px`;
                            const w = `${(s.bbox[2] - s.bbox[0]) * imageWidthFactor
                              }px`;
                            const topX = `${[
                              [s.bbox[0]] * imageWidthFactor,
                            ]}px`;
                            // const topY = `${[[s.bbox[1] * 1.03] * (imageHeightFactor)]}px`;
                            let topY;
                            // let topX;
                            if (i >= 0 && i <= 3) {
                              topY = `${[imageHeightFactor] * 20}px`;
                            } else {
                              topY = `${s.bbox[1] * imageHeightFactor * 1.03
                                }px`;
                            }
                            // if (i % 4 === 0) {
                            //   topX = `${[(imageWidthFactor)] * 30}px`;
                            // }
                            // else {
                            //   topX = `${(s.bbox[0] * imageWidthFactor) * 1.03}px`;
                            // }

                            const border =
                              i === specimenOnDisplay
                                ? "3px solid #428BCA"
                                : "0px solid #428BCA";

                            return (
                              <SpecimenBBox
                                s={s}
                                i={i}
                                w={w}
                                h={h}
                                topX={topX}
                                topY={topY}
                                border={border}
                                handleJump={() => handleSpecimenJump(i)}
                              ></SpecimenBBox>
                            );
                          }
                          return;
                        })}
                    </Box>
                  </div>
                  <div style={{
                    // border: '1px solid #D3D3D3',
                    // borderRadius: '4px',
                    display: "flex",
                    justifyContent: "flex-end",
                    // margin: 'auto',
                    width: '100%'
                  }}>
                    <ResponsiveButton
                      onClick={() => setShowDeleteConfirm(true)}
                      disabled={loading}
                      id={"delete-tray"}
                      sx={{
                        color: "status.danger",
                        fontSize: '12px',
                        border: "1px solid #D3D3D3",
                        // marginLeft: "10px",
                      }}
                    >
                      <Tooltip title={`Delete Tray`}
                        PopperProps={{
                          sx: {
                            '& .MuiTooltip-tooltip': {
                              fontSize: '15px', // Increase font size directly
                            },
                          },
                        }}
                      >
                        {/* DELETE */}
                        <DeleteIcon />
                      </Tooltip>
                    </ResponsiveButton>
                  </div>
                  <ConfirmDialog
                    title={"Delete tray?"}
                    children={
                      "All tray data will be deleted, including its specimens data and images"
                    }
                    open={showDeleteConfirm}
                    setOpen={setShowDeleteConfirm}
                    onConfirm={handleDeleteTray}
                    id={"delete-tray-confirm-dialog"}
                  />
                  <ConfirmDialog
                    {...metadataErrorDialogOpts}
                    title={"You may still have unsaved changes"}
                    children={"Continue anyway?"}
                    id={"metadata-error-confirm-dialog"}
                  />
                </div>

                {
                  //old tray viewer deprecated
                }
                {false && (
                  <div className={"bg-white"}>
                    <div className={"flex relative flex-1"} id={"tray-image"}>
                      <Box
                        className={"flex justify-center items-center"}
                        style={{
                          height: `${THUMB_H * trayImageFactor}px`,
                          width: `${THUMB_W * trayImageFactor}px`,
                        }}
                      >
                        {!trayImage ? (
                          <CircularProgress size={"5rem"} id={"loading-tray"} />
                        ) : (
                          <img
                            src={trayImage}
                            width={trayImageFactor * THUMB_W}
                            height={trayImageFactor * THUMB_H}
                            alt={"tray"}
                            data-testid="tray-image"
                          />
                        )}
                        {specimens &&
                          /*eslint array-callback-return: ["error", { allowImplicit: true }]*/
                          specimens.map((s, i) => {
                            if (Array.isArray(s?.bbox)) {
                              const h = `${(s.bbox[3] - s.bbox[1]) * trayImageFactor
                                }px`;
                              const w = `${(s.bbox[2] - s.bbox[0]) * trayImageFactor
                                }px`;
                              const topX = `${s.bbox[0] * trayImageFactor}px`;
                              const topY = `${s.bbox[1] * trayImageFactor}px`;
                              const border =
                                i === specimenOnDisplay
                                  ? "3px solid #428BCA"
                                  : "0px solid #428BCA";

                              return (
                                <SpecimenBBox
                                  s={s}
                                  i={i}
                                  w={w}
                                  h={h}
                                  topX={topX}
                                  topY={topY}
                                  border={border}
                                  handleJump={() => handleSpecimenJump(i)}
                                ></SpecimenBBox>
                              );
                            }
                            return;
                          })}

                        <Box
                          sx={{
                            position: "absolute",
                            bottom:
                              form?.values?.tags?.length > 0
                                ? [
                                  "-25px",
                                  "-25px",
                                  "-25px",
                                  "-26px",
                                  "-33px",
                                  "-33px",
                                ]
                                : "-5px",
                            left: "12px",
                            paddingRight: "20px",
                            width: "100%",
                          }}
                        >
                          <Field
                            // multi
                            label="Tray Tags"
                            name={`tags`}
                            chipColor="#ab47bc"
                            shadowLabel={false}
                            outlined={false}
                            inputProps={{
                              id: `tags`,
                              style: {
                                width: "100%",
                                minWidth: "100%",
                              },
                            }}
                            component={RenderAutocompleteMultiple}
                            // options={tagOptions || []}
                            fieldValue={form?.values?.tags}
                            onChange={(event, value) => {
                              form.form.mutators.setValue("tags", [...value]);
                            }}
                            onBlur={(e) => e.preventDefault()}
                            multiple={true}
                            validate={checkCommaAndBackSlashOnTags}
                          />
                        </Box>
                      </Box>
                    </div>
                    <div
                      className={"flex flex-1 flex-col p-2"}
                      id={"trap-info"}
                    >
                      <Typography
                        className={"text-gray-600"}
                        sx={{
                          fontSize: [
                            ".5rem",
                            ".5rem",
                            ".5rem",
                            ".5rem",
                            "1.15rem",
                            "1.25rem",
                          ],
                          transform:
                            form?.values?.tags?.length > 0
                              ? [
                                "translateY(16px)",
                                "translateY(16px)",
                                "translateY(16px)",
                                "translateY(16px)",
                                "translateY(16px)",
                                "translateY(16px)",
                              ]
                              : "translateY(0px)",
                        }}
                      >
                        Imaging Time:{" "}
                        {trayCaptureTimeStamp !== "" &&
                          phraseTimestamp(trayCaptureTimeStamp)}
                      </Typography>
                      <div className={"flex flex-1 justify-around py-4 px-6"}>
                        <Grid container spacing={{ xs: 1, md: 3, lg: 5 }}>
                          <Grid item sm={6}>
                            <Box
                              className={"flex flex-1 flex-col"}
                              sx={{
                                "& >div:first-of-type": {
                                  height: [
                                    "60px",
                                    "60px",
                                    "60px",
                                    "60px",
                                    "71px",
                                    "71px",
                                  ],
                                },
                                ".MuiAutocomplete-root + .MuiFormControl-root":
                                {
                                  mt: [
                                    "0.5rem",
                                    "0.5rem",
                                    "0.5rem",
                                    "0.5rem",
                                    "1rem",
                                    "1rem",
                                  ],
                                },
                              }}
                            >
                              <Field
                                key="trapType"
                                label="Collection Method"
                                name="trapType"
                                inputProps={{
                                  id: "trap-type-field",
                                  "data-testid": "collection-method",
                                }}
                                options={trapTypeAutoCompleteOptions}
                                disabled={loading}
                                component={renderAutocomplete}
                                validate={checkCommaAndBackSlash}
                              />
                              <Field
                                key="trapSiteId"
                                data-testid="collection-site"
                                id="Collection Site"
                                label="Collection Site"
                                name="trapSiteId"
                                autocompleteProps={{
                                  id: "trap-site-field",
                                  // style: { width: "100%" },
                                }}
                                disabled={loading}
                                options={trapSites || []}
                                getOptionLabel={(value) => {
                                  return (
                                    trapSites?.find(
                                      (option) => option?.id === value
                                    )?.displayName || ""
                                  );
                                }}
                                isOptionEqualToValue={(option, value) => {
                                  return option?.id === value;
                                }}
                                getOptionKey={(option) => {
                                  return option?.id || "";
                                }}
                                filterOptions={createFilterOptions({
                                  matchFrom: "any",
                                  stringify: (option) => option.displayName,
                                })}
                                component={RenderSelect}
                              />
                            </Box>
                          </Grid>
                          <Grid item sm={6}>
                            <Box
                              className={"flex flex-1 flex-col"}
                              sx={{
                                "& >div:first-of-type": {
                                  height: [
                                    "60px",
                                    "60px",
                                    "60px",
                                    "60px",
                                    "71px",
                                    "71px",
                                  ],
                                },
                                ".MuiFormControl-root + .MuiFormControl-root": {
                                  mt: [
                                    "0.5rem",
                                    "0.5rem",
                                    "0.5rem",
                                    "0.5rem",
                                    "1rem",
                                    "1rem",
                                  ],
                                },
                              }}
                            >
                              <Field
                                key="captureDate"
                                label="Specimen Capture Date"
                                name="captureDate"
                                disabled={loading}
                                component={RenderDatePicker}
                                inputProps={{
                                  "data-testid": "capture-date",
                                }}
                                setHasErrorsFlag={setHasValidationErrors}
                                cusMinDate="1900-01-01"
                                trayId={trayId}
                                handleChange={() => { }}
                              />
                              <Field
                                key="notes"
                                data-testid="notes"
                                label="Notes"
                                name="notes"
                                inputProps={{
                                  id: "tray-notes",
                                  multiline: true,
                                  maxRows: 5,
                                  fullWidth: true,
                                }}
                                testId="notes"
                                disabled={loading}
                                handleChange={() => { }}
                                component={renderInput}
                              />
                            </Box>
                          </Grid>
                        </Grid>
                      </div>
                      <div
                        className={
                          "flex flex-row justify-between pb-2 px-6 mr-4"
                        }
                      >
                        <ResponsiveButton
                          onClick={() => setShowDeleteConfirm(true)}
                          disabled={loading}
                          id={"delete-tray"}
                          sx={{
                            color: "status.danger",
                          }}
                        >
                          <DeleteIcon />
                          DELETE
                        </ResponsiveButton>

                        <ConfirmDialog
                          title={"Delete tray?"}
                          children={
                            "All tray data will be deleted, including its specimens data and images"
                          }
                          open={showDeleteConfirm}
                          setOpen={setShowDeleteConfirm}
                          onConfirm={handleDeleteTray}
                          id={"delete-tray-confirm-dialog"}
                        />
                        <ConfirmDialog
                          {...metadataErrorDialogOpts}
                          title={"You may still have unsaved changes"}
                          children={"Continue anyway?"}
                          id={"metadata-error-confirm-dialog"}
                        />
                        <Fab
                          onClick={handleCaptureOtherSide}
                          variant={"extended"}
                          color={"primary"}
                          size={"small"}
                          disabled={disableCaptureOtherSide}
                          id={"capture-other-side"}
                          sx={captureOtherSideSX}
                          style={{
                            visibility: hiddenViewElement
                              ? "hidden"
                              : "visible",
                          }}
                        >
                          <CameraIcon className={"mr-1"} />
                          CAPTURE OTHER SIDE
                        </Fab>
                        <RemoteSubmitButton
                          id={"upload-tray-metadata"}
                          disabled={hasValidationErrors || disableFinish}
                          onClick={() => handleFinish()}
                        >
                          Finish
                        </RemoteSubmitButton>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </Box>
        </div>
      )}
    ></Form>
  );
};

export default connect()(TrayViewer);
