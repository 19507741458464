import io from "socket.io-client";
import {
  WEBSOCKET_OPENED,
  WEBSOCKET_CLOSED,
  ESTABLISH_STATES_DEVICE,
  WEBSOCKET_REAUTHENTICATING,
  //WEBSOCKET_ERROR,
} from "../../actions/types";
import _ from "lodash";
import { displayPermanentErrorNotification } from "../../actions";

class SocketIOClient {
  constructor(store, socketUrl, getToken) {
    this.store = store;
    this.socketUrl = socketUrl;
    this.getToken = getToken;
    this.socketId = "";
    this.failCount = 0;

    this.checkStop = (socket, count) => {
      if (count > 10) {
        this.store.dispatch(
          displayPermanentErrorNotification(
            "Lost Connection. Please refresh the page and try again."
          )
        );
        socket.disconnect();
      }
      socket.failCount = 0;
    };

    this.debounceCheck = _.debounce(this.checkStop, 30000, { maxWait: 30000 });

    this.socket =
      process.env.REACT_APP_TEST_STATUS === "test"
        ? io(this.socketUrl)
        : io(this.socketUrl, {
            auth: (cb) => this.getAuth().then((resp) => cb(resp)),
            timeout: 2000,
            reconnectionDelayMax: 2000,
            path: "/ws/socket.io",
            withCredentials: true,
            // transports: [ "websocket" ],
          });

    this.socket.on("connect_error", (err) => {
      this.failCount += 1;

      if (err.message === "invalid credentials") {
        this.debounceCheck(this.socket, this.failCount);
      }
    });

    this.socket.on("connect", () => {
      this.store.dispatch({ type: WEBSOCKET_OPENED });
    });

    this.socket.io.on("reconnect_attempt", (attempt) => {
      console.log("RECONNECT ATTEMPT");
    });

    this.socket.on("disconnect", () => {
      console.log("i get discounnected");
      this.store.dispatch({ type: WEBSOCKET_CLOSED });
    });

    this.socket.io.on("reconnect", () => {
      this.store.dispatch({ type: WEBSOCKET_REAUTHENTICATING });
    });

    this.socket.on("message", (action) => {
      if (action.type === ESTABLISH_STATES_DEVICE) {
        this.socketId = action.payload.socketId;
      }
      this.store.dispatch(action);
    });
  }

  async getAuth() {
    const token = await this.getToken();
    if (this.socketId.length > 0) {
      return {
        token,
        socketId: this.socketId,
      };
    }
    return {
      token,
    };
  }

  send(action) {
    this.socket.emit("message", { ...action, t: +new Date() });
  }

  // organizationRefresh(action) {
  //   this.socket.emit("organizationRefresh", { ...action })
  // }
}

export default SocketIOClient;
