// filters an ordered list of strings values out of an
//      ordered list of string values in O(n)
// requires every value in toRemove to be in orig and
//    and for both lists to be sets
export const filterSortedList = (orig, toRemove) => {
  let i = 0;
  return orig.reduce((ret, x) => {
    if (x === toRemove[i]) {
      i++;
      return ret;
    }
    return [...ret, x];
  }, []);
};

// insert a value into an ordered list of values without allowing duplicates
export const insertSortedSet = (arr, el) => {
  let ret = arr;
  if (el.localeCompare(arr[0]) === -1) {
    ret = [el, ...arr];
  }
  for (let i = 0; i < arr.length; i++) {
    const cmp = el.localeCompare(arr[i]);
    if (cmp === 1) {
      ret = [...arr.slice(0, i + 1), el, ...arr.slice(i + 1)];
    } else if (cmp === 0) {
      return arr;
    }
  }
  return ret;
};

// converts list of numbers to list of continuous ranges
export const convertToRanges = (nums) => {
  const ranges = [[nums[0], nums[0]]];
  let j = 0;

  for (let i = 1; i < nums.length; i++) {
    if (nums[i] - ranges[j][1] === 1) {
      ranges[j][1] = nums[i];
    } else {
      ranges[++j] = [nums[i], nums[i]];
    }
  }
  return ranges
    .map(([start, end]) => (start !== end ? `${start}-${end}` : `${start}`))
    .join(", ");
};

const wellToNum = (well) =>
  (well.charCodeAt(0) - 64) * 100 + parseInt(well.substring(1));

const numToWell = (num) => {
  const row = Math.floor(num / 100);
  const col = num % 100;
  if (col < 10) {
    return String.fromCharCode(row + 64) + "0" + col;
  }
  return String.fromCharCode(row + 64) + col;
};

export const convertWellsToRanges = (wells) => {
  const nums = wells.map(wellToNum);
  const ranges = [[nums[0], nums[0]]];
  let j = 0;

  for (let i = 1; i < nums.length; i++) {
    if (nums[i] - ranges[j][1] === 1) {
      ranges[j][1] = nums[i];
    } else {
      ranges[++j] = [nums[i], nums[i]];
    }
  }
  return ranges
    .map(([start, end]) =>
      start !== end
        ? `${numToWell(start)}-${numToWell(end)}`
        : `${numToWell(start)}`
    )
    .join(", ");
};

export const compareVersions = (v1, v2) => {
  const parts1 = v1.slice(1).split('.').map(Number);
  const parts2 = v2.slice(1).split('.').map(Number);

  for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
    const num1 = parts1[i] || 0;
    const num2 = parts2[i] || 0;

    if (num1 !== num2) return num1 > num2 ? 1 : -1;
  }

  return 0;
}

export const findLatestVersion = (versions) => {
  return versions.slice().sort(compareVersions).pop();
}