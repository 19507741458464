import React from "react";
import { Typography, Box, Grid } from "@mui/material";

const fontSx = {
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"]
}

const NoShow = () => {

    return <Box sx={{
        width: '100%',
        px: '4rem',
        py: '5rem'
    }}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Typography sx={fontSx} className={" text-gray-600 text-center"}>Device Info</Typography>
            </Grid>
            <Grid item xs={12}>
                <Typography sx={fontSx} className={" text-gray-600 text-center mt-16"}>Please select a device</Typography>
            </Grid>
        </Grid>
    </Box>
};

export default NoShow;
