import { Button } from "@mui/material";
import React from "react";

const ResponsiveIconButton = React.forwardRef((props, ref) => {
    return <Button {...props}
        sx={{
            fontSize: ["0.55rem", "0.55rem", "0.55rem", "0.55rem", "0.875rem", "0.875rem"],
            padding: ["0.4rem 0.5rem", "0.4rem 0.5rem", "0.4rem 0.5rem",
                "0.4rem 0.5rem", "0.4rem 0.5rem", "0.4rem 0.5rem"],

            minWidth: ["2.68rem", "2.68rem", "2.68rem", "2.68rem", "4rem", "4rem"],
            "& .MuiSvgIcon-root": {
                fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem", "1.5rem"]
            },
        }}
        ref={ref}
    />;
});
export default ResponsiveIconButton;