import React, { useState } from "react";
import { Tooltip, IconButton, } from "@mui/material";
import ConfirmDialog from "../../../../ConfirmDialog";
import DeleteIcon from '@mui/icons-material/Delete';

const ForgetWifiTooltip = ({
    wifi,
    onForgetWifi
}) => {
    const [open, setOpen] = useState(false)
    return (
        <>
            <Tooltip title={`Delete password`}>
                <IconButton
                    onClick={() => setOpen(true)}
                    sx={{
                        "&:hover, &:focus": {
                            color: 'status.danger',
                        },
                        padding: ["0.5rem", "0.5rem", "0.5rem", "0.5rem", "0.75rem", "0.75rem"]
                    }}
                >
                    <DeleteIcon
                        sx={{
                            fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
                        }}
                    />
                </IconButton>
            </Tooltip>
            <ConfirmDialog
                title={`Forget WIFI ${wifi}`}
                children={`Are you sure you want to forget password for WIFI ${wifi}?\nThis action can not be undone`}
                open={open}
                setOpen={setOpen}
                onConfirm={() => onForgetWifi(wifi)}
            />
        </>

    );
};

export default ForgetWifiTooltip;
