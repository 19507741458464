import {
  NEXT_SPECIMEN,
  PREV_SPECIMEN,
  JUMP_SPECIMEN,
  UPDATE_SPECIMEN_AUTO_METADATA,
} from "./types";

export const nextSpecimen = () => async (dispatch) => {
  dispatch({ type: NEXT_SPECIMEN });
};

export const prevSpecimen = () => async (dispatch) => {
  dispatch({ type: PREV_SPECIMEN });
};

export const jumpSpecimen = (specimenNum) => async (dispatch) => {
  dispatch({ type: JUMP_SPECIMEN, payload: { specimenNum: specimenNum } });
};

export const updateSpecimenAutoMetadata = (
  trayId,
  selectedDevice,
  result
) => async (dispatch) => {
  dispatch({
    type: UPDATE_SPECIMEN_AUTO_METADATA,
    payload: { trayId, selectedDevice, result },
  });
};
