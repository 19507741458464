import React, { useEffect, useRef, useState } from "react";
import * as XLSX from "xlsx";
import fileDownload from "js-file-download";
import * as _ from 'lodash'

const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export default function DownloadPreviewedData({
    data,
    onDownloadData,
    filters,
    shouldDownload,
    onDownloadComplete,
    type,
    title,
    downloadColumns
}) {
    // const jsonData = JSON.stringify(data);
    const [hasDownloaded, setHasDownloaded] = useState(false);
    const [parsedData, setParsedData] = useState(
        JSON.parse(JSON.stringify(data))
    );

    useEffect(() => {
        setParsedData(JSON.parse(JSON.stringify(data)));
    }, [data]);

    useEffect(() => {
        if (parsedData?.length > 0) {
            return;
        }
        if (shouldDownload && !hasDownloaded) {
            onDownloadData();
            setHasDownloaded(true);
        }
        // eslint-disable-next-line
    }, [filters, shouldDownload, parsedData]);

    useEffect(() => {
        if (parsedData?.length > 0) {
            let modifiedData = [...parsedData];
            let columns = []
            if (type == "raw") {
                for (let key in downloadColumns) {
                    if (downloadColumns[key]) {
                        columns.push(key)
                    }
                }
                modifiedData = modifiedData.map((item) => {
                    const updatedItem = _.pick({ ...item }, columns);
                    Object.keys(updatedItem).forEach((key) => {
                        if (
                            key.toLowerCase().includes("genus") &&
                            typeof updatedItem[key] === "string"
                        ) {
                            updatedItem[key] = capitalizeFirstLetter(updatedItem[key]);
                        }
                    });
                    return updatedItem;
                });
            }

            if (type == "collections") {
                modifiedData = modifiedData.map((item) => ({
                    ...item,
                    Male: item.Male || 0,
                    Female: item.Female || 0,
                    "Sex unknown": item["Sex unknown"] || 0,
                    Total_Count: item.Total_Count || 0,
                }));

                const columnOrder = Object.keys(modifiedData[0] || {}).filter(
                    (col) =>
                        !["Male", "Female", "Sex unknown", "Total_Count"].includes(col)
                );
                const finalColumnOrder = [
                    ...columnOrder,
                    "Male",
                    "Female",
                    "Sex unknown",
                    "Total_Count",
                ];

                modifiedData = modifiedData.map((item) => {
                    const reorderedItem = {};
                    finalColumnOrder.forEach(
                        (col) => (reorderedItem[col] = item[col] || null)
                    );
                    return reorderedItem;
                });
            }

            if (type === "weekly") {
                let flattenedData = [];

                data.forEach((entry) => {
                    if (entry.trapsites) {
                        Object.entries(entry.trapsites).forEach(
                            ([trapSiteName, trapData]) => {
                                let row = {
                                    date: entry.date,
                                    trapSite: trapSiteName,
                                    sum: trapData.sum || 0,
                                    weeklyChange: trapData.weeklyChange || 0,
                                };

                                // Add species counts to the row
                                (trapData.species || []).forEach((species) => {
                                    row[species.species] = species.count || 0;
                                });

                                flattenedData.push(row);
                            }
                        );
                    }
                });

                const allColumns = new Set();
                flattenedData.forEach((row) => {
                    Object.keys(row).forEach((col) => allColumns.add(col));
                });

                const finalColumnOrder = [
                    "date",
                    "trapSite",
                    "sum",
                    "weeklyChange",
                    ...Array.from(allColumns).filter(
                        (col) => !["date", "trapSite", "sum", "weeklyChange"].includes(col)
                    ),
                ];

                flattenedData = flattenedData.map((row) => {
                    const reorderedItem = {};
                    finalColumnOrder.forEach(
                        (col) =>
                            (reorderedItem[col] = row[col] !== undefined ? row[col] : 0)
                    );
                    return reorderedItem;
                });

                modifiedData = flattenedData;
            }

            const worksheet = XLSX.utils.json_to_sheet(modifiedData, { header: columns });
            const workbook = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(workbook, worksheet, "Data Sheet");
            const excelBuffer = XLSX.write(workbook, {
                bookType: "xlsx",
                type: "array",
            });
            const blob = new Blob([excelBuffer], {
                type: "application/octet-stream",
            });
            fileDownload(blob, `${title}-Report-${(new Date()).toISOString().substring(0, 10)}.xlsx`);
            onDownloadComplete();

        }
    }, [shouldDownload, parsedData]);
    return <div></div>;
}
