import React from "react";
import Modal from "@mui/material/Modal";
import Backdrop from "@mui/material/Backdrop";
import { connect } from "react-redux";
import DialogContent from "@mui/material/DialogContent";
import { Typography, Box, Grid } from "@mui/material";
import ResponsiveButton from "../../../Forms/ResponsiveButton";

const textStyle = {
    fontSize: ["0.75rem", "0.75rem", "0.75rem", "0.75rem", "1.15rem", "1.15rem"]
}

const definitionStyle = {
    fontSize: ["0.75rem", "0.75rem", "0.75rem", "0.75rem", "1.15rem", "1.15rem"],
    opacity: .5
}

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
};

const NoteDisplayText = ({
    onClose,
}) => {

    const saveSettings = () => {
        onClose();
    };

    return (
        <div style={style} className={"m-auto transition-opacity duration-1000"}>
            <div
                className={
                    "flex flex-col bg-white max-w-2xl text-xl px-10 pt-8 pb-4 rounded-xl border-4 border-transparent"
                }
            >
                <Typography
                    sx={{
                        fontWeight: 500,
                        fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
                        mb: "0.5rem"
                    }}
                >
                    Notes
                </Typography>

                <Typography
                    sx={{
                        opacity: 0.5,
                        color: 'black',
                        fontSize: ["0.85rem", "0.85rem", "0.85rem", "0.85rem", "1.25rem", "1.25rem"]
                    }}
                >
                    For species column, contributed data has higher priority than algorithm data.
                    For example, a specimen had been identify as <i> Aedes aegypti</i> by the algorithm
                    and had contributed as <i>Aedes albopictus</i>. The aggregate function takes
                    <i> Aedes albopictus</i> as the species for this specimen.
                    <br />
                    <br />
                    <strong>Special terms:</strong>
                </Typography>
                <Grid container>
                    <Grid item xs={4}>
                        <Typography sx={textStyle}>Algorithm unsure: </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography sx={definitionStyle}> The algorithm value is different for front and back image of specimen.</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={textStyle}> Empty/Empty cell:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography sx={definitionStyle}>The algorithm value is empty cell for that cell in the tray.</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography sx={textStyle}>N/A:</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography sx={definitionStyle}>No contributed data and no algorithm data</Typography>
                    </Grid>
                </Grid>
                <Box className={"flex flex-row-reverse mt-8"}
                    sx={{
                        "& button + button": {
                            mr: "1rem"
                        }
                    }}
                >
                    <ResponsiveButton
                        variant="contained"
                        style={{
                            color: "#2196F3",
                            backgroundColor: "white",
                            borderColor: "rgba(63, 81, 181, 0.5)",
                            border: "1px solid",
                            boxShadow: "none",
                        }}
                        onClick={saveSettings}
                    >
                        OK
                    </ResponsiveButton>
                </Box>
            </div>
        </div>
    );
};

const NotesModal = ({
    autofillModalOpen,
    setAutofillModalOpen,
}) => {
    const onClose = () => setAutofillModalOpen(false);
    return (
        <div>
            <Modal
                open={autofillModalOpen}
                onClose={onClose}
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <DialogContent>
                    <NoteDisplayText
                        onClose={onClose}
                    />
                </DialogContent>
            </Modal>
        </div>
    );
};

export const mapStateToProps = () => {
    return {};
};

export default connect(mapStateToProps)(
    NotesModal
);
