import React, { useCallback } from "react";
import BluetoothSetup from "./BluetoothSetup";
import { connect } from "react-redux";
import { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import {
  displayPermanentSuccessNotification,
  displayErrorNotification,
  updateMacAddress,
} from "../../../../../actions";
import { bleInfoTable } from "../../../../../utils/const";

const getValue = async (characteristic) => {
  const enc = new TextDecoder("utf-8");
  const buf = await characteristic.readValue();
  const ret = enc.decode(buf);
  return ret;
};

const setValue = async (characteristic, value) => {
  const enc = new TextEncoder();
  const msg = enc.encode(value);
  const resp = await characteristic.writeValueWithResponse(msg);
  return resp;
};

const Index = ({
  selectedDevice,
  deviceState,
  deviceName,
  filterStr = "Aga",
  macAddress,
  updateMacAddress,
  displayPermanentSuccessNotification,
  displayErrorNotification,
}) => {
  const [bluetoothDevice, setBluetoothDevice] = useState(null);
  const [characteristics, setCharacteristics] = useState({});
  const [wifiList, setWifiList] = useState([]);
  const [ssid, setSSID] = useState("");
  const [psk, setPSK] = useState("");
  const [bluetoothState, setBluetoothState] = useState("disconnected");
  const history = useHistory();

  const characteristicsCached = useCallback(
    () =>
      characteristics?.scan?.list1 &&
      characteristics?.scan?.list2 &&
      characteristics?.scan?.list3 &&
      characteristics?.scan?.list4 &&
      characteristics?.scan?.list5 &&
      characteristics?.scan?.active &&
      characteristics?.connect?.wpa,
    [characteristics]
  );

  const cacheDeviceCharacteristics = useCallback(async (server) => {
    console.log("cacheDeviceCharacteristics");
    const servicePromises = Object.entries(bleInfoTable.services).map(
      async ([serviceName, serviceUuid]) => {
        console.log(`Caching ${serviceName}`);
        const service = await server.getPrimaryService(serviceUuid);
        console.log(service);
        const serviceCharObj = await getCharactersticsGivenService(
          service,
          serviceName
        );
        return [serviceName, serviceCharObj];
      }
    );
    console.log(servicePromises);
    const cachedServices = await Promise.all(servicePromises);
    console.log(cachedServices);
    const gattChars = Object.fromEntries(cachedServices);
    console.log(gattChars);
    setCharacteristics(gattChars);
    return gattChars;
  }, []);

  const disconnectDevice = useCallback(
    async (device) => {
      device = device || bluetoothDevice;
      if (device) {
        if (device.gatt.connected) {
          device.gatt.disconnect();
        }
      }
      setBluetoothDevice(null);
      setCharacteristics({});
      setBluetoothState("disconnected");
    },
    [bluetoothDevice]
  );

  useEffect(() => {
    const checkCompletionStatus = async () => {
      if (bluetoothState !== "disconnected" && deviceState === "BOOTING") {
        displayPermanentSuccessNotification(
          `${deviceName} was successfully connected. Device will restart.`
        );
        history.goBack();
      }
    };
    checkCompletionStatus();
  }, [
    bluetoothState,
    deviceState,
    deviceName,
    displayPermanentSuccessNotification,
    history,
  ]);

  useEffect(() => {
    const reconnectDevice = async () => {
      console.log("reconnecting...");
      console.log(bluetoothDevice);
      let server;
      for (let i = 0; i < 5; i++) {
        try {
          server = bluetoothDevice.gatt.connected
            ? bluetoothDevice.gatt
            : await bluetoothDevice.gatt.connect();
          console.log(server);
          if (server.connected) {
            break;
          } else {
            console.log("server completed but not connected");
          }
        } catch (error) {
          console.log(`Gatt connection failed, retrying (${i})...`);
        }
      }
      if (!server || !server.connected) {
        displayErrorNotification(
          `There was an error connecting to ${deviceName}. Please refresh the page and try again.`
        );
        disconnectDevice();
        return;
      }

      console.log(bluetoothDevice);
      let chars;
      try {
        chars = await cacheDeviceCharacteristics(server);
      } catch (err) {
        console.error(err);
        if (!bluetoothDevice.gatt.connected) {
          reconnectDevice();
        }
        return;
      }
      chars.scan.active.startNotifications().then((_) => {
        chars.scan.active.addEventListener(
          "characteristicvaluechanged",
          async function handler(event) {
            const value = event.target?.value?.getInt32(0, true);
            console.log(`wifi/scan updated value to:`);
            console.log(value);

            if (value) {
              this.removeEventListener("characteristicvaluechanged", handler);
              displayPermanentSuccessNotification(
                `${deviceName} was successfully connected. Device will restart.`
              );
              disconnectDevice();
              history.goBack();
            } else {
              console.log(bluetoothState);
              displayErrorNotification(
                `Wifi connection unsuccessful. Please check password.`
              );
              setBluetoothState("connected");
            }
          }
        );
      });
      console.log("reconnected");
      setBluetoothState("connected");
    };
    console.log("reconnect?");
    const areCharachteristicsCached = characteristicsCached();
    if (
      bluetoothState !== "connecting" &&
      bluetoothState !== "loading" &&
      bluetoothDevice?.gatt &&
      (!bluetoothDevice?.gatt?.connected || !areCharachteristicsCached)
    ) {
      setCharacteristics({});
      reconnectDevice();
    }
  }, [
    bluetoothDevice,
    bluetoothDevice?.gatt?.connected,
    bluetoothState,
    cacheDeviceCharacteristics,
    characteristicsCached,
    deviceName,
    disconnectDevice,
    displayErrorNotification,
    displayPermanentSuccessNotification,
    history,
  ]);

  useEffect(() => {
    const findMac = async () => {
      console.log("Searching for mac");
      if (characteristicsCached()) {
        await getValue(characteristics.mac.addr)
          .then((mac) => {
            console.log("mac", mac);
            updateMacAddress(selectedDevice, mac);
          })
          .catch((err) => {
            console.error("Mac scanning error: " + err);
            // displayErrorNotification(
            //   "There was an error fetching the mac address. Please refresh the page and try again."
            // );
            // disconnectDevice();
          });
      } else {
        setBluetoothState("loading");
      }
    };
    const findWifi = async () => {
      console.log("Searching for wifi");
      if (characteristicsCached()) {
        try {
          let res1 = await getValue(characteristics.scan.list1);
          let res2 = await getValue(characteristics.scan.list2);
          let res3 = await getValue(characteristics.scan.list3);
          let res4 = await getValue(characteristics.scan.list4);
          let res5 = await getValue(characteristics.scan.list5);

          let wifiListStr = res1 + res2 + res3 + res4 + res5;
          if (wifiListStr && wifiListStr?.length !== 0) {
            const wl = wifiListStr
              .substring(0, wifiListStr.length - 1)
              .split(",")
              .map((str) => str.substring(0, str.length - 4));
            setWifiList(wl);
          } else {
            throw Error("No wifis found");
          }
        } catch (err) {
          console.error("Wifi scanning error: " + err);
          displayErrorNotification(
            "There was an error scanning for available wifi networks. Please refresh the page and try again."
          );
          disconnectDevice();
        }
      } else {
        setBluetoothState("loading");
      }
    };
    if (bluetoothState === "connected" && wifiList.length === 0) {
      findMac();
      findWifi();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    wifiList,
    bluetoothState,
    characteristics,
    characteristicsCached,
    disconnectDevice,
    displayErrorNotification,
    updateMacAddress,
  ]);

  // useEffect(() => {
  //   const findMac = async () => {
  //     console.log("Searching for mac");
  //     if (characteristicsCached()) {
  //       await getValue(characteristics.mac.addr)
  //         .then((mac) => {
  //           console.log("mac", mac);
  //           updateMacAddress(selectedDevice, mac);
  //         })
  //         .catch((err) => {
  //           console.error("Mac scanning error: " + err);
  //           // displayErrorNotification(
  //           //   "There was an error fetching the mac address. Please refresh the page and try again."
  //           // );
  //           // disconnectDevice();
  //         });
  //     } else {
  //       setBluetoothState("loading");
  //     }
  //   };
  //   if (
  //     bluetoothState === "connected" &&
  //     !macAddress
  //   ) {
  //     findMac();
  //   }
  // }, [
  //   macAddress,
  //   updateMacAddress,
  //   bluetoothState,
  //   characteristics,
  //   characteristicsCached,
  //   disconnectDevice,
  //   displayErrorNotification,
  //   selectedDevice,
  // ]);

  const requestDevice = async () => {
    let requestTimer = null;
    requestTimer = setTimeout(() => {
      requestTimer = null;
      displayErrorNotification(
        `It is taking an unusually long time to connect to ${deviceName}. You may want to refresh the page and try again.`
      );
    }, 59 * 1000);

    const device = await navigator.bluetooth
      .requestDevice({
        filters: [
          { namePrefix: filterStr },
          { namePrefix: filterStr?.toLowerCase() },
        ],
        optionalServices: Object.values(bleInfoTable.services),
      })
      .catch((err) => {
        console.error(`Error requesting device: ${err}`);
        if (requestTimer !== null) {
          clearTimeout(requestTimer);
          requestTimer = null;
          displayErrorNotification(
            `There was an error connecting to ${deviceName}. You may want to refresh the page and try again.`
          );
        }
        setBluetoothState("disconnected");
      });
    clearTimeout(requestTimer);
    requestTimer = null;
    setBluetoothDevice(device);
    setBluetoothState(device ? "loading" : "disconnected");
    return device;
  };

  const getCharactersticsGivenService = async (service, serviceName) => {
    const charsPromises = Object.entries(
      bleInfoTable.characteristics[serviceName]
    ).map(async ([charName, charUuid]) => {
      console.log(`Caching ${serviceName}/${charName}`);
      const characteristic = await service.getCharacteristic(charUuid);
      console.log(characteristic);
      return [charName, characteristic];
    });
    const serviceChars = await Promise.all(charsPromises);

    return Object.fromEntries(serviceChars);
  };

  const onScanButtonPressed = async () => {
    if (!bluetoothDevice) await requestDevice();
  };

  const onConnectButtonPressed = async () => {
    try {
      if (!characteristicsCached()) {
        console.log("Could not find wpa characteristic");
        setBluetoothState("loading");
        return;
      }
      setBluetoothState("connecting");

      console.log("Set up connection timeout");
      // const wifiConnTimeout = setTimeout(() => {
      //   if (bluetoothState === "connecting" && deviceState === "OFF") {
      //     console.log("connection timeout called");
      //     displayErrorNotification(
      //       "Could not connect to device. Please try again."
      //     );
      //     setBluetoothState("connected");
      //   } else {
      //     console.log("connection timeout skipped");
      //   }
      // }, 20 * 1000);
      const prefix =
        ssid.length < 10
          ? ssid.length
          : String.fromCharCode("a".charCodeAt(0) + (ssid.length - 10));
      const buff = prefix + ssid + psk;

      let ret;
      try {
        ret = await setValue(characteristics.connect.wpa, buff);
      } catch (err) {
        console.error("connect.wpa failed: " + err);
      }
      console.log(`connect.wpa finished with ${ret}`);
    } catch (error) {
      console.log(`Error: ${error}`);
    }
  };

  const onChangeSSID = (obj) => {
    if (obj && obj.target && typeof obj.target.value === "string")
      setSSID(obj.target.value);
    else setSSID("");
  };

  const onChangePSK = (obj) => {
    if (obj && obj.target && typeof obj.target.value === "string")
      setPSK(obj.target.value);
    else setPSK("");
  };
  const onRescanButtonPressed = () => {
    if (bluetoothState === "connected") {
      setWifiList([]);
    }
  };
  return (
    <BluetoothSetup
      bluetoothState={bluetoothState}
      handleChangeSSID={onChangeSSID}
      handleChangePSK={onChangePSK}
      handleConnectButtonPressed={onConnectButtonPressed}
      handleScanButtonPressed={onScanButtonPressed}
      rescanWifiButtonPressed={onRescanButtonPressed}
      wifiList={wifiList}
      ssid={ssid}
      psk={psk}
    />
  );
};

export const mapStateToProps = (state) => {
  const { selectedDevice, ...devices } = state.devices;
  if (!selectedDevice) return {};
  const {
    stateMachine: deviceState,
    displayName: deviceName,
    macAddress = null,
  } = devices?.[selectedDevice];
  let filterStr = "Aga"; // maintain backwards compatability with older versions
  // if (firmwareVersion && compareVersions(firmwareVersion, "v1.5.0") >= 0) {
  //   const filterStrTmp = selectedDevice.split("-");
  //   filterStr = filterStrTmp[filterStrTmp.length - 1];
  // }
  return {
    selectedDevice,
    macAddress,
    deviceState,
    deviceName,
    filterStr,
  };
};

export default connect(mapStateToProps, {
  displayPermanentSuccessNotification,
  displayErrorNotification,
  updateMacAddress,
})(Index);
