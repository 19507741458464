const METERS_MILES_CONVERSION_FACTOR = 0.000621;

export const getDistanceMeters = (lat1, lon1, lat2, lon2) => {
  // calculates the distance between two geographic points
  //          using haversine's formula
  const R = 6371e3; // metres
  const sigma1 = (lat1 * Math.PI) / 180; // φ, λ in radians
  const sigma2 = (lat2 * Math.PI) / 180;
  const deltaSigma = ((lat2 - lat1) * Math.PI) / 180;
  const deltaLambda = ((lon2 - lon1) * Math.PI) / 180;

  const a =
    Math.sin(deltaSigma / 2) * Math.sin(deltaSigma / 2) +
    Math.cos(sigma1) *
      Math.cos(sigma2) *
      Math.sin(deltaLambda / 2) *
      Math.sin(deltaLambda / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  return Math.round(R * c); // in metres
};

export const getDistanceMiles = (lat1, lon1, lat2, lon2) =>
  Math.round(
    getDistanceMeters(lat1, lon1, lat2, lon2) * METERS_MILES_CONVERSION_FACTOR
  );
