import {
    TOGGLE_ENLARGED_SPECIMEN_MODAL
} from "../actions/types";
/* eslint-disable no-unused-vars */
import _ from "lodash";

export const DEFAULT_ENLARGEDSPECIMEN_MODAL = {
    isEnlargedSpecimenModalOpen: false,
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = DEFAULT_ENLARGEDSPECIMEN_MODAL, action) => {
    switch (action.type) {
        case TOGGLE_ENLARGED_SPECIMEN_MODAL:
            return {
                ...state,
                isEnlargedSpecimenModalOpen: !state.isEnlargedSpecimenModalOpen,
            };
        default:
            return state;
    }
};