import { FETCH_ALG_VECTORTYPES } from "../actions/types";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_ALG_VECTORTYPES:
      return {
        ...state,
        vectorTypes: action?.payload?.vectorTypes,
      };
    default:
      return state;
  }
};
