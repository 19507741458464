const ROUTES = {
  app: "/",
  dataTab: "/data",
  reports: "/reports",
  detailsTab: "/details",
  traysTab: "/trays",
  trayDetails: "/details/tray",
  captureTray: "/details/tray/capture",
  viewTray: "/details/tray/:id",
  trapSite: "/details/collection-site",
  createTrapSite: "/details/collection-site/create",
  editTrapSite: "/details/collection-site/edit/:id",
  toolDetails: "/details/tool",
  deviceSetup: "/details/tool/setup",
  superUserPanel: "/details/settings/superuser",
  settings: "/details/settings",
  enlargeImage: "/details/enlargedimage",
};

export default ROUTES;
