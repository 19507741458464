import {
  FETCH_NETWORKS_WIFI,
  SETUP_WPA_WIFI,
  CANCEL_WIFI,
  FINISH_WIFI,
  SELECT_WIFI,
  DELETE_WIFI,
  FETCH_CONNECTED_NETWORKS_WIFI,
  FETCH_CONNECTED_NETWORK_WIFI
} from "./types";

export const fetchWifiNetworks = (deviceUuid, jobId) => async (dispatch) => {
  dispatch({
    type: FETCH_NETWORKS_WIFI,
    payload: { deviceUuid, jobId },
    socket: { send: true },
  });
};

export const setupWPAWifi =
  (deviceUuid, jobId, ssid, psk) => async (dispatch) => {
    dispatch({
      type: SETUP_WPA_WIFI,
      payload: { deviceUuid, jobId, ssid, psk },
      socket: { send: true },
    });
  };

export const cancelWifiAction = (jobId) => async (dispatch) => {
  dispatch({
    type: CANCEL_WIFI,
    payload: { jobId },
    socket: { send: true },
  });
};

export const fetchConnectedNetworkWifi = (deviceUuid, jobId) => async (dispatch) => {
  dispatch({
    type: FETCH_CONNECTED_NETWORK_WIFI,
    payload: { deviceUuid, jobId },
    socket: { send: true },
  });
};

export const fetchConnectedNetworksWifi = (deviceUuid, jobId) => async (dispatch) => {
  dispatch({
    type: FETCH_CONNECTED_NETWORKS_WIFI,
    payload: { deviceUuid, jobId },
    socket: { send: true },
  });
};

export const deleteWifi = (deviceUuid, jobId, ssid) => async (dispatch) => {
  dispatch({
    type: DELETE_WIFI,
    payload: { deviceUuid, jobId, ssid },
    socket: { send: true },
  });
};

export const chooseWifi = (deviceUuid, jobId, ssid) => async (dispatch) => {
  dispatch({
    type: SELECT_WIFI,
    payload: { deviceUuid, jobId, ssid },
    socket: { send: true },
  });
};

export const finishWifiSetup = () => async (dispatch) => {
  dispatch({
    type: FINISH_WIFI,
  });
};
