import {
    SET_MAP_CENTER,
    SET_TRAP_SITE_PLACEMENT_MODE,
    SET_MAP_CENTER_PLACEMENT_MODE
} from "../actions/types";

const DEFAULT_MAP = {
    mapCenter: [0, 0],
    placementMode: false,
    mapCenterPlacementMode: false
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = DEFAULT_MAP, action) => {
    switch (action.type) {
        case SET_MAP_CENTER:
            return { ...state, mapCenter: action.payload };
        case SET_TRAP_SITE_PLACEMENT_MODE:
            return { ...state, placementMode: action.payload }
        case SET_MAP_CENTER_PLACEMENT_MODE:
            return { ...state, mapCenterPlacementMode: action.payload }
        default:
            return state;
    }
};
