import {
  FETCH_USER_AUTOFILL_SETTINGS,
  UPDATE_USER_AUTOFILL_SETTINGS,
  FETCH_USER_SETTINGS,
  UPDATE_USER_TIMEZONE_SETTINGS,
  UPDATE_USER_ADDRESS_SEARCH_RADIUS_SETTINGS,
  UPDATE_USER_MAP_CENTER_SETTINGS,
  UPDATE_USER_ALGORITHM_CONFIDENCE_SETTINGS,
  FETCH_ORGANIZATIONS,
  UPDATE_SUPER_USER_ORGANIZATION,
  FETCH_USERS_BY_ORG,
  APPROVE_USER,
  DELETE_USER,
  MOVE_DEVICE_ORG,
  RENAME_DEVICE,
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  CHANGE_DEVICE_HARDWARE_ID,
  UPDATE_USER_SELECTED_DEVICE
} from "../actions/types";
import _ from "lodash";

export const DEFAULT_USER_SETTINGS = {
  autofillSettings: {
    species: false,
    speciesDisplay: true,
    sex: false,
    sexDisplay: true,
    molecular: false,
    molecularDisplay: true,
    gonotrophic: false,
    gonotrophicDisplay: false,
    lifeStage: false,
    lifeStageDisplay: false,
    skipEmpty: false,
  },
  email: "",
  name: "",
  nickname: "",
  mapCenter: "39.294201, -76.588686",
  addressSearchRadius: 100,
  organization: "",
  timeZone: "AUTO",
  superUser: false,
  fetched: false,
  algConfidence: 0,
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = DEFAULT_USER_SETTINGS, action) => {
  switch (action.type) {
    case FETCH_USER_SETTINGS:
      return { ..._.defaultsDeep(action.payload, state), fetched: true };
    case UPDATE_USER_TIMEZONE_SETTINGS:
      return _.defaultsDeep(action.payload, state);
    case UPDATE_USER_ADDRESS_SEARCH_RADIUS_SETTINGS:
      return _.defaultsDeep(action.payload, state);
    case UPDATE_USER_ALGORITHM_CONFIDENCE_SETTINGS:
      return _.defaultsDeep(action.payload, state);
    case UPDATE_USER_MAP_CENTER_SETTINGS:
      return _.defaultsDeep(action.payload, state);
    case FETCH_USER_AUTOFILL_SETTINGS:
    case UPDATE_USER_AUTOFILL_SETTINGS:
      return _.defaultsDeep({ autofillSettings: action.payload }, state);
    case FETCH_ORGANIZATIONS:
      return {
        ...state,
        organizations: (action.payload?.organizations || []).reduce(
          (curr, org) => {
            const { tools, ...rest } = org;
            return {
              ...curr,
              [rest.uuid]: {
                ...rest,
              },
            };
          },
          {}
        ),
        orgDevices: (action.payload?.organizations || []).reduce(
          (curr, org) => ({
            ...curr,
            [org.uuid]: org?.tools || [],
          }),
          {}
        ),
      };
    case UPDATE_SUPER_USER_ORGANIZATION:
      return { ...state, organization: action.payload?.organizationUuid };
    case FETCH_USERS_BY_ORG:
      return _.defaultsDeep(action.payload, state);
    case APPROVE_USER:
      let otherOrgless = Object.fromEntries(
        Object.entries(state?.orgUsers?.orgless || {}).filter(
          ([k, v]) => k !== action.payload.userId
        )
      );
      return {
        ...state,
        orgUsers: {
          ...(state?.orgUsers || {}),
          orgless: otherOrgless,
          [action.payload.organization]: {
            ...(state.orgUsers?.[action.payload.organization] || {}),
            [action.payload.userId]: {
              ...state?.orgUsers?.orgless?.[action.payload.userId],
              metadata: action.payload.metadata,
            },
          },
        },
      };
    case DELETE_USER:
      return {
        ...state,
        orgUsers: {
          ...state.orgUsers,
          [action.payload.organization]: Object.fromEntries(
            Object.entries(
              state?.orgUsers?.[action.payload.organization] || {}
            ).filter(([k, v]) => k !== action.payload.userId)
          ),
        },
      };
    case MOVE_DEVICE_ORG:
      let movedDevice;
      const oldOrgState = state.orgDevices?.[action.payload.oldOrg].reduce(
        (arr, device) => {
          if (device.uuid !== action.payload.device) {
            return [...arr, device];
          }
          movedDevice = device;
          return arr;
        },
        []
      );
      if (movedDevice) {
        return {
          ...state,
          orgDevices: {
            ...state.orgDevices,
            [action.payload.oldOrg]: oldOrgState,
            [action.payload.newOrg]: [
              ...state.orgDevices?.[action.payload.newOrg],
              movedDevice,
            ],
          },
        };
      }
      return state;
    case RENAME_DEVICE:
      return {
        ...state,
        orgDevices: {
          ...state.orgDevices,
          [action.payload.org]: state.orgDevices?.[action.payload.org].map(
            (device) =>
              device.uuid === action.payload.device
                ? {
                  ...device,
                  display_name: action.payload.name,
                }
                : device
          ),
        },
      };
    case CHANGE_DEVICE_HARDWARE_ID:
      return {
        ...state,
        orgDevices: {
          ...state.orgDevices,
          [action.payload.org]: state.orgDevices?.[action.payload.org].map(
            (device) =>
              device.uuid === action.payload.device
                ? {
                  ...device,
                  hardware_id: action.payload.hardwareId,
                }
                : device
          ),
        },
      };
    case CREATE_ORGANIZATION:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          [action.payload.uuid]: action.payload,
        },
        orgDevices: { ...state.orgDevices, [action.payload.uuid]: [] },
        orgUsers: { ...state.orgUsers, [action.payload.uuid]: {} },
      };
    case UPDATE_ORGANIZATION:
      return {
        ...state,
        organizations: {
          ...state.organizations,
          [action.payload.uuid]: action.payload,
        },
      };
    case UPDATE_USER_SELECTED_DEVICE:
      return { ...state, selectedDevice: action.payload?.selectedDevice };
    default:
      return state;
  }
};
