import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

const fontSx = {
  fontSize: ["0.75rem", "0.75rem", "0.75rem", "0.75rem", "1rem", "1rem"],
};

const BANNED_CHARACTERS = [";", ",", "."];

const RenameDeviceDialog = ({
  actionName,
  device,
  setDevice,
  onConfirm,
  cancelMessage = "Cancel",
}) => {
  const [renameVal, setRenameVal] = useState("");
  const [error, setError] = useState([
    "Name must be at least 4 characters long.",
  ]);
  const handleClose = () => {
    setRenameVal("");
    setDevice(null);
  };

  const onChange = (event) => {
    const val = event.target.value || "";
    if (val !== renameVal) {
      // check for errors
      let currErrors = [];
      if (val.length < 4) {
        currErrors = [
          ...currErrors,
          "Name must be at least 4 characters long.",
        ];
      }
      if (val.trim() !== val) {
        currErrors = [...currErrors, "Name cannot start or end with a space."];
      }
      BANNED_CHARACTERS.filter((ban) => val.includes(ban)).forEach((banned) => {
        currErrors = [...currErrors, `Name cannot include character ${banned}`];
      });

      setError(currErrors);
      setRenameVal(val);
    }
  };

  return !!device ? (
    <Dialog
      open={!!device}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{`Rename ${device.displayName} (${device.uuid})`}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <Typography sx={fontSx} className={"text-gray-600"}>
              What should this device be renamed to?
            </Typography>
            <TextField
              id="outlined-rename-field"
              error={!!error && Array.isArray(error) && error.length > 0}
              label="Name"
              value={renameVal}
              onChange={onChange}
              helperText={error?.[0] || null}
            />
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="secondary"
          id={"dialog-cancel"}
        >
          {cancelMessage}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
            onConfirm(device.uuid, renameVal);
          }}
          id={"dialog-confirm"}
        >
          {actionName}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default RenameDeviceDialog;
