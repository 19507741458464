import _ from "lodash";
import { FORMAT_SPECIMENS } from "../actions/types";
import specimenReducer from "../reducers/specimenReducer";
import { orientationFlipped } from "./orientation";
import { camelify } from "./caseConvert";

export const formatTray = (old, payload) => {
  const { specimens: oldSpecimens } = old;
  const { specimens, ...tray } = payload;
  return _.defaultsDeep(
    {},
    {
      ...camelify(tray),
      displayName: `${tray?.displayName || tray.id}`,
      specimens: specimenReducer(oldSpecimens, {
        type: FORMAT_SPECIMENS,
        payload: specimens,
      }),
    },
    old
  );
};

export const formatSpecimen = (old, payload) => {
  const { ...specimen } = payload;

  return _.defaultsDeep({}, camelify({ ...specimen }, true), old);
};

export const modulo = (x, y) => ((x % y) + y) % y;

export const checkTrayValidity = (tray, side) => {
  // console.log(`checkTrayValidity: ${JSON.stringify(tray, null, 4)}, ${side}`);

  return (
    !!tray?.[`${side}Img`] &&
    Object.values(tray.specimens).reduce(
      (resp, s) =>
        resp &&
        !!s?.[side]?.imgPath &&
        (!!s?.[side]?.algorithmId?.genus ||
          s?.[side]?.algorithmId?.genus === "") &&
        (!!s?.[side]?.algorithmId?.species ||
          s?.[side]?.algorithmId?.species === "") &&
        (!!s?.[side]?.algorithmId?.speciesConfidence ||
          s?.[side]?.algorithmId?.speciesConfidence === 0),
      true
    )
  );
};

export const getRelevantSide = (tray, onerror = "front") => {
  if (checkTrayValidity(tray, "front")) {
    return "front";
  } else if (checkTrayValidity(tray, "back")) {
    return "back";
  }
  return onerror;
};

// export const checkTrayValidity = (tray, traySide) =>
//   tray?.[`${traySide}Img`] &&
//   Object.values(tray.specimens).reduce(
//     (isValid, { [traySide]: { imgPath, algorithmId } }) =>
//       isValid &&
//       imgPath &&
//       (algorithmId?.genus || algorithmId?.genus === "") &&
//       (algorithmId?.species || algorithmId?.species === "") &&
//       (algorithmId?.speciesConfidence || algorithmId?.speciesConfidence === 0),
//     true
//   );

export const unsetImages = (tray, traySide) => ({
  ..._.omit(tray, [`${traySide}Img`, "specimens"]),
  [`${traySide}Img`]: null,
  specimens: Object.fromEntries(
    Object.entries(tray.specimens).map(([key, specimen]) => {
      // const algId = specimen?.traySide?.algorithmId;
      // const { [traySide]: { algorithmId: algId }, ...other } = specimen;
      return [
        key,
        {
          ...specimen,
          [traySide]: { ...specimen[traySide], imgPath: null },
        },
      ];
    })
  ),
});

export const flipCaptureFail = (state) => {
  // if failing from a flipCapture, reflip the traySide and unset otherSideDone
  const newTrayValid = !!state?.otherSideDone;
  const newTray = newTrayValid
    ? unsetImages(state.tray, state.traySide)
    : state.tray;
  return {
    ...state,
    tray: newTray,
    traySide: orientationFlipped(state.traySide),
    trayValid: newTrayValid,
    otherSideDone: false,
    capturing: false,
  };
};

const reducerHelpers = {
  formatTray,
  formatSpecimen,
  modulo,
  getRelevantSide,
  checkTrayValidity,
  unsetImages,
  flipCaptureFail,
};

export default reducerHelpers;
