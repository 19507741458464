import {
  START_CAPTURE,
  FLIP_CAPTURE,
  RETAKE_CAPTURE,
  FINISH_CAPTURE,
  FLIP_TRAY,
  VIEW_TRAY_CAPTURE,
  TRAY_UPLOAD_CAPTURE,
  SPECIMEN_UPLOAD_CAPTURE,
  SPECIMEN_BATCH_UPLOAD_CAPTURE,
  UPDATE_SPECIMEN_FROM_METADATA,
  UPDATE_TRAY_FROM_METADATA,
  SET_METADATA_ERRORS,
  UPDATE_SPECIMEN_CAPTURE_METADATA,
  CLEAR_CAPTURE,
  VERIFY_TRAY,
} from "./types";

import _ from "lodash";

const DEBOUNCE_TIME = process.env.REACT_APP_TEST_STATUS === "test" ? 50 : 1000;

let currentSubmitTray = null;
export const startCapture = (deviceUuid) => async (dispatch) => {
  dispatch({
    type: START_CAPTURE,
    payload: { deviceUuid },
    socket: { send: true },
  });
};

export const retakeCapture =
  (toolId, trayId, orientation) => async (dispatch) => {
    dispatch({
      type: RETAKE_CAPTURE,
      payload: { toolId, trayId, orientation },
      socket: { send: true },
    });
  };

export const flipCapture =
  (toolId, trayId, orientation) => async (dispatch) => {
    dispatch({
      type: FLIP_CAPTURE,
      payload: { toolId, trayId, orientation },
      socket: { send: true },
    });
  };

export const finishCapture = (toolId, trayId) => async (dispatch) => {
  dispatch({
    type: FINISH_CAPTURE,
    payload: { toolId, trayId },
    socket: { send: true },
  });
};

export const uploadSpecimenMetadata =
  (trayId, specimenId, formValues) => async (dispatch) => {
    dispatch({
      type: SPECIMEN_UPLOAD_CAPTURE,
      payload: { specimenId, trayId, ...formValues },
      socket: { send: true },
    });
  };

export const uploadSpecimenBatchMetadata =
  (trayId, formValues) => async (dispatch) => {
    dispatch({
      type: SPECIMEN_BATCH_UPLOAD_CAPTURE,
      payload: { trayId, ...formValues },
      socket: { send: true },
    });
  };

export const uploadTrayMetadata = (trayId, formValues) => async (dispatch) => {
  if (currentSubmitTray === trayId) {
    debouncedTrayUpdate(trayId, formValues, dispatch);
  } else {
    debouncedTrayUpdate.flush();
    debouncedTrayUpdate(trayId, formValues, dispatch);
    currentSubmitTray = trayId;
  }
};

const debouncedTrayUpdate = _.debounce((trayId, formValues, dispatch) => {
  dispatch({
    type: TRAY_UPLOAD_CAPTURE,
    payload: { trayId, ...formValues },
    socket: { send: true },
  });
  currentSubmitTray = null;
}, DEBOUNCE_TIME);

export const flipTray = () => async (dispatch) => {
  dispatch({ type: FLIP_TRAY });
};

export const viewTray = (tray) => async (dispatch) => {
  dispatch({
    type: VIEW_TRAY_CAPTURE,
    payload: { trayId: tray.id, tray },
    socket: { send: true },
  });
};

export const updateSpecimenCaptureMetadata = (result) => (dispatch) => {
  dispatch({
    type: UPDATE_SPECIMEN_CAPTURE_METADATA,
    payload: { result },
  });
};

export const updateSpecimenFromMetadata =
  (trayId, selectedDevice, specimenNum, specimenId, result) =>
  async (dispatch) => {
    dispatch({
      type: UPDATE_SPECIMEN_FROM_METADATA,
      payload: { trayId, selectedDevice, specimenNum, specimenId, result },
    });
  };

export const updateTrayFromMetadata =
  (trayId, selectedDevice, result, trapSite) => async (dispatch) => {
    dispatch({
      type: UPDATE_TRAY_FROM_METADATA,
      payload: { trayId, selectedDevice, result, trapSite },
    });
  };

export const setMetadataErrors = (shouldSet) => async (dispatch) => {
  dispatch({
    type: SET_METADATA_ERRORS,
    payload: !!shouldSet,
  });
};

export const clearCapture = () => async (dispatch) => {
  dispatch({ type: CLEAR_CAPTURE });
};

export const checkOnCapture =
  (id, queryParams, sendRequest) => async (dispatch) => {
    const response = await sendRequest("get")({
      url: `/tray/${id}`,
      params: queryParams,
      devPort: 5005,
    });
    if (response.status >= 200 && response.status < 400) {
      dispatch({ type: VERIFY_TRAY, payload: response.data });
    }
  };
