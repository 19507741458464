import {
  UPDATE_TRAP_SITE_SETTINGS,
  UPDATE_TRAY_SETTINGS,
  FINISH_CAPTURE,
  SET_METADATA_ERRORS,
  SET_TRAP_SITE_LOADING,
  SET_TRAY_LOADING,
} from "../actions/types";
import _ from "lodash";

export const DEFAULT_DATA_SETTINGS = {
  Tray: {
    page: 0,
    pageSize: 15,
    orderBy: "createdOn",
    order: "desc",
    rowsPerPageOptions: [10, 15, 50],
    loading: false,
  },
  "Collection Site": {
    page: 0,
    pageSize: 15,
    orderBy: "displayName",
    order: "asc",
    rowsPerPageOptions: [10, 15, 50],
    loading: false,
  },
  displayMetadataErrors: false,
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = DEFAULT_DATA_SETTINGS, action) => {
  switch (action.type) {
    case UPDATE_TRAP_SITE_SETTINGS:
      return _.defaultsDeep({}, { "Collection Site": action.payload }, state);
    case UPDATE_TRAY_SETTINGS:
      return _.defaultsDeep({}, { Tray: action.payload }, state);
    case FINISH_CAPTURE:
      return { ...state, displayMetadataErrors: false };
    case SET_METADATA_ERRORS:
      return { ...state, displayMetadataErrors: action.payload };
    case SET_TRAP_SITE_LOADING:
      return _.defaultsDeep(
        {},
        { "Collection Site": { loading: action.payload } },
        state
      );
    case SET_TRAY_LOADING:
      return _.defaultsDeep({}, { Tray: { loading: action.payload } }, state);
    default:
      return state;
  }
};
