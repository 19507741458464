import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

const fontSx = {
  fontSize: ["0.75rem", "0.75rem", "0.75rem", "0.75rem", "1rem", "1rem"],
};

const MoveEnvDevice = ({
  actionName,
  device,
  active,
  setActive,
  onConfirm,
  cancelMessage = "Cancel",
}) => {
  const envOptions = [
    {
      value: "DEVELOPMENT",
      label: device?.env === "DEVELOPMENT" ? "dev (*)" : "dev",
    },
    {
      value: "STAGING",
      label: device?.env === "STAGING" ? "staging (*)" : "staging",
    },
    {
      value: "PRODUCTION",
      label: device?.env === "PRODUCTION" ? "production (*)" : "production",
    },
  ];

  const [moveVal, setMoveVal] = useState("");

  const handleClose = () => {
    setMoveVal("");
    setActive(null);
  };

  return !!active ? (
    <Dialog
      open={!!active}
      onClose={handleClose}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">{`Move ${device?.displayName} (${device?.uuid})`}</DialogTitle>
      <DialogContent>
        <Box
          component="form"
          sx={{
            "& .MuiTextField-root": { m: 1, width: "25ch" },
          }}
          noValidate
          autoComplete="off"
        >
          <div>
            <Typography sx={fontSx} className={"text-gray-600"}>
              Which environment should this device be moved to?
            </Typography>
            <TextField
              id="outlined-select-org"
              select
              label="New Env"
              value={moveVal}
              onChange={(event) => {
                setMoveVal(event.target.value);
              }}
            >
              {envOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          </div>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          onClick={handleClose}
          color="secondary"
          id={"dialog-cancel"}
        >
          {cancelMessage}
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleClose();
            onConfirm(moveVal);
          }}
          id={"dialog-confirm"}
        >
          {actionName}
        </Button>
      </DialogActions>
    </Dialog>
  ) : null;
};

export default MoveEnvDevice;
