import React from "react";
import {
    Box,
    Typography,
    Grid,
} from "@mui/material";

const HiddenField = (props) => {
    const {
        text,
        Name,
        display = false,
        isItalic = false,
    } = props

    return (
        <Grid container columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ display: display ? 'flex' : 'none' }}>
            <Grid item sx={6} ><Box><Typography sx={{ width: '10rem' }}>{Name}</Typography></Box></Grid>
            <Grid item sx={6}><Typography sx={{ fontStyle: isItalic ? 'italic' : 'normal' }}>{text}</Typography></Grid>
        </Grid>
    )

}

export default HiddenField;