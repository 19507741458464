import { UPDATE_TRAP_SITE_SETTINGS, UPDATE_TRAY_SETTINGS } from "./types";

export const updateDataTableSettings =
  (tableName, settings) => async (dispatch) => {
    switch (tableName) {
      case "Collection Site":
        dispatch({ type: UPDATE_TRAP_SITE_SETTINGS, payload: settings });
        break;
      case "Tray":
        dispatch({ type: UPDATE_TRAY_SETTINGS, payload: settings });
        break;
      default:
        return;
    }
  };
