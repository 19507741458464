import React, { useEffect, useState, useMemo } from "react";
import _, { flatten } from "lodash";
// eslint-disable-next-line
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "@mui/material";
import {
  fetchOrganizationFilters,
  updateGeneralFilters,
  clearGeneralFilters,
} from "../../../../actions";
import { useWebServer } from "../../../../providers";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import AutoCompleteField from "../../../Forms/AutoCompleteField";
import {
  TextField,
  CircularProgress,
  Chip,
  Grid,
  Box,
  Checkbox,
  FormGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
} from "@mui/material";
import { phraseDownloadDataFilter } from "../../../../utils/dataDownload";
// eslint-disable-next-line no-unused-vars
import {
  sexOptions,
  algorithmSexOptions,
  rawDataColumns,
  DEFAULT_ALG_CONFIDENCE_THRESHOLDS,
} from "../../../../utils/const";
import { useLocation } from "react-router-dom";
import SpeciesPieChart from "./SpeciesPieChart";
import RawDataPreview from "./RawDataPreview";
import AbundanceDataPreview from "./AbundanceDataPreview";
import ROUTES from "../../../AppRouter/routes";
import {
  ArrowBack as ArrowBackIcon,
  ArrowDropDown as ArrowDropDownIcon,
} from "@mui/icons-material";
import DownloadPreviewedData from "./DownloadPreviewedData";
import SpeciesStackedBarChart from "./SpeciesStackedBarChart";
import AbundanceLineGraph from "./AbundanceLineGraph";
import WeeklyReportPreview from "./WeeklyReportPreview";

const styleForDateTimePicker = {
  width: "100%",
  "& .MuiInputLabel-root": {
    fontSize: [".7rem", ".7rem", ".7rem", ".7rem", ".7rem", ".7rem"],
    transform: {
      md: "matrix(1, 0, 0, 1, 0, 15)",
      lg: "matrix(1, 0, 0, 1, 0, 20)",
    },
  },
  "& .MuiOutlinedInput-input": {
    fontSize: "12px",
  },
  "& .MuiInputLabel-shrink": {
    transform: {
      md: "matrix(0.75, 0, 0, 0.75, 0, -1.5)",
    },
  },
  "& .MuiInput-root": {
    mt: ["1rem", ".5rem", ".5rem", ".5rem", "1rem", "1rem"],
  },
  "& .MuiInput-input": {
    fontSize: ["1rem", ".66rem", ".66rem", ".66rem", "1rem", "1rem"],
  },
  "& .MuiInputAdornment-positionEnd": {
    mr: ["1rem", ".5rem", ".5rem", ".5rem", "1rem", "1rem"],
  },
  "& .MuiSvgIcon-root": {
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
  },
};

const checkboxSx = {
  "& .MuiTypography-root": {
    fontSize: ["1rem", ".66rem", ".66rem", ".66rem", "1rem", "1rem"],
  },
  "& .MuiSvgIcon-root": {
    fontSize: ["1rem", "1rem", "1rem", "1rem", "1.5rem", "1.5rem"],
  },
};

const Reports = ({
  fetchOrganizationFilters,
  timeZone,
  generalFilters,
  updateGeneralFilters,
  clearGeneralFilters,
  options,
}) => {
  const [loading, setLoading] = useState(false);
  const {
    sendRequest,
    downloadOrganizationDataWithRawData,
    downloadOrganizationDataWithAbundance,
    downloadOrganizationDataWithWeekly,
    downloadSpeciesLineChart,
  } = useWebServer();
  const [showReport, setShowReport] = useState(false);
  const [downloadedData, setDownloadedData] = useState(false);
  const location = useLocation();
  const [clickedButton, setClickedButton] = useState(null);

  useEffect(() => {
    fetchOrganizationFilters(sendRequest);
  }, [fetchOrganizationFilters, sendRequest]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const buttonParam = searchParams.get("button");
    if (buttonParam) {
      setClickedButton(buttonParam);
    }
  }, [location.search]);

  const [rawData, setRawData] = useState([]);

  const [shouldDownload, setShouldDownload] = useState(false);

  //raw data columns download
  const [columnVisibilityModel, setColumnVisibilityModel] = useState(
    rawDataColumns.reduce((json, value, key) => {
      json[value] = true;
      return json;
    }, {})
  );

  const onDownloadData = async ({ filters }, dataType = "data") => {
    setLoading(true);
    const response =
      dataType === "rawdata"
        ? await downloadOrganizationDataWithRawData({ filters })
        : dataType === "weeklyreport"
        ? await downloadOrganizationDataWithWeekly({ filters })
        : dataType === "abundance"
        ? await downloadOrganizationDataWithAbundance({ filters })
        : dataType === "weeklyReport"
        ? await downloadOrganizationDataWithWeekly({ filters })
        : await downloadSpeciesLineChart({ filters });
    setLoading(false);
    return response;
  };

  let filters = useMemo(
    () => phraseDownloadDataFilter(generalFilters, timeZone),
    [generalFilters, timeZone]
  );

  const [areFiltersActive, setAreFiltersActive] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const buttonType = params.get("button");
  const isChart = buttonType && buttonType.toLowerCase().includes("chart");

  useEffect(() => {
    const excludedKeys = [
      "excludeAlgorithmUnsure",
      "excludeEmpty",
      "excludeUnknown",
      "algThreshold",
    ];

    const hasActiveFilters = Object.entries(generalFilters)
      .filter(([key]) => !excludedKeys.includes(key)) // Exclude specific keys
      .some(([key, value]) => {
        if (Array.isArray(value)) {
          return value.length > 0;
        } else if (value === null || value === "") {
          return false;
        } else if (typeof value === "boolean") {
          return value === true;
        } else if (typeof value === "number") {
          return value > 0;
        } else if (value instanceof Date) {
          // Check if the Date is valid and not equal to an empty date
          return !isNaN(value.getTime());
        }
        return false;
      });
    setAreFiltersActive(hasActiveFilters);
    // console.log("filters are true", areFiltersActive);
  }, [generalFilters]);

  const onDownloadData2 = async (DownloadDataType) => {
    setShowReport(true);
    // console.log("filters selected", filters);
    const response = await onDownloadData({ filters }, DownloadDataType);
    // console.log(response.data);
    setRawData(response.data);
  };

  // const downloadRawDataFile = async () => {
  //     console.log('i am here')
  //     let filters = phraseDownloadDataFilter(generalFilters, timeZone);
  //     let dataUrl = await downloadOrganizationDataWithRawData({ filters: { ...filters, isDownload: true } })
  //     if (dataUrl?.data?.url) {
  //         const url = dataUrl.data.url;
  //         const fileName = 'rawData.xlsx';
  //         const a = document.createElement('a');
  //         a.href = url;
  //         a.download = fileName;
  //         document.body.appendChild(a);
  //         a.click();
  //         document.body.removeChild(a);
  //     }
  // }

  const renderReport = () => {
    switch (clickedButton) {
      case "Raw-Data":
        return (
          <div>
            <RawDataPreview
              data={rawData}
              onDownloadData={() => onDownloadData2("rawdata")}
              columnVisibilityModel={columnVisibilityModel}
              setColumnVisibilityModel={setColumnVisibilityModel}
              filters={filters}
              loading={loading}
              timeZone={timeZone}
            />
          </div>
        );
      case "Species-Summary-Among-Collections":
        return (
          <div>
            <AbundanceDataPreview
              data={rawData}
              onDownloadData={() => onDownloadData2("abundance")}
              filters={filters}
              loading={loading}
            />
          </div>
        );
      case "Weekly-Species-report":
        return (
          <div>
            <WeeklyReportPreview
              data={rawData}
              onDownloadData={() => onDownloadData2("weeklyreport")}
              filters={filters}
              loading={loading}
            />
          </div>
        );
      case "Pie-chart":
        return <SpeciesPieChart />;
      case "Linegraph-chart":
        return (
          <div>
            <AbundanceLineGraph
              data={rawData}
              onDownloadData={() => onDownloadData2("linechart")}
              filters={filters}
              loading={loading}
            />
          </div>
        );
      case "Bar-chart":
        return (
          <div>
            <SpeciesStackedBarChart
              data={rawData}
              onDownloadData={() => onDownloadData2("abundance")}
              filters={filters}
              loading={loading}
            />
          </div>
        );
      default:
        return <div>No report available yet</div>;
    }
  };

  const downloadReport = () => {
    switch (clickedButton) {
      case "Raw-Data":
        return (
          <div>
            <DownloadPreviewedData
              data={rawData}
              onDownloadData={() => onDownloadData2("rawdata")}
              filters={filters}
              loading={loading}
              shouldDownload={shouldDownload}
              onDownloadComplete={handleDownloadComplete}
              type={"raw"}
              title={"Raw-Data"}
              downloadColumns={columnVisibilityModel}
              // timeZone={timeZone}
            />
          </div>
        );
      case "Species-Summary-Among-Collections":
        return (
          <div>
            <DownloadPreviewedData
              data={rawData}
              onDownloadData={() => onDownloadData2("abundance")}
              disableDownloadButton={loading}
              filters={filters}
              shouldDownload={shouldDownload}
              onDownloadComplete={handleDownloadComplete}
              type={"Species-Summary-Among-Collections"}
              title={""}
            />
          </div>
        );

      case "Weekly-Species-report":
        return (
          <div>
            <DownloadPreviewedData
              data={rawData}
              onDownloadData={() => onDownloadData2("weeklyreport")}
              disableDownloadButton={loading}
              filters={filters}
              shouldDownload={shouldDownload}
              onDownloadComplete={handleDownloadComplete}
              type={"weekly"}
              title={"Weekly-Species"}
            />
          </div>
        );

      default:
        return <div>No Data</div>;
    }
  };

  const {
    algorithmGenusSpeciesOptions,
    contributedGenusSpeciesOptions,
    contributedPlatesOptions,
    trapTypesOptions,
    deviceOptions,
    trapSiteOptions,
    usersOptions,
    tagsOptions,
  } = options;

  const handleGenerateReport = () => {
    setShowReport(true);
    setIsDropdownVisible(false);
  };

  const handleDownloadReport = () => {
    // setDownloadedData(true);
    setShouldDownload(true);
  };

  const handleDownloadComplete = () => {
    setShouldDownload(false); // Reset the flag after download
  };

  const {
    deviceID,
    imagingStartDate,
    imagingEndDate,
    specimenCaptureStartDate,
    specimenCaptureEndDate,
    genusSpecies,
    plates,
    trapSitesFilter,
    trapTypesFilter,
    algorithmGenusSpeciesFilter,
    sexFilter,
    // eslint-disable-next-line no-unused-vars
    algorithmSexFilter,
    usersFilter,
    specimenTagFilter,
    excludeEmpty,
    excludeUnknown,
    excludeAlgorithmUnsure,
  } = generalFilters;
  const setDeviceID = (val) => updateGeneralFilters({ deviceID: val });
  const setImagingStartDate = (val) =>
    updateGeneralFilters({ imagingStartDate: val });
  const setImagingEndDate = (val) =>
    updateGeneralFilters({ imagingEndDate: val });
  const setSpecimenCaptureStartDate = (val) =>
    updateGeneralFilters({ specimenCaptureStartDate: val });
  const setSpecimenCaptureEndDate = (val) =>
    updateGeneralFilters({ specimenCaptureEndDate: val });
  const setGenusSpecies = (val) => updateGeneralFilters({ genusSpecies: val });
  const setPlates = (val) => updateGeneralFilters({ plates: val });
  const setTrapSitesFilter = (val) =>
    updateGeneralFilters({ trapSitesFilter: val });
  const setTrapTypesFilter = (val) =>
    updateGeneralFilters({ trapTypesFilter: val });
  const setAlgorithmGenusSpeciesFilter = (val) =>
    updateGeneralFilters({ algorithmGenusSpeciesFilter: val });
  const setUsersFilter = (val) => updateGeneralFilters({ usersFilter: val });
  const setSexFilter = (val) => updateGeneralFilters({ sexFilter: val });
  // eslint-disable-next-line no-unused-vars
  const setAlgorithmSexFilter = (val) =>
    updateGeneralFilters({ algorithmSexFilter: val });
  const setSpecimenTagFilter = (val) =>
    updateGeneralFilters({ specimenTagFilter: val });
  const setExcludeEmpty = () =>
    updateGeneralFilters({ excludeEmpty: !excludeEmpty });
  const setExcludeUnknown = () =>
    updateGeneralFilters({ excludeUnknown: !excludeUnknown });
  const setExcludeAlgorithmUnsure = () =>
    updateGeneralFilters({ excludeAlgorithmUnsure: !excludeAlgorithmUnsure });

  // eslint-disable-next-line
  const hasNoFilters =
    imagingStartDate === null &&
    imagingEndDate === null &&
    specimenCaptureStartDate === null &&
    specimenCaptureEndDate === null &&
    deviceID.length === 0 &&
    genusSpecies.length === 0 &&
    plates.length === 0 &&
    trapSitesFilter.length === 0 &&
    trapTypesFilter.length === 0 &&
    algorithmGenusSpeciesFilter.length === 0 &&
    usersFilter.length === 0 &&
    sexFilter.length === 0 &&
    specimenTagFilter.length === 0;

  const computeHelperText = (props, error, startDate, endDate) => {
    if (error) {
      if (startDate > endDate) {
        if (props?.label === "Start Datetime")
          return "Date should not be after maximal date";
        else return "Date should not be before minimal date";
      }
      if (props?.inputProps?.value?.length === "mm/dd/yyyy hh:mm".length) {
        return "Invalid Date Format";
      } else {
        return "mm/dd/yyyy hh:mm";
      }
    }
    return "";
  };

  // useEffect(() => {
  //     return () => {
  //         clearGeneralFilters();
  //     };
  // }, [clearGeneralFilters]);

  const history = useHistory();

  const handleGoBack = () => {
    history.push(ROUTES.dataTab);
  };

  const [isDropdownVisible, setIsDropdownVisible] = useState(true);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  const getTitle = (buttonType) => {
    if (buttonType.includes("Raw")) {
      return "RAW DATA";
    } else if (buttonType.includes("Summary-Among-Collections")) {
      return "SPECIES SUMMARY AMONG COLLECTION SITES";
    } else if (buttonType.includes("Weekly")) {
      return "WEEKLY SPECIES REPORT";
    } else if (buttonType.includes("Pie")) {
      return "SPECIES SUMMARY";
    } else if (buttonType.includes("Bar")) {
      return "SPECIES SUMMARY AMONG COLLECTION SITES";
    } else if (buttonType.includes("Line")) {
      return "SPECIES ABUNDANCE BY MONTH";
    }
    return "REPORTS";
  };

  const title = getTitle(buttonType);

  return (
    <div className="flex-auto">
      <div
        style={{
          display: "flex",
          marginTop: "30px",
          flexDirection: "column",
          color: "#3f51b5",
          fontFamily: "Roboto",
          maxWidth: "100vw",
          width: "100%",
        }}
      >
        {/* Title */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginTop: "5px",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <div style={{ marginLeft: "30px" }}>
            <Button onClick={handleGoBack} style={{ cursor: "pointer" }}>
              <ArrowBackIcon fontSize="medium" />
            </Button>
          </div>
          <div
            style={{
              // marginLeft: "auto",
              // marginRight: "auto",
              // marginBottom: "20px",
              // fontSize: "20px",
              // fontWeight: "500",
              flex: 1,
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "500",
              marginRight: "160px",
            }}
          >
            {/* {" "} */}
            {title}
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "10px",
            marginLeft: "50px",
            marginTop: "20px",
          }}
        >
          {/* Imaging Date selection */}
          <div style={{ flexBasis: "35%", margin: "auto" }}>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              IMAGING DATE:
            </div>
            <Grid container spacing={3}>
              <Grid item md={5} lg={5}>
                <DateTimePicker
                  autoOk
                  style={{ maxWidth: "4rem" }}
                  slotProps={{ textField: { size: "small" } }}
                  ampm={true}
                  value={imagingStartDate}
                  onChange={setImagingStartDate}
                  inputFormat="MM/dd/yyyy HH:mm"
                  label="Start Datetime"
                  mask="__/__/____ __:__"
                  maxDate={imagingEndDate}
                  showToolbar
                  renderInput={(props) => (
                    <TextField
                      data-testid="Imaging Start Datetime"
                      label="Start Datetime"
                      variant="standard"
                      {...props}
                      helperText={computeHelperText(
                        props,
                        props.error,
                        imagingStartDate,
                        imagingEndDate
                      )}
                      style={{ paddingRight: 10 }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5} lg={5}>
                <DateTimePicker
                  style={{ maxWidth: "4rem" }}
                  slotProps={{ textField: { size: "small" } }}
                  label="End Datetime"
                  value={imagingEndDate}
                  onChange={setImagingEndDate}
                  inputFormat="MM/dd/yyyy HH:mm"
                  mask="__/__/____ __:__"
                  minDate={imagingStartDate}
                  showToolbar={true}
                  renderInput={(props) => (
                    <TextField
                      data-testid="Imaging End Datetime"
                      label="End Datetime"
                      variant="standard"
                      sx={styleForDateTimePicker}
                      {...props}
                      helperText={computeHelperText(
                        props,
                        props.error,
                        imagingStartDate,
                        imagingEndDate
                      )}
                      style={{ paddingRight: 10 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
          {/* Specimen Capture date selection  */}
          <div style={{ flexBasis: "35%", margin: "auto" }}>
            <div
              style={{
                fontSize: "12px",
                fontWeight: "500",
                marginBottom: "5px",
              }}
            >
              SPECIMEN CAPTURE DATE:
            </div>
            <Grid container spacing={3}>
              <Grid item md={5} lg={5}>
                <DateTimePicker
                  autoOk
                  style={{ maxWidth: "4rem" }}
                  slotProps={{ textField: { size: "small" } }}
                  ampm={true}
                  value={specimenCaptureStartDate}
                  onChange={setSpecimenCaptureStartDate}
                  inputFormat="mm/dd/yyyy HH:mm"
                  label="Start Datetime"
                  mask="__/__/____ __:__"
                  maxDate={specimenCaptureEndDate}
                  showToolbar
                  renderInput={(props) => (
                    <TextField
                      data-testid="Specimen Capture Start Datetime"
                      label="Start Datetime"
                      variant="standard"
                      sx={styleForDateTimePicker}
                      {...props}
                      helperText={computeHelperText(
                        props,
                        props.error,
                        specimenCaptureStartDate,
                        specimenCaptureEndDate
                      )}
                      style={{ paddingRight: 10 }}
                    />
                  )}
                />
              </Grid>
              <Grid item md={5} lg={5}>
                <DateTimePicker
                  style={{ maxWidth: "10rem" }}
                  slotProps={{ textField: { size: "small" } }}
                  label="End Datetime"
                  value={specimenCaptureEndDate}
                  onChange={setSpecimenCaptureEndDate}
                  inputFormat="MM/dd/yyyy HH:mm"
                  mask="__/__/____ __:__"
                  minDate={specimenCaptureStartDate}
                  showToolbar={true}
                  renderInput={(props) => (
                    <TextField
                      data-testid="Specimen Capture End Datetime"
                      label="End Datetime"
                      variant="standard"
                      sx={styleForDateTimePicker}
                      {...props}
                      helperText={computeHelperText(
                        props,
                        props.error,
                        specimenCaptureStartDate,
                        specimenCaptureEndDate
                      )}
                      style={{ paddingRight: 10 }}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </div>
          <div style={{ flexBasis: "30%", justifyContent: "flex-start" }}>
            {/* Reset button  */}
            <Box
              className={"flex"}
              sx={{
                mt: ["1.5rem", "1.5rem", ".75rem", "1.5rem", "1.5rem"],
              }}
            >
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  clearGeneralFilters();
                }}
                id={"reset-data-download-filters"}
                color={"primary"}
                variant={"outlined"}
                size={"small"}
                sx={{ marginRight: "5px" }}
                disabled={!areFiltersActive}
              >
                <div style={{ fontSize: "12px", padding: "0 8px" }}>Reset</div>
              </Button>

              <Button
                onClick={toggleDropdown} // Handle the dropdown toggle
                id={"toggle-filters-dropdown"}
                color={"primary"}
                variant={"outlined"}
                size={"small"}
              >
                <span style={{ fontSize: "12px", padding: "0 10px" }}>
                  {isDropdownVisible ? "HIDE FILTERS" : "SHOW FILTERS"}
                </span>
                <ArrowDropDownIcon
                  style={{
                    cursor: "pointer",
                    transform: isDropdownVisible
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    fontSize: "20px",
                  }}
                />
              </Button>
            </Box>
          </div>
        </div>

        {isDropdownVisible && (
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              style={{
                flexBasis: "85%",
                marginLeft: "50px",
                display: "flex",
                justifyContent: "flex-start",
                flexWrap: "wrap",
              }}
            >
              <AutoCompleteField
                label={"Device"}
                disableCloseOnSelect
                value={deviceID}
                onChange={(e, newValue) => {
                  setDeviceID(newValue);
                }}
                // onChange={handleDeviceIDChange}
                getOptionLabel={(option) => option.displayName}
                multiple
                options={deviceOptions}
                isOptionEqualToValue={(option, value) => {
                  return option.id === value.id;
                }}
                style={{ flexBasis: "20%", padding: "5px" }}
              />
              <AutoCompleteField
                label={"Contributed Species"}
                value={genusSpecies}
                disableCloseOnSelect
                onChange={(e, newValue) => {
                  setGenusSpecies(newValue);
                }}
                getOptionLabel={(option) =>
                  `${option.name?.genus} ${option.name?.species}`
                }
                multiple
                options={contributedGenusSpeciesOptions}
                style={{ flexBasis: "20%", padding: "5px" }}
              />
              <AutoCompleteField
                label={"Algorithm Species"}
                value={algorithmGenusSpeciesFilter}
                disableCloseOnSelect
                onChange={(e, newValue) => {
                  setAlgorithmGenusSpeciesFilter(newValue);
                }}
                getOptionLabel={(option) =>
                  `${option.name.genus} ${option.name.species}`
                }
                multiple
                options={algorithmGenusSpeciesOptions}
                style={{ flexBasis: "20%", padding: "5px" }}
              />

              <AutoCompleteField
                label={"Plates"}
                value={plates}
                disableCloseOnSelect
                onChange={(e, newValue) => {
                  setPlates(newValue);
                }}
                getOptionLabel={(option) => option}
                multiple
                options={contributedPlatesOptions}
                style={{ flexBasis: "20%", padding: "5px" }}
              />
              <AutoCompleteField
                label={"Contributed Sex"}
                disableCloseOnSelect
                value={sexFilter}
                onChange={(e, newValue) => {
                  setSexFilter(newValue);
                }}
                getOptionLabel={(option) => option}
                multiple
                options={sexOptions}
                style={{ flexBasis: "20%", padding: "5px" }}
              />
              <AutoCompleteField
                label={"Specimens and Tray Tag"}
                value={specimenTagFilter}
                disableCloseOnSelect
                onChange={(e, newValue) => {
                  setSpecimenTagFilter(newValue);
                }}
                getOptionLabel={(option) => option}
                multiple
                freeSolo
                options={tagsOptions}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      label={option}
                      {...getTagProps({ index })}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="filled"
                    label="freeSolo"
                    placeholder="Favorites"
                  />
                )}
                style={{ flexBasis: "20%", padding: "5px" }}
              />

              <AutoCompleteField
                label={"Collection Sites"}
                disableCloseOnSelect
                value={trapSitesFilter}
                onChange={(e, newValue) => {
                  setTrapSitesFilter(newValue);
                }}
                getOptionLabel={(option) => option.displayName}
                multiple
                options={trapSiteOptions}
                style={{ flexBasis: "20%", padding: "5px" }}
              />
              <AutoCompleteField
                label={"Collection Methods"}
                value={trapTypesFilter}
                disableCloseOnSelect
                onChange={(e, newValue) => {
                  setTrapTypesFilter(newValue);
                }}
                getOptionLabel={(option) => option}
                multiple
                options={trapTypesOptions}
                style={{ flexBasis: "20%", padding: "5px" }}
              />
              <AutoCompleteField
                label={"Users"}
                value={usersFilter}
                disableCloseOnSelect
                onChange={(e, newValue) => {
                  setUsersFilter(newValue);
                }}
                getOptionLabel={(option) => option}
                multiple
                options={usersOptions}
                style={{ flexBasis: "20%", padding: "5px" }}
              />
            </div>
            {/* Checkbox selection  */}
            <div style={{ flexBasis: "15%", marginLeft: "10px" }}>
              <FormControl component="fieldset">
                <FormLabel component="legend">
                  <div style={{ color: "#3f51b5", marginTop: "20px" }}>
                    EXCLUDE
                  </div>
                </FormLabel>
                <FormGroup>
                  <FormControlLabel
                    checked={excludeEmpty}
                    value="empty"
                    control={<Checkbox />}
                    label="EMPTY"
                    sx={checkboxSx}
                    onClick={() => setExcludeEmpty()}
                  />
                  <FormControlLabel
                    checked={excludeUnknown}
                    value="unknown"
                    control={<Checkbox />}
                    label="UNKNOWN"
                    sx={checkboxSx}
                    onClick={() => setExcludeUnknown()}
                  />
                  {/* <FormControlLabel
                                        checked={excludeAlgorithmUnsure}
                                        value="Algorithm Unsure"
                                        control={<Checkbox />}
                                        label="ALGORITHM UNSURE"
                                        sx={checkboxSx}
                                        onClick={() => setExcludeAlgorithmUnsure()}
                                    /> */}
                </FormGroup>
              </FormControl>
            </div>
          </div>
        )}
      </div>

      {/* PREVIEW REPORT  */}
      <div>
        <div style={{ marginTop: "5px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "50px",
              marginTop: "20px",
              marginBottom: "20px",
              // marginLeft: "50px",
              // marginRight: '50px'
            }}
          >
            {isChart ? (
              <Button
                variant={"contained"}
                color={"primary"}
                style={{
                  fontSize: "12px",
                  marginLeft: "50px",
                }}
                onClick={handleGenerateReport}
              >
                Generate Chart
              </Button>
            ) : (
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ fontSize: "12px" }}
                onClick={handleGenerateReport}
              >
                Generate Report
              </Button>
            )}
            {isChart ? (
              <div></div>
            ) : (
              // <Button
              //     variant={"contained"}
              //     color={"primary"}
              //     style={{ fontSize: "12px" }}
              //     onClick={handleDownloadReport}
              // >
              //     Download Chart
              // </Button>
              <Button
                variant={"contained"}
                color={"primary"}
                style={{ fontSize: "12px" }}
                onClick={handleDownloadReport}
              >
                Download Report
              </Button>
            )}
            <div>{shouldDownload && downloadReport()}</div>
          </div>

          <hr
            style={{
              border: "none",
              borderTop: "2px dotted #8e9eab",
              margin: "5px 0",
              height: "1px",
            }}
          />
        </div>
        {/* REPORT  */}
        <div
          style={{
            textAlign: "center",
            fontWeight: "500",
            color: "#3f51b5",
            marginTop: "30px",
            justifyContent: "center",
            maxWidth: "100%",
          }}
        >
          <div
            style={{
              alignItems: "center",
              maxWidth: "95vw",
            }}
          >
            {showReport && renderReport()}
            {loading && (
              <div
                style={{
                  position: "absolute",
                  top: showReport ? "60%" : "50%",
                  left: "50%",
                  transform: "translate(-50%, -50%)",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  zIndex: 1000, // Ensure spinner is on top of other content
                  backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: adds a semi-transparent background
                  borderRadius: "4px", // Optional: for a rounded look
                  padding: "10px", // Optional: adds padding around the spinner
                }}
              >
                <CircularProgress size={"2rem"} />
                <span>Loading...</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export const mapStateToProps = (state) => {
  const { selectedDevice, ...devices } = state.devices;
  const { count, ...trapSites } = state.trapSites;
  const {
    contributedGenusSpecies = [],
    plateWells = {},
    trapTypes: trapTypes = [],
    algorithmGenusSpecies = [],
    users = [],
    tags: tagsOptions = [],
  } = state.organization;
  const contributedPlates = Object.keys(
    _.pickBy(plateWells, (plate, wells) => wells.length > 0)
  );
  const { timeZone } = state.user;

  const curDevices = Object.values(
    _.pickBy(
      devices,
      (value, _) => value?.env === process.env.REACT_APP_ENV.toUpperCase()
    )
  );

  //get all traptypes from the current trays
  let trayTypesAll = Object.values(state?.devices)?.reduce(
    (accumulator, cur) => {
      if (cur?.trays) {
        let res = Object.values(cur?.trays)?.reduce((acc, curTray) => {
          if (curTray?.trapType) {
            return [...acc, curTray?.trapType];
          }
          return acc;
        }, []);
        return [...accumulator, ...res];
      }
      return accumulator;
    },
    []
  );

  //get all tags from the current trays
  let tagsAll = Object.values(state?.devices)?.reduce((accumulator, cur) => {
    if (cur?.trays) {
      let res = Object.values(cur?.trays)?.reduce((acc, curTray) => {
        if (curTray?.tags) {
          return [...acc, curTray?.tags];
        }
        return acc;
      }, []);
      return [...accumulator, ...res];
    }
    return accumulator;
  }, []);

  const options = {
    deviceOptions: curDevices,
    trapSiteOptions: Object.values(trapSites),
    contributedGenusSpeciesOptions: contributedGenusSpecies,
    contributedPlatesOptions: contributedPlates,
    algorithmGenusSpeciesOptions: algorithmGenusSpecies,
    usersOptions: users,
    trapTypesOptions: Array.from(new Set([...trayTypesAll, ...trapTypes])).map(
      (x) => `${x}`
    ),
    tagsOptions: Array.from(new Set([...flatten(tagsAll), ...tagsOptions])),
  };

  return {
    devices: curDevices,
    options,
    selectedDevice,
    timeZone,
    generalFilters: {
      ...state.data.generalFilters,
      algThreshold: state.user.algConfidence
        ? state.user.algConfidence
        : DEFAULT_ALG_CONFIDENCE_THRESHOLDS.default,
    },
  };
};

// const mapDispatchToProps = (dispatch) => ({
//     fetchOrganizationFilters,
//     updateGeneralFilters: (val) => dispatch(updateGeneralFilters(val)),
//     clearGeneralFilters,
// });

export default connect(
  mapStateToProps,
  // mapDispatchToProps,
  {
    fetchOrganizationFilters,
    updateGeneralFilters,
    clearGeneralFilters,
  }
)(Reports);
