import {
  CREATE_TRAY,
  EDIT_TRAY,
  FETCH_TRAY,
  FETCH_TRAYS,
  DELETE_TRAY,
  UPDATE_SPECIMEN_FROM_METADATA,
  UPDATE_TRAY_FROM_METADATA,
  UPDATE_SPECIMEN_AUTO_METADATA,
} from "../actions/types";
import _ from "lodash";
import { formatTray } from "../utils/reducerHelpers";
import { arrayDefaultDeep } from "../utils/defaultDeep";

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (state = {}, action) => {
  const trayCount = state?.count || 0;
  let old;
  switch (action.type) {
    case FETCH_TRAYS:
      return {
        ...state,
        ..._.mapKeys(
          action.payload?.trays?.map((e) =>
            formatTray({ ...state?.[e?.id] }, e)
          ),
          "id"
        ),
        count: action.payload?.count,
      };
    case CREATE_TRAY:
      return {
        ...state,
        [action.payload?.id]: formatTray({}, action.payload),
        count: trayCount + 1,
      };
    case FETCH_TRAY:
    case EDIT_TRAY:
      old = { ...state?.[action.payload?.id] };
      return {
        ...state,
        [action.payload?.id]: formatTray(old, action.payload),
        count: !state[action?.payload?.id] ? trayCount + 1 : trayCount
      };
    case DELETE_TRAY:
      return {
        ..._.omit(state, action.payload),
        count: Math.max(trayCount - 1, 0),
      };
    case UPDATE_SPECIMEN_FROM_METADATA:
      if (!state.hasOwnProperty(action.payload?.trayId)) {
        return state;
      }
      return arrayDefaultDeep(
        {},
        {
          [action.payload?.trayId]: {
            specimens: {
              [action.payload?.specimenNum]: {
                institutionId: action.payload.result?.institutionId,
                tags: action.payload.result?.tags,
                humanId: {
                  genus: action.payload.result?.genus,
                  species: action.payload.result?.species,
                  sex: action.payload.result?.sex,
                  specimenId: action.payload?.specimenId,
                  gonotrophic: action.payload.result?.gonotrophic,
                  lifeStage: action.payload.result?.lifeStage
                },
                molecularId: {
                  specimenId: action.payload?.specimenId,
                  plate: action.payload.result?.plate,
                  well: action.payload.result?.well,
                },
              },
            },
          },
        },
        state
      );
    case UPDATE_SPECIMEN_AUTO_METADATA:
      return _.defaultsDeep(
        {},
        {
          [action.payload?.trayId]: {
            specimens: {
              ...action.payload?.result,
            },
          },
        },
        state
      );
    case UPDATE_TRAY_FROM_METADATA:
      if (!state.hasOwnProperty(action.payload?.trayId)) {
        return state;
      }
      const trapSite =
        action.payload.trapSite ||
        {};
      return arrayDefaultDeep(
        {},
        { [action.payload?.trayId]: { ...action.payload?.result } },
        {
          ...state,
          ...{
            [action.payload?.trayId]: {
              ...state?.[action.payload?.trayId],
              trapSiteV1: trapSite,
            },
          },
        }
      );
    default:
      return state;
  }
};
