// lodash default deep is not working with array, it will mmerge array

import _ from 'lodash'

export function arrayDefaultDeep() {
    let output = {};
    _.toArray(arguments).reverse().forEach(item => {
        _.mergeWith(output, item, (objectValue, sourceValue) => {
            return _.isArray(sourceValue) ? sourceValue : undefined;
        });
    });
    return output;
}