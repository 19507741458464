import {
  CREATE_ORGANIZATION,
  UPDATE_ORGANIZATION,
  FETCH_ORGANIZATION_FILTERS,
  FETCH_PLATE_WELLS,
  UPDATE_SPECIMEN_TAG_OPTIONS,
  UPDATE_ALGORITHM_CONFIDENCE
} from "./types";

import {
  displaySuccessNotification,
  displayErrorNotification,
} from "./notifications";

export const createOrganization =
  (sendRequest, uuid, address, gps, name, vectortypes) => async (dispatch) => {
    const res = await sendRequest("post")({
      url: `/org`,
      devPort: 5005,
      data: { uuid, address, gps, name, vectortypes },
    }).catch((err) => {
      dispatch(displayErrorNotification("Failed to create org..."));
    });
    if (res?.status === 200) {
      dispatch({
        type: CREATE_ORGANIZATION,
        payload: res.data,
      });

      dispatch(
        displaySuccessNotification(`Successfully created organization ${uuid}`)
      );
    }
  };

export const updateOrganization =
  (sendRequest, uuid, address, gps, name, vectortypes) => async (dispatch) => {
    const res = await sendRequest("put")({
      url: `/org`,
      devPort: 5005,
      data: { uuid, address, gps, name, vectortypes },
    }).catch((err) => {
      dispatch(displayErrorNotification("Failed to create org..."));
    });
    if (res?.status === 200) {
      dispatch({
        type: UPDATE_ORGANIZATION,
        payload: res.data,
      });

      dispatch(
        displaySuccessNotification(`Successfully created organization ${uuid}`)
      );
    }
  };

export const fetchOrganizationFilters = (sendRequest) => async (dispatch) => {
  const response = await sendRequest("get")({
    url: "/organization/filters",
    isOrganizationAPI: true,
  });
  if (response) {
    let body = response?.data;
    if (body?.body) {
      body = body.body;
      if (typeof body === "string") {
        body = JSON.parse(body);
      }
    }
    // const data = JSON.parse(body);
    dispatch({ type: FETCH_ORGANIZATION_FILTERS, payload: body });
  }
};

export const fetchPlateWells = (plate, sendRequest) => async (dispatch) => {
  if (plate) {
    const response = await sendRequest("get")({
      url: `/organization/plate/${plate}/wells`,
      isOrganizationAPI: true,
    });
    dispatch({
      type: FETCH_PLATE_WELLS,
      debug: "fetch",
      payload: { plate, wells: response.data },
    });
  }
};

export const updatePlateWells = (plate, wells) => async (dispatch) => {
  dispatch({
    type: FETCH_PLATE_WELLS,
    debug: "update",
    payload: { plate, wells },
  });
};

export const updateSpecimenTagOptions = (tags) => async (dispatch) => {
  dispatch({
    type: UPDATE_SPECIMEN_TAG_OPTIONS,
    payload: tags,
  });
};

export const updateAlgConfidenceSettings = (vectorType, confidence) => async (dispatch) => {
  console.log('i got called')
  dispatch({
    type: UPDATE_ALGORITHM_CONFIDENCE,
    payload: {
      vectorType, confidence
    }
  });
}